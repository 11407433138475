define("apollo/pods/components/transport/custom-fields-section/component", ["exports", "apollo/mixins/transport-form-mixin"], function (_exports, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    transport: Ember.computed(function () {
      return null;
    }),
    adviceSection: Ember.computed(function () {
      return false;
    }),

    /**
     * Metoda wykonująca się po zainicjowaniu komponentu.
     *
     * W niektórych typach transportu jest kilka takich komponentów w formularzu (sekcja lewa i prawa, protokół rozbieżności).
     * Chcemy więc wykonać inicjację custom-fields tylko raz po otwarciu formularza -- stąd flaga `hasCustomFieldsInitialized`.
     *
     * Wykonanie w trybie `once` służy głównie temu, by wykonać operację po wyrenderowaniu komponentu.
     * Moglibyśmy pewnie użyć run.next(), ale run.once() daje nam pewność (?), że operacja wykona się tylko raz w danym wywołaniu komponentu
     * (a więc i tak wykona się kilka razy dla każdego formularza, ale za to w odpowiednim momencie).
     */
    init() {
      this._super(...arguments);

      const self = this;
      const transport = this.get('transport');
      Ember.run.once(() => {
        if (this.get('transport.id')) {
          if (transport.get('hasCustomFieldsInitialized')) {
            console.debug(`Lista custom fields została już zainicjowana dla transportu ${transport.get('logString')}; nie robimy tego ponownie.`);
            return;
          }

          self.initializeCustomFields(transport);
        } else {
          transport.get('customFields').then(() => self.createMissingCustomFieldValues(transport));
        }

        transport.set('hasCustomFieldsInitialized', true);
      });
    },

    shouldTakePairsFromSection: Ember.computed('adviceSection', 'discrepancyReportSection', 'inspectionPanelSection', 'weightSection', function () {
      // Sekcje to są argumenty podane w template komponentu, któ©y wywołuje ten komponent np:
      // {{transport/custom-fields-section transport=transport withCurrentTaskBlockade=true weightSection=true isRequiredInCaseOfDelay=true}}
      // wyświetli wszystkie potrzebne cf z `withCurrentTaskBlockade`, 'weightSection' i `isRequiredInCaseOfDelay`
      const sections = Ember.A([this.get('adviceSection'), this.get('discrepancyReportSection'), this.get('inspectionPanelSection'), this.get('weightSection'), this.get('withCurrentTaskBlockade')]);
      return sections.includes(true);
    }),
    customFieldPairs: Ember.computed('transport.customFieldPairs.{@each.definition,@each.value}', function () {
      const self = this; // Zbieramy wszystkie potrzebne cfy i wyświetlamy je w jednym modalu

      let cfPairs = Ember.A();

      if (this.get('transport.customFieldPairs') === undefined) {
        return;
      }

      const customFieldPairs = this.get('transport.customFieldPairs').filter(pair => {
        return !pair.value || self.shouldBeVisible(pair);
      });

      if (this.shouldTakePairsFromSection) {
        if (this.get('adviceSection')) {
          const cfPairsFromAdviceSection = customFieldPairs.filter(field => {
            return field.definition.get('visibleInAdviceSection') === true;
          });
          cfPairs = Ember.A([...cfPairs, ...cfPairsFromAdviceSection]);
        }

        if (this.get('discrepancyReportSection')) {
          const cfPairsFromDiscrepanyReportSection = customFieldPairs.filter(field => {
            return field.definition.get('visibleInDiscrepancyReportSection') === true;
          });
          cfPairs = Ember.A([...cfPairs, ...cfPairsFromDiscrepanyReportSection]);
        }

        if (this.get('inspectionPanelSection')) {
          let cfPairsFromInspectionPanelSection;
          const transportPanelActive = customFieldPairs.any(field => {
            return field.value && field.value.get('option.allowsInspectionPanel');
          });

          if (!transportPanelActive) {
            // Jeśli nie uzupełniono CF z powiązaniem z sekcją "Kontrola", wyświetlamy tylko CF z tym powiązaniem
            cfPairsFromInspectionPanelSection = customFieldPairs.filter(field => {
              return field.definition.get('visibleInInspectionPanelSection') === true && field.definition.get('options').any(option => option.allowsInspectionPanel);
            });
            cfPairs = Ember.A([...cfPairs, ...cfPairsFromInspectionPanelSection]);
          }

          cfPairsFromInspectionPanelSection = customFieldPairs.filter(field => {
            return field.definition.get('visibleInInspectionPanelSection') === true;
          });
          cfPairs = Ember.A([...cfPairs, ...cfPairsFromInspectionPanelSection]);
        }

        if (this.get('weightSection')) {
          const cfPairsFromWeightSection = customFieldPairs.filter(field => {
            return field.definition.get('visibleInWeightSection') === true;
          });
          cfPairs = Ember.A([...cfPairs, ...cfPairsFromWeightSection]);
        }

        if (this.get('withCurrentTaskBlockade')) {
          const firstUnconfirmedCheckpoint = this.get('transport.firstUnconfirmedCheckpoint');
          const cfPairsWithCurrentTaskBlockade = firstUnconfirmedCheckpoint.get('customFieldsPairsWithCurrentTaskBlockade');
          cfPairs = Ember.A([...cfPairs, ...cfPairsWithCurrentTaskBlockade]);
        }

        if (this.get('isRequiredInCaseOfDelay')) {
          const firstUnconfirmedCheckpoint = this.get('transport.firstUnconfirmedCheckpoint');
          const cfFromDelay = customFieldPairs.filter(field => {
            return field.definition.get('requiredInDelayedTransport') === true && firstUnconfirmedCheckpoint.get('task.id') === field.definition.get('delayedTask.id') && firstUnconfirmedCheckpoint.get('isCustomFieldRequiredInDelayedCheckpoint') === true;
          });
          cfPairs = Ember.A([...cfPairs, ...cfFromDelay]);
        }
      } else {
        const visibleInTransport = customFieldPairs.filter(field => {
          return field.definition.get('visibleInTransportForm') === true;
        });
        cfPairs = Ember.A([...cfPairs, ...visibleInTransport]);
      }

      return cfPairs;
    }),
    shouldBeVisible: function (pair) {
      return pair.value.get('isVisibleBasedOnDefinition') && pair.value.get('isVisibleBasedOnAuthorityRestrictions');
    },
    leftCustomFieldPairs: Ember.computed('customFieldPairs', function () {
      return this.get('customFieldPairs').filter(pair => pair.definition.get('side') !== 'RIGHT');
    }),
    rightCustomFieldPairs: Ember.computed('customFieldPairs', function () {
      return this.get('customFieldPairs').filter(pair => pair.definition.get('side') === 'RIGHT');
    }),
    customFieldSplitValue: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CUSTOM_FIELD_SPLIT_VALUE');
    }),
    actions: {}
  });

  _exports.default = _default;
});