define("apollo/pods/components/server-table/filters/date-range-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setDate(date) {
        if (!date[0]) {
          this.set('column.filterString', null);
        }

        if (date.length === 2) {
          const dateFrom = moment(date[0]).format('DD.MM.YYYY');
          const dateTo = moment(date[1]).format('DD.MM.YYYY');
          this.set('column.filterString', `${dateFrom}-${dateTo}`);
        }
      }

    }
  });

  _exports.default = _default;
});