define("apollo/pods/components/transports/transports-table/load-more/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    loadMore: "loadMore",
    actions: {
      loadMore: function () {
        this.sendAction("loadMore");
      }
    }
  });

  _exports.default = _default;
});