define("apollo/pods/files/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/jn8B/e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[1,[27,\"errors-alert\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"files-server-table\",null,[[\"files\",\"page\",\"max\",\"saveFiles\",\"buttonDisabled\"],[[23,[\"model\",\"files\"]],[23,[\"page\"]],[23,[\"max\"]],[27,\"action\",[[22,0,[]],\"saveFileSet\"],null],[23,[\"buttonDisabled\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/files/index/template.hbs"
    }
  });

  _exports.default = _default;
});