define("apollo/mixins/checkpoint-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    showCustomFieldsModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);

      if (transport.get('hasCustomFieldsInitialized')) {
        transport.set('hasCustomFieldsInitialized', false);
      }

      this.send('showModal', 'custom-field-definitions.modals.form', checkpoint);
    },

    showBlockadeReasonModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);
      this.send('showModal', 'components.checkpoint-blockade-reason-modal', checkpoint);
    },

    confirmRejection() {
      const msg = this.get('intl').t('checkpoint.rejectConfirm');
      return confirm(msg);
    }

  });

  _exports.default = _default;
});