define("apollo/pods/companies/modals/add-driver/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    store: service(),
    countries: function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }.property(),
    actions: {
      save() {
        const self = this;
        const driver = this.get('model');
        const documentNumber = driver.get('documentNumber');

        if (documentNumber) {
          driver.set('documentNumber', documentNumber.replace(/\s+/g, ''));
        }

        const phoneNumber = driver.get('phoneNumber');

        if (phoneNumber) {
          driver.set('phoneNumber', phoneNumber.replace(/\s+/g, ''));
        }

        if (!driver.validate()) {
          return;
        }

        driver.save().then(() => {
          self.send('hideModal');
          self.send('refreshModel');
          self.setProperties({
            errors: [],
            model: null
          });
        });
      },

      disable() {
        const self = this;
        const driver = this.get('model');
        driver.set('enabled', false);
        driver.save().then(() => {
          self.send('hideModal');
          self.send('refreshModel');
          self.setProperties({
            errors: [],
            model: null
          });
        });
      },

      cancel() {
        console.debug(`Zamykamy okienko dodawania kierowcy...`);

        if (this.get('model')) {
          this.get('model').rollbackAttributes();
        }

        this.setProperties({
          errors: [],
          model: null
        });
      },

      setDocumentType(documentType) {
        this.get('model').set('documentType', documentType);
      },

      setPhonePrefix(phonePrefix) {
        this.set('model.phoneNumberPrefix', phonePrefix);
      }

    }
  });

  _exports.default = _default;
});