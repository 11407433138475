define("apollo/pods/components/transport/step-package-type-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedStepPackageTypes: Ember.computed('step.stepPackageTypes.@each.idx', function () {
      return this.get('step.stepPackageTypes').sortBy('idx');
    })
  });

  _exports.default = _default;
});