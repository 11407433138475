define("apollo/pods/components/company-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: "id",
    urlAddress: "/company/find",
    field: "name",
    options: [],
    suggestions: function () {
      var inputVal = this.get("inputVal") || "";
      return this.get("options").filter(function (item) {
        return item.name.toLowerCase().indexOf(inputVal.toLowerCase()) > -1 || item.shortName.toLowerCase().indexOf(inputVal.toLowerCase()) > -1;
      });
    }.property("inputVal", "options.@each"),
    actions: {
      selectItem: function (item) {
        this.set("selectedFromList", true);
        this.set("selectedValue", item.name);
        this.sendAction('selectItem', item);
      }
    }
  });

  _exports.default = _default;
});