define("apollo/pods/classification-resource/assign-queue-validator/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    queue: _emberData.default.belongsTo('queue'),
    square: _emberData.default.belongsTo('square'),
    validations: {
      queue: {
        custom: [{
          validation: function (value, model) {
            return model.get('id');
          },
          message: 'blank'
        }]
      },
      square: {
        custom: [{
          validation: function (value, model) {
            return model.get('id');
          },
          message: 'blank'
        }]
      }
    }
  });

  _exports.default = _default;
});