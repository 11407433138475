define("apollo/pods/components/role-group-statistics-report/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service('intl'),
    selectedReportIds: Ember.computed('roleGroupStatisticsReports', function () {
      return this.get('roleGroupStatisticsReports').map(rgsr => {
        return rgsr.get('report.id');
      });
    }),
    actions: {
      async save() {
        const data = JSON.stringify({
          roleGroupId: this.get('roleGroup.id'),
          reportIds: this.get('selectedReportIds')
        });

        try {
          const path = this.get('apolloApiService').APOLLO_API.ROLE_GROUP_STATISTICS_REPORTS.SAVE;
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: data
          });

          if (response.ok) {
            this.set('errors', []);
            this.set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },

      toggleRole: function (selection) {
        const report = this.get('selectedReportIds').find(id => id === selection.get('id'));

        if (!report) {
          this.get('selectedReportIds').addObject(selection.get('id'));
        } else {
          this.get('selectedReportIds').removeObject(selection.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});