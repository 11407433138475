define("apollo/pods/auth/activate-account/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    loginController: Ember.inject.controller('auth/login'),
    actions: {
      async activateButton() {
        const hash = this.get('model.hash');
        const path = this.get('apolloApiService').APOLLO_API.USER.ACTIVATE_ACCOUNT;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, null, {
            hash
          });

          if (response.ok) {
            this.get('loginController').setProperties({
              message: this.get('intl').t('auth.accountActivated')
            });
            this.transitionToRoute('auth.login');
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      }

    }
  });

  _exports.default = _default;
});