define("apollo/pods/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fdK/0E7W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"data-button-name\",\"file-upload-button\"],[11,\"class\",\"btn btn-default btn-file\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"common.uploadFile\"],null],false],[0,\" \"],[7,\"input\"],[11,\"class\",\"file-upload-input\"],[11,\"name\",\"files[]\"],[11,\"multiple\",\"\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"style\",\"display: none\"],[11,\"class\",\"progress\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bar\"],[11,\"style\",\"width: 0%;\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});