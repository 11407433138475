define("apollo/pods/products/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_PRODUCT'],

    beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    model() {
      const principalCompany = this.store.peekRecord('company', localStorage.getItem('principalId'));
      return this.get('store').createRecord('product', {
        company: principalCompany
      });
    },

    renderTemplate() {
      this.render('products.edit');
    },

    setupController(controller, model) {
      this.controllerFor('products.edit').setProperties({
        model: model
      });
    }

  });

  _exports.default = _default;
});