define("apollo/pods/components/custom-field-default-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1kBv7Cef",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"padding-top-big\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"custom-field-definition.defaultOption.title\"],null],false],[0,\"\\n    \"],[7,\"input\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"toggleShowDefaultOptionSection\"],null]],[12,\"checked\",[23,[\"model\",\"showDefaultOptionSection\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"showDefaultOptionSection\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"form-group \",[27,\"if\",[[23,[\"model\",\"errors\",\"defaultOption\"]],\"has-error\"],null]]]],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-sm-4 control-label\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"custom-field-definition.defaultOption.label\"],null],false],[0,\":\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"col-sm-8 form-control-static\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"selected\",\"options\",\"renderInPlace\",\"allowClear\",\"placeholder\",\"onchange\"],[\"form-control\",[27,\"readonly\",[[23,[\"model\",\"defaultOption\"]]],null],[23,[\"availableOptions\"]],true,true,[27,\"t\",[\"custom-field-definition.defaultOption.placeholder\"],null],[27,\"action\",[[22,0,[]],\"setDefaultOption\"],null]]],{\"statements\":[[0,\"                \"],[1,[22,1,[\"nameInAppropriateLanguage\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n            \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"model\"]],\"defaultOption\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"help-block padding-top text-center\"],[9],[1,[27,\"t\",[\"custom-field-definition.defaultOption.hint\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/custom-field-default-option/template.hbs"
    }
  });

  _exports.default = _default;
});