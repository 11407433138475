define("apollo/pods/components/transports/transports-table/intable-column-names/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    // Używane w hbs.
    tagName: 'tr',
    classNames: ['column-names'],
    priceTranslationKey: Ember.computed('transportType.id', function () {
      return this.get('transportType.priceLabelTranslation');
    }),
    actions: {}
  });

  _exports.default = _default;
});