define("apollo/pods/components/transaction-price-label-translation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    translationKeyLabel: Ember.computed('transaction.priceLabelTranslationKey', 'transaction', function () {
      return this.get('intl').t('transactionSetting.priceLabel.' + this.get('transaction.priceLabelTranslationKey'));
    })
  });

  _exports.default = _default;
});