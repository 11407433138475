define("apollo/pods/user-groups/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_USER_GROUPS'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const company = this.store.peekRecord('company', params.companyId);
      const userGroup = this.store.createRecord('userGroup', {
        company: company
      });
      return Ember.RSVP.hash({
        userGroup: userGroup
      });
    }
  });

  _exports.default = _default;
});