define("apollo/pods/components/companies/apply-form/licences-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    dragAndDrop: Ember.inject.service('drag-and-drop-service'),
    groupName: Ember.computed('', function () {
      return 'LICENCE';
    }),
    documentTypes: Ember.computed('other', function () {
      const countryIban = this.get('partnership.contractorCompany.country.ibanSymbol');
      return this.get('store').peekAll('documentType').filterBy('groupName', this.get('groupName')).filter(dt => {
        return !dt.get('ibanRestriction') || dt.get('ibanRestriction') === countryIban;
      });
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },

      refresh() {
        this.get('partnership').reload().then(p => p.get('documents').reload());
      },

      showArchived() {
        this.set('action', 'showModal');
        const model = {
          groupName: this.get('groupName'),
          documentTypes: this.get('documentTypes'),
          documents: this.get('partnership.archivedLicences')
        };
        this.sendAction('action', 'documents.modals.document-list-modal', model);
      }

    },

    dragOver(event) {
      const element = this.element;
      this.get('dragAndDrop').dragOver(event, element);
    },

    dragLeave(event) {
      const element = this.element;
      this.get('dragAndDrop').dragLeave(event, element);
    },

    drop(event) {
      const element = this.element;
      this.get('dragAndDrop').drop(event, element);
    }

  });

  _exports.default = _default;
});