define("apollo/pods/products/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_ACCESS_PRODUCT', 'ROLE_EDIT_PRODUCT', 'ROLE_DELETE_PRODUCT'],

    beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    model(params) {
      return this.store.findRecord('product', params.id, {
        reload: true
      });
    }

  });

  _exports.default = _default;
});