define("apollo/pods/users/queues/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O++7S5if",
    "block": "{\"symbols\":[],\"statements\":[[4,\"user-edit-form-outlet\",null,[[\"user\",\"errors\",\"successMessage\"],[[23,[\"user\"]],[23,[\"errors\"]],[23,[\"successMessage\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"form-horizontal\"],[11,\"autocomplete\",\"off\"],[9],[0,\"\\n\\n        \"],[1,[27,\"square-section\",null,[[\"squares\",\"user\"],[[23,[\"proxiedSquares\"]],[23,[\"user\"]]]]],false],[0,\"\\n\\n        \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"form-group pull-right\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"data-button-name\",\"save\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-button-name\",\"cancel\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"common.cancel\"],null],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"ok\"],[[\"on\",\"target\"],[\"submit\",[23,[\"view\"]]]]],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/users/queues/template.hbs"
    }
  });

  _exports.default = _default;
});