define("apollo/pods/components/transport/delivery-section/add-delivery-packaging-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addNewPackaging() {
        this.set('action', 'addPackaging');
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});