define("apollo/pods/contractors-group-type/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany
  } = _emberData.default;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    contractorsGroups: hasMany('contractorsGroup', {
      async: true
    }),
    name: attr('string'),
    idx: attr('number'),
    lastUpdated: attr('isodate'),
    requiresContractorsGroup: attr('boolean'),
    canSelectOnlyOneContractorsGroup: attr('boolean'),
    partnershipTypesWithAccess: attr('string'),
    isPrivate: attr('boolean'),
    nameInAppropriateLanguage: Ember.computed('name', function () {
      const self = this;
      return this.get('intl').t('contractors-group-type.' + self.get('name'));
    }).readOnly()
  });

  _exports.default = _default;
});