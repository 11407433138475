define("apollo/services/schedule/warehouse-zone-transport-type-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    principal: Ember.computed(function () {
      return this.get('sessionAccount.principalCompany');
    }),
    transportTypesUserCanCreate: Ember.computed('principal', function () {
      const availableTransportTypes = this.get('principal.transportTypesThatCanBeCreated');
      return availableTransportTypes.filter(tt => {
        return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE', tt.get('id')).length > 0;
      });
    }),

    /**
     * Metoda sprawdza czy user może utworzyć transport na danej rampie,
     * na podstawie warehouseZone przypisanego do rampy.
     * @param rampId
     * @returns transportTypes[]
     */
    transportTypesThatCanBeCreatedInWarehouseZone(rampId) {
      const transportTypes = this.get('transportTypesUserCanCreate');
      const clickedRampWarehouseZoneId = this.store.peekRecord('ramp', rampId).get('warehouseZone.id');
      const warehouseZoneTransportTypesIds = this.store.peekAll('warehouseZoneTransportType').filterBy('warehouseZone.id', clickedRampWarehouseZoneId).map(wztt => wztt.get('transportType.id'));
      return transportTypes.filter(tt => {
        return warehouseZoneTransportTypesIds.includes(tt.get('id'));
      });
    }

  });

  _exports.default = _default;
});