define("apollo/pods/auth/remind/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function (controller, model) {
      this.render('auth/remind', {
        into: 'application',
        outlet: 'login',
        controller: controller,
        model: model
      });
    }
  });

  _exports.default = _default;
});