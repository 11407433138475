define("apollo/pods/schedule-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    topLeft: _emberData.default.attr('string'),
    topMiddle: _emberData.default.attr('string'),
    topRight: _emberData.default.attr('string'),
    bottomLeft: _emberData.default.attr('string'),
    bottomMiddle: _emberData.default.attr('string'),
    bottomRight: _emberData.default.attr('string'),
    leftSideArea: _emberData.default.attr('string'),
    trimTopLeft: _emberData.default.attr('number'),
    trimTopMiddle: _emberData.default.attr('number'),
    trimTopRight: _emberData.default.attr('number'),
    trimBottomLeft: _emberData.default.attr('number'),
    trimBottomMiddle: _emberData.default.attr('number'),
    trimBottomRight: _emberData.default.attr('number'),
    topLeftHellip: _emberData.default.attr('boolean'),
    topMiddleHellip: _emberData.default.attr('boolean'),
    topRightHellip: _emberData.default.attr('boolean'),
    bottomLeftHellip: _emberData.default.attr('boolean'),
    bottomMiddleHellip: _emberData.default.attr('boolean'),
    bottomRightHellip: _emberData.default.attr('boolean'),
    stepTypeSymbolLoad: _emberData.default.attr('string'),
    stepTypeSymbolUnload: _emberData.default.attr('string'),
    transportCombiningSymbol: _emberData.default.attr('string')
  });

  _exports.default = _default;
});