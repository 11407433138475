define("apollo/pods/user-groups/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_USER_GROUPS'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const userGroup = this.store.findRecord('userGroup', params.id);
      return Ember.RSVP.hash({
        userGroup: userGroup
      });
    },
    renderTemplate: function () {
      this.render('userGroups.create');
    },
    setupController: function (controller, model) {
      this.controllerFor('userGroups.create').setProperties({
        model: model
      });
    }
  });

  _exports.default = _default;
});