define("apollo/pods/components/transport/step-user-group-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bujYueUN",
    "block": "{\"symbols\":[\"companyGroup\"],\"statements\":[[4,\"if\",[[23,[\"showUserGroup\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"form-group form-group-padding \",[27,\"if\",[[23,[\"step\",\"errors\",\"companyGroup\"]],\"has-error\"],null]]]],[11,\"data-field-name\",\"step-user-group-select\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-7 col-xs-12 step-field\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"selected\",\"options\",\"disabled\",\"renderInPlace\",\"placeholder\",\"onchange\"],[[23,[\"defaultUserGroup\"]],[23,[\"userGroupsList\"]],false,true,[27,\"t\",[\"step.companyGroup\"],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"step\",\"companyGroup\"]]],null]],null]]],{\"statements\":[[0,\"                \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n            \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\",\"className\"],[[23,[\"step\"]],\"companyGroup\",\"has-error\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport/step-user-group-select/template.hbs"
    }
  });

  _exports.default = _default;
});