define("apollo/pods/queue/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    name: _emberData.default.attr('string'),
    idx: _emberData.default.attr('string'),
    englishName: _emberData.default.attr('string'),
    shortName: _emberData.default.attr('string'),
    square: _emberData.default.belongsTo('square', {
      async: true
    }),
    places: _emberData.default.hasMany('place', {
      async: true
    }),
    users: _emberData.default.hasMany('user', {
      async: true
    }),
    enabled: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      idx: {
        presence: {
          message: 'blank'
        }
      }
    },
    nameInAppropriateLanguage: Ember.computed('name', 'englishName', function () {
      const properLabel = this.get('languageService').findProperLanguageVersion('name', 'englishName');
      return this.get(properLabel);
    }).readOnly(),
    enabledPlaces: Ember.computed('places.@each.enabled', function () {
      return this.get('places').filterBy('enabled');
    })
  });

  _exports.default = _default;
});