define("apollo/app", ["exports", "apollo/resolver", "apollo/config/environment", "ember-load-initializers"], function (_exports, _resolver, _environment, _emberLoadInitializers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.MODEL_FACTORY_INJECTIONS = true;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    customEvents: {
      paste: 'paste'
    },
    Resolver: _resolver.default
  });
  Ember.RSVP.configure('onerror', function (e) {
    console.error(e.message);
    console.error(e.stack);
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});