define("apollo/pods/time-windows-summary-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      close() {
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});