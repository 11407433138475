define("apollo/mixins/adjust-page-content-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    adjustPageContent(element, selectors) {
      const heights = selectors.map(selector => (0, _jquery.default)(selector).height());
      const summaryHeight = heights.reduce((a, b) => a + b, 0); // 15 - taki offset, żeby nie było "na styk"

      (0, _jquery.default)(element).css("margin-top", summaryHeight + 15);
    }

  });

  _exports.default = _default;
});