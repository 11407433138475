define("apollo/pods/components/transport/export-indexes-button/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    tagName: 'button',
    classNames: ['btn', 'btn-default'],
    attributeBindings: ['title', 'type', 'disabled'],
    type: Ember.computed('', function () {
      return 'button';
    }),

    click() {
      this.generateXLS();
    },

    generateXLS() {
      Ember.run.once(this, function () {
        const params = {
          token: this.get('sessionAccount.token')
        };
        const transportId = this.get('transport.id');
        window.location = `${_environment.default.serverURL}/transports/${transportId}/indices/xls?${$.param(params)}`;
      });
    }

  });

  _exports.default = _default;
});