define("apollo/pods/warehouse-zones/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    transportTypes: Ember.computed('model', function () {
      return this.get('sessionAccount.currentUser.transportTypes');
    }),
    // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
    proxiedTransportTypes: Ember.computed('model.transportTypes', 'transportTypes', function () {
      const properTransportTypesList = this.get('model.id') ? this.get('model.transportTypes') : this.get('model.warehouse.transportTypes');
      return this.get('transportTypes').map(tt => {
        return Ember.ObjectProxy.create({
          content: tt,
          checked: properTransportTypesList.getEach('id').includes(tt.get('id'))
        });
      });
    }),
    actions: {
      save() {
        const self = this;
        this.get('model').validate();

        if (this.get('model').get('errors.length')) {
          return;
        }

        const checkedTransportTypes = this.get('proxiedTransportTypes').filterBy('checked', true);
        const checkedTransportTypesIds = checkedTransportTypes.map(tt => tt.get('content.id'));
        this.get('model').set('checkedTransportTypesIds', checkedTransportTypesIds);
        const noTransportTypesSelected = checkedTransportTypesIds.length === 0;

        if (noTransportTypesSelected && !confirm(this.get('intl').t('warehouseZone.noTransportTypesSelected'))) {
          return;
        }

        this.get('model').save().then(zone => {
          console.debug(`Zone ${zone.get('name')} saved.`);
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('model.warehouse.id'));
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      disable() {
        const model = this.get('model');
        model.set('enabled', false);
        this.send('save');
      },

      enable() {
        const model = this.get('model');
        model.set('enabled', true);
        this.send('save');
      },

      cancel() {
        const warehouseId = this.get('model.warehouse.id');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('warehouses.edit', warehouseId);
      }

    }
  });

  _exports.default = _default;
});