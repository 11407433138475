define("apollo/pods/components/show-docs/component", ["exports", "apollo/pods/components/show-docs/template", "apollo/pods/components/custom-popover/component", "apollo/mixins/document-mixin"], function (_exports, _template, _component, _documentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_documentMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    layout: _template.default,
    errors: Ember.A([]),
    uploading: false,
    completed: 0,
    action: '',
    attachments: Ember.A([]),
    isUploading: Ember.computed('uploading', function () {
      return this.get('uploading');
    }),
    actions: {
      showDocs() {
        if (!this.get('isOpened')) {
          this.get('transport.files').then(f => this.showPopover(this.element, '.show-documents-button', '.documents-popover'));
        } else {
          this.destroyPopovers();
        }
      },

      fileUploadSuccess: function (transportId) {
        this.set('uploading', false);
        this.set('action', 'fileUploadSuccess');
        this.sendAction('action', transportId);
        this.destroyPopovers();
      },

      fileUploadStarted() {
        if (!this.get('uploading')) {
          this.set('uploading', true);
        }
      },

      deleteFile(file) {
        this.set('action', 'deleteFile');
        this.sendAction('action', file);
        this.destroyPopovers();
      },

      fileUploadFail: function (transportId) {
        this.set('uploading', false);
        this.set('action', 'fileUploadFail');
        this.sendAction('action', transportId);
      },

      showNoAuthorityAlert() {
        alert(this.get('intl').t('common.noAuthority'));
      }

    }
  });

  _exports.default = _default;
});