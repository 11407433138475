define("apollo/pods/product-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    // Ustawienia ogólne
    translationKey: _emberData.default.attr('string'),
    nameAutoCompletePlaceholder: Ember.computed('', function () {
      return this.getTranslation('name.autoCompletePlaceholder');
    }),

    getTranslation(propertyName) {
      const productSetting = this.get('sessionAccount').getProductSetting();

      if (!productSetting) {
        return this.get('intl').t(`product.INDEX.${propertyName}`);
      }

      const key = productSetting.get('translationKey');
      return this.get('intl').t(`product.${key}.${propertyName}`);
    }

  });

  _exports.default = _default;
});