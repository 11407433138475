define("apollo/pods/components/companies/apply-form/history-section/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Component.extend({
    store: service(),
    sessionAccount: service('session-account'),
    isOpened: false,
    page: 1,
    partnershipLogs: Ember.computed('', function () {
      return Ember.A();
    }),
    loadLogs: Ember.observer('isOpened', 'page', 'max', function () {
      const self = this;
      const token = this.get('sessionAccount').get('token');
      const partnership = this.get('partnership');
      const partnershipLogs = this.get('partnershipLogs'); // TODO :: apollo-api-service

      Ember.$.ajax({
        url: _environment.default.serverURL + '/partnerships/' + partnership.get('id') + '/logs',
        data: {
          max: self.get('max'),
          page: self.get('page')
        },
        type: 'GET',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (json) {
        partnershipLogs.clear();
        json.partnershipLogs.sortBy('dateCreated').reverse().forEach(function (partnershipLog) {
          partnershipLogs.pushObject(partnershipLog);
        });
        self.set('totalPages', json.meta.pagesCount);
      });
    }),
    actions: {
      togglePanel() {
        console.log(`togglePanel from.. ${this.get('isOpened')}`);
        this.toggleProperty('isOpened');
      },

      incrementPage() {
        this.incrementProperty('page');
      },

      decrementPage() {
        this.decrementProperty('page');
      },

      setPage(page) {
        this.set('page', page);
      }

    }
  });

  _exports.default = _default;
});