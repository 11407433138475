define("apollo/pods/step-package-type/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data-copyable"], function (_exports, _emberData, _modelValidator, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, _emberDataCopyable.default, {
    sessionAccount: Ember.inject.service('session-account'),
    validationService: Ember.inject.service('validation-service'),
    intl: Ember.inject.service(),
    step: _emberData.default.belongsTo('step'),
    packageType: _emberData.default.belongsTo('packageType'),
    quantity: _emberData.default.attr('number'),
    multiplier: _emberData.default.attr('number'),
    validations: {
      step: {
        presence: {
          message: 'blank'
        }
      },
      packageType: {
        presence: {
          message: 'blank'
        }
      },
      quantity: {
        custom: [{
          validation: function (key, value, model) {
            let isFieldVisible = false;
            const packageTypeTransportType = model.get('packageTypeTransportType');
            const isVisibleInWarehouseStep = packageTypeTransportType.get('visibleInWarehouseStep');
            const isVisibleInContractorStep = packageTypeTransportType.get('visibleInContractorStep');

            if (model.get('step.transport.transportType.hasVisibleSteps')) {
              if (model.get('step.belongsToWarehouse')) {
                isFieldVisible = isVisibleInWarehouseStep;
              } else {
                isFieldVisible = isVisibleInContractorStep;
              }
            } else {
              isFieldVisible = isVisibleInWarehouseStep || isVisibleInContractorStep;
            }

            if (!isFieldVisible || !packageTypeTransportType.get('required')) {
              return true;
            }

            return !Ember.isEmpty(value);
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            return model.get('validationService').isPositiveNumber(value);
          },
          message: 'noNegativeNumber'
        }, {
          validation: function (key, value, model) {
            if (Ember.isEmpty(value)) {
              return true;
            }

            const packageTypeTransportType = model.get('packageTypeTransportType');
            const minQuantityValue = packageTypeTransportType.get('minValue') ? packageTypeTransportType.get('minValue') : 0;
            return value >= minQuantityValue;
          },
          message: function (key, value, model) {
            const packageTypeTransportType = model.get('packageTypeTransportType');
            const minQuantityValue = packageTypeTransportType.get('minValue') ? packageTypeTransportType.get('minValue') : 0;
            return model.get('intl').t('step.errors.minQuantityValue', {
              minQuantityValue: minQuantityValue
            });
          }
        }]
      }
    },
    packageTypeTransportType: Ember.computed('step.transport.transportType.id', 'packageType.id', function () {
      const transportTypeId = this.get('step.transport.transportType.id');
      const packageId = this.get('packageType.id');
      return this.get('sessionAccount').getPackageTypeTransportType(packageId, transportTypeId);
    }),
    referencePackage: Ember.computed('step.transport.transportType.id', function () {
      const transportTypeId = this.get('step.transport.transportType.id');
      return this.get('sessionAccount').getPackageTypeTransportTypeForTransportType(transportTypeId).find(p => p.get('referencePackage')).get('packageType');
    }),
    referenceQuantityInfo: Ember.computed('referencePackage', 'packageType.id', 'asReferenceQuantity', function () {
      if (this.get('referencePackage.id') === this.get('packageType.id')) {
        return null;
      }

      return this.get('asReferenceQuantity');
    }),
    asReferenceQuantity: Ember.computed('quantity', 'multiplier', function () {
      const quantity = this.get('quantity');
      const multiplier = this.get('multiplier');

      if (Ember.isEmpty(quantity) || !multiplier) {
        return null;
      }

      return Math.ceil(quantity / multiplier);
    }),
    idx: Ember.computed('packageTypeTransportType.idx', function () {
      return this.get('packageTypeTransportType.idx');
    })
  });

  _exports.default = _default;
});