define("apollo/pods/components/companies/apply-form/secondary-data-section/component", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    store: service(),
    dragAndDrop: service('drag-and-drop-service'),

    willDestroyElement() {
      this._super(...arguments);

      const section = this.get('store').peekRecord('secondarySection', this.get('section.id'));

      if (section && section.rollbackAttributes) {
        section.rollbackAttributes();
      }

      if (this.get('section.partnership.content')) {
        this.get('section.partnership.content').rollbackAttributes();
      }
    },

    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    },

    dragOver(event) {
      const element = this.element;
      this.get('dragAndDrop').dragOver(event, element);
    },

    dragLeave(event) {
      const element = this.element;
      this.get('dragAndDrop').dragLeave(event, element);
    },

    drop(event) {
      const element = this.element;
      this.get('dragAndDrop').drop(event, element);
    }

  });

  _exports.default = _default;
});