define("apollo/pods/documents/modals/document-form-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eGCVAJ86",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"classNames\"],[[23,[\"title\"]],\"document-modal\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[1,[27,\"errors-alert\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\\n        \"],[1,[27,\"sections/document-section/document-form\",null,[[\"documentType\",\"document\",\"modal\"],[[23,[\"model\",\"documentType\"]],[23,[\"model\",\"document\"]],true]]],false],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"modal-footer form-buttons margin-top col-xs-12\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"savingInProgress\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"t\",[\"common.pleaseWait\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"data-button-name\",\"save\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-dismiss\",\"modal\"],[11,\"data-button-name\",\"cancel\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"common.cancel\"],null],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/documents/modals/document-form-modal/template.hbs"
    }
  });

  _exports.default = _default;
});