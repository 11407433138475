define("apollo/pods/components/transports/transports-table/load-more/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ItV2d42",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"hasMore\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-4 col-md-offset-4\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-info load-more\"],[12,\"disabled\",[21,\"loadingMore\"]],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"loadingMore\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"span\"],[9],[0,\"\\n                        \"],[7,\"i\"],[11,\"class\",\"fa fa-hourglass\"],[9],[10],[0,\"\\n                        \"],[1,[27,\"t\",[\"table.loading\"],null],false],[0,\"...\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[7,\"span\"],[9],[0,\"\\n                        \"],[7,\"i\"],[11,\"class\",\"fa fa-refresh\"],[9],[10],[0,\"\\n                        \"],[1,[27,\"t\",[\"table.loadMore\"],null],false],[0,\"\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[3,\"action\",[[22,0,[]],\"loadMore\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/load-more/template.hbs"
    }
  });

  _exports.default = _default;
});