define("apollo/pods/components/sections/document-section/document-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    isEditionDisabled: Ember.computed('document.id', 'document.canBeUpdated', function () {
      if (!this.get('document.id')) {
        return false;
      }

      return !this.get('document.canBeUpdated');
    }),
    actions: {
      setExpirationDate(dateValues) {
        Ember.run.next(() => {
          this.get('document').set('expirationDate', dateValues[0]);
        });
      },

      setExpirationDateFrom(dateValues) {
        Ember.run.next(() => {
          this.get('document').set('expirationDateFrom', dateValues[0]);
        });
      },

      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});