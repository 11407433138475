define("apollo/utils/validators/registration-number-validator", ["exports", "apollo/utils/validators/regex-helper"], function (_exports, _regexHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validate(value) {
    const pL = _regexHelper.default.pL();

    const regex = '^[$' + pL + '0-9 _.,;&/]{4,50}$';
    return new RegExp(`${regex}`).test(value);
  }

  var _default = {
    validate
  };
  _exports.default = _default;
});