define("apollo/pods/package-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    lowerCaseName: Ember.computed('name', function () {
      if (!this.get('name')) {
        return "";
      }

      return this.get('name').toLowerCase();
    })
  });

  _exports.default = _default;
});