define("apollo/pods/components/planning/modals/add-transport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s8hiiB7V",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"custom-modal\",null,[[\"title\"],[\"Nowa wysyłka\"]],{\"statements\":[[4,\"if\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"alert alert-danger alert-dismissible\"],[11,\"role\",\"alert\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"alert\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"li\"],[9],[0,\"\\n                        \"],[1,[22,1,[]],false],[0,\"\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"form\"],[11,\"class\",\"form-horizontal\"],[9],[0,\"\\n        \"],[1,[27,\"planning/custom-fields-section\",null,[[\"customFields\"],[[23,[\"customFields\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"modal-footer form-buttons padding-top-big\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"type\",\"submit\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.cancel\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/planning/modals/add-transport/template.hbs"
    }
  });

  _exports.default = _default;
});