define("apollo/pods/components/schedule/warehouse-zone-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['warehouse-zone-header'],
    attributeBindings: ['style'],
    classNameBindings: ['isZoneIdxEven:even'],
    languageService: Ember.inject.service('language-service'),
    sessionAccount: Ember.inject.service('session-account'),

    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);

      if ($(this.element).children().width() <= $(this.element).width()) {
        const propertyName = this.get('languageService').findProperLanguageVersion('warehouseZone.name', 'warehouseZone.englishName');
        this.set('fullOrShortWarehouseZoneName', this.get(propertyName));
      } else {
        this.set('fullOrShortWarehouseZoneName', this.get('warehouseZone.shortName'));
      }
    },

    // eslint-disable-next-line ember/no-function-prototype-extensions
    isZoneIdxEven: function () {
      return this.get('zoneIdx') % 2 === 0;
    }.property('zoneIdx'),
    // eslint-disable-next-line ember/no-function-prototype-extensions
    style: function () {
      // eslint-disable-next-line ember/no-string-prototype-extensions
      return `width: ${this.get('width')}%`.htmlSafe();
    }.property('width'),
    // eslint-disable-next-line ember/no-function-prototype-extensions
    width: function () {
      return this.userRampsInWarehouseZone().length * this.get('rampWidth');
    }.property('warehouseZone.enabledRamps.length', 'rampWidth', 'userRampsIds'),
    userRampsInWarehouseZone: function () {
      return this.get('warehouseZone.enabledRamps').filter(ramp => {
        return this.get('userRampsIds').includes(ramp.id);
      });
    },
    fullOrShortWarehouseZoneName: Ember.computed('warehouseZone.enabledRamps.[]', 'warehouseZone.name', 'warehouseZone.englishName', 'warehouseZone.shortName', 'width', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('warehouseZone.name', 'warehouseZone.englishName');
      return this.get(propertyName);
    })
  });

  _exports.default = _default;
});