define("apollo/pods/address-unloading-time/model", ["exports", "apollo/mixins/model-validator", "ember-data", "ember-data/model"], function (_exports, _modelValidator, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;
  const timeFormatValidator = {
    format: {
      with: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
      message: 'timeHour'
    }
  };
  const numberValdator = {
    presence: {
      message: 'blank'
    },
    numericality: {
      message: 'number'
    }
  };

  var _default = _model.default.extend(_modelValidator.default, {
    monday: attr('string'),
    tuesday: attr('string'),
    wednesday: attr('string'),
    thursday: attr('string'),
    friday: attr('string'),
    saturday: attr('string'),
    sunday: attr('string'),
    daysToUnload: attr('number'),
    address: belongsTo('address'),
    dayName: Object.freeze(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']),
    getCurrentDay: function (dayNameIndex) {
      return this.get('dayName').indexOf(dayNameIndex);
    },
    validations: {
      monday: timeFormatValidator,
      tuesday: timeFormatValidator,
      wednesday: timeFormatValidator,
      thursday: timeFormatValidator,
      friday: timeFormatValidator,
      saturday: timeFormatValidator,
      sunday: timeFormatValidator,
      daysToUnload: numberValdator
    }
  });

  _exports.default = _default;
});