define("apollo/pods/warehouse-location/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    warehouse: belongsTo('warehouse', {
      async: true
    }),
    namePl: attr('string'),
    nameEn: attr('string'),
    shortNamePl: attr('string'),
    shortNameEn: attr('string'),
    enabled: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    gate: belongsTo('gate'),
    nameInAppropriateLanguage: Ember.computed('namePl', 'nameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('namePl', 'nameEn');
      return this.get(propertyName);
    }).readOnly(),
    validations: {}
  });

  _exports.default = _default;
});