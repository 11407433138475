define("apollo/pods/user-group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: 'userGroups'
    }),
    name: _emberData.default.attr('string'),
    mainGroup: _emberData.default.attr('boolean'),
    canSeeOtherGroupsTransports: _emberData.default.attr('boolean'),
    identifier: _emberData.default.attr('string')
  });

  _exports.default = _default;
});