define("apollo/helpers/trunc-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncString = truncString;
  _exports.default = void 0;

  function truncString(params, namedArgs) {
    const string = params[0];
    const n = params[1] || 16;
    const hellip = namedArgs.hellip;

    if (!string) {
      return null;
    }

    let truncatedString = string;

    if (n > 0 && string.length > Math.abs(n)) {
      // n > 0 => tniemy normalnie z prawej
      truncatedString = hellip ? `${string.substr(0, n - 1).trim()}&hellip;` : `${string.substr(0, n - 1).trim()}`;
    } else if (n < 0 && string.length > Math.abs(n)) {
      // n < 0 => tniemy z lewej
      truncatedString = hellip ? `&hellip;${string.substr(string.length + n, string.length).trim()}` : `${string.substr(string.length + n, string.length).trim()}`;
    } // n == 0 =? nie tniemy


    return Ember.String.htmlSafe(truncatedString);
  }

  var _default = Ember.Helper.helper(truncString);

  _exports.default = _default;
});