define("apollo/pods/components/slider-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XjyZ/jyo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"slider\"],[11,\"class\",\"inline-block width-200 padding-right-small\"],[12,\"title\",[28,[[27,\"t\",[\"warehouse.slider.title\"],null]]]],[9],[10],[0,\"\\n\\n\"],[7,\"span\"],[11,\"id\",\"slider-info\"],[11,\"class\",\"small-font padding-left\"],[9],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"btn btn-small\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"common.reset\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"reset\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/slider-component/template.hbs"
    }
  });

  _exports.default = _default;
});