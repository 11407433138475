define("apollo/pods/components/warehouse/warehouse-location-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EFj1XVwK",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[4,\"if\",[[23,[\"warehouse\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[11,\"data-field-name\",\"assortment-group-section\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"col-xs-12\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n                        \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[9],[0,\"\\n                            \"],[7,\"b\"],[9],[1,[27,\"t\",[\"warehouse.warehouseLocationLabelPlural\"],null],false],[10],[0,\"\\n                        \"],[10],[0,\"\\n                        \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n                            \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"whLocations\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"id\"]]],null,{\"statements\":[[0,\"                                        \"],[7,\"p\"],[12,\"class\",[28,[[27,\"unless\",[[22,1,[\"enabled\"]],\"disabled\"],null]]]],[9],[0,\"\\n                                            \"],[1,[22,1,[\"namePl\"]],false],[0,\"\\n\"],[4,\"link-to\",[\"warehouse-locations.edit\",[22,1,[]]],null,{\"statements\":[[0,\"                                                \"],[7,\"span\"],[11,\"class\",\"fa fa-edit\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                                        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"                            \"],[10],[0,\"\\n                        \"],[10],[0,\"\\n                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/warehouse/warehouse-location-section/template.hbs"
    }
  });

  _exports.default = _default;
});