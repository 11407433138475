define("apollo/pods/terms/review/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    actions: {
      willTransition(transition) {
        if (!this.get('sessionAccount').get('currentUser').get('statuteAccepted')) {
          transition.abort();
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});