define("apollo/pods/companies/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_COMPANIES_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      params.max = this.get('sessionAccount.currentUser.maxRowsInCompaniesTable') || 10;
      params.page = params.page || 1;
      params.principalCompanyId = localStorage.getItem('principalId');
      const hasAccessToApplies = this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES') === 'true';

      if (hasAccessToApplies) {
        const partnershipTypesNames = this.store.peekAll('partnership-type').map(pt => pt.get('name'));
        params.partnershipTypesNames = partnershipTypesNames.filter(name => name !== 'CARRIER' && name !== 'OPERATOR');
      }

      return this.store.query('company', params);
    },
    setupController: function (controller, model) {
      this.get('controller').setProperties({
        model: model,
        totalPages: model.get('meta.totalPages'),
        showCarriersTable: false
      });
    },

    activate() {
      this.activateCurrentTab('COMPANIES_TAB');
    },

    actions: {
      willTransition() {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});