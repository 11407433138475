define("apollo/helpers/find-proper-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, nameArgs) {
      const notifications = nameArgs.notifications;
      const transportTypeId = nameArgs.transportTypeId;
      return notifications.filter(n => n.content.get('transportType.id') === transportTypeId);
    }

  });

  _exports.default = _default;
});