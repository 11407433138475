define("apollo/helpers/can-edit-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service(),
    ROLE_EDIT_ALL_USERS: "ROLE_EDIT_ALL_USERS",
    ROLES_TO_EDIT: ["ROLE_EDIT_USERS_MAIN_DATA", "ROLE_EDIT_USERS_NOTIFICATION", "ROLE_EDIT_USERS_RAMP"],
    hasAnyRoleToEdit: function () {
      return this.ROLES_TO_EDIT.some(role => this.get('sessionAccount').hasRole(role));
    },

    // X może edytować użytkownika, jeśli X:
    // - posiada którąś z ról "ROLE_EDIT_USERS_MAIN_DATA", "ROLE_EDIT_USERS_NOTIFICATION", "ROLE_EDIT_USERS_RAMP"
    // - użytkownik jest z tej samej firmy lub X ma rolę "ROLE_EDIT_ALL_USERS"
    compute(params, nameArgs) {
      const companyId = nameArgs.companyId;
      const principalInDifferentSpace = nameArgs.principalInDifferentSpace;
      const currentUserCompanyId = this.get('sessionAccount.currentUser.company.id');
      const hasRoleToEditAllUsers = this.get('sessionAccount').hasRole(this.ROLE_EDIT_ALL_USERS);
      const hasRoleToEdit = nameArgs.role ? this.get('sessionAccount').hasRole(nameArgs.role) : this.hasAnyRoleToEdit();
      return !principalInDifferentSpace && hasRoleToEdit && (companyId === currentUserCompanyId || hasRoleToEditAllUsers);
    }

  });

  _exports.default = _default;
});