define("apollo/services/multi-archive-tracker-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentTransports: Ember.A([]),
    sessionAccount: Ember.inject.service('session-account'),

    addTransport(transport) {
      this.currentTransports.pushObject(transport);
    },

    removeTransport(transport) {
      this.currentTransports.removeObject(transport);
    },

    findExisting(transport) {
      return this.currentTransports.find(relation => {
        return relation.get('id') === transport.get('id');
      });
    },

    getTransportsThatCantBeArchived() {
      const transportsThatCantBeArchived = Ember.A([]);

      if (!this.currentTransports.length < 1) {
        return transportsThatCantBeArchived;
      }

      this.currentTransports.forEach(transport => {
        if (!transport.actionCanBePerformed('DELETE_TRANSPORT')) {
          transportsThatCantBeArchived.pushObject(transport);
        }
      });
      return transportsThatCantBeArchived;
    },

    clearTransportsList() {
      const alias = this.currentTransports.get('firstObject').get('transportType.alias');
      this.set('currentTransports', Ember.A([]));
      this.transitionToRoute('transports.index', alias);
    }

  });

  _exports.default = _default;
});