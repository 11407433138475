define("apollo/pods/notification/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    smsNotifications: Object.freeze(['ADVICE_CREATED_SMS', 'ADVICE_UPDATED_SMS', 'CHECKPOINT_CONFIRMED_SMS', 'TRANSPORT_DELETED_SMS', 'CHECKPOINT_CONFIRMED_SMS_ASSOCIATED_WITH_USER', 'CHECKPOINT_CONFIRMED_BY_SMS']),
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    principalCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    alias: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    messagePl: _emberData.default.attr('string'),
    messageEn: _emberData.default.attr('string'),
    appendTransportFiles: _emberData.default.attr('boolean'),
    manualNotification: _emberData.default.attr('boolean'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      }
    },
    getNotificationType: function (notificationName) {
      return this.get('smsNotifications').includes(notificationName) ? 'SMS' : 'EMAIL';
    },
    getNotificationName: Ember.computed('model', function () {
      if (this.get('alias')) {
        return this.get('alias');
      }

      return this.get('name');
    }),
    transportTypeName: Ember.computed('model', function () {
      if (this.get('task.workflow.transportType.label')) {
        return this.get('task.workflow.transportType.label');
      }

      return this.get('transportType.label');
    }),
    label: Ember.computed('getNotificationName', 'task.checkpointNameInAppropriateLanguage', 'transportTypeName', function () {
      return this.get('intl').t(`notification.${this.get('getNotificationName')}.label`, {
        checkpointName: this.get('task.checkpointNameInAppropriateLanguage'),
        transportTypeName: this.get('transportTypeName')
      });
    })
  });

  _exports.default = _default;
});