define("apollo/pods/companies/modals/add-truck/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    intl: service(),
    store: service(),
    truckTypes: Ember.computed(function () {
      return this.get('store').peekAll('truck-type');
    }),
    actions: {
      setTruckType(truckType) {
        this.get('model').set('truckType', truckType);
      },

      save() {
        const self = this;
        const truck = this.get('model');

        if (!truck.validate()) {
          return;
        }

        truck.set('registrationNumber', truck.get('registrationNumber').toUpperCase().replace(/\s+/g, ''));

        if (truck.get('trailer') && truck.get('trailer').length > 0) {
          truck.set('trailer', truck.get('trailer').toUpperCase().replace(/\s+/g, ''));
        }

        truck.save().then(() => {
          self.send('hideModal');
          self.send('refreshModel');
          self.setProperties({
            errors: [],
            model: null
          });
        });
      },

      disable() {
        const self = this;
        const truck = this.get('model');
        truck.set('enabled', false);
        truck.save().then(() => {
          self.send('hideModal');
          self.send('refreshModel');
          self.setProperties({
            errors: [],
            model: null
          });
        });
      },

      cancel() {
        console.debug(`Zamykamy okienko dodawania pojazdu...`);

        if (this.get('model')) {
          this.get('model').rollbackAttributes();
        }

        this.setProperties({
          errors: [],
          model: null
        });
      }

    }
  });

  _exports.default = _default;
});