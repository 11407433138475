define("apollo/services/sent-notification-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    STATUS: Object.freeze({
      READ: 'READ',
      UNREAD: 'UNREAD'
    }),
    DEFAULT_PAGE_SIZE: Object.freeze(5),
    totalItems: 0,
    currentPage: 0,

    loadNotifications(page = 0) {
      console.log('Loading notifications...');
      this.store.query('sentNotification', {
        page,
        limit: `${this.get('DEFAULT_PAGE_SIZE')}`
      }).then(response => {
        this.store.pushPayload(response);

        this._setMetaDataFromResponse(response);
      });
    },

    nextPage() {
      return this.currentPage + 1;
    },

    _setMetaDataFromResponse(response) {
      this.set('totalItems', response.meta.totalItems);
      this.set('currentPage', response.meta.currentPage);
    },

    addToTotalItems(countToAdd) {
      this.set('totalItems', this.totalItems + countToAdd);
    },

    subtractFromTotalItems(countToSubtract) {
      this.set('totalItems', this.totalItems - countToSubtract);
    },

    markAsRead(notificationModel) {
      notificationModel.set('status', this.STATUS.READ);
      notificationModel.save().then(() => {
        notificationModel.unloadRecord();
        this.subtractFromTotalItems(1);
      });
    }

  });

  _exports.default = _default;
});