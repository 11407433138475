define("apollo/pods/components/by-whom/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    tagName: 'span'
  });

  _exports.default = _default;
});