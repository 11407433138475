define("apollo/pods/files/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    async model() {
      return {
        files: await this.store.query('file', {}),
        fileSet: this.store.createRecord('file-set', {
          creator: this.get('sessionAccount.currentUser')
        })
      };
    }

  });

  _exports.default = _default;
});