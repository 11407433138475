define("apollo/pods/components/phone-prefix/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "avJZ7vOK",
    "block": "{\"symbols\":[\"phonePrefix\"],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group\"],[11,\"data-field-name\",\"phone-number-prefix\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"asPlainTemplate\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[11,\"id\",\"numberPrefix\"],[9],[0,\"+\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"power-select\",null,[[\"class\",\"selected\",\"options\",\"search\",\"disabled\",\"renderInPlace\",\"placeholder\",\"onchange\"],[\"form-control\",[23,[\"selectedPhonePrefix\"]],[23,[\"phonePrefixes\"]],[23,[\"searchForPhonePrefix\"]],[23,[\"disabled\"]],true,[27,\"t\",[\"common.driverMobilePhonePrefix.placeholder\"],null],[27,\"action\",[[22,0,[]],\"setPhonePrefix\"],null]]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"ibanSymbol\"]],false],[0,\" : \"],[1,[22,1,[\"phonePrefix\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/phone-prefix/template.hbs"
    }
  });

  _exports.default = _default;
});