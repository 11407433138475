define("apollo/pods/anti-corruption-section/model", ["exports", "ember-data", "apollo/pods/section/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    partnership: _emberData.default.belongsTo('partnership', {
      async: true,
      deepEmbedded: ['contractorCompany']
    }),
    signedFiles: _emberData.default.hasMany('file', {
      async: true
    }),
    statementFilePL: _emberData.default.belongsTo('file', {
      async: true
    }),
    statementFileEN: _emberData.default.belongsTo('file', {
      async: true
    }),
    patternFilePL: _emberData.default.belongsTo('file', {
      async: true
    }),
    patternFileEN: _emberData.default.belongsTo('file', {
      async: true
    }),
    internalNotes: _emberData.default.attr('string')
  });

  _exports.default = _default;
});