define("apollo/pods/file-set/model", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _model.default.extend({
    files: hasMany('file', {
      async: true
    }),
    creator: belongsTo('user'),
    dateCreated: attr('isodate'),
    state: attr('string')
  });

  _exports.default = _default;
});