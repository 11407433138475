define("apollo/pods/components/transports/transports-table/action-progress-toast/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actionProgressToast: Ember.inject.service('action-progress-service'),
    intl: Ember.inject.service(),
    toastMessage: Ember.computed('actionProgressToast.typeOfToast', function () {
      return this.get('actionProgressToast.message');
    }),
    toastDetails: Ember.computed('actionProgressToast.typeOfToast', function () {
      return this.get('actionProgressToast.details');
    }),
    isLoading: Ember.computed('actionProgressToast.isLoading', function () {
      return this.get('actionProgressToast.isLoading');
    }),
    toastType: Ember.computed('actionProgressToast.typeOfToast', function () {
      return this.get('actionProgressToast.typeOfToast');
    }),
    toastTitle: Ember.computed('toastType', function () {
      return this.get('intl').t('notificationToast.titles.' + this.get('toastType'));
    }),
    displayIcon: Ember.computed('toastType', function () {
      const type = this.get('toastType');
      const icons = {
        success: 'check-circle',
        error: 'exclamation-circle'
      };
      return icons[type];
    }),
    actions: {
      close() {
        const toast = $('.toast');
        const progress = $('.toast-progress');
        toast.removeClass('loading error success toast-active');
        progress.removeClass('active');
      }

    }
  });

  _exports.default = _default;
});