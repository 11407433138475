define("apollo/services/amount-of-cover-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currencyExchangeService: Ember.inject.service(),
    ERROR_ROOT: 'Błąd przy próbie wyliczenia sumy gwarancyjej transportu. ',

    hasEnoughAmountOfCover(transport, company) {
      if (!transport.get('transportType.checkAmountOfCover')) {
        // Typ transportu nie posiada flagi do sprawdzania sumy gwarancyjnej, zwracamy true
        return true;
      }

      if (!transport.get('id') || !company.get('id')) {
        throw new Error(this.ERROR_ROOT + 'Transport :: ' + transport + ' :: lub firma :: ' + company + ' :: nie istnieje !');
      }

      const transportAoc = transport.get('amountOfCover');

      if (!transportAoc) {
        console.debug(this.ERROR_ROOT + 'Brak sumy gwarancyjnej transportu :: ' + transport.get('id'));
        return false;
      }

      const transportCurrency = transportAoc.get('currency');
      const transportAmount = Number(transportAoc.get('amount'));
      const companyInsurance = company.get('activeOCPInsurance') || company.get('activeOCSInsurance');

      if (!companyInsurance) {
        console.debug('Firma ' + company + ' nie posiada ubezpieczenia OCP i OCS.');
        return false;
      }

      const companyAmount = Number(companyInsurance.get('amount'));
      const companyCurrency = companyInsurance.get('currency');

      if (!companyAmount || !companyCurrency) {
        console.debug('Firma ' + company + ' nie posiada ubezpieczenia OCP i OCS.');
        return false;
      }

      return this._countAmountOfCovers(transportAmount, transportCurrency, companyAmount, companyCurrency);
    },

    _countAmountOfCovers(transportAmount, transportCurrency, companyAmount, companyCurrency) {
      transportAmount = this.currencyExchangeService.toPLN(transportAmount, transportCurrency.get('symbol'));
      companyAmount = this.currencyExchangeService.toPLN(companyAmount, companyCurrency.get('symbol'));
      return companyAmount >= transportAmount;
    }

  });

  _exports.default = _default;
});