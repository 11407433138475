define("apollo/pods/announcements/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'max'],
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    sessionAccount: Ember.inject.service('session-account'),
    applicationController: Ember.inject.controller('application'),
    announcements: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      async markAsRead(announcement) {
        if (announcement.get('isRead')) {
          return;
        }

        try {
          const path = this.get('apolloApiService').APOLLO_API.ANNOUNCEMENTS.MARK_AS_READ;
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: announcement.get('id')
          });

          if (response.ok) {
            announcement.set('isRead', true);
            this.get('applicationController').refreshAnnouncementCounter();
          }

          console.error(response);
        } catch (error) {
          console.error(error);
        }
      }

    }
  });

  _exports.default = _default;
});