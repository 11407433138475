define("apollo/pods/invitations/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_INVITATIONS_TAB']),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const self = this;
      const origin = window.location.origin + '#';
      const invitationModel = this.store.createRecord('invitation', {
        enabled: true,
        origin
      }); // Usuwamy typ Operator z listy typów partnerstw do wyboru przy zaproszeniu- A-9610

      let partnershipTypes = this.store.peekAll('partnershipType').filter(pt => pt.name !== 'OPERATOR');
      const principalCompanyId = localStorage.getItem('principalId');
      const roleGroups = this.store.peekRecord('company', principalCompanyId).get('roleGroups');
      const userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');
      const roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      const roleAccessQueuesAccessibility = this.get('sessionAccount').hasRole('ROLE_ACCESS_QUEUES_ACCESSIBILITY');
      const squares = roleAccessQueuesAccessibility ? this.get('store').findAll('square') : null;
      const queues = roleAccessQueuesAccessibility ? this.get('store').findAll('queue') : null;
      const companyId = this.get('sessionAccount.currentUser.company.id');

      if (!this.get('sessionAccount.isSuperUser')) {
        partnershipTypes = partnershipTypes.filter(pt => pt.name !== 'COURIER');
      }

      return new Promise(function (resolve) {
        self.store.query('company-ramp', {
          companyId
        }).then(companyRamps => {
          const hasCompanyRamps = !!companyRamps.get('length');
          const defaultCompanyRamps = hasCompanyRamps ? self.get('store').query('ramp', {
            rampIds: companyRamps.getEach('ramp.id')
          }) : null;
          const defaultCompanyWarehouses = hasCompanyRamps ? self.get('store').query('warehouse', {
            warehouseIds: companyRamps.getEach('warehouse.id')
          }) : null;
          resolve(Ember.RSVP.hash({
            invitation: invitationModel,
            partnershipTypes,
            roleGroups,
            roleGroupInvRestriction,
            squares,
            queues,
            defaultCompanyRamps,
            defaultCompanyWarehouses
          }));
        });
      });
    },
    actions: {
      willTransition: function () {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.get('controller').setProperties({
          errors: null,
          invitationSuccess: null,
          gusHasProblem: false,
          verifiedCompanyName: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});