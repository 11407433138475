define("apollo/pods/components/success-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Az5NiQ4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"alert alert-\",[21,\"alertStyle\"],\" success-alert no-vertical-margin alert-dismissible\"]]],[11,\"role\",\"alert\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"canBeDismissible\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"alert\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"strong\"],[9],[1,[21,\"strongText\"],false],[10],[0,\" \"],[7,\"span\"],[9],[1,[21,\"text\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/success-message/template.hbs"
    }
  });

  _exports.default = _default;
});