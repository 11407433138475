define("apollo/pods/transports/archive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vimqWJSx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"transport-table-nav\"],[9],[0,\"\\n\\n        \"],[7,\"div\"],[9],[0,\"\\n            \"],[1,[27,\"transports/search-transports\",null,[[\"archive\",\"query\",\"start\",\"stop\",\"transportType\"],[true,[23,[\"query\"]],[23,[\"start\"]],[23,[\"stop\"]],[23,[\"model\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"allAvailableTransportTypes\",\"length\"]],1],null]],null,{\"statements\":[[0,\"                \"],[1,[27,\"transports/choose-type\",null,[[\"archive\",\"transportTypes\",\"query\",\"start\",\"stop\"],[true,[23,[\"allAvailableTransportTypes\"]],[23,[\"query\"]],[23,[\"start\"]],[23,[\"stop\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"row transport-tables-container\"],[9],[0,\"\\n        \"],[1,[27,\"transports/transports-table\",null,[[\"transportType\",\"archive\",\"searchParams\",\"goToWarehouse\"],[[23,[\"model\"]],[23,[\"archive\"]],[23,[\"searchParams\"]],[27,\"action\",[[22,0,[]],\"goToWarehouse\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"outlet\",[\"modal\"],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/transports/archive/template.hbs"
    }
  });

  _exports.default = _default;
});