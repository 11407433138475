define("apollo/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "apollo/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  $.support.cors = true;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, _emberData.default.BuildURLMixin, {
    sessionAccount: service('session-account'),
    host: _environment.default.serverURL,

    authorize(xhr) {
      const token = this.get('sessionAccount').get('token');

      if (!token) {
        return;
      }

      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    },

    updateRecord: function (store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, "PUT", {
        data: data
      });
    }
  });

  _exports.default = _default;
});