define("apollo/pods/components/transport-types/package-type-settings-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    packageTypes: Ember.computed(function () {
      return this.get('store').peekAll('packageType');
    }),
    actions: {
      save() {
        const pttt = this.get('packageTypeTransportType');

        if (!pttt.validate()) {
          return;
        }

        pttt.save().then(() => {
          this.set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
        }).catch(response => {
          this.set('errors', response.errors);
        });
      },

      sendDelete() {
        this.delete(this.get('packageTypeTransportType'));
      },

      sendCancel() {
        this.cancel(this.get('packageTypeTransportType'));
      }

    }
  });

  _exports.default = _default;
});