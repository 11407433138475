define("apollo/pods/users/activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PIbCdua8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n\\n    \"],[1,[27,\"user-nav-header\",null,[[\"user\"],[[23,[\"user\"]]]]],false],[0,\"\\n\\n\"],[4,\"expandable-panel\",null,[[\"title\",\"customIconClass\",\"componentName\",\"defaultOpen\"],[[27,\"t\",[\"users.activity\"],null],\"fa fa-history\",\"user-activity\",true]],{\"statements\":[[0,\"        \"],[1,[27,\"logs-section\",null,[[\"logsProxy\",\"mode\",\"class\"],[[23,[\"model\",\"logs\"]],\"USER\",\"no-scroll\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"pagination-buttons\",null,[[\"url\",\"page\",\"max\",\"totalPages\"],[\"users.activity\",[23,[\"page\"]],[23,[\"max\"]],[23,[\"model\",\"logs\",\"meta\",\"totalPages\"]]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/users/activity/template.hbs"
    }
  });

  _exports.default = _default;
});