define("apollo/pods/transaction/modals/choose-carrier/transaction-prices-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Szmn7EZw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"transaction\",\"showPriceField\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"transaction/modals/choose-carrier/transaction-prices-section/price-label\",null,[[\"errorModel\",\"errorFieldName\",\"error\",\"label\",\"price\",\"isPriceDisabled\",\"selectedCurrency\",\"currencies\",\"isCurrencyDisabled\"],[[23,[\"transaction\"]],\"price\",[23,[\"transaction\",\"errors\",\"price\"]],[23,[\"label\"]],[23,[\"transaction\",\"price\"]],[23,[\"priceUpdateDisabled\"]],[23,[\"transaction\",\"currency\"]],[23,[\"currencies\"]],[23,[\"priceUpdateDisabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"transaction\",\"showPriceForDeliveriesField\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"transaction/modals/choose-carrier/transaction-prices-section/delivery-price-labels\",null,[[\"transaction\"],[[23,[\"transaction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/transaction/modals/choose-carrier/transaction-prices-section/template.hbs"
    }
  });

  _exports.default = _default;
});