define("apollo/pods/components/server-table/component", ["exports", "ember-models-table/components/models-table-server-paginated", "apollo/pods/themes/table-theme"], function (_exports, _modelsTableServerPaginated, _tableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = _modelsTableServerPaginated.default.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    // eslint-disable-next-line ember/no-observers
    filteredContentChanged: Ember.observer('filteredContent', function () {
      if (this.onFilteredContentChanged) {
        this.onFilteredContentChanged(this.get('filteredContent'));
      }
    }),

    willDestroyElement() {
      this._super(...arguments);

      this.set('saveSuccessMessage', '');
    },

    didRender: function () {
      this._super(...arguments);

      const self = this;
      Ember.run.next(function () {
        self.resizeTableHeader();
      });
    },
    filterQueryParameters: {
      globalFilter: 'globalSearch',
      sort: 'sort',
      sortDirection: 'sortDirection',
      page: 'page',
      pageSize: 'max'
    },
    themeInstance: Ember.computed('', function () {
      // https://stackoverflow.com/questions/34970881/inject-a-service-into-an-ember-object-not-an-ember-controller
      return _tableTheme.default.create({
        container: Ember.getOwner(this)
      });
    }),
    pageSize: Ember.computed('maxRowsInTable', function () {
      return this.get('maxRowsInTable') || 10;
    }),
    clearFilters: Ember.observer('clear', function () {
      this.send('clearFilters');
    }),
    saveUser: Ember.observer('maxRowsInTable', function () {
      this.send('saveUser');
    }),
    resizeTableHeader: function () {
      const self = this;
      Ember.run.later(function () {
        if (!self.isDestroyed) {
          $('thead.companies-table-header th').each(function (_, elem) {
            const name = elem.className.match('column-(.*?)[a-zA-Z]*')[0].split('-')[1];
            const windowInnerWidth = window.innerWidth;
            const numOfCells = $('.table-header').length / 2;
            const defaultWidth = Math.floor(windowInnerWidth / (numOfCells + 1));
            const maxCellWidth = Math.max(...Object.values($("td[data-field-name='" + name + "'] a, td[data-field-name='" + name + "'] span")).filter(e => e.offsetWidth !== undefined).map(e => e.offsetWidth));
            const width = maxCellWidth || defaultWidth;
            $(this).outerWidth(isFinite(width) ? width : defaultWidth);
          });
        }
      });
    },
    actions: {
      saveUser: function () {
        const user = this.get('sessionAccount.currentUser'); // TODO: sparametryzowac to paskudztwo

        user.set('maxRowsInCompaniesTable', this.get('pageSize'));
        user.save({
          backgroundReload: false
        }).then(() => {
          this.set('saveSuccessMessage', this.get('intl').t('common.saveSuccessMessage')); // nie wiem czemu tabela wraca do stanu początkowego dlatego musimy ją przeładować. Nie wiem jak to inaczej zrobić

          this._loadData();
        });
      }
    }
  });

  _exports.default = _default;
});