define("apollo/pods/components/modals/transaction-invoice-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),

    async init() {
      this._super(...arguments);

      const transaction = await this.get('transaction');
      const transactionFreightPrice = transaction.get('price');
      transaction.set('invoicePrice', transactionFreightPrice);
    },

    currencyList: Ember.computed('transaction', function () {
      return this.get('store').peekAll('currency');
    }),
    actions: {
      async save() {
        const self = this;
        const transaction = await this.get('transaction');
        const transport = transaction.get('transport');
        this.set('transaction.isInvoiceForm', true);

        if (!transaction.validate()) {
          return;
        }

        const path = this.get('apolloApiService').APOLLO_API.TRANSACTIONS.SAVE_INVOICE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: transaction.get('id')
          }, {
            body: JSON.stringify(transaction)
          });

          if (response.ok) {
            this.set('transaction.isInvoiceForm', false);
            this.get('store').findRecord('transport', transport.get('id')).then(transport => {
              transport.reload();
              self.actionProgressToast.showSuccessToast(this.get('intl').t('notificationToast.transactions.invoice.success'));
              self.onClose();
            });
          }
        } catch (error) {
          this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.transactions.invoice.error') + error);
          console.error(error);
        }
      },

      onCloseHandler: function () {
        this.onClose();
      },
      cancel: function () {
        console.debug('Zamykamy okienko dodawania faktury...');
        this.get('transaction.content').rollback();
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});