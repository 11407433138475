define("apollo/services/api/checkpoint-api-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Serwis odpowiedzialny za obsługę żądań API do serwera Apollo.
   */
  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),

    /**
     * @param checkpoint
     * @returns {Promise<Response>}
     */
    confirmCheckpoint(checkpoint, prevalidate = true) {
      const checkpointId = checkpoint.get('id');
      const executionDate = checkpoint.get('executionDate');
      const params = {
        id: checkpointId
      };
      const searchParams = {
        prevalidate
      };

      if (executionDate) {
        searchParams.executionDate = moment(executionDate).format();
      }

      if (checkpoint.get('skipGeneratingSubtasks')) {
        searchParams.skipGeneratingSubtasks = true;
      }

      const apolloApiService = this.get('apolloApiService');
      const path = apolloApiService.APOLLO_API.CHECKPOINTS.CONFIRM;
      return apolloApiService.callApolloApi(path, params, null, searchParams);
    },

    saveModalData(checkpointConfirmationModal) {
      const apolloApiService = this.get('apolloApiService');
      const path = apolloApiService.APOLLO_API.CHECKPOINTS.SAVE_MODAL;
      const body = JSON.stringify(checkpointConfirmationModal);
      return apolloApiService.callApolloApi(path, null, {
        body
      });
    },

    rejectCheckpoint(checkpoint) {
      const checkpointId = checkpoint.get('id');
      const params = {
        id: checkpointId
      };
      const apolloApiService = this.get('apolloApiService');
      const path = apolloApiService.APOLLO_API.CHECKPOINTS.REJECT;
      return apolloApiService.callApolloApi(path, params, null);
    }

  });

  _exports.default = _default;
});