define("apollo/services/validation-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isPositiveNumber: function (value) {
      if (Ember.isEmpty(value)) {
        return true;
      }

      return value >= 0;
    },
    isPositiveInteger: function (value) {
      if (Ember.isEmpty(value)) {
        return true;
      }

      return Number.isInteger(Number(value)) && value >= 0;
    }
  });

  _exports.default = _default;
});