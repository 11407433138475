define("apollo/pods/auctions/modals/auction-form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    amountOfCoverService: Ember.inject.service(),
    language: Ember.computed(function () {
      return this.get('intl.locale')[0];
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    carriers: Ember.computed('model.carriers', function () {
      const self = this;
      return this.get('model.carriers').filter(c => {
        return c.get('disabled') === false && self.amountOfCoverService.hasEnoughAmountOfCover(self.get('transport'), c);
      }).sortBy('shortName');
    }),
    blockedCarriers: Ember.computed('model.carriers', function () {
      const self = this;
      return this.get('model.carriers').filter(c => {
        return c.get('disabled') === true || !self.amountOfCoverService.hasEnoughAmountOfCover(self.get('transport'), c);
      }).sortBy('shortName');
    }),
    auctionSetting: Ember.computed('transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
    }),
    showBlockedCompaniesSection: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');
      return !!auctionSetting && auctionSetting.get('showBlockedCompaniesSection');
    }),
    showCompanyGroupsSection: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');
      return !!auctionSetting && auctionSetting.get('showCompanyGroupsSection');
    }),
    sortedCarriers: Ember.computed('carriers.@each.shortName', function () {
      return this.get('carriers').sortBy('shortName');
    }),
    auction: Ember.computed('model', function () {
      return this.get('model');
    }),
    transport: Ember.computed('auction.transport', function () {
      return this.get('auction.transport');
    }),
    title: Ember.computed('transport.{identifier,formattedCities}', function () {
      return `${this.get('transport.identifier')}: ${this.get('transport.formattedCities')}`;
    }),
    showPriceRangeFields: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('showPriceRangeFields');
    }),
    canAcceptWithMinPrice: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('canAcceptWithMinPrice');
    }),
    // Carriers' checkboxes
    proxiedCarriers: Ember.computed('sortedCarriers.[]', 'auction', 'auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');
      const defaultValue = auctionSetting ? auctionSetting.get('selectAllCarriersByDefault') : false;
      return this.get('sortedCarriers').map(carrier => {
        return Ember.ObjectProxy.create({
          content: carrier,
          checked: defaultValue
        });
      });
    }),
    sortedGroups: Ember.computed('model.carriersGroups.[]', function () {
      return this.get('model.carriersGroups').sortBy('nameInAppropriateLanguage');
    }),
    groupTypes: Ember.computed('sortedGroups', function () {
      return this.get('store').peekAll('contractors-group-type');
    }),
    proxiedGroups: Ember.computed('sortedGroups.[]', 'auction', function () {
      return this.get('sortedGroups').map(group => {
        return Ember.ObjectProxy.create({
          content: group,
          groupType: group.get('groupType'),
          checked: false
        });
      });
    }),
    proxiedCheckedCarriers: Ember.computed.filterBy('proxiedCarriers', 'checked', true),
    checkedCarriers: Ember.computed.mapBy('proxiedCheckedCarriers', 'content'),
    allCarriersSelected: Ember.computed('carriers.length', 'checkedCarriers.length', function () {
      return this.get('carriers.length') === this.get('checkedCarriers.length');
    }),
    proxiedCheckedGroups: Ember.computed.filterBy('proxiedGroups', 'checked', true),
    checkedGroups: Ember.computed.mapBy('proxiedCheckedGroups', 'content'),
    allGroupsSelected: Ember.computed('sortedGroups.length', 'checkedGroups.length', function () {
      return this.get('sortedGroups.length') === this.get('checkedGroups.length');
    }),
    actions: {
      setFinishDate(dateValues) {
        Ember.run.next(() => {
          this.get('model').set('plannedFinishTime', dateValues[0]);
        });
      },

      toggleAllCarriers() {
        const shouldBeChecked = !this.get('allCarriersSelected');
        this.get('proxiedCarriers').forEach(proxyCarrier => {
          proxyCarrier.set('checked', shouldBeChecked);
        });
      },

      toggleAllGroups() {
        const self = this;
        const shouldBeChecked = !this.get('allGroupsSelected');
        this.get('proxiedGroups').forEach(proxyGroup => {
          self.send('toggleGroup', proxyGroup, shouldBeChecked);
        });
      },

      toggleGroup(group, shouldBeChecked) {
        const self = this;
        shouldBeChecked = shouldBeChecked || !group.get('checked');
        group.set('checked', shouldBeChecked);
        const checkedGroupIds = self.get('checkedGroups').getEach('id');
        const carriersWhoShouldBe = this.get('proxiedCarriers').filter(pc => {
          const contractorsGroupIds = pc.get('partnershipWithPrincipal.contractorsGroups').getEach('id');
          return contractorsGroupIds.some(id => checkedGroupIds.includes(id));
        });
        carriersWhoShouldBe.forEach(proxyCarrier => {
          proxyCarrier.set('checked', true);
        });
        this.get('proxiedCarriers').filter(pc => {
          return !carriersWhoShouldBe.getEach('id').includes(pc.get('id'));
        }).forEach(proxyCarrier => {
          proxyCarrier.set('checked', false);
        });
      },

      save() {
        console.log(`Saving auction for transport ${this.get('transport.id')}..`);
        const self = this;
        this.set('savingInProgress', true);
        this.get('auction.companies').clear();
        this.get('checkedCarriers').forEach(carrier => {
          this.get('auction.companies').pushObject(carrier);
        });

        if (!this.get('auction').validate()) {
          console.log('Auction did not pass validation.');
          self.set('savingInProgress', false);
          return;
        }

        this.get('auction').save().then(savedAuction => {
          console.log(`Auction successfully saved with id ${savedAuction.get('id')}!`);
          self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
          self.setProperties({
            savingInProgress: false,
            errors: []
          });
          self.send('hideModal');
        }).catch(response => {
          self.setProperties({
            savingInProgress: false,
            errors: response.errors
          });
        });
      },

      close: function () {
        this.send('hideModal');
      },

      cancel() {
        console.debug('Wycofujemy zmiany z okienka aukcji...');

        if (!this.get('auction.id')) {
          this.get('transport.auctions').removeObject(this.get('auction'));
        }

        this.set('errors', []);
      }

    }
  });

  _exports.default = _default;
});