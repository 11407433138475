define("apollo/pods/custom-field-definition/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    changeTracker: Object.freeze({
      auto: true
    }),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    FIELD_TYPES: Object.freeze({
      TYPE_TEXT: 'text',
      TYPE_DATE: 'date',
      TYPE_DATETIME: 'datetime',
      TYPE_NUMBER: 'number',
      TYPE_SELECT: 'select',
      TYPE_TEXT_AREA: 'text_area'
    }),
    TRANSPORT_PANEL_VALUES: Ember.A(['ADVICE', 'DISCREPANCY_REPORT', 'WEIGHT', 'DOCUMENTS', 'LOGS', 'INSPECTION']),
    TRANSPORT_PANEL_CLASS: Object.freeze({
      ADVICE: 'advice-panel',
      DISCREPANCY_REPORT: 'discrepancy-report-panel',
      WEIGHT: 'weight-panel',
      DOCUMENTS: 'documents-panel',
      LOGS: 'logs-panel',
      INSPECTION: 'inspection-panel'
    }),
    transportType: belongsTo('transportType', {
      async: true
    }),
    task: belongsTo('task', {
      async: true
    }),
    options: hasMany('customFieldOption', {
      async: true
    }),
    type: attr('string'),
    name: attr('string'),
    textAreaRowsNumber: attr('number', {
      defaultValue() {
        return 2;
      }

    }),
    englishName: attr('string'),
    obligatory: attr('boolean'),
    visibleInTable: attr('boolean'),
    visibleDuringTransportCreation: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    visibleInDiscrepancyReportSection: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    visibleInInspectionPanelSection: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    visibleInAdviceSection: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    visibleInWeightSection: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    visibleOnTimeWindow: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    visibleInOfferForm: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    enabled: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    filterInArchive: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    includedInPriceList: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requiredInDelayedTransport: attr('boolean'),
    delayedTask: belongsTo('task'),
    timeIntervalValue: attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    timeIntervalUnit: attr('string', {
      defaultValue() {
        return 's';
      }

    }),
    visibilityDependsOnDefinition: belongsTo('customFieldDefinition', {
      async: true,
      inverse: null
    }),
    visibilityDependsOnOption: belongsTo('customFieldOption', {
      async: true,
      inverse: null
    }),
    defaultOption: belongsTo('customFieldOption', {
      async: true,
      inverse: null
    }),
    autocompleteEnabled: attr('boolean'),
    side: attr('string'),
    idx: attr('number'),
    minValue: attr('number'),
    maxValue: attr('number'),
    numberDecimalFormat: attr('string'),
    visibleInDeliveryDocument: attr('boolean'),
    visibleInTransportWeightReceipt: attr('boolean'),
    deliveryAlias: attr('string'),
    alias: attr('string'),
    validations: {
      type: {
        presence: {
          message: 'blankSelect'
        }
      },
      name: {
        presence: {
          message: 'blank'
        }
      },
      englishName: {
        presence: {
          message: 'blank'
        }
      },
      minValue: {
        custom: {
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            if (value.toString().includes(',')) {
              return false;
            }

            return !(model.get('maxValue') && model.get('maxValue') < value);
          },
          message: function (key, value, model) {
            if (value.toString().includes(',')) {
              return 'dotSeparator';
            }

            if (model.get('maxValue') && model.get('maxValue') < value) {
              return 'minHigherThanMax';
            }
          }
        }
      },
      maxValue: {
        custom: {
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            if (value.toString().includes(',')) {
              return false;
            }

            return !(model.get('minValue') && model.get('minValue') > value);
          },
          message: function (key, value, model) {
            if (value.toString().includes(',')) {
              return 'dotSeparator';
            }

            if (model.get('minValue') && model.get('minValue') > value) {
              return 'maxLowerThenMin';
            }
          }
        }
      },
      numberDecimalFormat: {
        custom: {
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            return /^[#,.0i_]+$/.test(value);
          },
          message: 'regex'
        }
      },
      obligatory: {
        custom: {
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            if (model.get('visibilityDependsOnDefinition.id')) {
              return true;
            }

            return model.get('task.id') || model.get('visibleDuringTransportCreation');
          },
          message: 'invalid'
        }
      },
      options: {
        custom: {
          validation: function (key, value, model) {
            if (model.get('type') !== 'select') {
              return true;
            }

            return value.get('length') >= 1;
          },
          message: 'empty'
        }
      },
      visibilityDependsOnDefinition: {
        custom: {
          validation: function (key, value, model) {
            if (!model.get('showDependencySection')) {
              return true;
            }

            return !!value.get('id');
          },
          message: 'blankSelect'
        }
      },
      textAreaRowsNumber: {
        custom: {
          validation: function (key, value, model) {
            if (model.get('type') !== 'text_area') {
              return true;
            }

            return value && value >= 1;
          },
          message: 'textAreaSize'
        }
      },
      timeIntervalValue: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('requiredInDelayedTransport')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            if (!model.get('requiredInDelayedTransport')) {
              return true;
            }

            return value && /[0-9]*/.test(value);
          },
          message: 'number'
        }]
      },
      timeIntervalUnit: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('requiredInDelayedTransport')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      }
    },
    visibleInTransportForm: Ember.computed('visibleInInspectionPanelSection', 'visibleInDiscrepancyReportSection', 'visibleInAdviceSection', 'visibleInWeightSection', 'visibleInOfferForm', function () {
      return !this.get('visibleInInspectionPanelSection') && !this.get('visibleInDiscrepancyReportSection') && !this.get('visibleInAdviceSection') && !this.get('visibleInWeightSection');
    }),
    showDependencySection: Ember.computed('visibilityDependsOnDefinition.id', function () {
      return this.get('visibilityDependsOnDefinition.id');
    }),
    showDefaultOptionSection: Ember.computed('defaultOption.id', function () {
      return this.get('defaultOption.id');
    }),
    enabledOptions: Ember.computed('options.@each.enabled', function () {
      return this.get('options').filterBy('enabled');
    }),
    hiddenInTable: Ember.computed('visibleInTable', function () {
      return !this.get('visibleInTable');
    }),
    isTextType: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_TEXT;
    }).readOnly(),
    isTextAreaType: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_TEXT_AREA;
    }).readOnly(),
    isNumberType: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_NUMBER;
    }).readOnly(),
    isDateType: Ember.computed('type', function () {
      return [this.get('FIELD_TYPES').TYPE_DATE, this.get('FIELD_TYPES').TYPE_DATETIME].includes(this.get('type'));
    }).readOnly(),
    isOnlyDateType: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_DATE;
    }),
    isDateTimeType: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_DATETIME;
    }).readOnly(),
    isSelectType: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_SELECT;
    }).readOnly(),
    findDateFormat: Ember.computed('type', function () {
      return this.get('type') === this.get('FIELD_TYPES').TYPE_DATETIME ? 'd.m.Y H:i' : 'd.m.Y';
    }),
    availableFieldTypes: Ember.computed(function () {
      const text = this.get('FIELD_TYPES').TYPE_TEXT;
      const number = this.get('FIELD_TYPES').TYPE_NUMBER;
      const date = this.get('FIELD_TYPES').TYPE_DATE;
      const datetime = this.get('FIELD_TYPES').TYPE_DATETIME;
      return [text, number, date, datetime, this.get('FIELD_TYPES').TYPE_SELECT, this.get('FIELD_TYPES').TYPE_TEXT_AREA];
    }).readOnly(),
    nameInAppropriateLanguage: Ember.computed('name', 'englishName', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('name', 'englishName');
      return this.get(propertyName);
    }).readOnly(),
    englishNameTrimmed: Ember.computed('englishName', function () {
      if (!this.get('englishName')) {
        return '';
      }

      return this.get('englishName').replace(/ /g, '-').toLowerCase();
    }),
    hasErrors: Ember.computed('errors.length', 'options.@each.numberOfErrors', function () {
      if (this.get('errors.length') > 0) {
        return true;
      }

      return this.get('options').filterBy('numberOfErrors').get('length') > 0;
    }).readOnly(),
    canBeViewed: Ember.computed('transportType', 'id', function () {
      const ars = this.get('transportType.content').findPositiveAuthorityRestrictionsByActionNameAndCustomFieldDefinition('ACCESS_CUSTOM_FIELD', this.get('id'));
      return ars && ars.get('length') !== 0;
    }),
    usedForDeliveries: Ember.computed('deliveryAlias', function () {
      return !!this.get('deliveryAlias');
    })
  });

  _exports.default = _default;
});