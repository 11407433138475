define("apollo/pods/route-address/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    address: belongsTo('address', {
      async: false
    }),
    company: belongsTo('company', {
      async: true
    }),
    route: belongsTo('route', {
      async: true
    }),
    warehouse: belongsTo('warehouse', {
      async: true
    }),
    enabled: attr('boolean'),
    stepType: attr('string'),
    idx: attr('number'),
    stepTypeIdx: attr('number'),
    dateCreated: _emberData.default.attr('isodate'),
    displayIdx: Ember.computed('stepTypeIdx', function () {
      return this.get('stepTypeIdx') + 1;
    }),
    validations: {
      address: {
        custom: [{
          validation: function (key, value) {
            return value && value.get('id');
          },
          message: 'blankSelect'
        }]
      }
    }
  });

  _exports.default = _default;
});