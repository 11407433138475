define("apollo/pods/index-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    translationKey: _emberData.default.attr('string'),
    showGlobalNumber: _emberData.default.attr('boolean'),
    showLocalNumber: _emberData.default.attr('boolean'),
    showName: _emberData.default.attr('boolean'),
    showDocumentNumber: _emberData.default.attr('boolean'),
    showTransportNumber: _emberData.default.attr('boolean'),
    showQuantity: _emberData.default.attr('boolean'),
    showNotes: _emberData.default.attr('boolean'),
    hasProductsEnabled: _emberData.default.attr('boolean'),
    hasProductsShortNameEnabled: _emberData.default.attr('boolean'),
    showIncompleteLoading: _emberData.default.attr('boolean'),
    showPasteFromExcel: _emberData.default.attr('boolean'),
    requiresGlobalNumber: _emberData.default.attr('boolean'),
    requiresName: _emberData.default.attr('boolean'),
    notesPlaceholder: Ember.computed('', function () {
      return this.getTranslation('notes');
    }),
    disableConfirmation: Ember.computed('', function () {
      return this.getTranslation('disableConfirm');
    }),
    sectionLabel: Ember.computed('', function () {
      return this.getTranslation('sectionLabel');
    }),

    getTranslation(propertyName) {
      const transportType = this.get('transportType');
      const indexSetting = this.get('sessionAccount').getIndexSetting(transportType.get('id'));

      if (!indexSetting) {
        return this.get('intl').t(`index.INDEX.${propertyName}`);
      }

      const key = indexSetting.get('translationKey');
      return this.get('intl').t(`index.${key}.${propertyName}`);
    }

  });

  _exports.default = _default;
});