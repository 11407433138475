define("apollo/pods/components/show-notes/component", ["exports", "apollo/pods/components/show-notes/template", "apollo/pods/components/custom-popover/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = _component.default.extend({
    layout: _template.default,
    sessionAccount: service('session-account'),
    canAddNote: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('ADD_NOTES');
    }),
    actions: {
      showNotes(transport) {
        this.get('transport.notes').then(() => {
          this.showPopover(this.element, '.notes-button', '.notes-popover');
          $(this.element).find('#noteInput').focus();
          this.set("action", "markNotesAsReadOff");
          this.sendAction('action', transport);
        });
      },

      addNote: function () {
        const $el = $(this.element);
        const noteText = $el.find('#noteInput').val();
        const transport = this.get('transport');
        const id = transport.get('id');

        if (noteText) {
          this.set("action", "addNote");
          this.sendAction('action', noteText, id);
          this.destroyPopovers();
        }
      }
    }
  });

  _exports.default = _default;
});