define("apollo/pods/reset-password/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    session: service('session'),
    sessionAccount: service('session-account'),
    intl: Ember.inject.service(),
    errors: [],
    actions: {
      resetPassword() {
        const self = this;
        let user = self.get('sessionAccount').get('currentUser');
        const passwords = self.getProperties('password', 'confirmedPassword');

        if (passwords.password) {
          passwords.password = passwords.password.trim();
        }

        if (passwords.password !== passwords.confirmedPassword || passwords.password == null || passwords.confirmedPassword == null) {
          const message = self.get('intl').t('resetPasswordWindow.enteredPasswordsIsNotIdentical');
          this.set('errorMessage', message);
          return;
        }

        user.set('password', passwords.password);
        user.save().then(function () {
          self.get('sessionAccount').set('passwordExpired', false);
          self.transitionToRoute('defaultTransports');
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      invalidateSession() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});