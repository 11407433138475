define("apollo/pods/components/transport-row-invoice-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    shouldShowInvoiceModal: false,
    canAddInvoiceData: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_ADD_INVOICE_IN_TRANSACTION');
    }),
    canEditInvoiceData: Ember.computed(function () {
      const actionName = 'ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION';
      const transport = this.get('transaction').get('transport');
      return this.get('authorityCheckerService').actionCanBePerformed(actionName, transport);
    }),
    shouldShowInvoiceData: Ember.computed('transaction.invoiceNumber', function () {
      return this.get('transaction').get('invoiceNumber');
    }),

    _setCurrency(transaction) {
      const transportType = this.get('transaction.transport').get('transportType');
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
      const symbol = transactionSetting ? transactionSetting.get('defaultInvoiceCurrency') : 'PLN';
      const currency = this.get('store').peekAll('currency').filterBy('symbol', symbol).get('firstObject');
      transaction.set('invoiceCurrency', currency);
    },

    actions: {
      toggleInvoiceFormModal() {
        this.toggleProperty('shouldShowInvoiceModal');

        if (this.get('shouldShowInvoiceModal')) {
          this._setCurrency(this.get('transaction'));
        }
      }

    }
  });

  _exports.default = _default;
});