define("apollo/pods/components/transports/transports-table/action-progress-toast/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KDqTByf6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"toast\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"toast-content\"],[9],[0,\"\\n        \"],[7,\"span\"],[12,\"class\",[28,[\"toast-icon \",[21,\"toastType\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"                \"],[7,\"img\"],[11,\"src\",\"/assets/images/ajax-loader.gif\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[27,\"fa-icon\",[[23,[\"displayIcon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"toast-message \",[21,\"toastType\"]]]],[9],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"toast-title\"],[9],[1,[21,\"toastTitle\"],false],[10],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"toast-text\"],[9],[0,\"\\n                \"],[1,[21,\"toastMessage\"],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"toast-text\"],[9],[0,\"\\n                \"],[1,[21,\"toastDetails\"],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"close\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"close\"],null]],[9],[1,[27,\"fa-icon\",[\"xmark\"],null],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"toast-progress\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/action-progress-toast/template.hbs"
    }
  });

  _exports.default = _default;
});