define("apollo/pods/role-group-statistics-report/model", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend({
    roleGroup: belongsTo('roleGroup', {
      async: true
    }),
    report: belongsTo('statisticsReport', {
      async: true
    })
  });

  _exports.default = _default;
});