define("apollo/pods/components/transaction/courier-transaction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      createTransactionAndOpenChooseCourierModal() {
        console.log('Przygotowujemy i pokazujemy okienko wyboru kuriera...');
        this.get('transport').reload().then(() => {
          const transport = this.get('transport');
          const steps = transport.get('steps');
          const courierPricings = transport.get('courierPricings');
          const firstLoadStep = steps.find(s => s.stepTypeName === 'load' && s.orderInTransport === 0);
          const lastUnloadStep = steps.find(s => s.stepTypeName === 'unload' && s.orderInTransport === steps.length - 1);
          const courierDate = moment(firstLoadStep.get('minAdviceDate')).format('YYYY-MM-DD');
          const courierHourMin = moment(firstLoadStep.get('minAdviceDate')).format('HH:mm');
          const courierHourMax = moment(firstLoadStep.get('maxAdviceDate')).format('HH:mm');
          const transaction = this.get('store').createRecord('transaction', {
            transport
          });
          this.set('action', 'showModal');
          const services = [];
          const couriers = [];
          courierPricings.forEach(it => {
            if (it.get('enabled') === true && it.get('pricingSuccesses').length > 0) {
              couriers.push(it.courierCompany);
            }

            it.pricingSuccesses.forEach(success => services.push(success));
          });
          const model = {
            transaction,
            courierDate,
            courierHourMin,
            courierHourMax,
            loadCity: firstLoadStep.get('address.city'),
            unloadCity: lastUnloadStep.get('address.city'),
            couriers,
            services
          };
          this.sendAction('action', 'transaction.modals.choose-courier', model);
        });
      }

    }
  });

  _exports.default = _default;
});