define("apollo/pods/company/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    validationService: Ember.inject.service('validation-service'),
    amountOfCoverService: Ember.inject.service(),
    users: hasMany('user', {
      async: true,
      inverse: null
    }),
    userGroups: hasMany('userGroup', {
      async: true
    }),
    roleGroups: hasMany('roleGroup', {
      async: true
    }),
    partnerships: hasMany('partnership', {
      async: true,
      inverse: null,
      deepEmbedded: ['partnershipType', 'principalCompany']
    }),
    suppliers: hasMany('company', {
      async: true
    }),
    availableRoles: hasMany('role', {
      async: true
    }),
    transportTypes: hasMany('transportType', {
      async: true
    }),
    driverLanguages: hasMany('country', {
      async: true
    }),
    drivers: hasMany('driver', {
      async: true,
      inverse: 'owner'
    }),
    trucks: hasMany('truck', {
      async: true,
      inverse: 'owner'
    }),
    workers: hasMany('worker', {
      async: true,
      inverse: 'company'
    }),
    awaitingInvitations: hasMany('invitation', {
      async: true,
      inverse: null
    }),
    country: belongsTo('country', {
      async: true
    }),
    headquarter: belongsTo('address', {
      async: true,
      deepEmbedded: ['country']
    }),
    partnershipType: belongsTo('partnershipType', {
      async: true
    }),
    routeOffer: belongsTo('routeOffer', {
      async: true
    }),
    isPrincipal: attr('boolean'),
    isPrincipalInGeneral: attr('boolean'),
    name: attr('string'),
    shortName: attr('string'),
    taxIdNumber: attr('string'),
    taxIdIsoNumber: attr('string'),
    regon: attr('string'),
    krs: attr('string'),
    legalStatus: attr('string'),
    email: attr('string'),
    alias: attr('string'),
    gusVerified: attr('boolean'),
    enabled: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),

    /**
     * Służy do określenia czy firma jest zablokowana. Jeśli równe true to do firmy nie przekażemy zlecenia ani nie wystawimy aukcji.
     */
    disabled: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    changeTaxIdNumber: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    allowsPeriodicTransport: attr('boolean'),
    maxNumberOfAccounts: attr('number'),
    pricingPlan: attr('string'),
    isCourier: attr('boolean'),
    isLdapCompany: attr('boolean'),
    validations: {
      headquarter: {
        relations: ['belongsTo']
      },
      name: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            return value;
          },
          message: 'blank'
        }]
      },
      country: {
        presence: {
          message: 'blankSelect'
        }
      },
      partnershipType: {
        custom: [{
          validation: function (key, value) {
            if (!value.content) {
              return false;
            }

            return value.content;
          },
          message: function (key, value, model) {
            return model.get('intl').t('company.chooseCompanyTypeHint');
          }
        }]
      },
      taxIdNumber: {
        presence: {
          message: 'blank'
        }
      },
      taxIdIsoNumber: {
        presence: {
          message: 'blank'
        }
      },
      maxNumberOfAccounts: {
        custom: [{
          validation: function (key, value, model) {
            return model.get('validationService').isPositiveInteger(value);
          },
          message: 'positiveNumber'
        }]
      },
      pricingPlan: {
        presence: {
          message: 'blank'
        }
      },
      regon: {
        custom: [{
          validation: function (key, value, model) {
            const hasAccessToApplies = model.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES'); // validacja obowiązuje tylko jeśli pryncypał ma dostep do zgłoszeń i firma jest przewoźnikiem

            if (hasAccessToApplies !== 'true' || !model.get('isCarrier')) {
              return true;
            }

            if (!model.get('shouldHaveRegon')) {
              return true;
            }

            return value;
          },
          message: 'blank'
        }]
      },
      krs: {
        custom: [{
          validation: function (key, value, model) {
            const hasAccessToApplies = model.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES'); // validacja obowiązuje tylko jeśli pryncypał ma dostep do zgłoszeń i firma jest przewoźnikiem

            if (hasAccessToApplies !== 'true' || !model.get('isCarrier')) {
              return true;
            }

            if (!model.get('shouldHaveKrs')) {
              return true;
            }

            return value;
          },
          message: 'blank'
        }]
      },
      legalStatus: {
        custom: [{
          validation: function (key, value, model) {
            const hasAccessToApplies = model.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES'); // validacja obowiązuje tylko jeśli pryncypał ma dostep do zgłoszeń i firma jest przewoźnikiem

            if (hasAccessToApplies !== 'true' || !model.get('isCarrier')) {
              return true;
            }

            return value;
          },
          message: 'blank'
        }]
      }
    },
    symbol: Ember.computed('shortNameOrName', function () {
      return this.get('shortNameOrName') ? this.get('shortNameOrName').substr(0, 5) : '';
    }),
    sortedUsers: Ember.computed('users.@each.name', function () {
      return this.get('users').sortBy('name');
    }),
    shortNameOrName: Ember.computed('shortName', 'name', function () {
      return this.get('shortName') ? this.get('shortName') : this.get('name');
    }),
    partnershipWithPrincipal: Ember.computed('partnerships.@each.principalCompanyId', function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('partnerships').filterBy('principalCompanyId', principalCompanyId).get('firstObject');
    }),
    refreshPartnershipWithCurrentCompany: function () {
      this.set('partnershipWithCurrentCompany', this.findPartnershipWithCurrentCompany());
    },
    partnershipWithCurrentCompany: Ember.computed('partnerships.@each.{contractorCompanyId,principalCompanyId}', 'sessionAccount.currentCompany.id', function () {
      return this.findPartnershipWithCurrentCompany();
    }),
    findPartnershipWithCurrentCompany: function () {
      const currentCompanyId = this.get('sessionAccount.currentCompany.id');
      return this.get('partnerships').filterBy('principalCompanyId', currentCompanyId).get('firstObject');
    },
    isAuxiliaryPartner: Ember.computed('partnershipWithCurrentCompany.isAuxiliary', function () {
      return this.get('partnershipWithCurrentCompany.isAuxiliary');
    }),
    sortedPartnershipsWithPrincipal: Ember.computed('partnerships.@each.principalCompany', function () {
      return this.get('partnerships').filterBy('principalCompany.isPrincipal').sortBy('partnerships.name');
    }),

    /**
     * Pryncypałem firmy w danej przestrzeni może być albo aktualny pryncypał (w większości przypadków),
     * albo kontrahent aktualnego pryncypała - np. dostawca, który zaprosił przewoźnika.
     */
    principalCompanyInCurrentSpace: Ember.computed('isAuxiliaryPartner', 'partnershipWithCurrentCompany.parentPartnership.principalCompany', function () {
      if (this.get('isAuxiliaryPartner')) {
        return this.get('partnershipWithCurrentCompany.parentPartnership.principalCompany');
      } else {
        const principalCompanyId = localStorage.getItem('principalId');
        return this.get('store').peekRecord('company', principalCompanyId);
      }
    }),
    transportTypesThatCanBeCreated: Ember.computed('transportTypes.@each.canBeCreatedByRoleGroup', function () {
      return this.get('transportTypes').filterBy('canBeCreatedByRoleGroup', true);
    }),
    isCurrentPrincipal: Ember.computed('id', function () {
      return this.get('id') === localStorage.getItem('principalId');
    }),
    isCarrier: Ember.computed('partnershipWithPrincipal.partnershipType.isCarrier', function () {
      return this.get('partnershipWithPrincipal.partnershipType.isCarrier');
    }),
    isSupplier: Ember.computed('partnershipWithPrincipal.partnershipType.isSupplier', function () {
      return this.get('partnershipWithPrincipal.partnershipType.isSupplier');
    }),
    isServiceman: Ember.computed('partnershipWithPrincipal.partnershipType.isServiceman', function () {
      return this.get('partnershipWithPrincipal.partnershipType.isServiceman');
    }),
    typeName: Ember.computed('partnershipType.name', function () {
      return this.get('partnershipType.name');
    }),
    shouldHaveRegon: Ember.computed('country.ibanSymbol', function () {
      return this.get('country.ibanSymbol') === 'PL';
    }),
    shouldHaveKrs: Ember.computed('country.ibanSymbol', 'legalStatus', function () {
      return this.get('legalStatus') === 'limitedCompany' && this.get('country.ibanSymbol') === 'PL';
    }),
    numberOfUsers: Ember.computed('users.@each.id', function () {
      return this.hasMany('users').ids().length;
    }),
    productSetting: Ember.computed('', function () {
      const self = this;
      return this.get('store').peekAll('product-setting').find(is => is.get('company.id') === self.get('id'));
    }),
    customUserGroupsExists: Ember.computed('userGroups.@each.mainGroup', function () {
      return this.get('userGroups').filter(ug => !ug.get('mainGroup')).length > 0;
    }),
    principalInDifferentPrincipalSpace: Ember.computed('isPrincipal', 'isPrincipalInGeneral', function () {
      return this.get('isPrincipalInGeneral') && !this.get('isPrincipal');
    }),
    announcementAttributeName: Ember.computed('shortName', function () {
      return `announcement-company-${this.get('shortName').replace(' ', '-')}`;
    }),
    announcementLabel: Ember.computed('shortName', function () {
      return this.get('shortName');
    }),
    showPartnershipTable: Ember.computed('isPrincipalInGeneral', function () {
      return this.get('sessionAccount').hasRole('ROLE_SHOW_PARTNERSHIP_TABLE') && !this.get('principalInDifferentPrincipalSpace');
    }),
    showAwaitingInvitations: Ember.computed('isPrincipalInGeneral', function () {
      return this.get('sessionAccount').hasRole('ROLE_SHOW_AWAITING_INVITATIONS_TABLE') && !this.get('principalInDifferentPrincipalSpace');
    }),
    registrationInProgress: Ember.computed('partnershipWithPrincipal.status', function () {
      return this.get('partnershipWithPrincipal.status') === 'REGISTRATION_IS_IN_PROGRESS';
    }),
    hasRouteOffer: Ember.computed('routeOffer.id', function () {
      return !!this.get('routeOffer.id');
    }),
    routeOfferPrice: Ember.computed('routeOffer.price', function () {
      return this.get('routeOffer.price');
    }),
    nonDefaultUserGroups: Ember.computed('userGroups.{[],@each.mainGroup}', function () {
      return this.get('userGroups').filter(ug => !ug.get('mainGroup'));
    }),
    activeOCPInsurance: Ember.computed('partnershipWithPrincipal.@each.activeInsurances', function () {
      const partnership = this.get('partnershipWithPrincipal');
      const insurances = partnership.get('activeInsurances');

      if (!insurances) {
        return null;
      }

      const activeOCPs = insurances.filter(i => {
        return i.get('documentType.name') === 'INSURANCE_DOMESTIC_OCP';
      });
      return activeOCPs ? activeOCPs.get('firstObject') : null;
    }),
    activeOCSInsurance: Ember.computed('partnershipWithPrincipal.@each.activeInsurances', function () {
      const partnership = this.get('partnershipWithPrincipal');
      const insurances = partnership.get('activeInsurances');

      if (!insurances) {
        return null;
      }

      const activeOCSs = insurances.filter(i => {
        return i.get('documentType.name') === 'INSURANCE_OCS';
      });
      return activeOCSs ? activeOCSs.get('firstObject') : null;
    })
  });

  _exports.default = _default;
});