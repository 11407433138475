define("apollo/pods/components/custom-field-default-option/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    availableOptions: Ember.computed('model.options', function () {
      const self = this;
      return this.get('store').peekAll('customFieldOption').filter(option => {
        return option.get('definition.id') === self.get('model.id') && option.get('enabled');
      });
    }),
    actions: {
      setDefaultOption(option) {
        this.set('model.defaultOption', option);
      },

      toggleShowDefaultOptionSection() {
        this.toggleProperty('model.showDefaultOptionSection');
      }

    }
  });

  _exports.default = _default;
});