define("apollo/pods/mode-of-transportation/index/controller", ["exports", "apollo/pods/themes/table-theme"], function (_exports, _tableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    themeInstance: Ember.computed('', function () {
      return _tableTheme.default.create({
        container: Ember.getOwner(this)
      });
    }),
    columns: Ember.computed('', function () {
      const self = this;
      return [{
        propertyName: 'registrationNumber',
        title: self.get('intl').t('truck.registrationNumber'),
        className: 'registration-number'
      }, {
        propertyName: 'carrierShortName',
        title: self.get('intl').t('columns.CARRIER'),
        className: 'company-short-name'
      }, {
        propertyName: 'brand',
        title: self.get('intl').t('modeOfTransportation.brand'),
        className: 'brand'
      }, {
        propertyName: 'subcontractor',
        title: self.get('intl').t('modeOfTransportation.subcontractor'),
        className: 'subcontractor'
      }, {
        propertyName: 'serialNumberOfRecorder',
        title: self.get('intl').t('modeOfTransportation.serialNumberOfRecorder'),
        className: 'serial-number-of-recorder'
      }, {
        propertyName: 'lastSensorCalibrationCertificateDate',
        title: self.getColumnTranslation('CERTIFICATE', 'expirationDateLabel'),
        className: 'last-sensor-calibration-certificate',
        componentForFilterCell: 'server-table/filters/date-filter',
        filterFunction: this.filterFunction
      }, {
        propertyName: 'lastSummerMappingDate',
        title: self.getColumnTranslation('SUMMER_MAPPING', 'name'),
        className: 'last-summer-mapping',
        componentForFilterCell: 'server-table/filters/date-filter',
        filterFunction: this.filterFunction
      }, {
        propertyName: 'lastWinterMappingDate',
        title: self.getColumnTranslation('WINTER_MAPPING', 'name'),
        className: 'last-winter-mapping',
        componentForFilterCell: 'server-table/filters/date-filter',
        filterFunction: this.filterFunction
      }, {
        propertyName: 'status',
        title: self.get('intl').t('columns.STATUS'),
        className: 'status',
        filterWithSelect: true,
        predefinedFilterOptions: ['AWAITING_VERIFICATION', 'ACCEPTED', 'UPDATED', 'REJECTED', 'BLOCKED', 'DISABLED'].map(status => {
          return {
            value: status,
            label: this.get('intl').t(`modeOfTransportation.status.${status}`)
          };
        })
      }];
    }),
    filterFunction: function (cell, needed) {
      if (!needed) {
        return true;
      }

      if (cell === 'null') {
        return false;
      }

      const currentDate = moment(cell).format('DD.MM.YYYY');
      return needed === currentDate;
    },

    getColumnTranslation(translationKey, key) {
      const fullKey = 'documentType.'.concat(translationKey, '.', key);
      return this.get('intl').t(fullKey);
    },

    trucks: Ember.computed('model.trucks', function () {
      return this.get('model.trucks');
    }),
    showEnabled: Ember.computed('', function () {
      return true;
    }),
    actions: {
      toggleShowEnabled() {
        const self = this;
        self.store.query('truck', {
          companyId: self.get('sessionAccount.currentUser.company.id'),
          isModeOfTransportation: true,
          enabled: self.toggleProperty('showEnabled'),
          principalCompanyId: localStorage.getItem('principalId'),
          max: 10,
          page: 1
        }).then(trucks => {
          self.set('trucks', trucks);
        });
      }

    }
  });

  _exports.default = _default;
});