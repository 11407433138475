define("apollo/pods/components/companies/apply-form/main-data-section/component", ["exports", "apollo/mixins/section-mixin", "apollo/config/environment"], function (_exports, _sectionMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    dragAndDrop: Ember.inject.service('drag-and-drop-service'),

    init() {
      this._super(...arguments);

      const self = this;

      if (!this.get('company.headquarter.content')) {
        const newAddress = this.get('store').createRecord('address', {
          isHeadquarters: true,
          enabled: true,
          company: self.get('company'),
          recipient: self.get('company.name')
        });
        this.get('company').set('headquarter', newAddress);
      }

      this.get('partnership').reload();
    },

    willDestroyElement() {
      this._super(...arguments);

      const companyObject = this.get('company').rollback ? this.get('company') : this.get('company.content');
      companyObject.rollback();

      if (this.get('section.content')) {
        this.get('section.content').rollback();
      }

      companyObject.reload();
    },

    tosFileHref: Ember.computed('', function () {
      const principalId = localStorage.getItem('principalId');
      const token = this.get("sessionAccount").get("token");
      return `${_environment.default.serverURL}/files/tos?token=${token}&principalId=${principalId}`;
    }),
    additionalDetailsDisabled: Ember.computed('', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_COMPANY');
    }),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country');
    }),
    isTOSConfirmed: Ember.computed('partnership.termsOfService.isConfirmed', function () {
      return this.get('partnership.termsOfService.isConfirmed');
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },

      toggleTOSConfirmed() {
        this.set('isTOSConfirmed', !this.get('isTOSConfirmed'));
        this.set('partnership.termsOfService.isConfirmed', true);
      }

    },

    dragOver(event) {
      const element = this.element;
      this.get('dragAndDrop').dragOver(event, element);
    },

    dragLeave(event) {
      const element = this.element;
      this.get('dragAndDrop').dragLeave(event, element);
    },

    drop(event) {
      const element = this.element;
      this.get('dragAndDrop').drop(event, element);
    }

  });

  _exports.default = _default;
});