define("apollo/pods/settings/index/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    sortedSettings: Ember.computed('model.settings.@each.translatedNameString', function () {
      return this.get('model.settings').sortBy('translatedNameString');
    }),
    contractorsReportMinDate: null,
    contractorsReportMaxDate: null,
    canCreateTransportType: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_TRANSPORT_TYPE');
    }),
    enabledSquares: Ember.computed('model.squares.@each.enabled', function () {
      return this.get('model.squares').filterBy('enabled');
    }),
    workflows: Ember.computed('model.workflows.@each', function () {
      return this.get('model.workflows').sortBy('transportType.idx');
    }),
    squareSetting: Ember.computed(function () {
      return this.get('sessionAccount').getSquareSetting();
    }),
    showRestSettings: Ember.computed('squareSetting', function () {
      return this.get('squareSetting');
    }),
    showOtherSettings: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_ALLOWED_DOMAINS') || this.get('sessionAccount').hasRole('ROLE_MANAGE_NOTIFICATIONS') || this.get('sessionAccount').hasRole('ROLE_MANAGE_APPLIES_SETTINGS');
    }),
    actions: {
      generateContractorsReport() {
        Ember.run.once(this, function () {
          let url = _environment.default.serverURL + '/reports/contractors?token=' + this.get("sessionAccount").get("token");
          const minDate = this.get('contractorsReportMinDate') ? moment(this.get('contractorsReportMinDate')[0]).format() : '';
          const maxDate = this.get('contractorsReportMaxDate') ? moment(this.get('contractorsReportMaxDate')[0]).format() : '';
          const params = {
            minDate: minDate,
            maxDate: maxDate
          };
          url += "&" + $.param(params);
          window.location = url;
        });
      }

    }
  });

  _exports.default = _default;
});