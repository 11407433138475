define("apollo/pods/components/ramp-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CQgfgPdt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ramp-label\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"moreThan8Columns\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,[\"ramp\",\"shortName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"hidden-md hidden-sm hidden-xs\"],[9],[0,\"\\n            \"],[1,[23,[\"ramp\",\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"hidden-lg\"],[9],[0,\"\\n            \"],[1,[23,[\"ramp\",\"shortName\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,[\"numberOfPalletsPerRamps\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"max-pallets\"],[11,\"data-field-name\",\"max-pallet-per-ramp\"],[9],[0,\"\\n           \"],[1,[21,\"numberOfPallets\"],false],[0,\"/\"],[1,[23,[\"ramp\",\"maxPallet\",\"quantity\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/ramp-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});