define("apollo/pods/combining-relations-wizard/index/route", ["exports", "apollo/models/relations-wizard-view-model"], function (_exports, _relationsWizardViewModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    relationsTracker: Ember.inject.service('transport-relations.relations-tracker'),
    store: Ember.inject.service(),
    beforeModel: function (transition) {
      if (!this.relationsTracker.currentTransports.length) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const transportsWithoutRelation = this.relationsTracker.currentTransports.filter(transport => !transport.get('isRelatedTransportInTransportCombining'));
      const transportWithRelation = this.relationsTracker.currentTransports.find(t => t.get('isRelatedTransportInTransportCombining'));
      const relatedTransports = transportWithRelation.get('allCombinedTransports').filter(t => !t.get('isMasterTransportInTransportCombining'));
      const model = {
        existingRelations: relatedTransports.map(this._mapTransportToRelationWizardViewModel),
        withoutRelations: transportsWithoutRelation.map(this._mapTransportToRelationWizardViewModel)
      };
      const availableRelations = this.get('store').peekAll('transportRelation');
      const transportRelation = availableRelations.find(transportRelation => {
        return transportRelation.get('primaryTransportType.id') === transportWithRelation.get('transportType.id') && transportRelation.get('subordinateTransportType.id') === transportsWithoutRelation.get('firstObject.transportType.id') || transportRelation.get('primaryTransportType.id') === transportsWithoutRelation.get('firstObject.transportType.id') && transportRelation.get('subordinateTransportType.id') === transportWithRelation.get('transportType.id');
      });
      return {
        viewModel: model,
        transportRelation
      };
    },
    _mapTransportToRelationWizardViewModel: function (transport) {
      return _relationsWizardViewModel.default.create({
        isChecked: true
      }).transform(transport);
    }
  });

  _exports.default = _default;
});