define("apollo/pods/components/success-message/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    alertStyle: Ember.computed('', function () {
      return this.get('style') ? this.get('style') : 'success';
    }),
    canBeDismissible: Ember.computed('', function () {
      return this.get('dismissible') !== false;
    }),

    didRender() {
      this._super(...arguments);

      if (!this.get('autoHide')) {
        return;
      }

      const timeout = this.get('timeout') ? parseInt(this.get('timeout')) * 1000 : 10000;
      setTimeout(function () {
        (0, _jquery.default)(".alert").fadeTo(500, 0).slideUp(500, function () {
          (0, _jquery.default)(this).remove();
        });
      }, timeout);
    },

    actions: {}
  });

  _exports.default = _default;
});