define("apollo/pods/components/refresh-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'button',
    classNames: ['btn', 'btn-default'],
    attributeBindings: ['disabled', 'data-field-name'],
    disabled: function () {
      return false;
    }.property(),

    click() {
      const self = this;
      self.set('disabled', true);
      Ember.run.later(self, function () {
        self.set('disabled', false);
      }, 1000);
      this.set('action', 'poll');
      this.sendAction("action");
    }

  });

  _exports.default = _default;
});