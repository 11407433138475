define("apollo/pods/role-groups/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    ADDITIONAL_FLAGS_OPTIONS: Ember.computed(function () {
      return [this.get('intl').t('common.yes'), this.get('intl').t('common.no')];
    }).readOnly('true'),
    getIsGuardValue: Ember.computed('model.roleGroup.isGuard', function () {
      return this._getFlagValue('isGuard');
    }),
    getUsedForDeliveriesValue: Ember.computed('model.roleGroup.usedForDeliveries', function () {
      return this._getFlagValue('usedForDeliveries');
    }),

    _getFlagValue(propertyName) {
      const value = this.get(`model.roleGroup.${propertyName}`);

      if (value !== undefined) {
        return value ? this.get('intl').t('common.yes') : this.get('intl').t('common.no');
      }

      return this.get('intl').t('common.no');
    },

    _setFlagValue(propertyName, flagValue) {
      if (flagValue === this.get('intl').t('common.yes')) {
        this.set(`model.roleGroup.${propertyName}`, true);
      } else {
        this.set(`model.roleGroup.${propertyName}`, false);
      }
    },

    actions: {
      save: function () {
        const self = this;
        const roleGroup = this.get('model.roleGroup');

        if (!roleGroup.validate()) {
          console.log('Nowa rola nie przeszła walidacji..');
          return;
        }

        roleGroup.save().then(roleGroup => {
          self.setProperties({
            errors: []
          });
          const msg = self.get('intl').t('settings.roleGroups.successCreate');
          self.get('settingsIndexController').set('successMessage', msg);
          self.transitionToRoute('settings.index');
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        this.get('model.roleGroup').unloadRecord();
        this.transitionToRoute('settings.index');
      },
      setIsGuardFlag: function (flagValue) {
        this._setFlagValue('isGuard', flagValue);
      },
      setUsedForDeliveriesFlag: function (flagValue) {
        this._setFlagValue('usedForDeliveries', flagValue);
      }
    }
  });

  _exports.default = _default;
});