define("apollo/services/currency-exchange-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),

    toPLN(amount, currency) {
      if (!amount || !currency) {
        throw new Error('Do przeliczenia wymagana jest wartość oraz symbol waluty');
      }

      if (currency === 'PLN') {
        return amount;
      }

      const savedCurrency = this.currencies.filter(curr => {
        return curr.symbol === currency;
      }).get('firstObject');

      if (!savedCurrency) {
        throw new Error('Platforma nie obsługuje waluty :: ' + currency);
      }

      if (!savedCurrency.get('exchangeRate')) {
        throw new Error('Nie mamy zapisanego kursu dla waluty :: ' + currency);
      }

      return Number(amount) * savedCurrency.get('exchangeRate');
    }

  });

  _exports.default = _default;
});