define("apollo/pods/components/paid-feature-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+PPzYe+r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-exclamation-triangle warning\"],[9],[10],[0,\"\\n    \"],[1,[27,\"t\",[[27,\"concat\",[\"paidFeaturesInfo.authorities.\",[23,[\"authority\"]]],null]],null],true],[0,\"\\n    \"],[1,[27,\"t\",[\"paidFeaturesInfo.label\"],null],false],[0,\": \"],[7,\"a\"],[11,\"target\",\"_blank\"],[11,\"href\",\"https://logintegra.com/cennik-pakietow/\"],[11,\"class\",\"bold\"],[9],[0,\"https://logintegra.com/cennik-pakietow/\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/paid-feature-info/template.hbs"
    }
  });

  _exports.default = _default;
});