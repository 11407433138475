define("apollo/pods/components/custom-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement: function () {
      this._super(...arguments);

      const modalId = this.get('modalId');
      console.log(`Pokazujemy okienko ${modalId}...`);
      const $mainModal = (0, _jquery.default)(`#${modalId}`);
      $mainModal.on('shown.bs.modal', function () {
        console.debug(`Wyświetliliśmy na ekranie modal ${modalId}.`);
        $mainModal.find('.focus-input').focus(); // A-373

        $mainModal.find('input:text, textarea').change(function () {
          (0, _jquery.default)(this).val((0, _jquery.default)(this).val().trim());
        });
      });
      $mainModal.on('hidden.bs.modal', function () {
        console.debug('Zamykamy modal...');
        (0, _jquery.default)('.modal-backdrop').remove();
        const outletName = this.get('outletName') ? this.get('outletName') : 'main-modal';
        Ember.getOwner(this).lookup('route:application').disconnectOutlet({
          outlet: outletName,
          parentView: 'application'
        }); // Ta akcja wykona się, gdy podamy `cancel` w komponencie, np. `{{#custom-modal cancel=(action 'myAction')}}` sprawi,
        // że po zamknięciu okienka, wykona się akcja `myAction`.
        //
        // Zamknięcie okienka można uzyskać albo wykonując `this.send('hideModal')`, albo dodając
        // `data-dismiss="modal"` do wybranego przycisku.

        const skipCancelAction = (0, _jquery.default)(`#${outletName}`).data('skip-cancel-action');

        if (this.cancel && !skipCancelAction) {
          this.cancel();
        }
      }.bind(this)); // To ważne, by zainicjalizować modal **po** dodaniu nasłuchiwaczy `shown.bs.modal` itp.
      // por. https://stackoverflow.com/a/25466850/2224598
      //
      // `backdrop: 'static'` <-- sprawia, że okienko **nie** zamyka się po kliknięciu poza jego obszar

      $mainModal.modal({
        backdrop: 'static'
      }); // Nie chcemy, żeby po zamknięciu modalu drugiego poziomu -- np. okienka dodawania/edycji indeksów
      // otwierane z poziomu formularza transportu, który też jest modalem -- usuwała się klasa `modal-open`
      // przypisywana do `body` po otwarciu okienka. Klasa ta pozwala na przewijanie (scroll) okienka
      // modalowego. Zamknięcie modalu drugiego poziomu (sub-modal) usuwa tę klasę,
      // przez co psuje się przewijanie np. formularza transportów po zamknięciu okienka indeksów.
      //
      // Problem jest znany: https://github.com/twbs/bootstrap/issues/15260. Rozwiązanie też zaczerpnąłem z tego wątku.

      (0, _jquery.default)('#sub-modal').on('hidden.bs.modal', function () {
        console.debug('Zamykamy modal drugiego poziomu (sub-modal) po wykonaniu akcji \'close\'...');
        (0, _jquery.default)('body').addClass('modal-open');
      }); // TODO: Nie powinniśmy przypisywać `draggable()` do całego okienka, bo nie można wtedy zaznaczać tekstu

      if (this.get('allowDraggable')) {
        $mainModal.find('.modal-content').draggable();
      }
    },
    action: '',
    modalId: Ember.computed('outletName', function () {
      return this.get('outletName') || 'main-modal';
    }),
    showCancelButton: Ember.computed(function () {
      return false;
    }),
    allowDraggable: Ember.computed(function () {
      return true;
    }),
    yieldWithLayout: Ember.computed(function () {
      return true;
    }),
    actions: {
      close: function () {
        this.set('action', 'hideModal');
        this.sendAction('action');
      }
    }
  });

  _exports.default = _default;
});