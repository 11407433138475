define("apollo/pods/components/sections/reject-section-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CmRBtt8+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-warning\"],[11,\"data-button-name\",\"reject-document\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"section.reject\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"showRejectionModal\",[23,[\"section\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/sections/reject-section-button/template.hbs"
    }
  });

  _exports.default = _default;
});