define("apollo/pods/components/server-table/filters/date-time-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setDate(date) {
        if (!date[0]) {
          this.set('column.filterString', null);
        }

        this.set('column.filterString', moment(date[0]).format('DD.MM.YYYY HH:mm'));
      }

    }
  });

  _exports.default = _default;
});