define("apollo/pods/users/notifications/controller", ["exports", "apollo/mixins/persistence-mixin", "apollo/mixins/ramps-access-mixin", "apollo/mixins/queues-access-mixin", "apollo/mixins/user-utils-mixin"], function (_exports, _persistenceMixin, _rampsAccessMixin, _queuesAccessMixin, _userUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _rampsAccessMixin.default, _userUtilsMixin.default, _queuesAccessMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errors: [],
    user: Ember.computed('model.user', function () {
      return this.get('model.user');
    }),
    limitedEdition: Ember.computed('user.{id,company.principalInDifferentPrincipalSpace}', function () {
      return this.get('user.id') && this.get('user.company.principalInDifferentPrincipalSpace');
    }),
    proxiedNotifications: Ember.computed.map('user.roleGroup.notifications', function (model) {
      return Ember.ObjectProxy.create({
        content: model,
        checked: true
      });
    }),
    setProxiedNotifications: Ember.observer('proxiedNotifications.length', 'model', 'user.userNotifications.@each.mode', function () {
      const self = this;
      Ember.run.once(this, function () {
        self.checkNotifications();
      });
    }),
    proxiedCheckedNotifications: Ember.computed.filterBy('proxiedNotifications', 'checked', true),
    checkedNotifications: Ember.computed.mapBy('proxiedCheckedNotifications', 'content'),

    checkNotifications() {
      const proxiedNotifications = this.get('proxiedNotifications');
      const userNotifications = this.get('user').get('userNotifications');
      const userNotificationsIds = this.get('user').get('selectedNotifications').map(function (s) {
        return s.get('id');
      });
      proxiedNotifications.forEach(notification => {
        const checked = userNotificationsIds.includes(notification.get('id'));
        notification.set('checked', checked);
        const mode = userNotifications.filter(un => un.get('notification.id') === notification.get('id')).get('firstObject.mode');
        notification.set('mode', mode);
      });
    },

    showUserGroupsSection: Ember.computed('user.company.{isPrincipal,userGroup.length}', function () {
      return !this.get('user.company.isPrincipal') && this.get('user.company.customUserGroupsExists') || this.get('sessionAccount').isGroupFilterEnabled() || this.get('sessionAccount').isGroupFilterEnabledForUser(this.get('user'));
    }),
    actions: {
      setGroupMode(item, mode) {
        item.set('mode', mode);
      },

      save: async function () {
        const user = this.get('user');
        const checkedNotifications = this.get('checkedNotifications');
        const jsonBody = JSON.stringify({
          user: user.get('id'),
          personNotifications: checkedNotifications.map(cn => {
            return {
              notification: cn.get('id'),
              mode: cn.get('mode')
            };
          })
        });
        const path = this.get('apolloApiService').APOLLO_API.PERSON_NOTIFICATIONS.SAVE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: jsonBody
          });

          if (response.ok) {
            const msg = this.get('intl').t('common.saveSuccessMessage');
            this.set('successMessage', msg);
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },
      cancel: function () {
        this.get('errors').clear();
        this.checkNotifications();
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});