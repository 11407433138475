define("apollo/services/polling/planning-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    websocket: Ember.inject.service('websocket'),

    useSockets(model) {
      const webSocket = this.get('websocket').getSocket();
      const transportTypeId = model.transportType.id;

      if (webSocket && webSocket.get('connected')) {
        webSocket.subscribe('/topic/planning/update', () => {
          Ember.set(model, 'deliveries', this.store.query('delivery', {
            transportTypeId
          }));
          Ember.set(model, 'transports', this.store.query('transport', {
            planning: true
          }));
        });
      } else {
        setTimeout(() => {
          this.useSockets(model);
        }, 1500);
      }
    },

    usePolling(params, context) {
      console.error('TODO :: NOT IMPLEMENTED');
      console.debug('Pryncypał nie ma włączonej obsługi web-socket, a odświeżanie tradycyjne nie działa -- nie pokażemy więc automatycznie zmian');
      return;
    }

  });

  _exports.default = _default;
});