define("apollo/pods/components/square-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FaSpgCje",
    "block": "{\"symbols\":[\"square\",\"queue\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"col-sm-2 control-label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"user.availableQueues\"],null],false],[0,\":\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-10 form-control-static\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"squares\"]]],null,{\"statements\":[[0,\"            \"],[7,\"label\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\",\"change\"],[\"checkbox\",[22,1,[\"checked\"]],[27,\"not\",[[23,[\"canManageQueuesAccessibility\"]]],null],[27,\"action\",[[22,0,[]],\"toggleSquare\",[22,1,[]]],null]]]],false],[0,\"\\n                \"],[1,[22,1,[\"name\"]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"ramp-checkboxes\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"proxiedQueues\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"label\"],[11,\"class\",\"label-not-bold\"],[9],[0,\"\\n                        \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[22,2,[\"checked\"]],[27,\"not\",[[23,[\"canManageQueuesAccessibility\"]]],null]]]],false],[0,\"\\n                        \"],[1,[22,2,[\"name\"]],false],[0,\"\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/square-section/template.hbs"
    }
  });

  _exports.default = _default;
});