define("apollo/pods/components/schedule-legend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9AYs50pN",
    "block": "{\"symbols\":[\"legendObjects\",\"lo\"],\"statements\":[[7,\"div\"],[11,\"class\",\"centered legend row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.legend\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"form-group legend-center\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"columns\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"legend-scale col-md-4\"],[9],[0,\"\\n                \"],[7,\"ul\"],[11,\"class\",\"legend-labels\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"                        \"],[7,\"li\"],[9],[7,\"span\"],[12,\"style\",[28,[\"background:\",[22,2,[\"color\"]],\";\"]]],[9],[10],[1,[22,2,[\"label\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"div\"],[11,\"class\",\"legend-scale col-md-4\"],[9],[0,\"\\n            \"],[7,\"ul\"],[11,\"class\",\"legend-labels\"],[9],[0,\"\\n                \"],[7,\"li\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-long-arrow-up fa-2x margin-right\"],[9],[10],[1,[27,\"t\",[\"transport.load\"],null],false],[10],[0,\"\\n                \"],[7,\"li\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-long-arrow-down fa-2x margin-right\"],[9],[10],[1,[27,\"t\",[\"transport.unload\"],null],false],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/schedule-legend/template.hbs"
    }
  });

  _exports.default = _default;
});