define("apollo/pods/transport-type-role-group-table-column/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    transportType: _emberData.default.belongsTo('transportType'),
    tableColumn: _emberData.default.belongsTo('tableColumn'),
    roleGroup: _emberData.default.belongsTo('roleGroup'),
    visibility: _emberData.default.attr(),
    isHighlighted: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});