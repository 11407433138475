define("apollo/pods/components/transports/transports-table/transport-sorter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    OPTIONS: [null, 'up', 'down'],
    STORAGE_KEY_PREFIX: 'sortingType_',
    tagName: 'span',
    classNames: ['clickable'],
    direction: null,

    init() {
      const self = this;

      self._super(...arguments);

      const transportTypeId = self.get('transportType.id');
      const storageKey = self.STORAGE_KEY_PREFIX + transportTypeId;
      const sortingType = JSON.parse(localStorage.getItem(storageKey));

      if (!sortingType) {
        return;
      }

      const sortingKey = sortingType.key;
      const sortingDirection = sortingType.direction;
      const sortingTransportTypeId = sortingType.transportTypeId;

      if (sortingKey === self.get('key') && sortingTransportTypeId === transportTypeId) {
        self.set('key', sortingKey);
        self.set('direction', sortingDirection);
        Ember.run.next(() => {
          self.changeSortingKey(sortingKey, sortingDirection);
        });
      }
    },

    activeKeyChangedObserver: Ember.observer('activeSortingKey', function () {
      if (this.get('key') !== this.get('activeSortingKey')) {
        this.set('direction', null);
      }
    }),
    icon: Ember.computed('direction', function () {
      const baseIcon = 'fa fa-sort';
      const direction = this.get('direction');
      return direction ? `${baseIcon}-${direction}` : baseIcon;
    }),
    click: function () {
      const self = this;
      const transportTypeId = self.get('transportType.id');
      const currentDirection = self.get('direction');
      const key = self.get('key');
      const currentOptionIndex = self.OPTIONS.indexOf(currentDirection);
      const nextOptionIndex = (currentOptionIndex + 1) % self.OPTIONS.length;
      const newDirection = self.OPTIONS[nextOptionIndex];
      const storageKey = self.STORAGE_KEY_PREFIX + transportTypeId;
      localStorage.setItem(storageKey, JSON.stringify({
        key: key,
        direction: newDirection,
        transportTypeId: transportTypeId
      }));
      self.set('direction', newDirection);
      self.changeSortingKey(key, newDirection);
    }
  });

  _exports.default = _default;
});