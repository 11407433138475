define("apollo/pods/contractors-group/modals/create-contractors-group/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    contractorsGroup: Ember.computed('model.contractorsGroup', function () {
      return this.get('model.contractorsGroup');
    }),
    ibans: Ember.computed('', function () {
      const ibans = Ember.A();
      this.get('store').peekAll('country').forEach(c => {
        ibans.pushObject(c.ibanSymbol);
      });
      return ibans;
    }),
    title: Ember.computed('transport.{title,internalIndex}', function () {
      return this.get('intl').t('contractors-group.addGroup');
    }),
    actions: {
      save() {
        const contractorsGroup = this.get('contractorsGroup');

        if (!contractorsGroup.validate()) {
          return;
        }

        const self = this;
        contractorsGroup.save().then(() => {
          self.get('model.groupType').notifyPropertyChange('lastUpdated');
          self.get('model.partnership').notifyPropertyChange('partnershipContractorsGroups');
          $('.save-group-button').button('reset');
          this.send('hideModal');
        }, function (errorResponse) {
          self.set('errors', errorResponse.errors);
        });
      },

      setCountry(country) {
        const iban = country.ibanSymbol;
        this.set('contractorsGroup.activeWhenCountryIn', iban);
      },

      cancel() {
        console.debug('Zamykamy okienko dodawania grupy...');
        this.get('contractorsGroup').rollback();
      }

    }
  });

  _exports.default = _default;
});