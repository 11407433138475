define("apollo/pods/components/warehouse/assortment-group-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H/iqcpaV",
    "block": "{\"symbols\":[\"ag\"],\"statements\":[[4,\"if\",[[23,[\"warehouse\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[11,\"data-field-name\",\"assortment-group-section\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"col-xs-12\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n                        \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[9],[0,\"\\n                            \"],[7,\"b\"],[9],[1,[27,\"t\",[\"assortmentGroup.listLabel\"],null],false],[10],[0,\"\\n                        \"],[10],[0,\"\\n                        \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n                            \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"warehouse\",\"assortmentGroups\"]]],null,{\"statements\":[[0,\"                                    \"],[7,\"p\"],[12,\"class\",[28,[[27,\"unless\",[[22,1,[\"enabled\"]],\"disabled\"],null]]]],[9],[0,\"\\n                                        \"],[1,[22,1,[\"nameInAppropriateLanguage\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"has-role\",[\"ROLE_EDIT_ASSORTMENT_GROUP\"],null]],null,{\"statements\":[[4,\"link-to\",[\"assortment-groups.edit\",[22,1,[]]],null,{\"statements\":[[0,\"                                                \"],[7,\"span\"],[11,\"class\",\"fa fa-edit\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"                                    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"                            \"],[10],[0,\"\\n                        \"],[10],[0,\"\\n                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/warehouse/assortment-group-section/template.hbs"
    }
  });

  _exports.default = _default;
});