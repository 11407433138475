define("apollo/pods/transport-types/courier-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    transportType: Ember.computed('model', function () {
      return this.get('model.transportType');
    }),
    courierSettings: Ember.computed('model', function () {
      return this.get('model.courierSettings');
    }),
    transportTypes: Ember.computed('sessionAccount.currentUser.transportTypes', function () {
      return this.get('sessionAccount.currentUser.transportTypes');
    }),
    actions: {
      save() {
        this.set('errors', []);
        this.get('courierSettings').save().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      create() {
        if (this.get('courierSettings')) {
          return;
        }

        this.set('courierSettings', this.get('store').createRecord('courier-setting', {
          transportType: this.get('transportType')
        }));
      },

      delete() {
        this.set('errors', []);
        this.get('courierSettings').destroyRecord().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      cancel() {
        this.get('courierSettings').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }

    }
  });

  _exports.default = _default;
});