define("apollo/pods/components/warehouse/warehouse-location-multi-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // TODO: A-12882 Mamy dwa komponenty do pola lokalizacja - ujednolicenie
    store: Ember.inject.service(),
    warehouseLocationsNames: Ember.computed('step.stepWarehouseLocations.length', function () {
      const objects = [];
      this.get('step.stepWarehouseLocations').forEach(whl => {
        objects.pushObject({
          id: whl.get('id'),
          nameInAppropriateLanguage: whl.get('warehouseLocation.nameInAppropriateLanguage'),
          wlId: whl.get('warehouseLocation.id')
        });
      });
      return objects;
    }),
    actions: {
      setWarehouseLocations: function (locations) {
        const self = this;
        const step = this.get('step');
        const swls = step.get('stepWarehouseLocations');

        if (!step || !swls) {
          return;
        }

        locations.forEach(loc => {
          if (!swls.get('length') || !swls.any(swl => {
            return loc.id === swl.get('id');
          })) {
            const newSwl = self.get('store').createRecord('stepWarehouseLocation', {
              step,
              warehouseLocation: self.get('store').peekRecord('warehouseLocation', loc.id),
              test: true
            });
            step.get('stepWarehouseLocations').pushObject(newSwl);
          }
        });

        if (locations.length === step.get('stepWarehouseLocations.length')) {
          return;
        }

        let obsoleteLoc = step.get('stepWarehouseLocations').find(swl => {
          return !locations.any(loc => loc.id === swl.get('id'));
        });

        if (!obsoleteLoc) {
          obsoleteLoc = step.get('stepWarehouseLocations').find(swl => {
            return !locations.any(loc => !loc.id && loc.wlId === swl.get('warehouseLocation.id'));
          });
        }

        if (obsoleteLoc) {
          step.get('stepWarehouseLocations').removeObject(obsoleteLoc);
        }
      }
    }
  });

  _exports.default = _default;
});