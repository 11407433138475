define("apollo/pods/components/transports/save-filters-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kt+3D8AR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[1,[21,\"transports/netpc-report-button\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-2\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel panel-default no-vertical-margin\"],[9],[0,\"\\n            \"],[7,\"ul\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n                \"],[7,\"li\"],[11,\"class\",\"list-group-item\"],[9],[0,\"\\n                    \"],[7,\"b\"],[9],[1,[27,\"t\",[\"common.rememberFilters\"],null],false],[10],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"material-switch pull-right\"],[9],[0,\"\\n                        \"],[7,\"input\"],[11,\"id\",\"cacheFiltersSwitch\"],[12,\"checked\",[21,\"cacheFiltersSwitchState\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"toggleCacheFiltersSwitch\"],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n                        \"],[7,\"label\"],[11,\"for\",\"cacheFiltersSwitch\"],[11,\"class\",\"label-success\"],[9],[10],[0,\"\\n                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/save-filters-switch/template.hbs"
    }
  });

  _exports.default = _default;
});