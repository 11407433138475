define("apollo/pods/components/toggle-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OV/w25iA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[11,\"role\",\"tab\"],[9],[0,\"\\n        \"],[7,\"h3\"],[12,\"class\",[28,[\"panel-title \",[27,\"unless\",[[23,[\"shouldBeExpanded\"]],\"collapsed\"],null]]]],[11,\"role\",\"button\"],[11,\"data-toggle\",\"collapse\"],[11,\"data-parent\",\"#accordion\"],[12,\"href\",[28,[\"#\",[21,\"name\"]]]],[12,\"aria-expanded\",[21,\"shouldBeExpanded\"]],[12,\"aria-controls\",[21,\"name\"]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"iconClass\"]]],null,{\"statements\":[[0,\"                \"],[7,\"i\"],[12,\"class\",[21,\"iconClass\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[1,[27,\"t\",[[27,\"concat\",[\"sectionType.\",[23,[\"name\"]]],null]],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"id\",[21,\"name\"]],[12,\"class\",[28,[\"panel-collapse collapse \",[27,\"if\",[[23,[\"shouldBeExpanded\"]],\"in\"],null]]]],[11,\"role\",\"tabpanel\"],[9],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/toggle-section/template.hbs"
    }
  });

  _exports.default = _default;
});