define("apollo/pods/authority-restrictions/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_MANAGE_AUTHORITIES'],
    queryParams: {
      roleGroupId: {
        refreshModel: true
      },
      transportTypeId: {
        refreshModel: true
      },
      multiSelect: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const roleGroup = params.roleGroupId ? this.store.peekRecord('roleGroup', params.roleGroupId) : null;
      const transportType = this.store.peekRecord('transportType', params.transportTypeId);
      const authorityRestriction = this.store.createRecord('authority-restriction', {
        roleGroup: roleGroup
      });
      return Ember.RSVP.hash({
        authorityRestriction: authorityRestriction,
        transportType: transportType,
        multiSelect: params.multiSelect
      });
    },
    renderTemplate: function () {
      this.render('authority-restrictions.edit');
    },
    setupController: function (controller, model) {
      this.controllerFor('authority-restrictions.edit').setProperties({
        model: model,
        tasksToSave: []
      });
    }
  });

  _exports.default = _default;
});