define("apollo/pods/settings/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_SETTINGS_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const principalCompanyId = localStorage.getItem('principalId');
      const userRoleGroupName = this.get('sessionAccount.currentUser.roleGroup.name');
      let roleGroups = this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);

      if (!this.get('sessionAccount').hasRole('ROLE_MANAGE_AUTHORITIES') || userRoleGroupName !== 'SUPER_USER') {
        roleGroups = roleGroups.filter(roleGroup => roleGroup.get('name') !== 'SUPER_USER' && roleGroup.get('name') !== 'E-MAIL_ADVICE');
      }

      const gates = this.store.findAll('gate');
      const squares = this.store.findAll('square');
      return Ember.RSVP.hash({
        principalCompany: this.store.findRecord('company', principalCompanyId),
        settings: this.store.findAll('setting', {
          reload: true
        }).then(data => {
          if (this.get('sessionAccount').hasRole('ROLE_MANAGE_SETTINGS')) {
            return data;
          } else {
            return data.filterBy('editable');
          }
        }),
        warehouses: this.store.findAll('warehouse', {
          reload: true
        }).then(function (data) {
          return data.sortBy('name');
        }),
        workflows: this.store.findAll('workflow').then(data => {
          return data;
        }),
        roleGroups: roleGroups,
        squares: squares,
        gates
      });
    },

    activate() {
      this.activateCurrentTab('SETTINGS_TAB');
    },

    actions: {
      willTransition() {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });
      }

    }
  });

  _exports.default = _default;
});