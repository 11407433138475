define("apollo/pods/time-window/modals/window-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zEJQthC7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"cancel\"],[[23,[\"title\"]],[27,\"action\",[[22,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n            \"],[7,\"label\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"warehouse.windowNoteLabel\"],null],false],[0,\":\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[9],[0,\"\\n                \"],[1,[27,\"textarea\",null,[[\"class\",\"rows\",\"value\"],[\"form-control full-width vertical-resizable-only focus-input\",5,[23,[\"model\",\"note\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"common.cancel\"],null],false],[0,\"\\n                \"],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"canNotEdit\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                        \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n                    \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/time-window/modals/window-note/template.hbs"
    }
  });

  _exports.default = _default;
});