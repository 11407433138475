define("apollo/pods/email-advice/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    email: _emberData.default.attr('string'),
    mobilePhonePrefix: _emberData.default.attr('string', {
      defaultValue: '48'
    }),
    mobilePhoneNumber: _emberData.default.attr('string'),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    rodoAccepted: _emberData.default.attr('boolean'),
    sent: _emberData.default.attr('boolean'),
    sender: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    expired: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('isodate'),
    validations: {
      rodoAccepted: {
        custom: [{
          validation: function (key, value) {
            return value;
          },
          message: function (key, value, self) {
            return this.get('intl').t('advice.errors.termsOfConditionsAccepted.notSelected');
          }
        }]
      },
      email: {
        presence: {
          message: 'blank'
        }
      },
      mobilePhoneNumber: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return /^[0-9]+$/.test(value) && value.length >= 9;
          },
          message: 'regex'
        }]
      },
      mobilePhonePrefix: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return value.length <= 3;
          },
          message: 'blank'
        }]
      }
    }
  });

  _exports.default = _default;
});