define("apollo/pods/components/temporary-account-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    tagName: 'a',
    classNames: 'btn btn-default',
    user: Ember.computed('sessionAccount.currentUser', function () {
      return this.get('sessionAccount.currentUser');
    }),
    isVisible: Ember.computed('user.temporaryAccount', function () {
      return this.get('user.temporaryAccount');
    }),

    click() {
      this.set('action', 'showModal');
      this.sendAction('action', 'temporary-account-modal');
    }

  });

  _exports.default = _default;
});