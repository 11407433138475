define("apollo/pods/companies/create/controller", ["exports", "apollo/config/environment", "apollo/mixins/company-mixin", "jquery"], function (_exports, _environment, _companyMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend(_companyMixin.default, {
    sessionAccount: service('session-account'),
    intl: service(),
    gusService: service('gus-service'),
    companiesIndexController: Ember.inject.controller('companies/index'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    errors: [],
    countries: function () {
      return this.store.findAll('country');
    }.property(),
    // eslint-disable-next-line ember/no-observers
    clearCompanyNameObserver: Ember.observer('model.country.ibanSymbol', function () {
      this.set('model.gusVerified', false);

      if (this.get('model.country.ibanSymbol') !== 'PL') {
        this.set('verifiedCompanyName', null);
        return;
      }

      this.set('model.name', null);
    }),
    additionalDetailsDisabled: Ember.computed('company.partnershipWithCurrentCompany', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_COMPANY');
    }),
    verifiedByGus: Ember.computed('verifiedCompanyName', 'model.country.ibanSymbol', function () {
      return this.get('model.country.ibanSymbol') === 'PL' && this.get('gusCompanyName');
    }),
    disabledCompanyField: Ember.computed('verifiedByGus', 'existingCompanyName', function () {
      return this.get('verifiedByGus') || this.get('existingCompanyName');
    }),
    // eslint-disable-next-line ember/no-function-prototype-extensions
    availableTypes: function () {
      const availableTypes = this.store.peekAll('partnershipType').toArray().filter(pt => {
        return pt.get('name') !== 'COURIER' && pt.get('name') !== 'OPERATOR';
      });
      const carrierType = availableTypes.find(t => t.get('name') === 'CARRIER');

      if (carrierType && this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES') === 'true') {
        availableTypes.removeObject(carrierType);
      }

      return availableTypes;
    }.property(),
    actions: {
      setCountry(country) {
        this.get('model').setProperties({
          oldCountry: this.get('model').get('country'),
          country
        });
      },

      selectCountry(id) {
        const self = this;
        this.store.findRecord('country', id).then(country => {
          self.get('model').set('country', country);
        });
      },

      save: function () {
        const self = this;
        const companyModel = this.get('model');
        this.trimCompany(companyModel);

        if (companyModel.get('partnershipWithCurrentCompany').hasDirtyAttributes) {
          if (!companyModel.get('partnershipWithCurrentCompany').validate({
            only: 'representativeEmail,representativePhoneNumber,representativePhoneNumberPrefix,additionalDetails'
          })) {
            return;
          }
        }

        if (!companyModel.validate()) {
          return;
        }

        const partnershipToUnload = companyModel.get('partnershipWithCurrentCompany');
        companyModel.save().then(function (company) {
          self.set('errors', []);
          console.log('company saved');
          partnershipToUnload.destroyRecord();
          company.refreshPartnershipWithCurrentCompany();
          self.transitionToRoute('companies.index');
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        this.get('model').unloadRecord();
        this.transitionToRoute('companies.index');
      },

      disable() {
        const self = this;
        const companyModel = this.get('model');
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/companies/' + companyModel.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          companyModel.set('enabled', false);
          const msg = self.get('intl').t('company.deletedMessage', {
            companyName: companyModel.get('shortName')
          });
          self.get('companiesIndexController').set('successMessage', msg);
          console.log(`Poprawnie usunęliśmy firmę ${companyModel.get('name')}.`);
          self.transitionToRoute('companies.index');
        }, function (response) {
          self.set('errors', response.errors);
        });
      },

      verifyNip: async function () {
        const self = this;
        this.set('gusHasProblem', false);
        const ibanSymbol = this.get('model.country.ibanSymbol');
        const nip = this.get('model.taxIdNumber').replaceAll(' ', '');

        if (nip === '') {
          return;
        }

        const nipVerificationPromise = this.get('gusService').verifyNip(ibanSymbol, nip);
        nipVerificationPromise.then(response => {
          const existingCompanyName = response.existingCompanyName;
          const gusCompanyName = response.gusCompanyName;
          const name = existingCompanyName != null ? existingCompanyName : gusCompanyName;
          console.log(`Odpowiedź z GUS: ${response}`);
          self.set('existingCompanyName', existingCompanyName);
          self.set('gusCompanyName', gusCompanyName);
          self.set('verifiedCompanyName', name);
          self.set('model.gusVerified', true);
          self.set('model.name', name);
        }).catch(error => {
          console.error(`Problem z połączeniem z GUS: ${error}`);
          self.set('existingCompanyName', null);
          self.set('gusCompanyName', null);
          self.set('verifiedCompanyName', null);
          self.set('model.gusVerified', false);
          self.set('gusHasProblem', true);
        });
      }
    }
  });

  _exports.default = _default;
});