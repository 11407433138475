define("apollo/pods/components/transports/transports-table/multi-archive-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    multiArchiveTrackerService: Ember.inject.service(),
    // TODO: Zastąpić przez apolloApiService
    ajaxService: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    showMultiArchiveButton: Ember.computed('multiArchiveTrackerService.currentTransports.length', function () {
      return this.multiArchiveTrackerService.get('currentTransports').length !== 0;
    }),
    actions: {
      validateAndArchive() {
        const self = this;
        const transports = this.get('multiArchiveTrackerService.currentTransports');

        if (!transports) {
          return;
        }

        const transportsThatCantBeArchived = this.get('multiArchiveTrackerService').getTransportsThatCantBeArchived();

        if (transportsThatCantBeArchived.length > 0) {
          alert(this.get('intl').t('transport.multiArchive.alert') + transportsThatCantBeArchived.map(t => {
            return t.get('internalIndex');
          }));
          return;
        }

        if (!confirm(this.get('intl').t('transport.multiArchive.confirm') + transports.map(t => {
          return t.get('internalIndex');
        }))) {
          return;
        }

        const url = '/transports/multiArchive';
        const data = transports.map(t => {
          return t.get('id');
        });
        this.get('ajaxService').send(url, 'POST', data, true).then(() => {
          console.debug('Poprawnie zarchiwizowaliśmy transporty');
          self.multiArchiveTrackerService.clearTransportsList();
        }).catch(response => {
          const errors = response.responseJSON.errors;

          if (errors) {
            const msg = this.get('intl').t('transport.multiArchive.confirm') + errors;
            console.error(msg);
            alert(msg);
          }
        });
      }

    }
  });

  _exports.default = _default;
});