define("apollo/pods/components/queue-status/modals/send-sms/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service(),
    errors: [],

    clearComponent() {
      this.set('errors', []);
      this.set('message', null);
    },

    actions: {
      async sendSms(queue, message) {
        const self = this;

        if (!message) {
          self.set('errors', [this.get('intl').t('errors.blank')]);
          return;
        }

        const data = JSON.stringify({
          queueId: queue.get('id'),
          message
        });
        const path = this.get('apolloApiService').APOLLO_API.NOTIFICATIONS.SEND_SMS;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: data
          });

          if (response.ok) {
            jQuery(`#${queue.get('id')}`).modal('hide');
            self.clearComponent();
          } else {
            const error = await response.json();
            self.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },

      close(queue) {
        jQuery(`#${queue.get('id')}`).modal('hide');
        this.clearComponent();
      }

    }
  });

  _exports.default = _default;
});