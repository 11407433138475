define("apollo/pods/weight-log/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    transport: _emberData.default.belongsTo('transport'),
    weight: _emberData.default.belongsTo('weight', {
      async: true
    }),
    oldReadOut: _emberData.default.attr('number'),
    dateCreated: _emberData.default.attr('date'),
    newValue: _emberData.default.attr('string'),
    oldValue: _emberData.default.attr('string'),
    propertyName: _emberData.default.attr('string'),
    className: _emberData.default.attr('string'),
    actor: _emberData.default.attr('string'),
    newValueFormat: Ember.computed('newValue', function () {
      return this.get('newValue');
    }),
    oldValueFormat: Ember.computed('oldValue', function () {
      return this.get('oldValue');
    }),
    eventLabel: Ember.computed('className', function () {
      return this.get('intl').t(`logs.${this.get('className')}`);
    })
  });

  _exports.default = _default;
});