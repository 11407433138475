define("apollo/pods/components/transport/add-indexes-section/component", ["exports", "apollo/utils/parsers/numberParsers"], function (_exports, _numberParsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: service('session-account'),
    overwritePreviousIndexes: Ember.computed('', function () {
      return false;
    }),
    disableIndexActionName: Ember.computed('', function () {
      return 'disableIndex';
    }),
    invalidIndexes: Ember.computed('transport.indexes.@each.hasErrors', function () {
      return this.get('transport.indexes').filterBy('hasErrors');
    }),
    defaultMapping: Ember.computed('', function () {
      return {
        properties: {
          globalNumber: 0,
          localNumber: 1,
          name: 2,
          documentNumber: 6,
          transportNumber: 7,
          notes: 10
        },
        quantities: {
          'Quantity': 3,
          'Number of pallets': 8,
          'Number of cartons': 9
        }
      };
    }).readOnly(),
    actions: {
      handlePastedContent(pastedContent) {
        const self = this;

        if (!pastedContent || !pastedContent.trim()) {
          return;
        }

        this.get('store').queryRecord('companyConfig', {
          name: 'INDEXES_XLS_MAPPING'
        }).then(config => {
          const customMappingString = config.get('value');
          const mapping = customMappingString ? JSON.parse(customMappingString) : self.get('defaultMapping');
          self.send('importIndexes', pastedContent, mapping);
        });
      },

      /** @namespace mapping.properties */

      /** @namespace mapping.quantities */
      importIndexes(pastedContent, mapping) {
        const self = this;

        if (!pastedContent || !pastedContent.trim()) {
          return;
        }

        if (this.get('overwritePreviousIndexes')) {
          this.get('transport.enabledIndexes').forEach(index => {
            self.sendAction('disableIndexActionName', index);
          });
        }

        const textMatrix = pastedContent.split(/\r?\n/).map(t => t.split('\t'));
        console.table(textMatrix);
        const propertyNames = ['globalNumber', 'localNumber', 'name', 'documentNumber', 'transportNumber', 'notes'];
        const propertyMapping = mapping.properties;
        textMatrix.forEach(row => {
          if (!row.filter(col => col.trim()).get('length')) {
            console.debug(`The row is empty. Skipping.`);
            return true;
          }

          const newIndex = this.get('store').createRecord('index', {
            transport: this.get('transport'),
            enabled: true
          });
          propertyNames.forEach(n => newIndex.set(n, row[propertyMapping[n]]));
          this.get('transport.transportType.indexQuantityTypes').forEach(qt => {
            const quantityIdx = mapping.quantities[qt.get('nameEn')];
            let q = row[quantityIdx];
            this.get('store').createRecord('indexQuantity', {
              indexQuantityType: qt,
              index: newIndex,
              quantity: _numberParsers.default.getParsableNumber(q)
            });
          });
          newIndex.validate();
          newIndex.get('quantities').forEach(q => q.validate());
          this.get('transport.indexes').addObject(newIndex);
        });
      },

      addIndex() {
        const newIndex = this.get('store').createRecord('index', {
          transport: this.get('transport')
        });
        this.get('transport.transportType.indexQuantityTypes').forEach(qt => {
          this.get('store').createRecord('indexQuantity', {
            indexQuantityType: qt,
            index: newIndex
          });
        });

        if (this.get('transport.contractors.length') === 1) {
          newIndex.set('step', this.get('transport.contractorSteps.firstObject'));
        }

        this.get('transport.indexes').addObject(newIndex);
        this.set("action", "showSubModal");
        this.sendAction('action', 'indexes.modals.index-form', newIndex);
      }

    }
  });

  _exports.default = _default;
});