define("apollo/pods/components/related-to-labels/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      showTransportModal: function (id) {
        this.get('store').findRecord('transport', id).then(transport => {
          transport.get('customFields').forEach(cf => cf.set('changeMessage', null));
          this.set('openTransportId', transport.get('id'));
          this.set('action', 'showModal');
          this.sendAction('action', transport);
        });
      }
    }
  });

  _exports.default = _default;
});