define("apollo/pods/amount-of-cover/model", ["exports", "ember-data/model", "ember-data", "apollo/mixins/model-validator"], function (_exports, _model, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    amount: attr('number'),
    currency: belongsTo('currency'),
    validations: {
      amount: {
        custom: [{
          validation: function (key, value) {
            return Number(value) > 0;
          },
          message: 'positiveNumber'
        }]
      }
    }
  });

  _exports.default = _default;
});