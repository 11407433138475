define("apollo/pods/ramps/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_EDIT_RAMP'],
    model: function (params, transition) {
      const currentUser = this.get('sessionAccount').get('currentUser');
      const hasAnyOfTheRoles = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));

      if (hasAnyOfTheRoles) {
        return this.store.findRecord('ramp', params.id, {
          reload: true
        });
      } else {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    renderTemplate: function () {
      this.render('ramps.create');
    },
    setupController: function (controller, model) {
      this.controllerFor('ramps.create').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function () {
        this.controllerFor('ramps.create').setProperties({
          success: false,
          errors: []
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});