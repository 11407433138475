define("apollo/pods/components/logs-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    classNames: ['logs-div'],
    groupingLogService: Ember.inject.service('grouping-log-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    isApiIntegrationLogs: Ember.computed(function () {
      return this.get('apiIntegration');
    }),
    isARTRLogs: Ember.computed(function () {
      return this.get('artrLogs');
    }),
    isSuperUser: Ember.computed('sessionAccount.isSuperUser', function () {
      return this.get('sessionAccount.isSuperUser');
    }),
    logs: Ember.computed('logsProxy', function () {
      if (this.get('isApiIntegrationLogs') || this.get('isARTRLogs')) {
        return this.get('logsProxy').sortBy('dateCreated').reverse();
      }

      return this.get('groupingLogService').groupLogs(this.get('logsProxy').sortBy('dateCreated').reverse());
    }),
    actions: {
      getIntegrationRequestLog: function (log) {
        if (!log.request) {
          this.getIntegrationRequestLog(log);
        }
      },
      resend: async function (log) {
        if (!confirm(this.get('intl').t('log.integration.sureToResend'))) {
          return;
        }

        const logId = log.get('id');
        const path = this.get('apolloApiService').APOLLO_API.TRANSPORTS.RESEND_API_MESSAGE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            logId
          });

          if (response.ok) {
            alert(this.get('intl').t('log.integration.resendSuccess'));
            this.refreshLogs(log.get('transport'));
          } else {
            alert(this.get('intl').t('log.integration.resendFailed'));
          }
        } catch (error) {
          alert(this.get('intl').t('common.defaultError'));
        }
      }
    }
  });

  _exports.default = _default;
});