define("apollo/pods/dialogs/message-popup-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DcDJCbKd",
    "block": "{\"symbols\":[\"e\"],\"statements\":[[4,\"popup-dialog\",null,[[\"title\",\"close\"],[[23,[\"title\"]],\"removePopupDialog\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\"],[9],[0,\"\\n                \"],[1,[22,1,[\"detail\"]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"            \"],[1,[21,\"model\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/dialogs/message-popup-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});