define("apollo/pods/components/documents-applies/show-document-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    store: Ember.inject.service(),
    actions: {
      showDocumentForm(groupName) {
        const partnership = this.get('partnership');
        const isInsurance = groupName === 'INSURANCE';
        let currency;

        if (isInsurance) {
          const countryIban = this.get('partnership.contractorCompany.country.ibanSymbol');
          const defaultCurrencySymbol = countryIban === 'PL' ? 'PLN' : 'EUR';
          currency = this.get('store').peekAll('currency').findBy('symbol', defaultCurrencySymbol);
        }

        const documentTypes = this.get('documentTypes');
        const documentType = this.get('documentType') || documentTypes.get('firstObject');
        const suggestedExpirationDate = moment().add(1, 'year').startOf('day').toDate();
        const suggestedExpirationDateFrom = isInsurance ? moment().startOf('day').toDate() : null;
        const scopeOfInsurance = this.get('documentType.ibanRestriction') === 'PL' ? 'Polska' : '';
        const documentModel = this.get('store').createRecord('document', {
          partnership: partnership,
          documentType: documentType,
          expirationDate: suggestedExpirationDate,
          expirationDateFrom: suggestedExpirationDateFrom,
          currency: currency,
          scopeOfInsurance: scopeOfInsurance
        });
        const model = {
          documentTypes: documentTypes,
          document: documentModel
        };
        this.set('action', 'showModal');
        this.sendAction('action', 'documents.modals.document-modal', model);
      }

    }
  });

  _exports.default = _default;
});