define("apollo/pods/components/companies/apply-form/component", ["exports", "apollo/mixins/section-mixin", "apollo/mixins/error-utils-mixin"], function (_exports, _sectionMixin, _errorUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend(_sectionMixin.default, _errorUtilsMixin.default, {
    sessionAccount: service('session-account'),
    store: service(),
    intl: service(),
    partnership: Ember.computed('model.company.partnershipWithPrincipal', function () {
      return this.get('model.company.partnershipWithPrincipal');
    }),
    contractorsGroupTypes: Ember.computed('partnership.partnershipType.name', function () {
      const self = this;

      if (!this.get('partnership.partnershipType.name')) {
        return;
      }

      return this.get('store').query('contractorsGroupType', {
        principalCompanyId: localStorage.getItem('principalId'),
        partnershipTypeName: self.get('partnership.partnershipType.name')
      });
    }),
    insuranceTypes: Ember.computed('other', function () {
      const countryIban = this.get('partnership.contractorCompany.country.ibanSymbol');
      return this.get('store').peekAll('documentType').filterBy('groupName', 'INSURANCE').filter(dt => {
        return !dt.get('ibanRestriction') || dt.get('ibanRestriction') === countryIban;
      });
    }),
    showRequestVerificationButton: Ember.computed('partnership.verificationRequestSent', function () {
      return !this.get('sessionAccount.currentUser.isPrincipal') && !this.get('partnership.verificationRequestSent');
    }),
    showApplyHistory: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_ACCESS_TO_APPLY_HISTORY');
    }),
    actions: {
      showModal(templateName = 'transports.modals.create-transport', model) {
        this.set('action', 'showMainModal');
        this.sendAction('action', templateName, model);
      },

      sendRequestVerification: function () {
        const self = this;
        this.clearErrorBeforeSendRequestVerification();
        this.get('partnership').set('sendRequestForVerification', true);

        if (!this.get('partnership').validate()) {
          this.get('partnership').set('sendRequestForVerification', false);
          this.set('showRequestVerificationErrors', true);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
          return;
        }

        this.get('partnership').save().then(p => {
          self.set('showRequestVerificationErrors', false);
          this.get('partnership').set('sendRequestForVerification', false);
        }).catch(response => {
          const errors = response.errors[0];
          self.handleErrors(errors.contractorsGroup, 'contractors-group');
          self.handleErrors(errors.contractorsGroupType, 'contractors-group-type');
          self.get('partnership').set('sendRequestForVerification', false);
          self.set('showRequestVerificationErrors', true);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      }
    },

    clearErrorBeforeSendRequestVerification() {
      this.setProperties({
        hasErrors: false,
        showRequestVerificationErrors: false,
        errors: []
      });
      this.get('store').peekAll('contractorsGroup').map(cg => {
        cg.get('errors').clear();
        cg.get('groupType.errors').clear();
      });
    }

  });

  _exports.default = _default;
});