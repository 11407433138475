define("apollo/pods/terms/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pV6Vrs/Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container container-medium\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"statute.accept.header\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"padding-top-big\"],[9],[0,\"\\n        \"],[1,[21,\"users/rodo-information\"],false],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"padding-top-big\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"statuteAccepted\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n                        \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n                    \"],[3,\"action\",[[22,0,[]],\"acceptStatute\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"disabled\",\"disabled\"],[12,\"title\",[27,\"t\",[\"statute.accept.disabled.title\"],null]],[9],[0,\"\\n                        \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"button-with-confirmation\",null,[[\"class\",\"text\",\"onConfirm\"],[\"btn btn-danger\",[27,\"t\",[\"statute.reject.confirmation\"],null],[27,\"action\",[[22,0,[]],\"rejectStatute\"],null]]],{\"statements\":[[0,\"                    \"],[1,[27,\"t\",[\"statute.reject.button\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/terms/review/template.hbs"
    }
  });

  _exports.default = _default;
});