define("apollo/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serializeIntoHash: function (data, type, record, options) {
      var object = this.serialize(record, options);

      for (var key in object) {
        data[key] = object[key];
      }
    }
  });

  _exports.default = _default;
});