define("apollo/pods/squares/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    store: service(),
    apolloApiService: service('api.apollo-api-service'),
    actions: {
      save() {
        const self = this;
        const square = this.get('model');
        square.validate();

        if (square.get('errors.length')) {
          return;
        }

        square.save().then(function (response) {
          const msg = self.get('intl').t('square.updateSuccessMessage', {
            squareName: square.get('name')
          });
          self.set('successMessage', msg);
          self.transitionToRoute('squares.edit', response.id);
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      disable() {
        const self = this;
        const model = this.get('model');
        const squareId = this.get('model.id');
        const jsonBody = JSON.stringify(model);
        const path = this.get('apolloApiService').APOLLO_API.SQUARES.DISABLE;
        this.get('apolloApiService').callApolloApi(path, {
          squareId
        }, {
          body: jsonBody
        }).then(() => {
          model.set('enabled', false);
          self.transitionToRoute('settings.index');
        });
      },

      enable() {
        const model = this.get('model');
        const squareId = this.get('model.id');
        const jsonBody = JSON.stringify(model);
        const path = this.get('apolloApiService').APOLLO_API.SQUARES.ENABLE;
        this.get('apolloApiService').callApolloApi(path, {
          squareId
        }, {
          body: jsonBody
        }).then(() => {
          model.set('enabled', true);
        });
      },

      cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }

    }
  });

  _exports.default = _default;
});