define("apollo/pods/components/companies/apply-form/anti-corruption-data-section/component", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    dragAndDrop: Ember.inject.service('drag-and-drop-service'),
    appliesSetting: Ember.computed(function () {
      return this.get('sessionAccount').getAppliesSetting();
    }),
    principalCompany: Ember.computed(function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekRecord('company', principalCompanyId);
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    },

    dragOver(event) {
      const element = this.element;
      this.get('dragAndDrop').dragOver(event, element);
    },

    dragLeave(event) {
      const element = this.element;
      this.get('dragAndDrop').dragLeave(event, element);
    },

    drop(event) {
      const element = this.element;
      this.get('dragAndDrop').drop(event, element);
    }

  });

  _exports.default = _default;
});