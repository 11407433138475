define("apollo/pods/temporary-account-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UnJ5/oXw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"showCancelButton\",\"classNames\"],[[27,\"t\",[\"temporaryAccountAlert.title\"],null],true,\"temporary-account-modal\"]],{\"statements\":[[0,\"\\n    \"],[1,[27,\"t\",[\"temporaryAccountAlert.limitReached\"],null],false],[0,\"\\n    \"],[1,[27,\"t\",[\"temporaryAccountAlert.daysLeft\"],[[\"days\"],[[23,[\"user\",\"temporaryAccountDaysLeft\"]]]]],false],[0,\"\\n    \"],[1,[27,\"t\",[\"temporaryAccountAlert.upgrade\"],null],false],[0,\"\\n    \"],[7,\"a\"],[11,\"target\",\"_blank\"],[11,\"href\",\"https://logintegra.com/cennik-pakietow/\"],[9],[1,[27,\"t\",[\"temporaryAccountAlert.pricingPlans\"],null],false],[10],[0,\".\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/temporary-account-modal/template.hbs"
    }
  });

  _exports.default = _default;
});