define("apollo/pods/components/confirm-statuses/component", ["exports", "apollo/mixins/checkpoint-mixin", "apollo/mixins/transport-form-mixin"], function (_exports, _checkpointMixin, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointMixin.default, _transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    checkpointService: Ember.inject.service(),
    layoutName: Ember.computed(function () {
      return this.get('isFromGuardTable') ? 'components/confirm-statuses/guard' : 'components/confirm-statuses/default';
    }),
    showTaskConfirmationDateForm: Ember.computed('transport.firstUnconfirmedCheckpoint.id', function () {
      const userCanConfirmCheckpointDate = this.get('transport.firstUnconfirmedCheckpoint.task.canAddConfirmationDateByRole');
      const userHasToAddCheckpointConfirmationDate = this.get('transport.firstUnconfirmedCheckpoint.task.userHasToAddCheckpointConfirmationDate');
      return !!(userCanConfirmCheckpointDate || userHasToAddCheckpointConfirmationDate);
    }),
    actions: {
      revoke(checkpoint, hide) {
        this.revoke(checkpoint);
        hide();
      },

      showModal(templateName, checkpoint) {
        this.sendAction('showModal', templateName, checkpoint);
      },

      hidePopup() {
        if (this.get('isFromShowStatuses')) {
          this.set('action', 'toggleStatusesPopup');
          this.sendAction('action');
        }
      }

    }
  });

  _exports.default = _default;
});