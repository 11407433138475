define("apollo/pods/components/transports/search-transports/scan-barcode-modal/scanner-input-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    intl: Ember.inject.service(),
    scannerInputService: Ember.inject.service('scanner-input-service'),
    classNames: Ember.A(['form-control']),
    reading: false,
    focusOut: function (e) {
      this._setFocus();
    },
    // eslint-disable-next-line ember/no-function-prototype-extensions
    becomeFocused: function () {
      const self = this;

      this._setFocus();

      let code = this.$().val();
      this.$().on('paste', function (e) {
        self._clearInput();
      });
      this.$().on('keypress', function (e) {
        if (e.keyCode === self.get('scannerInputService').ENTER_KEY_CODE) {
          if (code.length === self.get('scannerInputService').BARCODE_LENGTH) {
            self._handlePastedContent();
          }
        } else {
          code += e.key;
        }

        self._clearInput();
      });
    }.on('didRender'),

    _clearInput() {
      const self = this;

      if (!this.reading) {
        this.reading = true;
        setTimeout(() => {
          if (self.$()) {
            self.$().val('');
          }

          self.reading = false;
        }, self.get('scannerInputService').LETTER_INPUT_TIMEOUT_MS);
      }
    },

    _handlePastedContent() {
      const value = this.$().val();

      if (this.get('scannerInputService').verifyChecksum(value)) {
        this._searchTransport();
      } else {
        console.debug('Bląd podczas walidacji sumy kontrolnej podczas skanowania kodu kreskowego,' + ' wprowadzona wartość :: ' + value);
        this.set('errors', Ember.A([this.get('intl').t('table.scanBarcodeError')]));
      }
    },

    _setFocus() {
      try {
        const self = this;
        setTimeout(() => {
          if (self.$()) {
            self.$().focus();
          }
        }, 200);
      } catch (e) {
        console.debug(e);
      }
    },

    _searchTransport() {
      const self = this;
      setTimeout(() => {
        if (self.$()) {
          const value = self.get('value').substring(0, self.get('value').length - 1);
          self.send('searchTransport', value);
        }
      }, 100);
    },

    actions: {
      searchTransport(searchText) {
        this.set('action', 'searchTransport');
        this.sendAction('action', searchText);
      }

    }
  });

  _exports.default = _default;
});