define("apollo/pods/users/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_USER'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        return this.transitionTo('forbidden');
      }
    },
    model: function () {
      const currentUser = this.get('sessionAccount').get('currentUser');
      const userRoleGroup = currentUser.get('roleGroup');
      const roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      return Ember.RSVP.hash({
        roleGroupInvRestriction: roleGroupInvRestriction,
        user: this.store.createRecord('user', {
          company: currentUser.get('company')
        })
      });
    },
    actions: {
      willTransition() {
        this.get('controller').setProperties({
          'successMessage': null
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});