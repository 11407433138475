define("apollo/pods/companies/ramp-edition/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ["ROLE_MANAGE_COMPANY_RAMP"],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const company = this.store.findRecord('company', params.company_id);
      const companyRamps = this.store.query('company-ramp', {
        companyId: params.company_id
      });
      const users = this.store.query('user', {
        individualRampAccess: true,
        companyId: params.company_id
      });
      return Ember.RSVP.hash({
        company: company,
        companyRamps: companyRamps,
        users: users
      });
    }
  });

  _exports.default = _default;
});