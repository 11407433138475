define("apollo/helpers/has-authority-to-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service('session-account'),

    compute(params) {
      const permission = params[0];
      const transportTypeId = params[1].get('transportType.id');
      return this.get('sessionAccount').hasAuthorityInTransportType(permission, transportTypeId);
    }

  });

  _exports.default = _default;
});