define("apollo/pods/components/pagination-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Określa, czy zmiana stron będzie odbywała się przy pomocy route'a (link-to) czy przy pomocy akcji
     */
    navigateWithActions: Ember.computed('', function () {
      return false;
    }),
    separator: Ember.computed(function () {
      return '...';
    }).readOnly(),
    pageOffset: Ember.computed(function () {
      return 2;
    }).readOnly(),
    availableMaxPages: Ember.computed(function () {
      return ['10', '25', '100'];
    }),
    prevPage: Ember.computed('page', function () {
      return (parseInt(this.get('page')) || 1) - 1;
    }),
    nextPage: Ember.computed('page', function () {
      return (parseInt(this.get('page')) || 1) + 1;
    }),
    firstVisiblePage: Ember.computed('page', function () {
      return Math.max(1, parseInt(this.get('page')) - this.get('pageOffset'));
    }),
    lastVisiblePage: Ember.computed('page', 'totalPages', function () {
      return Math.min(parseInt(this.get('totalPages')), parseInt(this.get('page')) + this.get('pageOffset'));
    }),

    /**
     * Wyświetlamy aktualną stronę plus-minus `pageOffset` stron, oraz pierwszą i ostatnią stronę
     * - jeśli jeszcze nie są widoczne.
     *
     * Na przykład, dla `pageOffset` = 2, dostaniemy:
     * 1: 1, 2, 3, ..., 99,
     * 4: 1, 2, 3, 4, 5, 6, ..., 99,
     * 13: 1, ..., 11, 12, 13, 14, 15, ..., 99,
     * 98: 1, ..., 96, 97, 98, 99.
     */
    availablePages: Ember.computed('totalPages', 'firstVisiblePage', 'lastVisiblePage', function () {
      const pages = [];
      const firstVisiblePage = this.get('firstVisiblePage');
      const lastVisiblePage = this.get('lastVisiblePage');
      const lastPage = parseInt(this.get('totalPages'));

      if (firstVisiblePage !== 1) {
        pages.push(1);

        if (firstVisiblePage - 1 > 1) {
          pages.push(this.get('separator'));
        }
      }

      for (let idx = firstVisiblePage; idx <= lastVisiblePage; idx++) {
        pages.push(idx.toString());
      }

      if (lastVisiblePage !== lastPage) {
        if (lastPage - lastVisiblePage > 1) {
          pages.push(this.get('separator'));
        }

        pages.push(lastPage);
      }

      return pages;
    }),
    isLastPage: Ember.computed('page', 'totalPages', function () {
      return (parseInt(this.get('page')) || 1) >= parseInt(this.get('totalPages'));
    }),
    isFirstPage: Ember.computed('page', function () {
      return (parseInt(this.get('page')) || 1) === 1;
    }),
    actions: {
      incrementPage() {
        this.set('action', 'incrementPage');
        this.sendAction('action');
      },

      decrementPage() {
        this.set('action', 'decrementPage');
        this.sendAction('action');
      },

      setPage(page) {
        this.set('action', 'setPage');
        this.sendAction('action', page);
      }

    }
  });

  _exports.default = _default;
});