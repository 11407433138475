define("apollo/pods/users/queues/controller", ["exports", "apollo/mixins/persistence-mixin", "apollo/mixins/ramps-access-mixin", "apollo/mixins/queues-access-mixin", "apollo/mixins/user-utils-mixin"], function (_exports, _persistenceMixin, _rampsAccessMixin, _queuesAccessMixin, _userUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _rampsAccessMixin.default, _userUtilsMixin.default, _queuesAccessMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    usersIndexController: Ember.inject.controller('users/index'),
    usersShowController: Ember.inject.controller('users/show'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errors: [],
    user: Ember.computed('model.user', function () {
      return this.get('model.user');
    }),
    setProxiedSquares: Ember.observer('proxiedSquares.length', 'model', function () {
      const self = this;
      Ember.run.once(this, function () {
        self.checkSquares();
      });
    }),
    setProxiedQueues: Ember.observer('proxiedQueues.length', 'model', function () {
      const self = this;
      Ember.run.once(this, function () {
        self.checkQueues();
      });
    }),

    checkSquares() {
      const proxiedSquares = this.get('proxiedSquares');
      const squaresIds = this.get('user.squaresIds');
      proxiedSquares.forEach(function (s) {
        const checked = squaresIds.includes(s.get('id'));
        s.set('checked', checked);
      });
    },

    checkQueues() {
      const proxiedQueues = this.get('proxiedQueues');
      const userQueuesIds = this.get('user.queuesIds');
      proxiedQueues.forEach(function (q) {
        const checked = userQueuesIds.includes(q.get('id'));
        q.set('checked', checked);
      });
    },

    actions: {
      save: async function () {
        const user = this.get('user');
        const checkedQueues = this.get('checkedQueues');
        const jsonBody = JSON.stringify({
          user: user.get('id'),
          queues: checkedQueues.map(cq => cq.get('id'))
        });
        const path = this.get('apolloApiService').APOLLO_API.PERSON_QUEUES.SAVE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: jsonBody
          });

          if (response.ok) {
            const msg = this.get('intl').t('common.saveSuccessMessage');
            this.set('successMessage', msg);
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },
      cancel: function () {
        this.get('errors').clear();
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});