define("apollo/pods/authority-restrictions/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_MANAGE_AUTHORITIES']),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const authorityRestriction = this.store.peekRecord('authority-restriction', params.id);
      const transportType = authorityRestriction.get('transportType');
      return Ember.RSVP.hash({
        authorityRestriction,
        transportType
      });
    },
    actions: {
      willTransition: function () {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.controllerFor('authority-restrictions.edit').setProperties({
          success: false,
          errors: []
        });
        return true;
      }
    },
    setupController: function (controller, model) {
      const taskToSaveId = model.authorityRestriction.task.get('id'); // eslint-disable-next-line ember/no-controller-access-in-routes

      this.controllerFor('authority-restrictions.edit').setProperties({
        model,
        tasksToSave: taskToSaveId ? [model.authorityRestriction.task] : Ember.A([]),
        cfsToSave: [model.authorityRestriction.customFieldDefinition]
      });
    }
  });

  _exports.default = _default;
});