define("apollo/pods/components/dummy-input-plug/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zuvNYvnC",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"numberOfInputs\"]],1],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"form-group small-margin-form-group\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-12 col-xs-12\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"disabled\"],[\"text\",\"form-control\",true]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-12 col-xs-12 dummy-field\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"disabled\"],[\"text\",\"form-control\",true]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[4,\"template-loop\",null,[[\"range\"],[[23,[\"range\"]]]],{\"statements\":[[0,\"            \"],[7,\"div\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"col-md-4 col-xs-12 dummy-field dummy-field-left\"],[9],[0,\"\\n                    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"disabled\"],[\"text\",\"form-control\",true]]],false],[0,\"\\n                \"],[10],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-xs-12 dummy-field dummy-field-right\"],[9],[0,\"\\n                    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"disabled\"],[\"text\",\"form-control\",true]]],false],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-12 col-xs-12 dummy-field\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"disabled\"],[\"text\",\"form-control\",true]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/dummy-input-plug/template.hbs"
    }
  });

  _exports.default = _default;
});