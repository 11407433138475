define("apollo/helpers/format-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCalendar = formatCalendar;
  _exports.default = void 0;

  function formatCalendar(params) {
    const date = params[0];
    let dateIsValidDate = date && Object.prototype.toString.call(date) === '[object Date]';

    if (!dateIsValidDate) {
      return '';
    }

    return moment(date).calendar();
  }

  var _default = Ember.Helper.helper(formatCalendar);

  _exports.default = _default;
});