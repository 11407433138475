define("apollo/models/relations-wizard-view-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RelationsWizardViewModel = Ember.Object.extend({
    id: null,
    erpIdentifier: null,
    relationIdentifier: null,
    address: null,
    weight: null,
    isChecked: null,
    transport: null,
    transform: function (transport) {
      this.id = transport.get('id');
      this.erpIdentifier = transport.get('erpIdentifier');
      this.relationIdentifier = transport.get('transportRelationIdentifier');
      this.address = transport.get('unloadSteps').map(step => `${step.get('postal')} ${step.get('city')}`).join(', ');
      this.weight = transport.get('totalWeight');
      this.transport = transport;
      return this;
    }
  });
  var _default = RelationsWizardViewModel;
  _exports.default = _default;
});