define("apollo/pods/groups-settings/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_APPLIES_SETTINGS'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      return Ember.RSVP.hash({
        principalContractorGroupTypes: this.store.findAll('principal-contractors-group-type')
      });
    },
    actions: {
      willTransition() {
        this.get('controller').setProperties({
          errors: null,
          successMessage: null
        });
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});