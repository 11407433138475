define("apollo/pods/components/companies/apply-form/contractors-group-section/component", ["exports", "apollo/mixins/error-utils-mixin", "apollo/config/environment"], function (_exports, _errorUtilsMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_errorUtilsMixin.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    classNames: ['padding-left'],

    init() {
      this._super(...arguments);

      const self = this;
      this.get('contractorsGroupTypes').then(groupTypes => {
        groupTypes.forEach(gt => {
          gt.get('contractorsGroups').forEach(group => {
            const pcg = this.get('partnership.partnershipContractorsGroups').filterBy('contractorsGroup.id', group.get('id')).get('firstObject');

            if (!pcg) {
              this.get('store').createRecord('partnershipContractorsGroup', {
                partnership: self.get('partnership'),
                contractorsGroup: group,
                enabled: false
              });
            }
          });
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.send('rollbackChanges');
    },

    showGroupTypeTitle: Ember.computed('contractorsGroupTypes.length', function () {
      return this.get('contractorsGroupTypes.length') > 1;
    }),
    showAddNewGroupButton: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_ADD_CONTRACTOR_GROUP');
    }),
    showEditGroupButton: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_EDIT_CONTRACTOR_GROUP');
    }),
    proxiedGroupsConfiguration: Ember.computed('contractorsGroupTypes.[]', 'contractorsGroupTypes.@each.contractorsGroups', 'partnership.partnershipContractorsGroups.[]', 'partnership.partnershipContractorsGroups.@each.enabled', 'partnership.partnershipContractorsGroups.@each.errorMessages', 'partnership.contractorCompany.headquarter.country', 'principalContractorsGroupType', function () {
      const partnership = this.get('partnership');
      const partnershipContractorsGroups = this.get('partnership.partnershipContractorsGroups');
      const principalContractorsGroupType = this.get('principalContractorsGroupType');
      return this.get('contractorsGroupTypes').map(gt => {
        const contractorsGroups = gt.get('contractorsGroups').filter(cg => cg !== null);
        const shouldBeVisible = principalContractorsGroupType.filter(pcgt => pcgt.get('contractorsGroupType.id') === gt.id).get('firstObject').get('visible');
        return Ember.ObjectProxy.create({
          groupType: gt,
          groups: contractorsGroups.map(g => {
            const pcg = partnershipContractorsGroups.find(pcg => pcg.get('contractorsGroup.id') === g.get('id'));
            return Ember.ObjectProxy.create({
              content: g,
              pcg,
              disabled: g.shouldBeDisabled(partnership)
            });
          }),
          visible: shouldBeVisible
        });
      });
    }),
    actions: {
      toggleGroup: function (groupId) {
        const group = this.get('store').peekRecord('contractorsGroup', groupId);
        const pcg = this.get('partnership.partnershipContractorsGroups').filterBy('contractorsGroup.id', group.get('id')).get('firstObject');

        if (group.get('name') === 'ADR' && !confirm(this.get('intl').t(!pcg.get('enabled') ? 'contractors-group.selectADRInfo' : 'contractors-group.unSelectADRInfo'))) {
          return;
        }

        const shouldBeChecked = !this.get('partnership.contractorsGroupsIds').includes(group.get('id'));

        if (pcg) {
          pcg.set('enabled', shouldBeChecked);
        } else if (shouldBeChecked) {
          this.get('store').createRecord('partnershipContractorsGroup', {
            partnership: this.get('partnership'),
            contractorsGroup: group,
            enabled: true
          });
        }
      },
      save: function () {
        const self = this;
        self.set('successMessage', '');
        const token = this.get('sessionAccount').get('token');
        const partnership = this.get('partnership');
        const groupsData = partnership.get('contractorsGroups').map(g => g.get('id'));
        self.get('store').peekAll('contractorsGroup').map(g => {
          g.get('errors').clear();
          g.get('groupType.errors').clear();
        }); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: `${_environment.default.serverURL}/partnerships/${partnership.get('id')}/groups`,
          data: JSON.stringify({
            partnership: partnership.get('id'),
            groups: groupsData
          }),
          type: 'PUT',
          contentType: 'application/json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          const partnershipContractorsGroupsCopy = self.get('partnership.partnershipContractorsGroups').slice(0);
          partnershipContractorsGroupsCopy.forEach(pcg => {
            if (pcg !== undefined && !pcg.get('id') && pcg.unloadRecord) {
              console.debug(`Unloading redundant partnership group for group ${pcg.get('contractorsGroup.name')}..`);
              self.get('partnership.partnershipContractorsGroups').removeObject(pcg);
              pcg.unloadRecord();
            }
          });
          self.get('partnership').reload().then(() => {
            self.set('successMessage', self.get('intl').t('section.successMessage'));
          });
        }, function (response) {
          const errors = JSON.parse(response.responseText).errors;
          self.handleErrors(errors.contractorsGroup, 'contractors-group');
          self.handleErrors(errors.contractorsGroupType, 'contractors-group-type');
        });
      },
      cancel: function () {
        this.send('rollbackChanges');
      },
      showAddGroupModal: function (groupType, groupId) {
        const self = this;
        const principalId = localStorage.getItem('principalId');
        const principalCompany = self.get('store').peekRecord('company', principalId);
        const existingContractorsGroup = self.get('store').peekRecord('contractorsGroup', groupId);
        const model = {
          contractorsGroup: existingContractorsGroup || self.get('store').createRecord('contractorsGroup', {
            principalCompany,
            groupType
          }),
          groupType,
          partnership: self.get('partnership')
        };
        self.set('action', 'showModal');
        self.sendAction('action', 'contractors-group.modals.create-contractors-group', model);
      },

      rollbackChanges() {
        this.get('partnership').rollbackAttributes();
        const groups = this.get('partnership.partnershipContractorsGroups').map(g => g);
        groups.forEach(g => {
          if (g && g.rollback) {
            g.rollback();
          }
        });
      }

    }
  });

  _exports.default = _default;
});