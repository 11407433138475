define("apollo/pods/file/model", ["exports", "ember-data", "ember-data/model", "apollo/config/environment"], function (_exports, _emberData, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend({
    sessionAccount: Ember.inject.service('session-account'),
    transport: belongsTo('transport', {
      async: true
    }),
    name: attr(),
    canBeDeleted: attr('boolean'),
    type: attr(),
    warehouse: belongsTo('warehouse', {
      async: true
    }),
    creator: attr('string'),
    date: attr('isodate'),
    refLink: Ember.computed(function () {
      return _environment.default.serverURL + '/files/' + this.id + '/download?token=' + this.get('sessionAccount').get('token');
    }),
    showDeleteIcon: Ember.computed('canBeDeleted', function () {
      const transport = this.store.peekRecord('transport', this.transport.get('id'));
      return transport.actionCanBePerformed('DELETE_DOCUMENTS') && this.get('canBeDeleted');
    }),
    canBeDownloaded: Ember.computed(function () {
      const fileType = this.type ? this.type : '';

      if (fileType.includes('TYPE_ENTRANCE_PASS_DOCUMENT')) {
        return this.get('sessionAccount').hasRole('ROLE_CAN_DOWNLOAD_ENTRY_PASS');
      } else return true;
    })
  });

  _exports.default = _default;
});