define("apollo/pods/components/trucks/truck-type-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    truckTypeList: Ember.A([]),
    tagName: '',

    init() {
      this._super(...arguments);

      const transportTypeId = this.get('model.transportType.id') !== undefined ? this.get('model.transportType.id') : this.get('model.route.transportType.id');
      this.set('truckTypeList', this.get('store').query('truckType', {
        transportTypeId
      }));
    },

    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    })
  });

  _exports.default = _default;
});