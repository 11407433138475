define("apollo/pods/invitations/review/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    model: function (params) {
      return this.store.queryRecord("invitation", {
        token: params.token
      });
    }
  });

  _exports.default = _default;
});