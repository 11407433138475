define("apollo/pods/components/paste-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nboF0c2B",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"textarea\",null,[[\"type\",\"class\",\"placeholder\",\"rows\"],[\"textarea\",\"form-control non-resizable paste-area inline-block\",\"Ctrl+V\",\"1\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/paste-area/template.hbs"
    }
  });

  _exports.default = _default;
});