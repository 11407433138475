define("apollo/pods/courier-pricing-success/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    changeTracker: Object.freeze({
      auto: true
    }),
    intl: Ember.inject.service(),
    serviceName: attr('string'),
    priceFuel: attr('number'),
    priceSumNetto: attr('number'),
    priceSumVat: attr('number'),
    price: attr('number'),
    priceBase: attr('number'),
    priceBaseVat: attr('number'),
    pricePeak: attr('number'),
    priceMultiplePackaging: attr('number'),
    priceCurrency: belongsTo('currency'),
    dhlPricePremium: attr('number'),
    dhlPriceEs: attr('number'),
    dhlPickupCapabilities: attr('string'),
    dhlDeliveryCapabilities: attr('string'),
    dhlProductCode: attr('string'),
    courierPricing: belongsTo('courierPricing'),
    validations: {}
  });

  _exports.default = _default;
});