define("apollo/services/transport-relations/relations-combiner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransportCombiningOverweight extends Error {
    constructor(...params) {
      super(...params);

      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, TransportCombiningOverweight);
      }

      this.name = 'TransportCombiningOverweight';
      this.date = new Date();
    }

  }

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    relationsTracker: Ember.inject.service('transport-relations.relations-tracker'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    timeWindowService: Ember.inject.service('time-window-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    combinedTransports: Ember.A([]),
    combinedTransportsLabel: Ember.A([]),
    combiningSuccess: null,
    isOverweight: function (relation) {
      const maxWeight = relation.relation.maxTotalWeight;

      if (!maxWeight) {
        return false;
      }

      let totalWeight = 0;
      const masters = Ember.A(relation.masters.slice());
      const slaves = Ember.A(relation.slaves.slice());
      const allTransports = masters.concat(slaves);
      allTransports.forEach(transport => {
        transport.get('unloadSteps').forEach(step => {
          if (!step || !step.get('weight')) {
            return;
          }

          totalWeight += step.get('weight');
        });
      });
      return totalWeight > maxWeight;
    },
    _transportWithHighestNumOfWindows: function (relation) {
      // eslint-disable-next-line no-useless-call
      return [].concat.apply([], [relation.masters, relation.slaves]).sortBy('stepsWithWarehouse.length').get('lastObject');
    },
    _handleTransportCombining: function (relation) {
      const self = this;

      if (this._combinedTransportsHasAdviceAlready(relation)) {
        if (!confirm(this.intl.t('transport.relations.combineClearingAlert'))) {
          console.debug('Użytkownik przerwał operacje łączenia');
          return;
        }
      }

      this._removeAdvicesAndTimeWindows(relation).then(() => {
        self.decombine(relation).then(() => {
          self._saveTransportCombining(relation).then(() => {
            self._handleAutoAdviceWhenCombined(relation);
          }, error => {
            throw new Error(error);
          });
        }, error => {
          throw new Error(error);
        });
      }, error => {
        throw new Error(error);
      });
    },
    _handleAutoAdviceWhenCombined: function (relation) {
      if (!relation.relation.get('autoAdviceWhenCombined')) {
        return;
      }

      this._handleCreatingTimeWindow(relation);
    },
    _saveTransportCombining: async function (selectedRelation) {
      const self = this;
      const slaveTransports = selectedRelation.slaves;
      const masterTransports = selectedRelation.masters;
      const transports = slaveTransports.concat(masterTransports);
      const relationType = selectedRelation.relation;

      if (!transports || !relationType) {
        throw new Error('Błąd podczas łaczenia transportów, lista transportów ' + ':: ' + transports + ' :: jest pusta lub relacja ' + ':: ' + relationType + ' :: nie istnieje.');
      }

      const slaveTransportType = slaveTransports.get('firstObject.transportType');
      const masterTransportType = masterTransports.get('firstObject.transportType');

      self._prepareCombinedTransportsLabel(transports);

      self.actionProgressToast.showLoadingToast(this.get('intl').t('notificationToast.transportCombining.loading'));

      try {
        const path = this.get('apolloApiService').APOLLO_API.TRANSPORT_TRANSPORT_RELATIONS.SAVE_TRANSPORT_COMBINING;
        const payload = JSON.stringify(self._prepareCombiningPayload(relationType, transports));
        const response = await this.get('apolloApiService').callApolloApi(path, null, {
          body: payload
        });

        if (response.ok) {
          const data = await response.json();
          this.get('store').pushPayload(data.transportTransportRelations);
          this.set('combiningSuccess', true);
        } else {
          console.debug(response);
          const error = await response.json();
          console.error(error.errors);
          self.set('combiningSuccess', false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this._refreshAfterTransportCombining(masterTransportType, slaveTransportType, transports).then(() => {
          this._showCombiningResultToast();
        });
      }
    },
    _handleHasToWaitFor: function (relation) {
      const masterTransports = relation.masters;
      const masterTransportType = masterTransports.get('firstObject.transportType');
      const slaveTransportType = relation.slaves.get('firstObject.transportType');
      const relationIdentifier = masterTransports.get('firstObject.internalIndex');
      masterTransports.forEach(master => {
        if (!master) {
          return;
        }

        const otherTransports = relation.slaves;
        const relationType = relation.relation;

        if (master && otherTransports) {
          otherTransports.forEach(relatedTransport => {
            const transportTransportRelation = this.get('store').createRecord('transportTransportRelation', {
              transportRelation: relationType,
              masterTransport: master,
              relatedTransport,
              alias: relationType.get('alias'),
              relationIdentifier
            });
            transportTransportRelation.save().then(() => {
              relatedTransport.reload();
            }).catch(response => {
              console.debug(response);
              console.error(response.errors);
            });
          });
          master.reload();
        }
      });

      this._refreshAfterTransportCombining(masterTransportType, slaveTransportType, this._getAllTransports(relation));
    },
    _refreshAfterTransportCombining: function (masterTransportType, slaveTransportType, transports) {
      return new Promise(function (resolve) {
        masterTransportType.set('transportsTabVisited', false);
        masterTransportType.set('forcedPollRequestTime', new Date().getTime());
        slaveTransportType.set('transportsTabVisited', false);
        slaveTransportType.set('forcedPollRequestTime', new Date().getTime());
        transports.forEach(t => {
          t.reload();
        });
        resolve();
      });
    },

    combine(relation) {
      try {
        if (this._noRelations(relation)) {
          console.warn('Brak transportów w modelu relacji');
          this.router.transitionTo('transports.index', localStorage.getItem('LastVisitedTransportType'));
        }

        if (this.isOverweight(relation)) {
          throw new TransportCombiningOverweight();
        }

        if (relation.relation.get('alias') === 'HAS_TO_WAIT_FOR') {
          this._handleHasToWaitFor(relation);

          this.relationsTracker.clear();
          return;
        }

        if (relation.relation.get('alias') === 'TRANSPORT_COMBINING') {
          this._handleTransportCombining(relation);

          this.relationsTracker.clear();
          return;
        }
      } catch (e) {
        console.error('Błąd podczas tworzenia relacji :: ' + e);

        if (e instanceof TransportCombiningOverweight) {
          const maxWieghtTranslate = this.get('intl').t('transport.relations.maxWeight') + relation.relation.maxTotalWeight + 'kg';
          this.actionProgressToast.showErrorToast(this.get('intl').t('transport.relations.overweight'), maxWieghtTranslate);
        } else {
          this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.transportCombining.error'));
        }
      }
    },

    decombineAllRelatedTransports(transport) {
      if (transport.get('relatedTo.length') < 1) {
        return;
      }

      const relation = {
        masters: [transport],
        slaves: []
      };
      this.decombine(relation);
    },

    async decombine(relation) {
      try {
        const transportId = this._getAllTransports(relation).find(transport => transport.get('isCombinedTransport')).get('id');

        const path = this.get('apolloApiService').APOLLO_API.TRANSPORT_TRANSPORT_RELATIONS.DELETE_ALL_COMBINED_RELATIONS;
        const response = await this.get('apolloApiService').callApolloApi(path, {
          id: transportId
        });

        if (response.status === 204) {
          return;
        }

        if (!response.ok) {
          const error = await response.json();
          console.error('Błąd podczas próby rozłączania transportów. ' + error.errors);
        }

        console.log(`Usuneliśmy wszystkie relacje combining transportu :: ${transportId}`);
        console.debug(response);
      } catch (error) {
        console.error(error);
      }
    },

    _handleCreatingTimeWindow(relation) {
      this._transportWithHighestNumOfWindows(relation).reload().then(transport => {
        const stepsWithWarehouse = transport.get('stepsWithWarehouse');
        const step = stepsWithWarehouse && stepsWithWarehouse.get('length') > 0 ? stepsWithWarehouse.get('firstObject') : transport.get('steps.firstObject');

        this._goToWarehouse(step);
      });
    },

    _goToWarehouse(step) {
      const adviceButton = $('.advice-button-' + step.get('transport.id'));
      adviceButton.button('loading');

      if (!step.get('isInWarehouse')) {
        this.openCreateAdviceModal(step.get('transport'));
        adviceButton.button('reset');
        return;
      }

      const warehouseId = step.get('warehouse.id');
      const transport = this.get('store').peekRecord('transport', step.get('transport.id'));
      this.timeWindowService.getFirstPossibleWindowStart(step, transport).then(response => {
        const firstPossibleStart = response.firstPossibleStart;

        if (!firstPossibleStart) {
          alert(response.error);
          adviceButton.button('reset');
          return;
        }

        const dateString = moment(firstPossibleStart).format('YYYY-MM-DD');
        this.router.transitionTo('warehouses.combined-schedule', warehouseId, dateString, '1', step.get('id'));
        adviceButton.button('reset');
      });
    },

    _combinedTransportsHasAdviceAlready(relation) {
      const transports = this._getAllTransports(relation);

      if (!transports || transports.length < 1) {
        return;
      }

      return transports.any(t => t.get('hasAdvice'));
    },

    async _removeAdvicesAndTimeWindows(relation) {
      const transports = this._getAllTransports(relation);

      if (!transports) {
        return new Error('Nie znaleziono transportow :: ' + transports);
      }

      if (!relation.relation.get('autoAdviceWhenCombined')) {
        // Transporty nie mają flagi auto advice, nie usuwamy okien
        return Promise.resolve();
      }

      const self = this;
      const transportWithMaster = transports.find(t => {
        return t.get('isCombinedTransport');
      });
      const response = await this.get('apolloApiService').callApolloApi(this.get('apolloApiService').APOLLO_API.TRANSPORT_TRANSPORT_RELATIONS.CLEAR_ADVICES_AND_TWS, null, {
        body: JSON.stringify(self._prepareCombiningPayload(relation.relation, transports))
      });

      if (response.ok) {
        transports.forEach(t => {
          t.get('timeWindows').forEach(tw => {
            t.get('timeWindows').removeObject(tw);
            tw.unloadRecord();
          });
        });

        if (transportWithMaster) {
          transportWithMaster.get('masterTransports.firstObject.timeWindows').forEach(tw => {
            console.debug('Unlaoding tw for master transport' + tw.id);
            transportWithMaster.get('masterTransports.firstObject.timeWindows').removeObject(tw);
            tw.unloadRecord();
          });
        }
      } else {
        const error = await response.json();
        throw new Error(error.errors);
      }
    },

    _getAllTransports(relation) {
      return relation.masters.concat(relation.slaves).slice();
    },

    _prepareCombiningPayload(relationType, transports) {
      return {
        transportRelation: relationType.get('id'),
        relatedTransports: transports.map(transport => transport.get('id'))
      };
    },

    _prepareCombinedTransportsLabel(transports) {
      this.set('combinedTransports', Ember.A([]));
      transports.forEach(t => {
        this.get('combinedTransports').pushObject(t);
      });
      this.set('combinedTransportsLabel', this.get('combinedTransports').getEach('erpIdentifier').join(', '));
    },

    _showCombiningResultToast() {
      const isCombiningTransports = this.get('combinedTransports').length > 0;

      if (this.get('combiningSuccess') && isCombiningTransports) {
        this.actionProgressToast.showSuccessToast(this.get('intl').t('notificationToast.transportCombining.success') + this.get('combinedTransportsLabel'));
      } else if (!this.get('combiningSuccess') && isCombiningTransports) {
        this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.transportCombining.error'));
      }

      this.set('combiningSuccess', null);
    },

    _noRelations(relation) {
      return (relation.masters.length === 0 || relation.masters.every(t => {
        return t === null || t === undefined;
      })) && (relation.slaves.length === 0 || relation.slaves.every(t => {
        return t === null || t === undefined;
      }));
    }

  });

  _exports.default = _default;
});