define("apollo/pods/components/schedule/time-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8wNA00kw",
    "block": "{\"symbols\":[\"time\"],\"statements\":[[4,\"each\",[[23,[\"timeObjects\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"time-label \",[21,\"timeSlotIntervalClass\"],\" hour-\",[22,1,[\"hour\"]],\" \",[27,\"if\",[[27,\"and\",[[23,[\"shownDaysContainCurrentDay\"]],[27,\"hour-is-between\",[[23,[\"currentHour\"]],[22,1,[]],[23,[\"timeWindowOffsetInterval\"]]],null]],null],\"current-hour\"],null]]]],[9],[0,\"\\n        \"],[1,[22,1,[\"hour\"]],false],[0,\":\"],[1,[22,1,[\"minutes\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/schedule/time-bar/template.hbs"
    }
  });

  _exports.default = _default;
});