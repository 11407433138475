define("apollo/pods/auth/reset-password/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    loginController: Ember.inject.controller('auth/login'),
    actions: {
      resetPassword() {
        const self = this;
        self.set('errorMessage', null);
        $(".reminding-progress").show();
        const url = _environment.default.serverURL + '/resetPassword';
        const data = {
          password: this.get('password'),
          repeatedPassword: this.get('repeatedPassword'),
          hash: this.get('model.hash')
        }; // TODO :: apollo-api-service

        Ember.$.ajax({
          url: url,
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(data)
        }).then(function (resp) {
          $('.reminding-progress').hide();

          if (resp.errors) {
            self.set('errorMessage', resp.errors);
          } else {
            const msg = self.get('intl').t('auth.passwordChanged', {
              username: resp.user.name
            });
            self.get('loginController').set('message', msg);
            self.transitionToRoute('auth.login');
          }
        });
      }

    }
  });

  _exports.default = _default;
});