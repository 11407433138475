define("apollo/pods/components/sections/document-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dragAndDrop: Ember.inject.service('drag-and-drop-service'),
    activeDocumentsOfType: Ember.computed('documentType.id', 'modelObject.activeDocuments.length', function () {
      const self = this;
      return this.get('modelObject.activeDocuments').filter(doc => {
        return doc.get('documentType.id') === self.get('documentType.id');
      });
    }),

    dragOver(event) {
      const element = this.element;

      if (this.get('modelObject').status !== 'DISABLED') {
        this.get('dragAndDrop').dragOver(event, element);
      }
    },

    dragLeave(event) {
      const element = this.element;
      this.get('dragAndDrop').dragLeave(event, element);
    },

    drop(event) {
      const element = this.element;
      this.get('dragAndDrop').drop(event, element);
    },

    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});