define("apollo/pods/transport-relation/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend({
    alias: attr('string'),
    primaryTransportType: belongsTo('transport-type', {
      async: true,
      inverse: null
    }),
    subordinateTransportType: belongsTo('transport-type', {
      async: true,
      inverse: null
    }),
    maxTotalWeight: attr('number'),
    requiresTheSameWarehouseInDifferentStepType: attr('boolean'),
    requiresTheSameWarehouseInTheSameStepType: attr('boolean'),
    autoAdviceWhenCombined: attr('boolean')
  });

  _exports.default = _default;
});