define("apollo/pods/max-pallet/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    warehouse: _emberData.default.belongsTo('warehouse'),
    ramp: _emberData.default.belongsTo('ramp', {
      async: true
    }),
    quantity: _emberData.default.attr('number'),
    day: _emberData.default.attr('isodate'),
    validations: {
      quantity: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});