define("apollo/pods/components/partnership-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    CARRIER: 'CARRIER',
    SUPPLIER: 'SUPPLIER',
    OPERATOR: 'OPERATOR',
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    sessionAccount: Ember.inject.service('session-account'),
    newContractorType: Ember.computed('company.partnershipType.name', function () {
      const companyPartnershipType = this.get('company.partnershipType.name');
      return companyPartnershipType === 'CARRIER' ? 'SUPPLIER' : 'CARRIER';
    }),
    filteredPartnerships: Ember.computed('partnerships.[]', function () {
      return this.get('partnerships').filter(p => p.get('principalCompany.id') === this.get('company.id') || !p.get('principalCompany.isPrincipal'));
    }),
    findCurrentCompanyPartnershipSide: function () {
      const newContractorType = this.get('newContractorType');
      return newContractorType === 'CARRIER' ? 'principalCompany' : 'contractorCompany';
    },
    actions: {
      openAddPartnershipModal() {
        const partnershipType = this.get('store').peekAll('partnershipType').find(pt => pt.get('name') === 'CARRIER');
        const currentCompanyPartnershipSide = this.findCurrentCompanyPartnershipSide();
        const newContractorType = this.get('newContractorType');
        const partnership = this.get('store').createRecord('partnership', {
          status: 'NO_INVITATION',
          type: 'DEFAULT'
        });
        const company = this.get('company');
        partnership.set('partnershipType', partnershipType);
        partnership.set(currentCompanyPartnershipSide, company);
        this.showModal('companies.modals.add-partnership', {
          partnership,
          company,
          newContractorType
        });
      },

      async connectAllContractors() {
        const company = this.get('company');
        const principalId = localStorage.getItem('principalId');
        const principalCompany = this.get('store').peekRecord('company', principalId);
        const contractorsType = company.get('partnershipType.name') === 'CARRIER' ? 'SUPPLIER' : 'CARRIER';
        const settingKey = this.get('sessionAccount').getSettingValue('SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY');
        const contractorsTypeKey = settingKey ? `${contractorsType}.${settingKey}` : contractorsType;
        const fallbackKey = `partnership.connectAll.${contractorsType}`;
        const key = `partnership.connectAll.${contractorsTypeKey}`;
        const msg = this.get('intl').t('partnership.connectAll.cofirmMsg', {
          companyName: company.get('name'),
          contractorsType: this.get('intl').t(key, {
            default: fallbackKey
          }),
          principalCompanyName: principalCompany.get('name')
        });

        if (!confirm(msg)) {
          return;
        }

        const data = JSON.stringify({
          principalCompany: {
            id: principalId
          },
          company: {
            id: company.get('id')
          },
          contractorsType
        });
        const path = this.get('apolloApiService').APOLLO_API.PARTNERSHIPS.CONNECT_ALL;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: data
          });

          if (response.ok) {
            alert(this.get('intl').t('partnership.connectAll.success'));
            window.location.reload();
          } else {
            const error = await response.json();
            alert(error.errors);
          }
        } catch (error) {
          alert(this.get('intl').t('common.defaultError'));
          console.error(error);
        }
      }

    }
  });

  _exports.default = _default;
});