define("apollo/pods/index-quantity-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    nameEn: _emberData.default.attr('string'),
    idx: _emberData.default.attr('number'),
    obligatory: _emberData.default.attr('boolean'),
    conditionallyObligatory: _emberData.default.attr('boolean'),
    requiresUnit: _emberData.default.attr('boolean'),
    enabledForIncompleteLoading: _emberData.default.attr('boolean'),
    editableViaSchedule: _emberData.default.attr('boolean'),
    nameInAppropriateLanguage: Ember.computed('name', 'nameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('name', 'nameEn');
      return this.get(propertyName);
    }).readOnly()
  });

  _exports.default = _default;
});