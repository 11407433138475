define("apollo/pods/components/table-column-settings/component", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    tableColumns: Ember.computed('tableColumn.[]', function () {
      return this.get('store').peekAll('tableColumn');
    }),
    labelForTransportSupervisor: Ember.computed('tableColumn.[]', function () {
      const translationKey = this.get('transportType.transportSupervisorTranslationKey');

      if (translationKey) {
        return this.get('intl').t('transportSupervisorTranslationKeys.' + translationKey);
      }

      return this.get('intl').t('transportSupervisorTranslationKeys.CONTACT_PERSON');
    }),
    labelForWorkerPhoneNumber: Ember.computed('tableColumn.[]', 'transportType.id', function () {
      const translationKey = this.get('transportType.workerFieldTranslationKey');

      if (translationKey) {
        return this.get('intl').t('worker.phoneNumber.' + translationKey);
      }

      return this.get('intl').t('worker.phoneNumber.WORKERS');
    }),
    availableColumns: Ember.computed('transportTypeRoleGroupTableColumns.[]', 'roleGroup.id', 'transportType.id', function () {
      const self = this;
      const transportTypeRoleGroupTableColumns = this.get('transportTypeRoleGroupTableColumns').filter(transportTypeRoleColumn => {
        return transportTypeRoleColumn.get('transportType.id') === self.get('transportType.id') && transportTypeRoleColumn.get('roleGroup.id') === self.get('roleGroup.id');
      });
      return transportTypeRoleGroupTableColumns.map(transportTypeRoleColumn => transportTypeRoleColumn.get('tableColumn'));
    }),
    availableColumnIds: Ember.computed('availableColumns.[]', function () {
      return this.get('availableColumns').map(c => c.get('id'));
    }),
    actions: {
      toggleTableColumn: function (tc) {
        const tableColumn = this.get('availableColumns').find(ac => ac.get('id') === tc.get('id'));

        if (tableColumn !== undefined) {
          this.get('availableColumns').removeObject(tableColumn);
        } else {
          this.get('availableColumns').addObject(tc);
        }
      },
      save: function () {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: `${_environment.default.serverURL}/transport-type-role-group-table-columns`,
          data: JSON.stringify({
            tableColumns: self.get('availableColumnIds'),
            roleGroup: self.get('roleGroup.id'),
            transportType: self.get('transportType.id')
          }),
          type: 'PUT',
          contentType: 'application/json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          self.set('successMessage', `${self.get('intl').t('common.saveSuccessMessage') + '. ' + self.get('intl').t('common.saveSuccessAdditionalMessage')}`);
        }, function (response) {
          self.set('successMessage', null);
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});