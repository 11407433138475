define("apollo/utils/parsers/numberParsers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getParsableNumber = getParsableNumber;
  _exports.default = void 0;

  function getParsableNumber(n) {
    if (!n || !n.trim() || !/^[0-9,. ]*$/.test(n)) {
      return n;
    }

    while (n.indexOf('.') === 0) {
      // Jeśli kropki są na początku, to na bank są tam błędnie i je wywalamy
      n = n.replace(/\./, '');
    }

    while (n.indexOf(',') === 0) {
      // Jeśli przecinki są na początku, to na bank są tam błędnie i je wywalamy
      n = n.replace(/,/, '');
    }

    const numberOfDots = n.split('.').length - 1;
    const numberOfCommas = n.split(',').length - 1;

    if (numberOfDots > 1 && numberOfCommas > 1) {
      console.log(`We don't know what to do whit this number: ${n}.`);
      return n;
    }

    const indexOfLastComma = n.lastIndexOf(',');
    const indexOfLastDot = n.lastIndexOf('.');
    let parsableNumber = n.replace(/\s/g, '');

    if (indexOfLastDot < 0 && indexOfLastComma < 0) {
      // Wyrażenie powinno już być poprawne.
      return n;
    } else if (indexOfLastDot >= 0 && indexOfLastComma < 0) {
      // Występują tylko "." - jeśli jest tylko jedna, to pewnie jest separatorem dzięsiętnym
      // i nie musimy już zmieniać wprowadzonego numeru; w przeciwnym razie znak ten rozdziela pewnie tysiące,
      // więc trzeba go usunąć.
      if (numberOfDots > 1) {
        parsableNumber = parsableNumber.replace(/\./g, '');
      }
    } else if (indexOfLastComma >= 0 && indexOfLastDot < 0) {
      // Występują tylko "," - jeśli jest tylko jeden, to pewnie jest separatorem dzięsiętnym
      // i trzeba go zmienić na kropkę; w przeciwnym razie znak ten rozdziela pewnie tysiące,
      // więc trzeba go usunąć.
      if (numberOfCommas === 1) {
        parsableNumber = parsableNumber.replace(',', '.');
      } else if (numberOfCommas > 1) {
        parsableNumber = parsableNumber.replace(/,/g, '');
      }
    } else if (indexOfLastComma > indexOfLastDot) {
      // Znak "," jest ostatni, więc przyjmujemy, że jest separatorem dziesiętnym; usuwamy więc wszystkie ".",
      // które służyły rozdzielaniu tysięcy.
      parsableNumber = parsableNumber.replace(/\./g, '').replace(',', '.');
    } else {
      // Znak "." jest ostatni, więc przyjmujemy, że jest separatorem dziesiętnym; usuwamy więc wszystkie ",",
      // które służyły rozdzielaniu tysięcy.
      parsableNumber = parsableNumber.replace(/,/g, '');
    }

    if (n !== parsableNumber) {
      console.debug(`We guessed that ${n} was meant to be ${parsableNumber}.`);
    }

    return parsableNumber;
  }

  var _default = {
    getParsableNumber
  };
  _exports.default = _default;
});