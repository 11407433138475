define("apollo/pods/courier-order-error/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    errorCode: _emberData.default.attr('string'),
    errorMessage: _emberData.default.attr('string'),
    errorType: _emberData.default.attr('string')
  });

  _exports.default = _default;
});