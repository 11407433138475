define("apollo/pods/api-integration-log/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    url: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    request: _emberData.default.attr('string'),
    responseCode: _emberData.default.attr('string'),
    httpMethod: _emberData.default.attr('string'),
    event: _emberData.default.attr('string'),
    actor: _emberData.default.attr('string'),
    canBeResend: _emberData.default.attr('boolean'),
    eventLabel: Ember.computed('propertyName', function () {
      return this.get('intl').t(`apiIntegrationLogs.event.${this.get('event')}`);
    })
  });

  _exports.default = _default;
});