define("apollo/pods/components/tax-id-number-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SgIlwv9n",
    "block": "{\"symbols\":[\"isoCode\"],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showIsoSelect\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"input-group-addon iso-select\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"selected\",\"options\",\"onchange\"],[[23,[\"company\",\"taxIdIsoNumber\"]],[23,[\"company\",\"country\",\"alternativeIsoCodes\"]],[27,\"action\",[[22,0,[]],\"changeCountry\"],null]]],{\"statements\":[[0,\"                \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[9],[0,\"\\n            \"],[1,[23,[\"company\",\"taxIdIsoNumber\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\"],[11,\"data-field-name\",\"TAX_ID_INPUT\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"placeholder\",\"focus-out\",\"class\",\"value\",\"disabled\"],[[27,\"t\",[\"company.taxIdNumber\"],null],[27,\"action\",[[22,0,[]],\"verifyNip\"],null],\"form-control\",[23,[\"company\",\"taxIdNumber\"]],[23,[\"disabled\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"company\"]],\"taxIdIsoNumber\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/tax-id-number-field/template.hbs"
    }
  });

  _exports.default = _default;
});