define("apollo/pods/partnership/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      principalCompany: {
        serialize: 'records'
      },
      contractorCompany: {
        embedded: 'save'
      },
      mainSection: {
        embedded: 'save'
      }
    },
    serializeHasMany: function (record, json, relationship) {
      const key = relationship.key;
      const hasManyRecords = record.hasMany(key);

      if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === "save") {
        json[key] = [];
        hasManyRecords.forEach(function (item) {
          const data = item.attributes();
          data.id = item.id;
          json[key].push(data);
        });
      } else {
        this._super(record, json, relationship);
      }
    },
    serializeBelongsTo: function (record, json, relationship) {
      const key = relationship.key;
      const embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === "save") {
        const data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = embeddedRecord.belongsTo(deepKey).serialize();
            }
          });
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});