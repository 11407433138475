define("apollo/pods/notifications/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    notificationsIndexController: Ember.inject.controller('notifications/index'),
    commonMessageInputType: ['TRANSPORT_ID', 'CAR_REG_NUM', 'TRAILER_REG_NUM', 'COMPANY_SHORT_NAME', 'RAMP_NUMBER', 'SQUARE_NAME', 'QUEUE_NAME', 'PLACE_NAME', 'LOCATION', 'GATE', 'TIME_WINDOW_DATES', 'RAMP_MESSAGE_PL', 'RAMP_MESSAGE_EN', 'ADDRESS_CITY'],
    adviceUpdatedMessageInputType: ['OLD_REGISTRATION_NUMBER', 'OLD_ADVICE_DATE', 'NEW_ADVICE_DATE'],
    messageInputType: Ember.computed.union('commonMessageInputType', 'adviceUpdatedMessageInputType'),
    notification: Ember.computed('model.notification', function () {
      return this.get('model.notification');
    }),
    notificationTypes: Ember.computed(function () {
      return this.get('sessionAccount.notificationTypes');
    }),
    transportTypes: Ember.computed(function () {
      return this.get('store').peekAll('transportType').sortBy('idx');
    }),
    sortTaskProperties: ['taskGroupIdentifier:asc', 'idxInGroup:asc'],
    sortedTasks: Ember.computed.sort('tasks', 'sortTaskProperties'),
    tasks: Ember.computed('notification.transportType', function () {
      const transportTypeId = this.get('notification.transportType.id');
      return this.get('store').peekAll('task').filter(t => t.get('workflow.transportType.id') === transportTypeId);
    }),
    actions: {
      setNotification(notificationName) {
        const notificationType = this.get('notification').getNotificationType(notificationName);
        this.get('model.notification').set('name', notificationName);
        this.get('model.notification').set('type', notificationType);
      },

      save() {
        this.set('errors', []);
        const notification = this.get('notification');

        if (!notification.validate()) {
          return;
        }

        notification.save().then(() => {
          this.get('notificationsIndexController').set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          this.transitionToRoute('notifications.index');
        }).catch(response => {
          this.set('errors', response.errors);
        });
      },

      delete() {
        this.get('notification').destroyRecord().then(() => {
          this.get('notificationsIndexController').set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          this.transitionToRoute('notifications.index');
        }).catch(response => {
          this.set('errors', response.errors);
        });
      },

      cancel() {
        this.transitionToRoute('notifications.index');
      }

    }
  });

  _exports.default = _default;
});