define("apollo/pods/time-window/modals/window-note/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: service('session-account'),
    title: function () {
      return "#" + this.get('model.id') + " " + this.get('intl').t('warehouse.windowNoteTitle');
    }.property(),
    errors: [],
    canNotEdit: function () {
      return !this.get('sessionAccount').hasRole('ROLE_ADD_WINDOW_NOTES');
    }.property(),
    actions: {
      save() {
        const self = this;
        const window = this.get('model');
        console.log(`save window note / isTemplateView: ${window.get('isTemplateView')}`);
        (0, _jquery.default)("#main-modal").data("skip-cancel-action", true);

        if (window.get('isTemplateView')) {
          this.send('hideModal');
        } else {
          window.save().then(function () {
            self.send('hideModal');
          });
        }
      },

      cancel() {
        console.debug(`Zamykamy okienko dodawania notatki do okna...`);
        this.get('model').setProperties({
          note: this.get('model').get('oldNote'),
          oldNote: null
        });
      }

    }
  });

  _exports.default = _default;
});