define("apollo/pods/components/log-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xqy5TnZW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\" \"],[1,[21,\"classTranslationName\"],false],[0,\" \"],[1,[21,\"additionalDetails\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"log\",\"oldValueFormat\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"old-value\"],[9],[0,\"\\n        \"],[1,[23,[\"log\",\"oldValueFormat\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"log\",\"oldValueFormat\"]],[23,[\"log\",\"newValueFormat\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-long-arrow-right\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"log\",\"newValueFormat\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"new-value\"],[9],[0,\"\\n        \"],[1,[23,[\"log\",\"newValueFormat\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/log-change/template.hbs"
    }
  });

  _exports.default = _default;
});