define("apollo/mixins/queues-access-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    squares: Ember.computed('', function () {
      return this.store.peekAll('square').filter(square => {
        return !Ember.isEmpty(square.get('queues.content.currentState'));
      });
    }),
    // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
    proxiedSquares: Ember.computed('squares.@each', 'proxiedQueues.@each', function () {
      const self = this;
      return this.get('squares').map(s => {
        const proxiedQueues = self.get('proxiedQueues').filter(q => {
          return q.get('square.id') === s.get('id');
        });
        return Ember.ObjectProxy.create({
          content: s,
          proxiedQueues: proxiedQueues,
          checked: true
        });
      });
    }),
    accessibleQueues: function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_QUEUES')) {
        return this.get('store').peekAll('queue').filterBy('enabled');
      }

      return this.get('sessionAccount.currentUser.queues');
    }.property('sessionAccount.currentUser.queues.@each'),
    proxiedQueues: Ember.computed.map('accessibleQueues', function (model) {
      return Ember.ObjectProxy.create({
        content: model,
        checked: true
      });
    }),
    proxiedCheckedQueues: Ember.computed.filterBy('proxiedQueues', 'checked', true),
    checkedQueues: Ember.computed.mapBy('proxiedCheckedQueues', 'content')
  });

  _exports.default = _default;
});