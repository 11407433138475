define("apollo/helpers/table-column-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),

    compute(params, namedArgs) {
      const columnName = namedArgs.columnName;
      let style = '';
      const transportTypeRoleGroupTableColumn = this.get('store').peekAll('transportTypeRoleGroupTableColumn').find(ttrgtc => {
        return ttrgtc.get('tableColumn.name') === columnName;
      });

      if (transportTypeRoleGroupTableColumn && transportTypeRoleGroupTableColumn.get('isHighlighted')) {
        style = 'highlighted';
      }

      return Ember.String.htmlSafe(style);
    }

  });

  _exports.default = _default;
});