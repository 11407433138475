define("apollo/pods/terms/review/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    session: service('session'),
    sessionAccount: service('session-account'),
    statuteAccepted: false,
    actions: {
      acceptStatute() {
        const self = this;
        const currentUser = this.get('sessionAccount.currentUser');
        currentUser.set('statuteAccepted', true);
        currentUser.save().then(user => {
          console.log(`User ${user.get('username')} accepted Terms.`);
          self.transitionToRoute('defaultTransports');
        });
      },

      rejectStatute() {
        const self = this;
        this.get('sessionAccount.currentUser').save().then(user => {
          console.log(`User ${user.get('username')} rejected Terms and must be logged-out.`);
          self.get('session').invalidate();
        });
      },

      approveAllPoints() {
        this.set('statuteAccepted', true);
      },

      unApproveSomePoints() {
        this.set('statuteAccepted', false);
      }

    }
  });

  _exports.default = _default;
});