define("apollo/pods/users/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ["ROLE_USERS_TAB"],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        return this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const self = this;
      return new Promise(function (resolve) {
        self.store.findRecord('user', params.user_id).then(response => {
          response.get('userNotifications').then(() => {
            resolve(Ember.RSVP.hash({
              user: response
            }));
          });
        }, function () {
          return self.transitionTo('forbidden');
        });
      });
    },
    actions: {
      willTransition() {
        this.get('controller').setProperties({
          'successMessage': null
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});