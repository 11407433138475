define("apollo/pods/components/max-pallet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8DPh6RDz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"class\",[28,[[27,\"if\",[[27,\"gte\",[[23,[\"numberOfPallets\"]],[23,[\"maxPallet\",\"quantity\"]]],null],\"too-much-pallets\"],null]]]],[9],[0,\"\\n    \"],[1,[21,\"numberOfPallets\"],false],[0,\"/\"],[1,[23,[\"maxPallet\",\"quantity\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/max-pallet/template.hbs"
    }
  });

  _exports.default = _default;
});