define("apollo/pods/components/transport/delivery-section/delivery-packaging-form/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    intl: Ember.inject.service(),
    deliveryPackaging: Ember.computed('model.deliveryPackaging', function () {
      return this.get('model.deliveryPackaging');
    }),
    delivery: Ember.computed('model.delivery', function () {
      return this.get('model.delivery');
    }),
    modalTitle: Ember.computed('', function () {
      return this.get('intl').t('deliveryPackaging.sectionTitle');
    }),
    packageTypes: Ember.computed('', function () {
      return this.get('store').peekAll('packageType');
    }),
    isEditionDisabled: Ember.computed('delivery.{id,transportType.id}', function () {
      return !this._checkAuthority('ROLE_EDIT_DELIVERY_PACKAGING', this.get('delivery.transportType.id'));
    }),
    isDeletionAllowed: Ember.computed('delivery.{id,transportType.id}', function () {
      if (!this.get('delivery.id')) {
        return false;
      }

      return this._checkAuthority('ROLE_DELETE_DELIVERY_PACKAGING', this.get('delivery.transportType.id'));
    }),
    _checkAuthority: function (name, ttId) {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType(name, ttId);
    },
    isNewPackage: Ember.computed('deliveryPackaging.id', function () {
      return this.get('deliveryPackaging.id');
    }),
    availableStackingFactors: Ember.computed('delivery.deliverySetting.availableStackingFactors', function () {
      return this.get('delivery.deliverySetting').get('availableStackingFactors');
    }),
    validateStackingFactors: Ember.computed('delivery.deliverySetting.requireStackingFactor', function () {
      return this.get('delivery.deliverySetting.requireStackingFactor');
    }),
    actions: {
      save() {
        if (this.get('validateStackingFactors')) {
          this.get('deliveryPackaging').validate();
        } else {
          this.get('deliveryPackaging').validate({
            except: ['stackingFactor']
          });
        }

        if (this.get('deliveryPackaging').get('errors.length')) {
          return;
        }

        this.get('deliveryPackaging').set('enabled', true);
        (0, _jquery.default)('#sub-modal').data('skip-cancel-action', true);
        this.send('hideModal', 'sub-modal');
      },

      setPackageType(packageType) {
        this.get('deliveryPackaging').set('packageType', packageType);
      },

      disable() {
        const delivery = this.get('delivery');

        if (this.get('deliveryPackaging').get('id')) {
          this.get('deliveryPackaging').set('enabled', false);
          delivery.get('packaging').filter(cp => cp.get('id') === this.get('deliveryPackaging').get('id')).get('firstObject').set('enabled', false);
        } else {
          delivery.get('packaging').removeObject(this.get('deliveryPackaging'));
          this.get('deliveryPackaging').unloadRecord();
        }

        this.send('hideModal', 'sub-modal');
      },

      cancel() {
        if (this.get('deliveryPackaging') && !this.get('deliveryPackaging.id') && !this.get('deliveryPackaging.enabled')) {
          // 1. deliveryPackaging jest niezapisany ani w bazie, ani w ember-data
          this.get('delivery.packaging').removeObject(this.get('deliveryPackaging'));
          this.get('deliveryPackaging').unloadRecord();
        } else if (this.get('model.deliveryPackaging.id')) {
          // deliveryPackaging jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie wybieramy "Anuluj"
          const enabled = this.get('deliveryPackaging.enabled');
          this.get('deliveryPackaging').rollback();

          if (!enabled) {
            this.get('deliveryPackaging').set('enabled', false);
          }
        }
      },

      close() {
        this.send('hideModal', 'sub-modal');
      }

    }
  });

  _exports.default = _default;
});