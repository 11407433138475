define("apollo/pods/courier/modals/package-form/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    courierPackage: Ember.computed('model', function () {
      return this.get('model.courierPackage') ? this.get('model.courierPackage') : this.get('model');
    }),
    modalTitle: Ember.computed('', function () {
      const transportType = this.get('transport.transportType');
      const cpSetting = this.get('sessionAccount').getCourierSetting(transportType.get('id'));

      if (!cpSetting) {
        return this.get('intl').t('courier.COURIER_PACKAGE.add');
      }

      const key = cpSetting.get('translationKey');
      return this.get('intl').t(`courier.${key}.add`);
    }),
    showCourierPackageValue: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_SEE_COURIER_PACKAGE_VALUE');
    }),
    packageTypes: Ember.computed('', function () {
      return this.get('store').peekAll('package-type').filter(packageType => {
        return packageType.get('name') === 'PALLETS' || packageType.get('name') === 'CARTONS';
      });
    }),
    courierSetting: Ember.computed('model.transport.transportType.courierSetting', function () {
      return this.get('model.transport.transportType.courierSetting');
    }),
    // eslint-disable-next-line ember/no-observers
    saveInitialProperties: Ember.observer('courierPackage.modelOpenTime', function () {
      const initialProperties = {
        courierPackage: this.get('courierPackage').toJSON()
      };
      this.set('initialProperties', initialProperties);
    }),
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    isEditionDisabled: Ember.computed('transport.disabled', function () {
      if (!this.get('transport')) {
        return true;
      }

      const transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return !transport.actionCanBePerformed('EDIT_PACKAGES');
    }),
    isNewPackage: Ember.computed('courierPackage.id', function () {
      return this.get('courierPackage.id');
    }),
    isDeletionAllowed: Ember.computed('courierPackage.id', 'transport.transportType', function () {
      if (!this.get('courierPackage.id') && this.get('courierPackage.isNew') || !this.get('transport')) {
        return false;
      }

      const transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('DELETE_PACKAGES');
    }),
    actions: {
      save() {
        this.get('courierPackage').validate();

        if (this.get('courierPackage').get('errors.length')) {
          return;
        }

        this.get('courierPackage').set('enabled', true);
        (0, _jquery.default)('#sub-modal').data('skip-cancel-action', true);
        this.send('hideModal', 'sub-modal');
      },

      setPackageType(packageType) {
        this.get('courierPackage').set('packageType', packageType);
      },

      disable() {
        if (this.get('courierPackage').get('id')) {
          this.get('courierPackage').set('enabled', false);
          const transport = this.get('courierPackage').get('transport');
          transport.get('courierPackages').filter(cp => cp.get('id') === this.get('courierPackage').get('id')).get('firstObject').set('enabled', false);
        } else {
          const transport = this.get('courierPackage').get('transport');
          transport.get('courierPackages').removeObject(this.get('model'));
          this.get('courierPackage').unloadRecord();
        }

        this.send('hideModal', 'sub-modal');
      },

      /**
       * Obsługa anulowania / zamknięcia formularza courierPackage'u.
       *
       * Musimy rozważyć trzy przypadki.
       *
       * 1. courierPackage jest niezapisany ani w bazie, ani w ember-data --> zupełnie usuwamy jego model; taka sytuacja wystąpi,
       * jeśli dodajemy nowy courierPackage, ale nie zapiszemy go i klikniemy "Anuluj".
       *
       * 2. courierPackage jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie
       * wybieramy "Anuluj" --> usuwamy wprowadzone zmiany, ale poza tym courierPackage pozostaje przypisany do transportu.
       *
       * 3. courierPackage jest niezapisany w bazie, ale jest już dodany do transportu --> taka sytuacja wystąpi,
       * gdy dodajemy nowy courierPackage i uda się go zapisać (courierPackage pojawi się w tabelce), następnie otworzymy
       * formularz ze szczegółami (po kliknięciu ołówka) oraz wybierzemy "Anuluj" --> zamykamy wtedy formularz, ale nie usuwamy courierPackage'u
       */
      cancel() {
        console.debug('Zamykamy okienko edycji courierPackageów...'); // To, że courierPackage został poprawnie dodany do transportu (pojawił się na liście courierPackageów) możemy
        // poznać sprawdzając flagę `enabled` .
        //
        // Nie możemy użyć tu `this.get('model').rollbackAttributes()`, ponieważ możliwa jest taka sytuacja:
        //  - użytkownik dodaje nowy courierPackage;
        //  - użytkownik otwiera formularz edycji courierPackage'u (ikona ołówka) oraz klika "Anuluj"
        //
        // Efekt jest taki, że `rollbackAttributes` uzna, że powinien zupełnie usunąć courierPackage, a nie powinniśmy tego
        // robić w tej sytuacji (użytkownik spodziewa się tylko zamknięcia formularza).
        //
        // Stąd tak naprawdę te skomplikowane operacje z `initialProperties` itp (gałąź `else`).

        if (this.get('courierPackage') && !this.get('courierPackage.id') && !this.get('courierPackage.enabled')) {
          // 1. courierPackage jest niezapisany ani w bazie, ani w ember-data
          this.get('transport.courierPackages').removeObject(this.get('courierPackage'));
          this.get('courierPackage').unloadRecord();
        } else if (this.get('model.id')) {
          // courierPackage jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie wybieramy "Anuluj"
          const enabled = this.get('courierPackage.enabled');
          this.get('courierPackage').rollback();

          if (!enabled) {
            this.get('courierPackage').set('enabled', false);
          }
        } else {
          // courierPackage jest niezapisany w bazie, ale jest już dodany do transportu
          const self = this;
          const initialProperties = this.get('initialProperties');
          const initialModelProperties = initialProperties.courierPackage;
          const excluded = ['transport', 'enabled', 'loadStep', 'unloadStep', 'packageType'];
          const belongsToKeys = ['step', 'product'];
          Object.keys(initialModelProperties).filter(key => !excluded.includes(key)).forEach(key => {
            const value = initialModelProperties[key];

            if (belongsToKeys.includes(key)) {
              if (value) {
                this.get('courierPackage').set(key, self.get('store').peekRecord(key, value));
              }
            } else {
              this.get('courierPackage').set(key, initialModelProperties[key]);
            }
          });
        }
      },

      close() {
        this.send('hideModal', 'sub-modal');
      }

    }
  });

  _exports.default = _default;
});