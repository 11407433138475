define("apollo/pods/files/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: Ember.A(['page', 'max']),
    intl: Ember.inject.service(),
    buttonDisabled: false,
    actions: {
      saveFileSet(files) {
        const self = this;
        const BUTTON_DISABLE_TIMEOUT_MS = 500;
        this.toggleProperty('buttonDisabled');
        this.get('model.fileSet').set('files', files);
        this.get('model.fileSet').save().then(fileSet => {
          this.transitionToRoute('file-sets.status', fileSet.get('id'));
        }).catch(err => {
          if (err.errors[0].status === '429') {
            // TODO: Lepsza obsługa tej odpowiedzi (może jakiś globalny handler)
            self.set('errors', [self.get('intl').t('common.rateLimited')]);
          }
        }).finally(() => {
          Ember.run.later(this, function () {
            this.toggleProperty('buttonDisabled');
          }, BUTTON_DISABLE_TIMEOUT_MS);
        });
      },

      onFilterChange: function (filteredData) {
        this.set('model.files', filteredData);
      }
    }
  });

  _exports.default = _default;
});