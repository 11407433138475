define("apollo/pods/documents/modals/document-list-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    documents: Ember.computed('model.documents', function () {
      return this.get('model.documents');
    }),
    title: Ember.computed('document.id', 'model.documentType.name', 'model.groupName', function () {
      const typeName = this.get('model.documentType.name') || this.get('model.groupName');
      const typeLabel = this.get('intl').t(`sectionType.${typeName}`);
      return `${typeLabel} - Archiwum`;
    })
  });

  _exports.default = _default;
});