define("apollo/pods/default-transports/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;
  /**
   * Domyślna ścieżka do tabeli transportów - po wybraniu adresu /transports/,
   * użytkownik zostanie przeniesiony do widoku zapmiętanego w localStorage, jeśli takiego nie ma
   * to przenosimy użytkownika do widoku tabeli pierwszego z dostępnych typów,
   * lub do widoku wszystkich typów - w zależności od ustawień.
   */

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_TRANSPORT_TABLE'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }

      const shouldDisplayAllTypes = this.get('sessionAccount').getSettingValue('SHOULD_RENDER_ALL_TRANSPORT_TYPES') === 'true' || this.get('sessionAccount.currentUser.roleGroup.isGuard'); // Po przejściu do zakładki `transporty` sprawdzamy czy uzytkownik ma zapisane w localStorage wartość ostatnio przeglądanego typu transportu
      // i sprawdzamy czy ma dostęp do tego typu transportu, jesli tak to po przejsciu do tej zakładki wyświetlamy transport z zapamiętanego typu transportu

      const transportTypes = this.get('store').peekAll('transportType');
      const transportTypeFromLocalStorage = localStorage.getItem('LastVisitedTransportType');
      const isTransportTypeFromLocalStorageInAvalibleTransportTypes = transportTypes.filterBy('canBeSeenByRoleGroup').any(tt => tt.alias === transportTypeFromLocalStorage);

      if (shouldDisplayAllTypes) {
        this.transitionTo('transport.index', transportTypeFromLocalStorage);
      } else if (isTransportTypeFromLocalStorageInAvalibleTransportTypes || transportTypeFromLocalStorage === 'ALL') {
        this.transitionTo('transports.index', transportTypeFromLocalStorage);
      } else {
        const userTransportTypes = this.get('sessionAccount.currentUser.transportTypes');
        this.transitionTo('transports.index', userTransportTypes.get('firstObject.alias'));
      }
    }
  });

  _exports.default = _default;
});