define("apollo/pods/notifications/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // kontroler potrzebny żeby wstrzyknąć go do `notifications/create` i ustawić komunikat o sukcesie zapisu
  var _default = Ember.Controller.extend({});

  _exports.default = _default;
});