define("apollo/pods/components/schedule-legend/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    store: service(),
    intl: service(),
    specialWindows: Ember.computed('', function () {
      return [{
        label: this.get('intl').t('common.grayWindow'),
        color: '#444'
      }, {
        label: this.get('intl').t('common.lateWindow'),
        color: 'red'
      }, {
        label: this.get('intl').t('common.dedicatedWindow'),
        color: 'rgba(223,223,203,.7)'
      }, {
        label: this.get('intl').t('common.finishedWindow'),
        color: 'silver'
      }, {
        label: this.get('intl').t('common.transportCombiningIndicator'),
        color: '#34ebb7'
      }];
    }),
    tasks: Ember.computed('', function () {
      return this.get('store').peekAll('task');
    }),
    columns: Ember.computed('legendObjects', function () {
      const legendObjects = this.get('legendObjects');
      const length = legendObjects.get('length');
      const partIndex = length / 2;
      return [legendObjects.slice(0, partIndex), legendObjects.slice(partIndex, length)];
    }),
    legendObjects: Ember.computed('tasks', function () {
      const tasks = this.get('tasks');
      const uniqueColors = tasks.getEach('color').uniq();
      const legendObjects = uniqueColors.map(color => this.mapToLegendObjects(color, tasks));
      this.get('specialWindows').forEach(sw => legendObjects.push(sw));
      return legendObjects.sort(function (a, b) {
        return a.label.length - b.label.length;
      });
    }),
    mapToLegendObjects: function (color, tasks) {
      const label = tasks.filter(task => task.get('color') === color).map(task => this.findAppropriateLegendString(task)).uniq().join(", ");
      return {
        label: label,
        color: color
      };
    },
    findAppropriateLegendString: function (task) {
      const taskWithSameNameAndDifferentColorExists = this.get('tasks').filter(t => {
        return t.get('stageNameInAppropriateLanguage') === task.get('stageNameInAppropriateLanguage') && t.get('color') !== task.get('color');
      }).get('length') !== 0;
      return taskWithSameNameAndDifferentColorExists ? task.get('legendString') : task.get('stageNameInAppropriateLanguage');
    }
  });

  _exports.default = _default;
});