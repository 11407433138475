define("apollo/pods/components/custom-field-value-formatted/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2wjYDmLm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"definition\",\"isOnlyDateType\"]],[23,[\"value\",\"value\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"format-date\",[[23,[\"value\",\"value\"]],\"DD.MM.YYYY\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"definition\",\"isDateTimeType\"]],[23,[\"value\",\"value\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"format-date\",[[23,[\"value\",\"value\"]],\"DD.MM.YYYY HH:mm\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"definition\",\"isSelectType\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,[\"value\",\"option\",\"nameInAppropriateLanguage\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,[\"value\",\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/custom-field-value-formatted/template.hbs"
    }
  });

  _exports.default = _default;
});