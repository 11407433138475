define("apollo/pods/themes/table-theme", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject; //http://kronus.me/2017/10/theming-ember-models-table-2-x/
  //https://blog.fossasia.org/tag/ember-models-table/

  var _default = _bootstrap.default.extend({
    intl: service(),
    'sort-asc': 'fa fa-sort-up',
    'sort-desc': 'fa fa-sort-down',
    'clearFilterIcon': 'fa fa-times-circle form-control-feedback',
    'nav-first': 'fa fa-angle-double-left',
    'nav-prev': 'fa fa-chevron-left',
    'nav-next': 'fa fa-chevron-right',
    'nav-last': 'fa fa-angle-double-right',
    messages: Ember.computed('', function () {
      return {
        searchLabel: this.get('intl').t('common.search')
      };
    })
  });

  _exports.default = _default;
});