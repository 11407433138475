define("apollo/pods/announcement/model", ["exports", "apollo/mixins/model-validator", "ember-data"], function (_exports, _modelValidator, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    location: _emberData.default.attr('string'),
    range: _emberData.default.attr('string'),
    type: _emberData.default.attr('string', {
      defaultValue() {
        return 'PRINCIPAL';
      }

    }),
    subject: _emberData.default.attr('string'),
    msgContent: _emberData.default.attr('string'),
    subjectEn: _emberData.default.attr('string'),
    msgContentEn: _emberData.default.attr('string'),
    dateFrom: _emberData.default.attr('isodate'),
    dateTo: _emberData.default.attr('isodate'),
    dateCreated: _emberData.default.attr('isodate'),
    lastUpdated: _emberData.default.attr('isodate'),
    author: _emberData.default.attr('string'),
    lastEditor: _emberData.default.attr('string'),
    roleGroups: _emberData.default.hasMany('roleGroup', {
      inverse: null,
      async: false
    }),
    companies: _emberData.default.hasMany('company', {
      inverse: null,
      async: true
    }),
    isRead: _emberData.default.attr('boolean'),
    validations: {
      subject: {
        presence: {
          message: 'blank'
        }
      },
      msgContent: {
        presence: {
          message: 'blank'
        }
      },
      range: {
        presence: {
          message: 'blank'
        }
      },
      type: {
        presence: {
          message: 'blank'
        }
      },
      subjectEn: {
        custom: [{
          validation: function (key, value, model) {
            return !model.get('hasEnglishVersion') || value && value.trim();
          },
          message: 'blank'
        }]
      },
      msgContentEn: {
        custom: [{
          validation: function (key, value, model) {
            return !model.get('hasEnglishVersion') || value && value.trim();
          },
          message: 'blank'
        }]
      },
      dateFrom: {
        custom: [{
          presence: {
            message: 'blank'
          },
          validation: function (key, value, model) {
            if (!value || !model.get('dateTo')) {
              return true;
            }

            return moment(value).isBefore(model.get('dateTo'));
          },
          message: 'invalidDatesSequence'
        }]
      },
      dateTo: {
        presence: {
          message: 'blank'
        }
      },
      roleGroups: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('range') !== 'ROLE_GROUP') {
              return true;
            }

            return value && value.length >= 1;
          },
          message: 'chooseAtLeastOneRoleGroup'
        }]
      },
      companies: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('range') !== 'COMPANY') {
              return true;
            }

            return value && value.length >= 1;
          },
          message: 'chooseAtLeastOneCompany'
        }]
      }
    },
    lastEditorOrAuthor: Ember.computed('author', 'lastEditor', function () {
      return this.get('lastEditor') ? this.get('lastEditor') : this.get('author');
    }),
    subjectInAppropriateLanguage: Ember.computed('subject', 'subjectEn', function () {
      if (!this.get('subjectEn')) {
        return this.get('subject');
      }

      const propertyName = this.get('languageService').findProperLanguageVersion('subject', 'subjectEn');
      return this.get(propertyName);
    }),
    contentInAppropriateLanguage: Ember.computed('msgContent', 'msgContentEn', function () {
      if (!this.get('msgContentEn')) {
        return this.get('msgContent');
      }

      const properKey = this.get('languageService').findProperLanguageVersion('msgContent', 'msgContentEn');
      return this.get(properKey);
    }),
    expired: Ember.computed('dateTo', function () {
      return moment().isAfter(moment(this.get('dateTo')));
    }),
    hasEnglishVersion: Ember.computed('id', 'msgContentEn', function () {
      return this.get('id') && this.get('msgContentEn');
    }),
    typeLabel: Ember.computed('type', function () {
      switch (this.get('type')) {
        case 'PRINCIPAL':
          return this.get('sessionAccount.principalCompany.shortName').toUpperCase();

        case 'ADMIN':
          return Ember.String.htmlSafe('<img src="/assets/images/logintegra-logo-new-dark.png" class="logintegra-icon">');

        case 'SYSTEM':
          return 'SYSTEM';

        default:
          return '';
      }
    }),
    canBeManaged: Ember.computed('type', function () {
      const isRestrictedAnnoucementType = this.get('type') === 'ADMIN' || this.get('type') === 'SYSTEM';
      return !!(isRestrictedAnnoucementType && this.get('sessionAccount').hasRole('ROLE_MANAGE_ADMIN_ANNOUNCEMENTS') || !isRestrictedAnnoucementType);
    })
  });

  _exports.default = _default;
});