define("apollo/services/action-progress-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    typeOfToast: 'loading',
    isLoading: false,
    message: '',
    details: '',
    showToast: function (type, message, details) {
      this.set('message', message);
      this.set('details', details);
      this.set('typeOfToast', type);
      const toast = $('.toast');
      const progress = $('.toast-progress');
      progress.addClass('active');
      toast.addClass('toast-active ' + type);
      this.hideToast(5000, true);
    },
    showLoadingToast: function (message, details) {
      const type = 'loading';
      this.showToast(type, message, details);
      this.set('isLoading', true);
    },
    showSuccessToast: function (message, details) {
      const type = 'success';
      this.showToast(type, message, details);
      this.set('isLoading', false);
    },
    showErrorToast: function (message, details) {
      const type = 'error';
      this.showToast(type, message, details);
      this.set('isLoading', false);
    },
    hideToast: function (displayTime) {
      const timeout = displayTime || 5000;
      const toast = $('.toast');
      const progress = $('.toast-progress');
      setTimeout(() => {
        if (this.get('isLoading')) {
          this.set('isLoading', false);
        }

        toast.removeClass('loading error success toast-active');
        progress.removeClass('active');
      }, timeout);
    }
  });

  _exports.default = _default;
});