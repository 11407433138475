define("apollo/pods/delivery/serializer", ["exports", "ember-data", "apollo/mixins/serializer-mixin"], function (_exports, _emberData, _serializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _serializerMixin.default, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    attrs: {
      packaging: {
        serialize: 'records'
      },
      deliveryCustomFieldValues: {
        serialize: 'records',
        deserialize: 'records'
      },
      loadStep: {
        serialize: 'records'
      },
      unloadStep: {
        serialize: 'records'
      },
      deliverySetting: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});