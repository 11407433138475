define("apollo/mixins/advice-utils-mixin", ["exports", "apollo/mixins/persistence-mixin"], function (_exports, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_persistenceMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),

    setRodoValue(advice) {
      console.debug(`Advice has changes in properties: ${advice.get('isDirty')}.`);
      console.debug(`Advice has changes in relationships: ${advice.get('hasDirtyRelations')}.`); // log naprawia A-1753

      if (advice.get('content')) {
        console.debug(advice.get('content').modelChanges());
      }

      if (advice.get('rodoAcceptanceChangedManually') || !advice.get('content')) {
        return;
      }

      if (!advice.get('id')) {
        advice.set('rodoAccepted', false);
        return;
      }

      const adviceModel = this.get('store').peekRecord('advice', advice.get('id'));

      if (!adviceModel) {
        return;
      }

      const didDriverLanguageChanged = adviceModel.get('didDriverLanguageChanged');
      const changes = adviceModel.modelChanges();
      const hasChanges = Object.keys(changes).filter(k => {
        return !['rodoAccepted', 'dateCreated', 'creator', 'driverLanguage'].includes(k);
      }).length > 0;
      advice.set('rodoAccepted', !(hasChanges || didDriverLanguageChanged));
    },

    removeWhiteSpacesAndSetUpperCase: function (e) {
      const driverMobilePhoneNumberLength = e.get('driverMobilePhoneNumber.length');
      const carRegistrationNumberLength = e.get('carRegistrationNumber.length');
      const trailerRegistrationNumberLength = e.get('trailerRegistrationNumber.length');
      const documentNumberLength = e.get('documentNumber.length');

      if (driverMobilePhoneNumberLength > 0) {
        e.set('driverMobilePhoneNumber', e.get('driverMobilePhoneNumber').replace(/\s+/g, ''));
      }

      if (carRegistrationNumberLength > 0) {
        e.set('carRegistrationNumber', e.get('carRegistrationNumber').toUpperCase().replace(/\s+/g, ''));
      }

      if (trailerRegistrationNumberLength > 0) {
        e.set('trailerRegistrationNumber', e.get('trailerRegistrationNumber').toUpperCase().replace(/\s+/g, ''));
      }

      if (documentNumberLength > 0) {
        e.set('documentNumber', e.get('documentNumber').replace(/\s+/g, ''));
      }

      return e;
    },
    updateAllFuturePeriodicTransports: function (transport) {
      return transport.get('id') && transport.get('periodicTransport.id') && this.get('sessionAccount').hasRole('ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS') && confirm(this.get('intl').t('periodicTransport.updateAllConfirmMsg'));
    },
    unloadRedundantTimeWindows: function (transport) {
      const timeWindowsCopy = transport.get('timeWindows').slice(0);
      timeWindowsCopy.forEach(tw => {
        if (tw && !tw.get('id')) {
          console.debug(`Unloading redundant time window for newly saved advice of transport ${transport.get('id')}..`);
          transport.get('timeWindows').removeObject(tw);
          tw.unloadRecord();

          if (transport && transport.get('masterTransports.firstObject.id')) {
            transport.get('masterTransports.firstObject.timeWindows').forEach(tw => {
              tw.unloadRecord(tw);
            });
          }
        }
      });
    },
    handleTransportReload: function (transport, scheduleController) {
      this.unloadDeletedRecords('transport', transport.get('id'), 'checkpoint');
      this.handleStepsUpdate(transport);
      transport.get('timeWindows').forEach(tw => {
        if (!tw) {
          return;
        }

        scheduleController.pushTimeWindow(tw.get('id'));
      });
    }
  });

  _exports.default = _default;
});