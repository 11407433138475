define("apollo/pods/transport-types/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    store: service(),
    transportType: Ember.computed('model', function () {
      return this.get('model');
    }),
    typeGroups: Ember.computed('transportType', function () {
      return Object.keys(this.get('transportType.TYPE_GROUPS'));
    }),
    actions: {
      cancel() {
        this.get('transportType').unloadRecord();
        window.history.back();
      },

      save() {
        let self = this;

        if (!this.get('transportType').validate()) {
          return;
        }

        this.get('transportType').save().then(transportType => {
          self.setProperties({
            success: true,
            errors: []
          });
          const userRoleGroupId = self.get('sessionAccount.currentUser.roleGroup.id');
          this.store.query('authority-restriction', {
            roleGroupId: userRoleGroupId
          }).then(data => {
            transportType.get('workflow').reload().then(() => {
              const transportTypeId = transportType.get('id');
              const authorityRestrictions = data.filter(aR => {
                return aR.assetId === Number(transportTypeId);
              });
              this.get('sessionAccount').set('authorityRestrictions', data);
              transportType.set('authorityRestrictions', authorityRestrictions);
              transportType.reload().then(() => {
                let msg = self.get('intl').t('settings.transportTypes.settingsSaveSuccess');
                self.set('successMessage', msg);
              });
            });
          });
        }).catch(response => {
          self.set('errors', response.errors);
        });
      }

    }
  });

  _exports.default = _default;
});