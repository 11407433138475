define("apollo/pods/package-type-transport-type/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    validationService: Ember.inject.service('validation-service'),
    transportType: _emberData.default.belongsTo('transportType'),
    packageType: _emberData.default.belongsTo('packageType'),
    multiplier: _emberData.default.attr('number'),
    defaultValue: _emberData.default.attr('number'),
    minValue: _emberData.default.attr('number'),
    visibleInContractorStep: _emberData.default.attr('boolean'),
    visibleInWarehouseStep: _emberData.default.attr('boolean'),
    visibleInLoadPackageQuantityInTransportTable: _emberData.default.attr('boolean'),
    visibleInUnloadPackageQuantityInTransportTable: _emberData.default.attr('boolean'),
    required: _emberData.default.attr('boolean'),
    referencePackage: _emberData.default.attr('boolean'),
    usedForMaxPalletLimit: _emberData.default.attr('boolean'),
    idx: _emberData.default.attr('number'),
    validations: {
      transportType: {
        presence: {
          message: 'blank'
        }
      },
      packageType: {
        presence: {
          message: 'blank'
        }
      },
      multiplier: {
        custom: [{
          validation: function (key, value, model) {
            return model.get('validationService').isPositiveInteger(value);
          },
          message: 'positiveNumber'
        }]
      },
      defaultValue: {
        custom: [{
          validation: function (key, value, model) {
            return model.get('validationService').isPositiveInteger(value);
          },
          message: 'positiveNumber'
        }]
      },
      minValue: {
        custom: [{
          validation: function (key, value, model) {
            return model.get('validationService').isPositiveInteger(value);
          },
          message: 'positiveNumber'
        }]
      },
      idx: {
        custom: [{
          validation: function (key, value, model) {
            return model.get('validationService').isPositiveInteger(value);
          },
          message: 'positiveNumber'
        }]
      }
    }
  });

  _exports.default = _default;
});