define("apollo/pods/auth/remind/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    intl: service(),
    apolloApiService: service('api.apollo-api-service'),
    loginController: Ember.inject.controller('auth/login'),
    remindEmail: '',
    actions: {
      async sendRemindEmail() {
        const self = this;
        self.set('errorMessage', null);
        const remindingProgess = $('.reminding-progress');
        remindingProgess.show();
        const email = this.get('remindEmail');
        const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

        if (email.length === 0) {
          remindingProgess.hide();
          self.set('errorMessage', self.get('intl').t('common.formShouldNotBeEmpty'));
          return;
        }

        if (!email.match(emailFormat)) {
          remindingProgess.hide();
          self.set('errorMessage', self.get('intl').t('errors.invalidEmail'));
          return;
        }

        const jsonBody = JSON.stringify({
          email
        });
        const path = this.get('apolloApiService').APOLLO_API.USER.REMIND_PASSWORD;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: jsonBody
          });

          if (response.ok) {
            const msg = self.get('intl').t('auth.passwordRemindedMessage', {
              email
            });
            self.get('loginController').set('message', msg);
            self.transitionToRoute('auth.login');
          } else {
            remindingProgess.hide();
            const error = await response.json();
            self.set('errorMessage', error.errors);
            console.error(error);
          }
        } catch (error) {
          this.set('errorMessage', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      }

    }
  });

  _exports.default = _default;
});