define("apollo/pods/products/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_PRODUCTS_TAB'],
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },

    beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    model(params) {
      params.max = params.max || 25;
      params.page = params.page || 1;
      return this.store.query('product', params);
    },

    setupController(controller, model) {
      this.get('controller').setProperties({
        model: model,
        totalPages: model.get('meta.totalPages')
      });
    },

    activate() {
      this.activateCurrentTab('PRODUCTS_TAB');
    },

    actions: {
      willTransition() {
        this.get('controller').setProperties({
          successMessage: null
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});