define("apollo/pods/mode-of-transportation/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION'],

    beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    model: function (params) {
      const self = this;
      const truck = self.get('store').findRecord('truck', params.id);
      const truckTypesArray = this.get('store').query('truckType', {});
      const sensorCalibrationCertificateSection = self.get('store').peekAll('document-type').filter(dt => {
        return dt.get('name') === 'SENSOR_CALIBRATION_CERTIFICATE';
      }).get('firstObject');
      const winterMappingSection = self.get('store').peekAll('document-type').filter(dt => {
        return dt.get('name') === 'WINTER_MAPPING';
      }).get('firstObject');
      const summerMappingSection = self.get('store').peekAll('document-type').filter(dt => {
        return dt.get('name') === 'SUMMER_MAPPING';
      }).get('firstObject');
      return Ember.RSVP.hash({
        truck,
        mainSection: truck.get('vehicleMainSection'),
        sensorCalibrationCertificateSection,
        winterMappingSection,
        summerMappingSection,
        truckTypesArray
      });
    },
    renderTemplate: function () {
      this.render('mode-of-transportation.create');
    },
    setupController: function (controller, model) {
      this.controllerFor('mode-of-transportation.create').setProperties({
        model
      });
    },
    actions: {
      willTransition: function () {
        this.get('controller').setProperties({
          errors: []
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});