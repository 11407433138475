define("apollo/pods/courier-order/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    transport: belongsTo('transport', {
      async: true
    }),
    bookingNumber: attr('string'),
    trackingNumber: attr('string'),
    tntAccessCode: attr('string'),
    documents: attr()
  });

  _exports.default = _default;
});