define("apollo/pods/queues/add-queue/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_QUEUE'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const square = this.store.findRecord('square', params.square_id);
      const queue = this.store.createRecord('queue', {});
      return Ember.RSVP.hash({
        square: square,
        queue: queue
      });
    },
    renderTemplate: function () {
      this.render('queues.create');
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      const queue = model.queue;
      queue.setProperties({
        square: model.square
      });
      this.controllerFor('queues.create').setProperties({
        model: queue
      });
    }
  });

  _exports.default = _default;
});