define("apollo/helpers/last-weight-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, nameArgs) {
      const transport = nameArgs.transport;
      const weight = nameArgs.weight;
      const lastConfirmedCheckpointWithWeighingIncluded = nameArgs.checkpoint;
      const currentWeight = lastConfirmedCheckpointWithWeighingIncluded ? lastConfirmedCheckpointWithWeighingIncluded.get('currentWeight') : null;
      return currentWeight ? currentWeight.get('id') === weight.get('id') : transport.get('enabledWeights').length === 1;
    }

  });

  _exports.default = _default;
});