define("apollo/pods/periodic-transport/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    internalIndex: _emberData.default.attr('number'),
    frequency: _emberData.default.attr('string'),
    dayNumbers: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('pldate'),
    endDate: _emberData.default.attr('pldate'),
    MAX_DAY_RANGE: {
      DAILY: {
        interval: 31,
        unit: "d"
      },
      WEEKLY: {
        interval: 61,
        unit: "d"
      },
      MONTHLY: {
        interval: 13,
        unit: "M"
      }
    },
    validations: {
      startDate: {
        custom: [{
          presence: {
            message: 'blank'
          }
        }, {
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            const dateOfAdvice = model.get("dateOfAdvice");
            const formattedStartDate = moment(value).format("YYYY-MM-DD");
            return dateOfAdvice === formattedStartDate;
          },
          message: function (key, value, model) {
            return model.get('intl').t('periodicTransport.errors.windowMustBeInFirstDayOfPeriodicTransport');
          }
        }]
      },
      endDate: {
        custom: [{
          presence: {
            message: 'blank'
          }
        }, {
          validation: function (key, value, model) {
            const startDate = model.get('startDate');

            if (!startDate || !value) {
              return true;
            }

            return moment(value).isAfter(moment(startDate));
          },
          message: function (key, value, model) {
            return model.get('intl').t('periodicTransport.errors.endDateMustBeAfterStartDate');
          }
        }]
      },
      frequency: {
        custom: [{
          validation: function (key, value) {
            return ["DAILY", "WEEKLY", "MONTHLY"].includes(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('periodicTransport.errors.incorrectRangeOption');
          }
        }, {
          validation: function (key, value, model) {
            const startDate = model.get('startDate');
            const endDate = model.get('endDate');

            if (!startDate || !endDate || !value) {
              return true;
            }

            const interval = model.MAX_DAY_RANGE[value].interval;
            const unit = model.MAX_DAY_RANGE[value].unit;
            return moment(endDate).diff(moment(startDate), unit) <= interval;
          },
          message: function (key, value, model) {
            const interval = model.MAX_DAY_RANGE[value].interval;
            const unit = model.MAX_DAY_RANGE[value].unit;
            const intl = model.get('intl');
            const rangeTranslation = intl.t(`periodicTransport.frequencies.${value}`);
            const intervalTranslation = intl.t(`common.interval.${unit}`, {
              count: interval
            });
            return intl.t("periodicTransport.errors.wrongDateRange", {
              range: rangeTranslation,
              interval: intervalTranslation
            });
          }
        }]
      },
      dayNumbers: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('frequency') !== "WEEKLY") {
              return true;
            }

            return !!value && value.length > 0;
          },
          message: function (key, value, model) {
            return model.get('intl').t("periodicTransport.errors.chooseAtLeastOneDay");
          }
        }, {
          validation: function (key, value, model) {
            if (model.get('frequency') !== "WEEKLY" || !model.get('startDate')) {
              return true;
            }

            let startDateDayIndex = moment(model.get('startDate')).day(); //moment.js indeksuje dni od 0 - niedziela jest pierwszym dniem tygodnia z indeksem 0
            //my indeksujemy od 1 - poniedziałek jest pierwszym dniem tygodnia z indeksem 1
            //zatem jeśli pierwszym dniem awizacji będzie niedziela musi skorygowac indeks

            if (startDateDayIndex === 0) {
              startDateDayIndex = 7;
            }

            return value.search(startDateDayIndex) > -1;
          },
          message: function (key, value, model) {
            return model.get('intl').t("periodicTransport.errors.chooseFirstDayOfPeriodicTransport");
          }
        }]
      }
    }
  });

  _exports.default = _default;
});