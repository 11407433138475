define("apollo/mixins/user-utils-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service('session-account'),
    showDivisionLabel: Ember.computed('user.roleGroup.name', function () {
      return this.get('sessionAccount').isGroupFilterEnabled() || this.get('sessionAccount').isGroupFilterEnabledForUser(this.get('user'));
    })
  });

  _exports.default = _default;
});