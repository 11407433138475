define("apollo/pods/super-admin/create/controller", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    errors: [],
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),
    actions: {
      save: function () {
        const self = this;
        const companyModel = this.get('model.company');
        const addressModel = this.get('model.address');
        const userModel = this.get('model.user');
        addressModel.set('recipient', companyModel.get('name'));
        companyModel.set('taxIdIsoNumber', companyModel.get('country.ibanSymbol'));
        const companyModelErrors = !companyModel.validate({
          except: ['partnershipType']
        });
        const addressModelErrors = !addressModel.validate();

        if (companyModelErrors || addressModelErrors) {
          return;
        }

        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/companies/createPrincipal',
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify({
            company: companyModel,
            address: addressModel,
            user: userModel
          }),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(res => {
          const msg = self.get('intl').t('administration.newPrincipal.successMsg', {
            username: res.person.username
          });
          self.set('successMessage', msg);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(response => {
          self.set('errors', response.responseJSON.errors);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      },
      cancel: function () {
        this.transitionToRoute('super-admin.index');
      }
    }
  });

  _exports.default = _default;
});