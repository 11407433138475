define("apollo/pods/components/tasks-sortable-table/task-sortable-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service(),
    dateSigns: Ember.A(['before', 'after']),
    dateUnits: Ember.A(['h', 'm', 's']),
    stepTypes: Ember.A(['load', 'unload', 'firstLoad', 'lastLoad', 'firstUnload', 'lastUnload']),
    hasSubTasks: Ember.computed('task.subTasks.{@each.enabled,length}', function () {
      const subTasks = this.get('task.subTasks').filterBy('enabled');
      return subTasks && subTasks.length > 0;
    }),
    sortedSubTasks: Ember.computed('task.subTasks.@each.{idx,enabled}', function () {
      return this.get('task.subTasks').filterBy('enabled').sortBy('idxInGroup');
    }),
    reloadTasks: function () {
      const taskToReload = this.get('store').peekAll('task').filter(task => {
        return task.get('id') === null;
      });
      taskToReload.forEach(task => {
        task.deleteRecord();
      });
      this.get('task').reload();
    },
    actions: {
      sortEndAction() {
        this.get('task.subTasks').forEach((task, idx) => {
          task.set('idxInGroup', idx);
        });
      },

      addSubTask(masterTask, idx) {
        const subTask = this.get('store').createRecord('task', {
          workflow: masterTask.workflow,
          taskGroupIdentifier: masterTask.taskGroupIdentifier,
          idxInGroup: idx,
          masterTask,
          expectedDateInterval: '30m',
          enabled: true
        });
        masterTask.get('subTasks').pushObject(subTask);
        console.debug('Utworzyliśmy task dodatkowy :: ' + subTask + ' :: przypisany do tasku głównego :: ' + masterTask + ' ::');
      },

      async saveSubTasks() {
        const self = this;
        const data = this.get('task');

        if (!data) {
          console.error('Błąd podczas próby zapisu tasków dodatkowych, masterTask :: ' + data);
        }

        const jsonBody = JSON.stringify(data.serialize());
        const path = this.get('apolloApiService').SUB_TASKS.SAVE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            taskId: this.get('task.id')
          }, {
            body: jsonBody
          });

          if (response.ok) {
            self.set('successMessage', self.get('intl').t('workflows.saveSuccessMessage'));
            alert(self.get('intl').t('workflows.saveSuccessMessage'));
            self.reloadTasks();
            console.debug('Zapisalismy task i jego subtaski :: ' + data);
            self.transitionToRoute('workflows.edit', self.get('model.workflow.id'));
          } else {
            const error = await response.json();
            alert(error.errors);
            self.reloadTasks();
          }
        } catch (error) {
          alert(this.get('intl').t('common.defaultError'));
        }
      },

      toggleMarkIfWasDelayed(task) {
        this.set('action', 'toggleMarkIfWasDelayed');
        this.sendAction('action', task);
      },

      toggleTracked(task) {
        this.set('action', 'toggleTracked');
        this.sendAction('action', task);
      }

    }
  });

  _exports.default = _default;
});