define("apollo/pods/components/synchronise-carrier-data-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    tagName: '',
    intl: Ember.inject.service(),

    async _syncData() {
      const path = this.get('apolloApiService').APOLLO_API.SYNCHRONISE_CARRIERS_DATA.SYNC;

      try {
        const response = await this.get('apolloApiService').callApolloApi(path, null, null);

        if (!response.ok) {
          const error = await response.json();
          this.set('errors', error.errors);
        }
      } catch (error) {
        this.set('errors', [this.get('intl').t('common.defaultError')]);
        console.error(error);
      }
    },

    actions: {
      async synchroniseCarriersData() {
        Ember.run.debounce(this, '_syncData', 1000);
      }

    }
  });

  _exports.default = _default;
});