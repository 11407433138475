define("apollo/pods/transport-types/artr-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    artrSetting: Ember.computed('model.artrSetting', function () {
      return this.get('model.artrSetting');
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    actions: {
      save() {
        this.set('errors', []);
        const artrSetting = this.get('artrSetting');
        artrSetting.set('transportType', this.get('transportType'));

        if (!artrSetting.validate()) {
          return;
        }

        artrSetting.save().then(() => {
          this.send('refreshModel');
          const msg = this.get('intl').t('common.saveSuccessMessage');
          this.set('successMessage', msg);
          jQuery('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(response => {
          this.set('errors', response.errors);
          jQuery('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      },

      create() {
        if (this.get('artrSetting')) {
          return;
        }

        const transportType = this.get('transportType');
        this.set('artrSetting', this.get('store').createRecord('artr-setting', {
          transportType
        }));
      },

      delete() {
        this.set('errors', []);
        this.get('artrSetting').destroyRecord().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      cancel() {
        this.get('artrSetting').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      }

    }
  });

  _exports.default = _default;
});