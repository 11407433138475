define("apollo/pods/partnership-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    name: _emberData.default.attr('string'),
    availableRoleGroupNames: Ember.computed('name', function () {
      switch (this.get('name')) {
        case 'CARRIER':
          return ['CARRIER'];
        // sprawdzić

        case 'SUPPLIER':
          return ['SUPPLIER', 'RECEIVER', 'CLIENT', 'CISTERN', 'SUPPLIER+CISTERN', 'SUPPLIER+COURIER', 'SUPPLIER+RECEIVER', 'RECIPIENTS_OF_WASTE'];

        case 'SERVICEMAN':
          return ['SERVICEMAN'];

        case 'OPERATOR':
          return ['OPERATOR'];
      }
    }),
    isCarrier: function () {
      return this.get('name') === 'CARRIER' || this.get('name') === 'OPERATOR';
    }.property('name'),
    isSupplier: function () {
      return this.get('name') === 'SUPPLIER' || this.get('name') === 'OPERATOR';
    }.property('name'),
    isServiceman: function () {
      return this.get('name') === 'SERVICEMAN';
    }.property('name'),
    translationLabel: Ember.computed('name', function () {
      const settingKey = this.get('sessionAccount').getSettingValue('SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY');
      const name = this.get('name'); // Poniższy kod został dodany roboczo dla zadania A-11791, w którym przy okazji zmieniamy nazwę typu
      // partnestwa z "serwisant" na "serwis", zmiana tego wszędzie wymaga osobnego zadania

      if (name === 'SERVICEMAN') {
        return 'SERVICE';
      }

      return settingKey ? `${name}.${settingKey}` : name;
    })
  });

  _exports.default = _default;
});