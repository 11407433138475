define("apollo/pods/classification-resources/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    poolingService: Ember.inject.service('classification-resource-pooling-service'),
    access: ['ROLE_CLASSIFICATION_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      return Ember.RSVP.hash({
        classificationResources: this.store.findAll('classification-resource'),
        squares: this.store.findAll('square'),
        queues: this.store.findAll('queue')
      });
    },

    activate() {
      this.activateCurrentTab('CLASSIFICATION_TAB');
    },

    setupController(controller, model) {
      const self = this;

      self._super(...arguments);

      self.get('controller').setProperties({
        model: model,
        classificationResources: model.classificationResources.filter(cr => cr.get('state') === cr.get('STATE_NAMES.CREATED')),
        classifiedResourceDisappearanceDelay: self.classifiedResourceDisappearanceDelay()
      });
      self.get('poolingService').init(self.pollUpdatesInterval(), self.handleResponse.bind(self));
    },

    resetController() {
      this._super(...arguments);

      this.get('poolingService').cancel();
    },

    pollUpdatesInterval() {
      const pollIntervalString = this.get('sessionAccount').getSettingValue('CLASSIFICATIONS_TAB_POLL_INTERVAL');
      return pollIntervalString ? parseInt(pollIntervalString) : 10000;
    },

    classifiedResourceDisappearanceDelay() {
      const delay = this.get('sessionAccount').getSettingValue('CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY');
      return delay ? parseInt(delay) : 15000;
    },

    handleResponse(json) {
      const self = this;
      json.classificationResourcesIds.forEach(function (classificationResourceId) {
        self.store.findRecord('classification-resource', classificationResourceId, {
          reload: true
        }).then(loadedClassificationResource => {
          if (loadedClassificationResource.get('enabled') === false || loadedClassificationResource.get('state') === loadedClassificationResource.STATE_NAMES.COMPLETED) {
            console.debug(`Object ClassificationResource: ${loadedClassificationResource.get('id')} with state: ${loadedClassificationResource.get('state')} has been removed.`);
            self.get('controller.classificationResources').removeObject(loadedClassificationResource);
          } else if (!loadedClassificationResource.get('queue.id')) {
            self.controllerFor('classification-resources.index').send('setSquare', loadedClassificationResource, null);

            if (!self.findClassificationResources(loadedClassificationResource)) {
              self.get('controller.classificationResources').pushObject(loadedClassificationResource);
            }
          } else if (loadedClassificationResource.get('queue.id')) {
            setTimeout(function () {
              self.get('controller.classificationResources').removeObject(loadedClassificationResource);
            }, self.classifiedResourceDisappearanceDelay());
          }
        });
      });
    },

    findClassificationResources(resource) {
      return this.get('controller.classificationResources').find(cr => {
        return cr.get('id') === resource.get('id');
      });
    }

  });

  _exports.default = _default;
});