define("apollo/pods/authorities/aggregate/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    superUser: Ember.computed(function () {
      const principalCompanyId = localStorage.getItem('principalId');
      const roleGroups = this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);
      return roleGroups.filterBy('name', 'SUPER_USER');
    }),
    closeAllSections: Ember.computed('', function () {
      return false;
    }),
    actions: {
      addPermission: function () {
        const self = this;
        const transportType = self.get('selectedTransportType');

        if (transportType) {
          self.transitionToRoute('authority-restrictions.create', {
            queryParams: {
              transportTypeId: transportType.id,
              multiSelect: true
            }
          });
        } else {
          self.transitionToRoute('authorities.create', {
            queryParams: {
              multiSelect: true
            }
          });
        }
      },
      toggleCloseAllSections: function () {
        this.set('closeAllSections', !this.closeAllSections);
      },
      setTransportType: function (transportType) {
        this.set('selectedTransportType', transportType);
        this.send('toggleCloseAllSections');
      }
    }
  });

  _exports.default = _default;
});