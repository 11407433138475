define("apollo/pods/components/voivodeship-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "benIogq5",
    "block": "{\"symbols\":[\"voivodeship\"],\"statements\":[[4,\"power-select\",null,[[\"class\",\"selected\",\"options\",\"placeholder\",\"renderInPlace\",\"onchange\"],[\"form-control\",[23,[\"voivodeship\",\"name\"]],[23,[\"country\",\"voivodeships\"]],[27,\"t\",[\"company.selectVoivodeship\"],null],true,[27,\"action\",[[22,0,[]],\"setVoivodeship\"],null]]],{\"statements\":[[0,\"    \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/voivodeship-select/template.hbs"
    }
  });

  _exports.default = _default;
});