define("apollo/pods/components/company-transport-limit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    partnership: Ember.computed(function () {
      return this.get('model');
    }),
    shouldBeRendered: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT');
    }),
    allowTransportLimitEdition: Ember.computed(function () {
      return !this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT');
    }),
    shouldTransportsLimitDetailsBeRendered: Ember.computed(function () {
      return this.get('partnership.transportLimit') != null && this.get('partnership.transportLimit') > 0;
    })
  });

  _exports.default = _default;
});