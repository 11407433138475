define("apollo/pods/warehouse-zones/add-zone/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const warehouseId = params.warehouse_id;
      const warehouseZone = this.store.createRecord('warehouse-zone', {});
      return Ember.RSVP.hash({
        warehouse: this.store.findRecord('warehouse', warehouseId),
        warehouseZone: warehouseZone
      });
    },

    renderTemplate: function () {
      this.render('warehouseZones.create');
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      const warehouseZone = model.warehouseZone;
      warehouseZone.setProperties({
        warehouse: model.warehouse
      });
      this.controllerFor('warehouseZones.create').set('model', warehouseZone);
    }
  });

  _exports.default = _default;
});