define("apollo/pods/custom-field-value/model", ["exports", "ember-data/model", "ember-data", "ember-data-copyable", "apollo/mixins/model-validator"], function (_exports, _model, _emberData, _emberDataCopyable, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    transport: belongsTo('transport', {
      async: true
    }),
    definition: belongsTo('customFieldDefinition'),
    option: belongsTo('customFieldOption'),
    createdFromRoute: attr('boolean', {
      defaultValue: function () {
        return false;
      }
    }),
    value: attr('string'),
    lastUpdated: attr('date'),
    validations: {
      option: {
        custom: [{
          validation: function (key, value, model) {
            // Validation is correct if definition is visibleInAdviceSection and transport not have advice,
            if (model.get('definition.visibleInAdviceSection') && model.get('transport.advice.content') === null) {
              return true;
            } // This field is not visible when adding the advice, so we can not require it to be filled.


            if (model.get('transport.isDuringAddingOfAdvice') && !model.get('definition.visibleInAdviceSection')) {
              return true;
            } // jeżeli pole nie jest widoczne - bo w polu zależnym została wybrana opcja
            // powodująca jego ukrycie - nie przeprowadzamy walidacji


            if (!model.get('isVisibleBasedOnDefinition')) {
              return true;
            } // Validation is correct if value is set,
            // definition is not of a select type (in which case the 'value' is used),
            // or if the field is not obligatory.


            if (value && value.get('id') || model.get('definition.type') !== 'select' || !model.get('definition.obligatory')) {
              return true;
            }

            if (model.get('definition.task.id')) {
              const customFieldCheckpoints = model.get('transport.checkpoints').filterBy('task.id', model.get('definition.task.id'));
              const customFieldCheckpoint = customFieldCheckpoints.sortBy('idx:desc').get('firstObject');
              const currentCheckpoint = model.get('transport.firstUnconfirmedCheckpoint');

              if (!customFieldCheckpoint) {
                return true;
              }

              const currentCheckpointBlockade = currentCheckpoint && currentCheckpoint.get('idx') === customFieldCheckpoint.get('idx');

              if (currentCheckpointBlockade && currentCheckpoint.get('isDuringAction')) {
                return value && value.get('id');
              }

              return !currentCheckpoint || currentCheckpoint.get('idx') <= customFieldCheckpoint.get('idx');
            } // Empty obligatory field should not validate if it's visible during transport creation
            // or the transport is being updated.


            return !(model.get('transport.id') || model.get('definition.visibleDuringTransportCreation'));
          },
          message: 'blank'
        }]
      },
      value: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('definition.requiredInDelayedTransport')) {
              const currentCheckpoint = model.get('transport.firstUnconfirmedCheckpoint');

              if (currentCheckpoint.get('isCustomFieldRequiredInDelayedCheckpoint') === false) {
                return true;
              }

              if (model.get('definition.delayedTask').get('id') !== currentCheckpoint.get('task.id')) {
                return true;
              }

              if (!value && !model.get('formattedValue')) {
                return false;
              }

              if (model.get('definition.type') === 'select' && model.get('formattedValue')) {
                return true;
              }

              return !!value;
            } // Validation is correct if definition is visibleInAdviceSection and transport not have advice,


            if (model.get('definition.visibleInAdviceSection') && model.get('transport.advice.content') === null) {
              return true;
            } // This field is not visible when adding the advice, so we can not require it to be filled.


            if (model.get('transport.isDuringAddingOfAdvice') && !model.get('definition.visibleInAdviceSection')) {
              return true;
            } // jeżeli pole nie jest widoczne - bo w polu zależnym została wybrana opcja
            // powodująca jego ukrycie - nie przeprowadzamy walidacji


            if (!model.get('isVisibleBasedOnDefinition')) {
              return true;
            } // Validation is correct if value is set,
            // definition is of a select type (in which case the 'value' is not used at all),
            // or if the field is not obligatory.


            if (value || model.get('definition.type') === 'select' || !model.get('definition.obligatory')) {
              return true;
            }

            if (model.get('definition.task.id')) {
              const customFieldCheckpoints = model.get('transport.checkpoints').filterBy('task.id', model.get('definition.task.id'));
              const customFieldCheckpoint = customFieldCheckpoints.sortBy('idx:desc').get('firstObject');
              const currentCheckpoint = model.get('transport.firstUnconfirmedCheckpoint');

              if (!customFieldCheckpoint) {
                return true;
              }

              const currentCheckpointBlockade = currentCheckpoint && currentCheckpoint.get('idx') === customFieldCheckpoint.get('idx');

              if (currentCheckpointBlockade && currentCheckpoint.get('isDuringAction')) {
                return !!value;
              }

              return !currentCheckpoint || currentCheckpoint.get('idx') <= customFieldCheckpoint.get('idx');
            } // Empty obligatory field should not validate if it's visible during transport creation
            // or the transport is being updated.


            return !(model.get('transport.id') || model.get('definition.visibleDuringTransportCreation'));
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            // walidacja dla pól numerycznych
            if (!model.get('definition.isNumberType') || !value) {
              return true;
            }

            return /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:([.]|[,])\d+|)$/.test(value);
          },
          message: 'notProperValueType'
        }, {
          validation: function (key, value, model) {
            // walidacja dla pól numerycznych
            if (!model.get('definition.isNumberType') || !value) {
              return true;
            }

            const minValue = model.get('definition.minValue');

            if (minValue) {
              if (Number(value) < minValue) {
                return false;
              }
            }

            return true;
          },
          message: function (key, value, model) {
            const minValue = model.get('definition.minValue');
            return this.get('intl').t('custom-field-value.errors.minValue.value', {
              minValue
            });
          }
        }, {
          validation: function (key, value, model) {
            // walidacja dla pól numerycznych
            if (!model.get('definition.isNumberType') || !value) {
              return true;
            }

            const maxValue = model.get('definition.maxValue');

            if (maxValue) {
              if (Number(value) > maxValue) {
                return false;
              }
            }

            return true;
          },
          message: function (key, value, model) {
            const maxValue = model.get('definition.maxValue');
            return this.get('intl').t('custom-field-value.errors.maxValue.value', {
              maxValue
            });
          }
        }]
      }
    },

    /*
        Fired when the record is ready to be interacted with, that is either loaded from the server or created locally.
         Hook ustawia domyślną wartość pola CustomField w momencie gdy:
        - pole jest typu 'SELECT'
        - pole jest niewypełnione (nie przyszła jego wartość z backendu)
        - definicja pola posiada przypisaną wartość domyślną
    */
    ready() {
      const isSelectType = this.get('definition.isSelectType');
      const isEmpty = !this.get('id');
      const defaultOption = this.get('definition.defaultOption');

      if (isSelectType && isEmpty && defaultOption) {
        this.set('option', defaultOption);
      }
    },

    numberOfErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length');
    }),
    visibleInOfferForm: Ember.computed('definition.visibleInOfferForm', function () {
      return this.get('definition.visibleInOfferForm');
    }),
    editionDisabled: Ember.computed('transport.{id,activeAuction,transportType.authorityRestrictions.@each.authorityRange}', 'definition', function () {
      return this.get('createdFromRoute') || !this.actionCanBePerformed('UPDATE_CUSTOM_FIELD');
    }),
    shouldDataBeHidden: Ember.computed('transport.{id,firstUnconfirmedCheckpoint,transportType.authorityRestrictions.@each.authorityRange}', function () {
      if (!this.get('transport.id')) {
        return false;
      }

      return this.actionCanBePerformed('DISABLE_DATA_FIELDS');
    }),
    actionCanBePerformed: function (actionType) {
      if (!this.get('transport.id')) {
        return true;
      }

      const positiveAuthorityRestrictions = this.get('transport.transportType.content').findPositiveAuthorityRestrictionsByActionNameAndCustomFieldDefinition(actionType, this.get('definition.id'));

      if (positiveAuthorityRestrictions.get('length') === 0) {
        return false;
      }

      return positiveAuthorityRestrictions.every(ar => ar.conditionIsFulfilled(this.get('transport')));
    },
    formattedValue: Ember.computed('value', 'option', 'definition.type', function () {
      const value = this.get('value');

      if (this.get('definition.isDateType')) {
        return value ? moment(value).format('DD.MM.YYYY') : null;
      } else if (this.get('definition.isSelectType')) {
        return this.get('option.nameInAppropriateLanguage');
      } else {
        return value;
      }
    }),
    isVisibleBasedOnAuthorityRestrictions: Ember.computed('transport.{id,transportType.authorityRestrictions.@each.authorityRange}', 'definition', function () {
      return this.actionCanBePerformed('ACCESS_CUSTOM_FIELD');
    }),
    isVisibleBasedOnDefinition: Ember.computed('definition.{visibilityDependsOnDefinition.id,visibilityDependsOnOption.id}', 'transport.customFields.@each.option', function () {
      if (!this.get('definition.visibilityDependsOnDefinition.id')) {
        return true;
      }

      const dependentDefinitionId = this.get('definition.visibilityDependsOnDefinition.id');
      const dependentOptionId = this.get('definition.visibilityDependsOnOption.id');
      const selectedValueInDependentDefinition = this.get('transport.customFields').find(value => {
        return value.get('definition.id') === dependentDefinitionId;
      });
      return selectedValueInDependentDefinition && selectedValueInDependentDefinition.get('option.id') && (!dependentOptionId || selectedValueInDependentDefinition.get('option.id') === dependentOptionId);
    }),
    isFilled: Ember.computed('value', 'option', 'definition.type', function () {
      return this.get('value') || this.get('option.id');
    }),
    logString: Ember.computed('id', 'value', 'option.value', 'definition.name', function () {
      const value = this.get('value') || this.get('option.value');
      return `${this.get('definition.name')} -- ${value} (#${this.get('id')})`;
    }),
    isAssociatedWithPanel: Ember.computed('option', 'definition.isSelectType', 'option.transportPanel', function () {
      return this.get('option') && this.get('definition.isSelectType') && this.get('option.transportPanel');
    })
  });

  _exports.default = _default;
});