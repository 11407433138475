define("apollo/mixins/schedule-mixin", ["exports", "apollo/config/environment", "apollo/mixins/persistence-mixin", "apollo/mixins/advice-utils-mixin", "jquery"], function (_exports, _environment, _persistenceMixin, _adviceUtilsMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create(_persistenceMixin.default, _adviceUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    dateUtils: Ember.inject.service('date-utils'),
    windows: Ember.A([]),
    showMultipleTimeWindowsModal: Ember.computed('transportToHandle', {
      get(key) {
        return !!this.get('transportToHandle') && this.get('transportToHandle.stepsWithWarehouse').length > 1;
      },

      set(key, value) {
        return value;
      }

    }),
    scheduleStartDateFormatted: Ember.computed('scheduleStartDate', function () {
      return moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
    }),
    // eslint-disable-next-line ember/no-observers
    showErrors: Ember.observer('errors', function () {
      if (this.get('errors.length')) {
        this.send('showPopupDialog', 'dialogs.message-popup-dialog', this.get('errors'));
      }
    }),
    // eslint-disable-next-line ember/no-observers
    hideErrors: Ember.observer('warehouse.id', function () {
      try {
        this.send('removePopupDialog');
      } catch (e) {
        console.debug(e);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    pollRequested: Ember.observer('editedTransportType.forcedPollRequestTime', function () {
      if (this.get('editedTransportType.forcedPollRequestTime')) {
        console.debug(`Sending poll request for transport type ${this.get('transportType.name')}..`);
        this.send('poll');
      }

      this.get('editedTransportType').set('forcedPollRequestTime', null);
    }),
    // eslint-disable-next-line ember/no-observers
    selectedUserGroupObserver: Ember.observer('selectedUserGroup', function () {
      if (this.get('selectedUserGroup')) {
        this.set('company', null);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    selectedCompanyObserver: Ember.observer('company', function () {
      if (this.get('company')) {
        this.set('selectedUserGroup', null);
      }
    }),
    shownDays: Ember.computed('scheduleStartDate', 'scheduleEndDate', function () {
      const days = Ember.A();

      for (let i = 0; moment(this.scheduleStartDate).add(i, 'days') < this.get('scheduleEndDate'); i++) {
        const day = Ember.Object.create({
          date: moment(this.scheduleStartDate).add(i, 'days'),
          name: moment(this.scheduleStartDate).add(i, 'days').format('YYYY-MM-DD'),
          longName: moment(this.scheduleStartDate).add(i, 'days').format('DD.MM.YYYY dddd')
        });
        days.pushObject(day);
      }

      return days;
    }),
    warehouse: Ember.computed('model.warehouse', function () {
      return this.get('model.warehouse');
    }),
    userRamps: Ember.computed('warehouse', 'sessionAccount.currentUser.accessibleRamps.[]', function () {
      return this.get('sessionAccount.currentUser.accessibleRamps').filterBy('warehouse.id', this.get('warehouse.id'));
    }),
    userRampsIds: Ember.computed.map('userRamps', function (model) {
      return model.get('id');
    }),
    userWarehouseZones: Ember.computed('userRamps.@each.warehouseZone', function () {
      return this.get('userRamps').getEach('warehouseZone').uniqBy('id') || Ember.A([]);
    }),
    warehouseZones: Ember.computed('userWarehouseZones.[]', 'warehouseStepToHandle.warehouseZone', function () {
      const ttId = this.get('warehouseStepToHandle.transport.transportType.id');
      const availableZones = this.get('userWarehouseZones').sortBy('idx');

      if (!ttId) {
        // Brak stepu do obsłużenia, pokazujemy wszystkie strefy
        return availableZones;
      }

      const warehouseZoneId = this.get('warehouseStepToHandle.warehouseZone.id');

      if (!warehouseZoneId) {
        // Brak wybranej strefy magazynowej, pokazujemy wszystkie strefy dostępne dla typu transportu
        return availableZones.reduce((acc, zone) => {
          const zoneTtIds = zone.get('transportTypes').getEach('id');

          if (zoneTtIds.includes(ttId)) {
            acc.push(zone);
          }

          return acc;
        }, []);
      } // Pokazujemy wybraną strefe


      return availableZones.filter(zone => {
        return zone.get('id') === warehouseZoneId;
      });
    }),
    ramps: Ember.computed('userRamps.[]', 'warehouseZones.@each.ramps', function () {
      const userRamps = this.get('userRamps');
      const ramps = [];
      this.get('warehouseZones').forEach(zone => {
        if (zone.get('id')) {
          zone.get('enabledRamps').forEach(r => {
            if (userRamps.includes(r)) {
              ramps.push(r);
            }
          });
        }
      });
      return ramps;
    }),
    rampsIds: Ember.computed('ramps.@each.id', function () {
      const ids = [];
      this.get('ramps').forEach(function (r) {
        ids.push(r.get('id'));
      });
      return ids;
    }),
    maxDate: Ember.computed(function () {
      return moment().startOf('day').add(14, 'days');
    }),
    rampStyle: Ember.computed('rampWidth', function () {
      const width = this.get('rampWidth') + '%';
      return Ember.String.htmlSafe('width: ' + width);
    }),
    rampWidth: Ember.computed('ramps.length', function () {
      return '' + 100 / this.get('ramps.length');
    }),
    dayStyle: Ember.computed('dayWidth', function () {
      const width = this.get('dayWidth') + '%';
      return Ember.String.htmlSafe('width: ' + width);
    }),
    dayWidth: Ember.computed('scheduleStartDate', 'scheduleEndDate', function () {
      return 100 / this.get('shownDays.length');
    }),
    weekStyle: Ember.computed('ramps', 'shownDays', function () {
      let width = 100 + '%';

      if (this.get('ramps.length') > 8 && this.get('shownDays.length') > 4) {
        width = 200 + '%';
      }

      return Ember.String.htmlSafe('width: ' + width);
    }),
    selectedDateISO: Ember.computed('scheduleStartDate', function () {
      return moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
    }),

    /**
     * Chcemy, żeby w harmonogramie najpierw dodawane były div-y z oknami dedykowanymi
     * - żeby nie przykrywały one okien awizacji. Sortowanie po `state` daje taki efekt,
     * bo statusy okien dedykowanych (`state-dedicated`, `state-dedicated-template`) znajdą się przed
     * statusami okien awizacji (`state-with-transport`) i jest wydajne, bo `state` jest właściwością `timeWindow`.
     *
     * Jeżeli kiedyś dojdą nowe statusy, być może trzeba będzie zmienić to sortowanie.
     */
    filteredWindows: Ember.computed('scheduleStartDate', 'scheduleEndDate', 'windows.[]', function () {
      const self = this;
      const warehouseId = self.get('model.warehouse.id');
      return this.get('windows').filter(w => {
        return w.get('ramp.warehouse.id') === warehouseId && (w.get('start') >= self.get('scheduleStartDate') && w.get('start') < self.get('scheduleEndDate') || w.get('stop') > self.get('scheduleStartDate') && w.get('stop') <= self.get('scheduleEndDate') || w.get('start') <= self.get('scheduleStartDate') && w.get('stop') >= self.get('scheduleEndDate'));
      }).sortBy('state');
    }),
    getStartDate: function (div) {
      const $div = (0, _jquery.default)(div);
      const $hourDiv = $div.parent();
      const $dayDiv = $hourDiv.parent();
      const dateString = $dayDiv.parent().data('schedule-date');
      const hours = $hourDiv.data('schedule-hour');
      const minutes = $div.data('schedule-minute');
      const time = moment(`${dateString} ${hours} ${minutes}`, 'YYYY-MM-DD HH:mm');
      return time;
    },
    timeWindowOffsetIntervalSetting: Ember.computed('model.warehouse.timeWindowOffsetInterval', function () {
      let s = this.get('model.warehouse.timeWindowOffsetInterval');

      if (!s) {
        s = this.get('sessionAccount').getSettingValue('TIME_WINDOW_OFFSET_INTERVAL');
      }

      return parseInt(s);
    }).readOnly(),
    defaultGridHeight: function () {
      const slotsPerHour = this.get('timeWindowOffsetIntervalSetting') ? 60 / this.get('timeWindowOffsetIntervalSetting') : 1;
      const hdMultiplier = this.get('hdMode') ? 3 : 1;
      return this.defaultCssHeight() / slotsPerHour * hdMultiplier;
    },
    defaultCssHeight: function () {
      if (this.get('timeWindowOffsetIntervalSetting') === 15) {
        return 80;
      } else if (this.get('timeWindowOffsetIntervalSetting') === 30) {
        return 60;
      } else if (this.get('timeWindowOffsetIntervalSetting') === 60) {
        return 48;
      } else {
        return 48;
      }
    },
    cssHeight: Ember.computed('timeWindowOffsetIntervalSetting', function () {
      return this.defaultCssHeight();
    }),
    timeSlotIntervalClass: Ember.computed('timeWindowOffsetIntervalSetting', function () {
      const interval = this.get('timeWindowOffsetIntervalSetting');

      switch (interval) {
        case 15:
          return 'slot-height-four-slots-per-hour';

        case 30:
          return 'slot-height-two-slots-per-hour';

        case 45:
          return 'slot-height-three-quarters-slots-per-hour';

        case 90:
          return 'slot-height-one-slot-per-one-and-half-hour';

        case 120:
          return 'slot-height-one-slot-per-two-hours';

        case 150:
          return 'slot-height-one-slot-per-two-and-half-hour';

        case 180:
          return 'slot-height-one-slots-per-three-hour';

        case 240:
          return 'slot-height-one-slots-per-four-hour';

        case 300:
          return 'slot-height-one-slots-per-five-hour';

        default:
          return '';
      }
    }),
    // eslint-disable-next-line ember/no-observers
    setTimeObjects: Ember.observer('timeWindowOffsetIntervalSetting', 'scheduleStartHour', 'scheduleEndHour', function () {
      const s = this.get('timeWindowOffsetIntervalSetting');
      const self = this; // eslint-disable-next-line ember/no-new-mixins

      const TimeObject = Ember.Object.extend({
        // eslint-disable-next-line ember/require-return-from-computed
        hour: Ember.computed('', function () {}),
        // eslint-disable-next-line ember/require-return-from-computed
        minutes: Ember.computed('', function () {})
      });
      self.set('timeObjects', []);
      const start = moment().startOf('day').add(this.get('scheduleStartHour'), 'hours');
      const stop = moment().startOf('day').add(this.get('scheduleEndHour'), 'hours');
      const numberOfHour = moment.duration(stop.diff(start)).asHours();
      const numberOfMinutes = numberOfHour * 60;
      const numberOfSteps = Math.floor(numberOfMinutes / s);

      for (let i = 0; i < numberOfSteps; i++) {
        const timeObject = TimeObject.create({
          hour: start.format('HH'),
          minutes: start.format('mm')
        });
        start.add(s, 'minutes');
        self.get('timeObjects').pushObject(timeObject);
      }
    }).on('init'),
    // eslint-disable-next-line ember/no-observers
    scheduleSetWindows: Ember.observer('shownDays.[]', 'ramps.length', 'scheduleStartDate', 'model.isTemplate', 'warehouseStepToHandle', function () {
      const webSocket = this.get('websocket').getSocket();
      const isTemplate = this.get('model.isTemplate');

      if (!isTemplate && webSocket && webSocket.connected) {
        return;
      }

      Ember.run.once(this, 'setWindows');
    }),

    setWindows() {
      if (!this.get('rampsIds.length')) {
        return;
      }

      console.log('setWindows');
      const self = this;
      const scheduleStartDate = this.get('scheduleStartDate');
      const startString = moment(scheduleStartDate).format('DD.MM.YYYY HH:mm');
      const data = {
        start: startString,
        isTemplate: true,
        rampsIds: this.get('rampsIds').join(',')
      };
      return this.store.query('timeWindow', data).then(function (results) {
        self.set('windows', results.toArray());
      });
    },

    hasAccessToMaxPallet: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_MAX_PALLET_PER_DAY') === 'true';
    }),
    hasAccessToMaxPalletPerRamp: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_MAX_PALLET_PER_RAMP') === 'true';
    }),
    hasAccessToNumberOfWindows: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_NUMBER_OF_WINDOWS') === 'true' && this.get('sessionAccount').hasRole('ROLE_ACCESS_TO_NUMBER_OF_WINDOWS');
    }),
    assortmentGroup: Ember.computed('warehouseStepToHandle.assortmentGroup', function () {
      return this.get('warehouseStepToHandle.assortmentGroup');
    }),
    adviceFromTime: Ember.computed('assortmentGroup', function () {
      return parseInt(this.get('assortmentGroup.adviceFromTime'));
    }),
    adviceToTime: Ember.computed('assortmentGroup', function () {
      return parseInt(this.get('assortmentGroup.adviceToTime'));
    }),
    scheduleStartHour: Ember.computed('warehouse.workStartHour', function () {
      return parseInt(this.get('warehouse.workStartHour'));
    }),
    scheduleEndHour: Ember.computed('warehouse.workEndHour', function () {
      return parseInt(this.get('warehouse.workEndHour'));
    }),
    hours: Ember.computed('scheduleStartHour', 'scheduleEndHour', function () {
      return this.hoursArray(1);
    }),

    /**
     * Zwraca tablicę godzin jakie należy wyświetlić w harmonogramie
     * scheduleStartHour - początek pracy magazynu
     * scheduleEndHour - koniec pracy magazynu
     */
    hoursArray: function (interval) {
      const hoursArray = [];

      for (let i = this.get('scheduleStartHour'); i < this.get('scheduleEndHour'); i += interval) {
        hoursArray.push(i);
      }

      return hoursArray;
    },

    loadWindows(idx, data) {
      this.set('loadingInProgress', true);
      const days = this.get('shownDays');

      if (idx >= days.length) {
        this.set('loadingInProgress', false); // Po załadowaniu wszystkich okien w dniach widocznych w harmonogrmie odpalamy
        // metodę nakładającą blokady związnae z grupami asortymentowymi oraz oknami dedykowanymi

        this._setAutoBlockades();

        return;
      }

      const self = this;
      const d = days[idx];
      const start = d.date.format('DD.MM.YYYY HH:mm');
      self.setPalletSummary(d);
      self.setNumberOfWindows(d);
      const lessOrEqualsTransportsThenWindows = data.loadedTransportIds.split(',').length <= this.get('windows').filter(w => w.get('transport.id')).length;

      if (this.get('warehouse').get('loadedDays').includes(start) && lessOrEqualsTransportsThenWindows) {
        return self.loadWindows(idx + 1, data);
      }

      data.start = start;
      const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/time-windows/render',
        data: JSON.stringify(data),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (results) {
        self.get('store').pushPayload(results);
        const windows = [];
        results.timeWindows.forEach(w => {
          windows.pushObject(self.get('store').peekRecord('time-window', w.id));
        }); // A-8699 :: Okna dublowały się podczas zmiany okresu wyświetlania transportów w harmonogramie

        const savedWindows = self.get('windows');
        savedWindows.pushObjects(windows);
        const uniqueWindows = [...new Set(savedWindows)];
        self.set('windows', uniqueWindows);
        self.get('warehouse').get('loadedDays').pushObject(start);
        self.loadWindows(idx + 1, data);
      });
    },

    setPalletSummary(day) {
      if (!this.get('hasAccessToMaxPallet') && !this.get('hasAccessToMaxPalletPerRamp')) {
        return;
      }

      this.getPalletSummary(day).then(response => {
        if (this.get('hasAccessToMaxPallet')) {
          day.set('numberOfPallets', response.numberOfPallets);
        }

        if (this.get('hasAccessToMaxPalletPerRamp')) {
          day.set('numberOfPalletsPerRamps', response);
        }
      }).catch(e => {
        console.error(e);
      });
    },

    getPalletSummary(day) {
      const token = this.get('sessionAccount').get('token');
      const warehouseId = this.get('warehouse.id');
      return new Ember.RSVP.Promise(function (resolve) {
        const data = {
          day: day.get('name') // TODO :: apollo-api-service

        };

        _jquery.default.ajax({
          url: `${_environment.default.serverURL}/warehouses/${warehouseId}/palletSummary`,
          type: 'POST',
          crossDomain: true,
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          resolve(response);
        });
      });
    },

    setNumberOfWindows(day) {
      if (!this.get('hasAccessToNumberOfWindows')) {
        return;
      }

      this.getNumberOfWindows(day).then(response => day.set('numberOfWindows', response.numberOfWindows));
    },

    getNumberOfWindows(day) {
      const token = this.get('sessionAccount').get('token');
      const warehouseId = this.get('warehouse.id');
      return new Ember.RSVP.Promise(function (resolve) {
        const data = {
          day: day.get('name') // TODO :: apollo-api-service

        };

        _jquery.default.ajax({
          url: `${_environment.default.serverURL}/warehouses/${warehouseId}/numberOfWindows`,
          type: 'POST',
          crossDomain: true,
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          resolve(response);
        });
      });
    },

    transportCanBeCreatedInThisWarehouse(transport, ramp) {
      const allTransportWarehousesId = transport.get('steps').getEach('warehouse.id');
      const warehouseId = ramp.get('warehouse.id');
      return allTransportWarehousesId.includes(warehouseId);
    },

    handleGridSize: function (newGridHeight) {
      this.set('gridHeight', newGridHeight);
      const gridHeightFormatted = `${newGridHeight}px`;
      const timeLabel = (0, _jquery.default)('.time-label');
      timeLabel.css('height', gridHeightFormatted);
      timeLabel.css('lineHeight', gridHeightFormatted);
      (0, _jquery.default)('.time-slot').css('height', gridHeightFormatted);
      (0, _jquery.default)('.minutes-slot ').css('height', gridHeightFormatted);
    },
    actions: {
      timeBarRendered() {
        // Jeżeli użytkownik ręcznie zmienił ustawienia przybliżenia (poprzez slider), musimy tę wartość używać
        // przy każdym renderowaniu harmonogramu -- po zmianie dnia itp.
        if (this.get('hasZoomBeenSetManually')) {
          const delta = jQuery('#slider').slider('option', 'value');
          this.send('handleGridChange', delta, true);
          return;
        }

        this.send('resetGrid');
      },

      handleGridChange(zoomInPercent = 100, hasBeenSetManually = false) {
        const defaultGridHeight = this.defaultGridHeight();
        const defaultCssHeight = this.defaultCssHeight();
        const newCssHeight = zoomInPercent / 100 * defaultCssHeight;
        const newGridHeight = zoomInPercent / 100 * defaultGridHeight;
        console.debug(`Ustawiamy wysokość wierszy na ${zoomInPercent}% (${newCssHeight}px, ${newGridHeight}px); zmiana manualna: ${hasBeenSetManually}...`);
        this.set('hasZoomBeenSetManually', hasBeenSetManually);
        this.set('cssHeight', newCssHeight);
        this.handleGridSize(newGridHeight);
      },

      resetGrid() {
        // Liczba slotów -- 14:00, 14:15 itp.
        const numberOfTimeSlots = (0, _jquery.default)('.time-label').length; // Domyślna wysokość slotu -- liczona niestety trochę na piechotę

        const gridHeight = this.defaultGridHeight(); // Wysokość harmonogramu gdybyśmy nie wykonali żadnej akcji

        const defaultScheduleHeight = gridHeight * numberOfTimeSlots;
        const scheduleOffsetTop = (0, _jquery.default)('.schedule-calendar').offset().top;
        const legendHeight = (0, _jquery.default)('.legend').outerHeight() || 0;
        const footerHeight = (0, _jquery.default)('.footer').outerHeight() || 0; // Sekcja "Po kliknięciu w wolne pole..."

        const scheduleFooterHeight = (0, _jquery.default)('.schedule-footer').height() || 0; // Margines sprawiający, że harmonogram nie zajmie jednak 100% widocznego ekranu -- ta wartość nie jest
        // w żaden sposób obliczana, ustalamy ją "na oko"

        const offsetHeight = 15; // Wysokość dostępnego ekranu to miejsce, w którym mogą wyświetlić się okienka -- a więc bez nagłówków, stopek, legend itp.

        const availableDisplayHeight = (0, _jquery.default)(window).height() - scheduleOffsetTop - legendHeight - footerHeight - scheduleFooterHeight - offsetHeight; // Jeżeli wyświetlony domyślnie harmonogram będzie dłuższy niż wyświetlacz -- pojawi się scroll
        // -- nie ruszamy go, nie chcemy go skracać, bo sloty staną się zbyt niskie

        if (defaultScheduleHeight > availableDisplayHeight) {
          this.set('defaultZoom', 100);
          this.send('setDefaultZoom');
          return;
        } // Raz określony poziom przybliżenia (zoom) zachowujemy, żebyśmy mogli do niego resetować slider,
        // jeżeli użytkownik kliknie "Resetuj"


        if (this.get('defaultZoom')) {
          this.send('setDefaultZoom');
          return;
        }

        console.debug('Zwiększamy powiększenie harmonogramu, żeby wypełnił całą dostępną przestrzeń...'); // Jakie musi być przybliżenie, żeby sloty zajęły pełen dostępny obszar wyświetlacza?
        // gridHeight -- 100%
        //       zoom -- x%

        const zoom = availableDisplayHeight / numberOfTimeSlots * 100 / gridHeight;
        this.set('defaultZoom', zoom);
        this.send('setSlider', zoom);
      },

      setDefaultZoom() {
        this.send('setSlider', this.get('defaultZoom'));
      },

      setSlider(value) {
        jQuery('#slider').slider('option', 'value', value);
      },

      spotClicked(d, r, h, m) {
        console.debug(`Spot ${d}, ${r}, ${h}, ${m} clicked.`);
        const selector = `div[data-schedule-date='${d}'] div[data-schedule-ramp='${r}'] div[data-schedule-hour='${h}'] div[data-schedule-minute='${m}']`;
        const $div = (0, _jquery.default)(selector);
        this.send('handleClick', $div);
      },

      dedicatedSpotClicked(event) {
        const $dedicatedWindowDiv = (0, _jquery.default)(document.elementFromPoint(event.clientX, event.clientY));

        if (!$dedicatedWindowDiv.closest('.window-state-dedicated')) {
          return;
        }

        $dedicatedWindowDiv.hide();
        const $spotDiv = this.findMinutesDiv(event.clientX, event.clientY);
        $dedicatedWindowDiv.show();
        this.send('handleClick', $spotDiv);
      },

      /**
       * Obsługa kliknięcia w puste miejsce w harmonogramie (podczas tworzenia awizacji albo blokady).
       *
       * @param $div - div, w który kliknął użytkownik
       */
      handleClick($div) {
        if (!$div.hasClass('minutes-' + this.get('timeWindowOffsetIntervalSetting'))) {
          return;
        }

        const isTransportWindow = $div.find('.window-state-with-transport').length;
        const isBlockadeWindow = $div.find('.window-state-disabled').length;
        const isDedicatedWindow = $div.find('.window-state-dedicated').length || $div.find('.window-state-dedicated-template').length;
        const transportToHandle = this.get('transportToHandle');
        const dedicatedTimeWindowToHandle = this.get('dedicatedTimeWindowToHandle'); // kliknięcie w okno dedykowane tworzy awizację tylko, gdy jesteśmy w trakcie zapisywania awizacji;
        // w przeciwnym razie, okno dedykowane powinno zostać usunięte (po potwierdzeniu przez użytkownika).

        if (isDedicatedWindow && !transportToHandle) {
          return;
        } // FIXME:
        // na urządzeniach dotykowych, gdy naciśniemy na okno blokady
        // lub okno z transportem to zostaje wywołana akcja `spotClicked` na slocie czasowym
        // pod tym naciśniętym oknem blokady lub transportu
        // przez co aplikacja myśli, że chcemy utworzyć blokadę okna na istniejącym już oknie
        // wynika to prawdopodobnie z tego, że div'y time-slot są zawsze nad oknami w DOM-ie.
        // Trzeba byłoby to w przyszłości poprawić
        // (zarówno poniższy kod jak i hierarchię renderowania tych elementów w DOM-ie).


        if (isBlockadeWindow || isTransportWindow) {
          return;
        }

        const warehouse = this.get('warehouse');
        const timeWindowMustOccupyEntireRamp = warehouse.get('timeWindowMustOccupyEntireRamp');
        let start = this.getStartDate($div);
        let stop;

        if (timeWindowMustOccupyEntireRamp) {
          start = warehouse.getStartOnDay(start);
          stop = warehouse.getEndOnDay(start);
        } else {
          const defaultWindowLength = warehouse.get('timeWindowDefaultLengthInMinutes');
          const windowLength = this.get('editedWindow.id') ? this.get('editedWindow.durationInMinutes') : defaultWindowLength;
          stop = moment(start).add(windowLength, 'minutes');
        }

        const rampId = this.getRampId($div);
        const initialStart = this.getStartDate($div);

        if (transportToHandle) {
          this.send('handleClickWithTransport', start, stop, rampId, initialStart);
        } else if (dedicatedTimeWindowToHandle) {
          this.send('handleClickDedicatedWindow', start, stop, rampId, initialStart);
        } else {
          this.send('createWindow', start, stop, rampId, initialStart);
        }
      },

      onResize(event, ui) {
        const self = this;
        const element = (0, _jquery.default)(ui.element);
        const windowId = element.data('schedule-window');
        const left = element.offset().left - (0, _jquery.default)(document).scrollLeft();
        const top = element.offset().top + element.outerHeight() - (0, _jquery.default)(document).scrollTop();
        const windowModel = this.store.peekRecord('timeWindow', windowId);
        const div = this.findMinutesDiv(left + 10, top + 2);
        let newStop;

        if (div) {
          newStop = this.getDate(div);
        } else {
          newStop = moment(windowModel.get('formattedStop') + this.get('warehouse').get('workEndHour'), this.dateUtils.getBaseDateFormat() + ' ' + this.dateUtils.getBaseHourFormat());
        }

        const oldStop = windowModel.get('stop');
        windowModel.set('stop', newStop);
        console.log(`Próba rozciągnięcia okna ${windowId}: ${oldStop} -> ${newStop}..`);
        let canResize = false;

        if (windowModel.get('isDedicated') && this.get('sessionAccount').hasRole('ROLE_RESIZE_DEDICATED_WINDOWS')) {
          canResize = true;
        } else if (windowModel.get('isBlocked') && this.get('sessionAccount').hasRole('ROLE_RESIZE_BLOCKED_WINDOWS')) {
          canResize = true;
        } else if (windowModel.get('transport.content')) {
          canResize = windowModel.get('transport.content').actionCanBePerformed('RESIZE_ADVICE_WINDOW');
        }

        if (!canResize) {
          windowModel.set('stop', oldStop);
          self.replaceTimeWindow(windowModel);
          return;
        }

        if (self.get('isTemplate')) {
          if (this.modelOverlapsAnyWindow(windowModel)) {
            console.log('windows overlap');
            windowModel.set('stop', oldStop);
          }

          self.replaceTimeWindow(windowModel);
          self.send('removePopupDialog');
          self.set('inProgress', false);
          return;
        }

        windowModel.lockForEditing();
        const transport = windowModel.get('transport');

        if (transport && transport.get('id')) {
          windowModel.set('updateAllFuturePeriodicWindows', this.updateAllFuturePeriodicTransports(transport));
        }

        windowModel.save().then(function (w) {
          self.send('removePopupDialog');
          self.reloadTimeWindow(windowId);

          if (w.get('transport.id')) {
            w.get('transport').then(t => {
              t.reload().then(() => {
                self.unloadDeletedRecords('transport', t.id, 'checkpoint');
                windowModel.unlockForEditing();
                console.debug(`Zakończyliśmy aktualizację okna ${windowId} z transportem ${t.get('logString')}.`);
              });
            });
          } else {
            windowModel.unlockForEditing();
            console.debug(`Zakończyliśmy aktualizację okna ${windowId}.`);
          }
        }, function (error) {
          windowModel.rollbackAttributes();
          windowModel.save();
          self.reloadTimeWindow(windowId);
          self.setProperties({
            inProgress: false,
            errors: error.errors
          });
          windowModel.unlockForEditing();
        });
      },

      onStart(event) {
        // to jest potrzebne do unikania klikania na okno w momencie gdy jest przeciagane
        (0, _jquery.default)(event.target).addClass('noclick');
      },

      setCompany(item) {
        const self = this;
        this.store.findRecord('company', item.id).then(companyPayload => {
          self.set('company', companyPayload);
        });
      },

      clearDedicatedSelects() {
        this.setProperties({
          company: null,
          selectedUserGroup: null
        });
      },

      notifyWindowFocusIn(window, position) {
        this.set('position', position);
        this.set('focusedWindowId', window.id);
      },

      notifyWindowFocusOut(window) {
        if (this.get('focusedWindowId') === window.id) {
          this.set('focusedWindowId', null);
        }
      }

    },

    modelOverlapsAnyWindow(windowModel) {
      const windowData = {
        id: windowModel.get('id'),
        start: windowModel.get('start'),
        stop: windowModel.get('stop'),
        ramp: {
          id: windowModel.get('ramp').get('id')
        }
      };
      return this.overlapsAnyWindow(windowData);
    },

    overlapsAnyWindow(window) {
      const rampId = window.ramp.id;
      const newStart = window.start;
      const newStop = window.stop;
      const overlappingWindows = this.get('windows').filter(w => {
        return parseInt(w.get('id')) !== parseInt(window.id) && parseInt(w.get('ramp').get('id')) === parseInt(rampId) && !(w.get('stop') <= newStart || w.get('start') >= newStop);
      });
      return overlappingWindows.length > 0;
    },

    windowsSwappingBlocked(sourceWindow, targetWindow) {
      if (targetWindow && targetWindow.get('model') && !targetWindow.get('model').get('isDedicated')) {
        if (parseInt(sourceWindow.get('model.transport.carrierCompany.id')) === parseInt(targetWindow.get('model.transport.carrierCompany.id'))) {
          this.swapWindowPosition(sourceWindow, targetWindow);
          return false;
        }

        return true;
      }

      return false;
    },

    restorePreviousWindowPlace: function (window) {
      const windowModel = window.get('model');
      windowModel.rollbackAttributes();
      windowModel.unlockForEditing();
      windowModel.setProperties({
        ramp: window.get('oldRamp')
      });
    },
    replaceTimeWindow: function (result) {
      const existingWindows = this.get('windows').filter(w => {
        return w.id === result.id;
      });

      if (existingWindows) {
        this.get('windows').removeObjects(existingWindows);
      }

      this.get('windows').pushObject(result);
    },

    reloadTimeWindow(windowId) {
      console.debug(`Reloading window ${windowId}..`);
      const self = this;
      this.store.findRecord('timeWindow', windowId, {
        reload: true
      }).then(function (data) {
        const changeIdx = self.get('windows').indexOf(data);
        self.get('windows').replace(changeIdx, 1, [data]);
        self.set('inProgress', false);
        console.log(`after reload / id: ${data.get('id')} / changeIdx: ${changeIdx} / start: ${data.get('start')} / stop: ${data.get('stop')} / ramp: ${data.get('ramp').get('name')}`);
      });
    },

    pushTimeWindow(windowId) {
      console.debug(`Pushing window ${windowId}..`);
      const self = this;
      this.store.findRecord('timeWindow', windowId, {
        reload: true
      }).then(function (data) {
        const changeIdx = self.get('windows').indexOf(data);

        if (changeIdx >= 0) {
          self.get('windows').replace(changeIdx, 1, [data]);
          self.set('inProgress', false);
          console.log(`After reload / id: ${data.get('id')} / changeIdx: ${changeIdx} / start: ${data.get('start')} / stop: ${data.get('stop')} / ramp: ${data.get('ramp').get('name')}`);
        } else {
          console.log(`Adding window ${data.get('id')}: start: ${data.get('start')} / stop: ${data.get('stop')} / ramp: ${data.get('ramp').get('name')}..`);

          if (!data.get('step.id')) {
            self.get('windows').pushObject(data);
            return;
          }

          data.get('step').then(s => {
            console.debug(`Step ${s.get('id')} loaded.`);
            s.get('transport').then(t => {
              t.reload().then(reloadedTransport => {
                console.debug(`Transport ${reloadedTransport.get('id')} reloaded.`);
                self.get('windows').pushObject(data);
              });
            });
          });
        }
      });
    },

    findMinutesDiv(left, top) {
      try {
        if (left < 50) {
          left += 50; // zabezpieczenie przed dojechaniem do lewej krawedzi ekranu
        }

        let topElement = (0, _jquery.default)(document.elementFromPoint(left, top));
        const visibility = topElement.css('visibility');

        if (topElement.hasClass('ember-application') || topElement.hasClass('schedule-container') || topElement.hasClass('footer')) {
          return null; // okno wyjechalo poza harmonogram, nie chce wywalac bledu, zeby wykonaly sie wszystkie
          // topElement.css('visibility', visibility);
        }

        if (!topElement.hasClass('minutes-slot')) {
          topElement.css('visibility', 'hidden'); // zabezpiecznie przed memory leak

          if (top < 0) {
            return null;
          }

          const bottomElement = this.findMinutesDiv(left, top);
          topElement.css('visibility', visibility);
          topElement = bottomElement;
        }

        return topElement;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    getDate: function (div) {
      const $minuteDiv = (0, _jquery.default)(div);
      const $hourDiv = $minuteDiv.parent();
      const $rampDiv = $hourDiv.parent();
      const $dayDiv = $rampDiv.parent();
      const dateString = $dayDiv.data('schedule-date');
      const h = $hourDiv.data('schedule-hour');
      const m = $minuteDiv.data('schedule-minute');
      const date = moment(`${dateString} ${h} ${m}`, 'YYYY-MM-DD HH:mm');
      return date.toDate();
    },
    getRampId: function (div) {
      return (0, _jquery.default)(div).closest('.ramp').data('schedule-ramp');
    },

    swapWindowPosition(sourceWindow, targetWindow) {
      const self = this;
      this.set('windowSwapping', true);

      if (targetWindow.get('model.stop') - targetWindow.get('model.start') !== sourceWindow.get('currentLengthInMS')) {
        const message = this.get('intl').t('ramp.errors.wrongSwappedWindowSize');
        this.send('showPopupDialog', 'dialogs.message-popup-dialog', sourceWindow.set('detail', message));
        this.restorePreviousWindowPlace(sourceWindow);
        this.set('windowSwapping', false);
        return;
      }

      const message = this.get('intl').t('ramp.wantToSwapWindow');

      if (confirm(message)) {
        sourceWindow.get('model').setProperties({
          'isSwapped': true,
          isLockedForEditing: true
        });
        targetWindow.get('model').setProperties({
          ramp: sourceWindow.get('oldRamp'),
          start: sourceWindow.get('oldStart'),
          stop: sourceWindow.get('oldStop'),
          isSwapped: true,
          swappedWindowId: sourceWindow.get('model.id'),
          isLockedForEditing: true
        });
        targetWindow.get('model').save().then(() => {
          self.set('windowSwapping', false);
          sourceWindow.get('model').setProperties({
            isSwapped: false,
            swappedWindowId: null,
            isLockedForEditing: false
          }); // wymuszenie odswieżenia danych o drugim oknie

          sourceWindow = this.get('store').findRecord('time-window', targetWindow.get('model.swappedWindowId'));
          targetWindow.get('model').setProperties({
            swappedWindowId: null,
            isSwapped: false,
            isLockedForEditing: false
          });
          console.log('Zamiana okien wykonana poprawnie.');
        }, function (response) {
          sourceWindow.get('model').setProperties({
            isSwapped: false,
            swappedWindowId: null,
            isLockedForEditing: false
          });
          targetWindow.get('model').setProperties({
            swappedWindowId: null,
            isSwapped: false,
            isLockedForEditing: false
          });
          self.restorePreviousWindowPlace(sourceWindow);
          self.restorePreviousWindowPlace(targetWindow);
          self.setProperties({
            inProgress: false,
            windowSwapping: false,
            errors: response.errors
          });
        });
      } else {
        this.restorePreviousWindowPlace(sourceWindow);
      }
    },

    /** @namespace response.estimatedWindowLengthInMinutes */
    getWindowSize(step, transport) {
      const token = this.get('sessionAccount').get('token');
      return new Ember.RSVP.Promise(function (resolve) {
        const data = {
          transportCommand: transport.get('content') ? transport.get('content').serialize() : transport.serialize()
        };
        data.transportCommand.id = transport.get('id');
        data.warehouseZone = {
          id: step.get('warehouseZone.id') // TODO :: apollo-api-service

        };

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/estimatedWindowLengthInMinutes',
          type: 'POST',
          crossDomain: true,
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          resolve(response);
        });
      });
    }

  });

  _exports.default = _default;
});