define("apollo/pods/role-group-invitation-restriction/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    invitingRoleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    invitedRoleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    lastUpdate: _emberData.default.attr(),
    dateCreated: _emberData.default.attr()
  });

  _exports.default = _default;
});