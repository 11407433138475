define("apollo/pods/algorithm-settings/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    algorithmSettingsIndexController: Ember.inject.controller('algorithm-settings/index'),
    intl: Ember.inject.service(),
    errors: [],
    actions: {
      save: function () {
        const self = this;
        const algorithmSetting = this.get('model');
        algorithmSetting.save().then(function () {
          self.setProperties({
            success: true,
            errors: []
          });
          const msg = self.get('intl').t('algorithmSettings.saveSuccessMessage');
          self.get('algorithmSettingsIndexController').set('successMessage', msg);
          self.transitionToRoute('algorithm-settings.index', {
            queryParams: {
              warehouseZoneId: self.get('model.warehouseZone.id')
            }
          });
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        const warehouseZoneId = this.get('model.warehouseZone.id');
        this.get('model').set('warehouseZone', null);
        this.get('model').unloadRecord();
        this.transitionToRoute('algorithm-settings.index', {
          queryParams: {
            warehouseZoneId: warehouseZoneId
          }
        });
      },

      delete() {
        const self = this;
        const algorithmSetting = this.get('model');
        algorithmSetting.destroyRecord().then(a => {
          const msg = self.get('intl').t('algorithmSettings.deletionSuccessMessage', {
            n: a.get('numberOfPallets')
          });
          self.get('algorithmSettingsIndexController').set('successMessage', msg);
          console.log(`Poprawnie usunęliśmy ustawienia algorytmu dla ${a.get('numberOfPallets')} palet.`);
          this.transitionToRoute('algorithm-settings.index', {
            queryParams: {
              warehouseZoneId: this.get('model.warehouseZone.id')
            }
          });
        }).catch(response => {
          self.set('errors', response.errors);
        });
      }

    }
  });

  _exports.default = _default;
});