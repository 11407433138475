define("apollo/pods/currency/model", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    symbol: attr('string'),
    idx: attr('number'),
    exchangeRate: attr('number')
  });

  _exports.default = _default;
});