define("apollo/pods/components/companies/apply-form/blockade-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oz8TT0QI",
    "block": "{\"symbols\":[\"b\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-horizontal\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"partnership\",\"blockades\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"fa-icon\",[\"smile\",\"regular\"],null],false],[0,\" \"],[1,[27,\"t\",[\"blockades.noActiveBlockades\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"partnership\",\"blockades\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[1,[27,\"format-date\",[[22,1,[\"dateCreated\"]]],null],false],[0,\" -\\n\"],[4,\"if\",[[22,1,[\"notes\"]]],null,{\"statements\":[[0,\"                \"],[1,[22,1,[\"notes\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[27,\"t\",[\"blockades.invalidSection\"],null],false],[0,\" : \"],[7,\"label\"],[11,\"class\",\"bold\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"sectionType.\",[22,1,[\"section\",\"name\"]]],null]],null],false],[10],[0,\"\\n            \"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/companies/apply-form/blockade-section/template.hbs"
    }
  });

  _exports.default = _default;
});