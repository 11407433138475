define("apollo/pods/components/ramps-checkboxes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qsJK6fGK",
    "block": "{\"symbols\":[\"w\",\"r\"],\"statements\":[[4,\"each\",[[23,[\"proxiedWarehouses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\",\"change\"],[\"checkbox\",[22,1,[\"checked\"]],[23,[\"canNotEditRamps\"]],[27,\"action\",[[22,0,[]],[23,[\"changeAction\"]],[22,1,[]]],null]]]],false],[0,\"\\n            \"],[1,[22,1,[\"nameInAppropriateLanguage\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"ramp-checkboxes\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"proxiedRamps\"]]],null,{\"statements\":[[0,\"                \"],[7,\"label\"],[11,\"class\",\"label-not-bold\"],[9],[0,\"\\n                    \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[22,2,[\"checked\"]],[23,[\"canNotEditRamps\"]]]]],false],[0,\"\\n\\n                    \"],[1,[22,2,[\"name\"]],false],[0,\" [\"],[1,[22,2,[\"warehouseZone\",\"name\"]],false],[0,\"]\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/ramps-checkboxes/template.hbs"
    }
  });

  _exports.default = _default;
});