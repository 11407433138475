define("apollo/pods/statistics-report/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vDBWSMyS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"noReportsAvailable\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"errors\"],[9],[1,[27,\"t\",[\"noAccessibleStatisticsReports\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"statistics-report-nav-header\",null,[[\"model\",\"activeReport\"],[[23,[\"model\"]],[23,[\"report\",\"nameInCurrentLanguage\"]]]]],false],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"container-fluid \"],[9],[0,\"\\n                \"],[7,\"label\"],[11,\"class\",\"title\"],[9],[1,[23,[\"report\",\"nameInCurrentLanguage\"]],false],[10],[0,\"\\n                \"],[1,[27,\"iframe-component\",null,[[\"height\",\"width\",\"title\",\"url\"],[[23,[\"reportHeight\"]],[23,[\"reportWidth\"]],[23,[\"reportTitle\"]],[23,[\"url\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/statistics-report/index/template.hbs"
    }
  });

  _exports.default = _default;
});