define("apollo/pods/components/schedule/schedule-header/component", ["exports", "apollo/mixins/touch-mixin"], function (_exports, _touchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend(_touchMixin.default, {
    sessionAccount: service('session-account'),
    tagName: 'div',
    classNames: ['schedule-header'],
    actionName: '',
    initResizeObserver: Ember.on('init', function () {
      $(window).on('resize', this.resize.bind(this));
    }),
    didInsertElement: function () {
      if (this.isTouchDevice) {
        this.send('makeScheduleScrollable');
      }
    },
    didReceiveAttrs: function () {
      if (this.isTouchDevice) {
        this.send('makeScheduleScrollable');
      }
    },
    resize: function () {
      //żeby na emulatorze się nie rozjeżdzało
      //przy zmianie rozmiaru okna
      if (this.isTouchDevice) {
        this.send('makeScheduleScrollable');
      }
    },
    touchStart: function (event) {
      this.touchstart(event);
    },
    touchEnd: function (event) {
      this.touchend(event, () => {
        this.handleSwipeX(() => {
          this.get('shiftScheduleRight')();
        }, () => {
          this.get('shiftScheduleLeft')();
        });
      });
    },
    actions: {
      makeScheduleScrollable() {
        // Gdy szerokość rampy będzie mniejsza niż RAMP_MIN_WIDTH px
        // to rozszerzamy pole harmonogramu aby móc je scrollować w poziomie
        const RAMP_MIN_WIDTH = 80; //px

        const NUMBER_OF_RAMPS = this.get('viewedColumns');
        const rampWidth = $('.ramp.even').first().outerWidth();

        if (rampWidth <= RAMP_MIN_WIDTH) {
          const minScheduleWidth = RAMP_MIN_WIDTH * NUMBER_OF_RAMPS;
          $('.schedule-header').css('min-width', minScheduleWidth);
          $('.schedule-body').css('min-width', minScheduleWidth);
        }
      },

      toggleWholeRampWindow(time, rampId) {
        console.log(`toggleWholeRampWindow :: t: ${time}, r: ${rampId}..`);
        this.set('actionName', 'toggleWholeRampWindow');
        this.sendAction('actionName', time, rampId);
      },

      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});