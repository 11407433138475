define("apollo/pods/components/custom-auto-complete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dQkyL0RV",
    "block": "{\"symbols\":[\"result\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"typeahead-wrap\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"class\",\"disabled\",\"value\",\"placeholder\",\"autocomplete\",\"spellcheck\"],[[23,[\"inputClazz\"]],[23,[\"disabled\"]],[23,[\"selectedValue\"]],[23,[\"placeHolderText\"]],\"off\",\"false\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"suggestions\"]],[23,[\"noMesssagePlaceHolderText\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"tt-dropdown-menu\"],[12,\"style\",[21,\"visibility\"]],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"tt-dataset-states\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"tt-suggestions\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"suggestions\"]]],null,{\"statements\":[[0,\"                        \"],[7,\"div\"],[12,\"class\",[28,[\"suggestion \",[27,\"if\",[[22,1,[\"highlight\"]],\"highlight\"],null]]]],[9],[0,\"\\n                            \"],[14,2,[[22,1,[]]]],[0,\"\\n                        \"],[3,\"action\",[[22,0,[]],\"selectItem\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"                        \"],[7,\"div\"],[11,\"class\",\"message\"],[9],[0,\"\\n                            \"],[7,\"p\"],[9],[1,[21,\"noMesssagePlaceHolderText\"],false],[10],[0,\"\\n                        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/custom-auto-complete/template.hbs"
    }
  });

  _exports.default = _default;
});