define("apollo/pods/components/related-to-labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iI7/bfeh",
    "block": "{\"symbols\":[\"transport\"],\"statements\":[[1,[21,\"title\"],false],[0,\"\\n\"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"transports\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"archived\"]]],null,{\"statements\":[[0,\"                \"],[7,\"s\"],[9],[1,[22,1,[\"relatedToLabel\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[22,1,[\"relatedToLabel\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[3,\"action\",[[22,0,[]],\"showTransportModal\",[22,1,[\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/related-to-labels/template.hbs"
    }
  });

  _exports.default = _default;
});