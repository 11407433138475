define("apollo/pods/indexes/modals/indexes-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XhNvEfvM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"cancel\"],[[23,[\"modalTitle\"]],[27,\"action\",[[22,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"successMessage\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"success-message\",null,[[\"text\"],[[23,[\"successMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"errors-alert\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\\n    \"],[7,\"form\"],[11,\"data-form-name\",\"indexes-details\"],[11,\"class\",\"form-horizontal section\"],[9],[0,\"\\n\\n        \"],[1,[27,\"transport/indexes-section\",null,[[\"transport\",\"asPlainTemplate\",\"showExpanded\",\"hideAddIndexesSection\",\"columns\",\"viaSchedule\"],[[23,[\"transport\"]],[23,[\"asPlainTemplate\"]],[23,[\"showExpandedIndexSection\"]],true,[23,[\"columns\"]],true]]],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"canSaveIndexes\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\"],[11,\"data-button-name\",\"save-indexes-button\"],[11,\"class\",\"btn btn-primary\"],[11,\"type\",\"button\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n            \"],[7,\"button\"],[11,\"data-button-name\",\"cancel-indexes-button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"common.close\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/indexes/modals/indexes-details/template.hbs"
    }
  });

  _exports.default = _default;
});