define("apollo/pods/components/transports/transports-table/add-relations-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    sessionAccount: Ember.inject.service(),
    showButton: Ember.computed('canCombineTransports', 'relationsTrackerService.currentTransports.length', function () {
      const atLeastTwoTransportsSelected = this.get('relationsTrackerService.currentTransports.length') > 1;
      const userHasAuthorityToAddRelations = this.get('sessionAccount').hasRole('ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION') || this.get('sessionAccount').hasRole('ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION');
      return userHasAuthorityToAddRelations && atLeastTwoTransportsSelected;
    }),
    shouldRedirectToRelationsWizard: Ember.computed('relationsTrackerService.currentTransports.length', function () {
      const chosenTransport = this.relationsTrackerService.currentTransports;
      const chosenAnyRelationCombiningTransport = chosenTransport.any(transport => transport.get('isRelatedTransportInTransportCombining'));
      const chosenAnyTransportWithoutRelations = chosenTransport.any(transport => !transport.get('transportRelationIdentifier'));
      return chosenAnyRelationCombiningTransport && chosenAnyTransportWithoutRelations;
    }),
    actions: {
      openRelationsModal() {
        this.set('action', 'openRelationsModal');
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});