define("apollo/pods/components/role-group-invitation-restriction/component", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    roleGroupCheckboxes: Ember.computed('roleGroupInvRestriction.@each', function () {
      const principalId = localStorage.getItem('principalId');
      return this.get('store').peekAll('roleGroup').filter(rg => {
        return rg.get('principalCompany.id') === principalId && rg.get('canBeInvited') && rg.get('name') !== 'SUPER_USER' && rg.get('name') !== 'E-MAIL_ADVICE';
      });
    }),
    roleGroupIds: Ember.computed('roleGroupInvRestriction.@each', function () {
      let roleGroupInvRestriction = this.get('roleGroupInvRestriction');
      return roleGroupInvRestriction.map(r => r.get('invitedRoleGroup.id'));
    }),
    availableRoleGroupIds: Ember.computed('', function () {
      return this.get('roleGroupInvRestriction').map(c => c.get('invitedRoleGroup.id'));
    }),
    actions: {
      toggleRole: function (rg) {
        const roleGroup = this.get('availableRoleGroupIds').find(rgt => rgt === rg.get('id'));

        if (roleGroup !== undefined) {
          this.get('availableRoleGroupIds').removeObject(roleGroup);
        } else {
          this.get('availableRoleGroupIds').addObject(rg.get('id'));
        }
      },
      save: function () {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: `${_environment.default.serverURL}/role-group-invitation-restrictions`,
          data: JSON.stringify({
            availableRoleGroupIds: self.get('availableRoleGroupIds'),
            invitingRoleGroupId: this.get('roleGroupId')
          }),
          type: 'POST',
          contentType: 'application/json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          self.set('successMessage', `${self.get('intl').t('common.saveSuccessMessage') + ". " + self.get('intl').t('common.saveSuccessAdditionalMessage')}`);
        }, function (response) {
          self.set('successMessage', null);
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});