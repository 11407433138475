define("apollo/pods/components/related-to-labels-has-to-wait/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    isMasterTransport: Ember.computed('transport.isMasterTransportInHasToWaitFor', function () {
      return this.get('transport.isMasterTransportInHasToWaitFor');
    }),
    title: Ember.computed('isMasterTransport', function () {
      if (this.get('isMasterTransport')) {
        return this.get('intl').t('transport.isWaitingFor');
      }

      return this.get('intl').t('transport.isRequiredFor');
    }),
    relatedTransports: Ember.computed('transport.relatedHasToWaitForTransports.@each.relatedToLabel', function () {
      return this.get('transport.relatedHasToWaitForTransports');
    }),
    relatedTransportsLabelsOnly: Ember.computed('transport.relatedHasToWaitForNotCreatedTransports', function () {
      return this.get('transport.relatedHasToWaitForNotCreatedTransports');
    }),
    masterTransports: Ember.computed('transport.masterHasToWaitForTransports.{length,[]]}', function () {
      if (this.get('transport.masterHasToWaitForTransports.length') < 1) {
        return [];
      }

      return this.get('transport.masterHasToWaitForTransports');
    }),
    actions: {
      showTransportModal: function (id) {
        this.get('store').findRecord('transport', id).then(transport => {
          transport.get('customFields').forEach(cf => cf.set('changeMessage', null));
          this.set('openTransportId', transport.get('id'));
          this.set('action', 'showModal');
          this.sendAction('action', transport);
        });
      }
    }
  });

  _exports.default = _default;
});