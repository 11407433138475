define("apollo/pods/places/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actions: {
      save() {
        const self = this;
        this.get('model').validate();

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').save().then(place => {
          console.debug(`Place ${place.get('name')} saved.`);
          self.setProperties({
            errors: []
          });
        }).catch(response => {
          self.set('errors', response.errors);
        }).finally(() => {
          self.transitionToRoute('squares.edit', self.get('model.queue.square'));
        });
      },

      disable() {
        const self = this;
        const model = this.get('model');
        const placeId = this.get('model.id');
        const jsonBody = JSON.stringify(model);
        const path = this.get('apolloApiService').APOLLO_API.PLACES.DISABLE; // TODO Przerobić na ember-data

        this.get('apolloApiService').callApolloApi(path, {
          placeId
        }, {
          body: jsonBody
        }).then(() => {
          model.set('enabled', false);
          self.transitionToRoute('squares.edit', self.get('model.queue.square'));
        });
      },

      enable() {
        const self = this;
        const model = this.get('model');
        const placeId = this.get('model.id');
        const jsonBody = JSON.stringify(model);
        const path = this.get('apolloApiService').APOLLO_API.PLACES.ENABLE; // TODO Przerobić na ember-data

        this.get('apolloApiService').callApolloApi(path, {
          placeId
        }, {
          body: jsonBody
        }).then(() => {
          model.set('enabled', true);
          self.transitionToRoute('squares.edit', self.get('model.queue.square'));
        });
      },

      cancel() {
        const square = this.get('model.queue.square');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('squares.edit', square);
      }

    }
  });

  _exports.default = _default;
});