define("apollo/pods/components/errors-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KuW6vzdg",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"alert alert-danger alert-dismissible\"],[11,\"role\",\"alert\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"alert\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"                \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"detail\"]]],null,{\"statements\":[[0,\"                        \"],[1,[22,1,[\"detail\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                        \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/errors-alert/template.hbs"
    }
  });

  _exports.default = _default;
});