define("apollo/pods/components/advice-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),

    async init() {
      this._super(...arguments);

      if (this.get('advice.id')) {
        return;
      }

      await this._sendExternalRessourcesRequest();
    },

    layoutName: Ember.computed(function () {
      return this.get('isSplitAdvice') === true ? 'components/advice-button/create-window-button' : 'components/advice-button/create-advice-button';
    }),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    modalService: Ember.inject.service('modal-service'),
    _transportOrRelatedTransportWithHighestNumOfWindows: function (transport) {
      if (!transport.get('isCombinedTransport')) {
        return transport;
      }

      return transport.get('allCombinedTransports').filterBy('isRelatedTransportInTransportCombining').sortBy('stepsWithWarehouse.length').get('lastObject');
    },
    actions: {
      handleCreatingAdvice: function (transport) {
        const self = this;

        this._transportOrRelatedTransportWithHighestNumOfWindows(transport).reload().then(t => {
          if (!t.get('advice.id') && (t.get('transportType.adviceSeparatedFromWindow') || t.get('stepsWithWarehouse.length') === 0)) {
            if (t.get('transportType.selectionOfWindowBeforeAdvice')) {
              const settingValue = self.get('sessionAccount').getSettingValue('SHOW_TERMS_OF_CONDITIONS_ACCEPTED');
              const advice = self.get('store').createRecord('advice', {
                requiresAcceptanceOfTermsOfCondition: settingValue === 'true'
              });
              t.set('advice', advice);
            }

            this.openCreateAdviceModal(t);
            return;
          }

          this.send('handleCreatingTimeWindow', t);
        });
      },

      handleCreatingTimeWindow(transport) {
        const stepsWithWarehouse = transport.get('stepsWithWarehouse');
        let step = null;

        if (stepsWithWarehouse.get('length') > 0) {
          step = stepsWithWarehouse.get('firstObject');
        } else {
          step = transport.get('steps.firstObject');
        }

        this.get('modalService').hideModal();
        this.goToWarehouse(step);
      }

    },
    _sendExternalRessourcesRequest: async function () {
      if (!this.get('transport.transportType.useResourcesFromExternalSource')) {
        return;
      }

      const path = this.get('apolloApiService').APOLLO_API.MARCOS.REFRESH_RESOURCES;
      const response = await this.get('apolloApiService').callApolloApi(path, {
        transportId: this.get('transport.id')
      });

      if (!response.ok) {
        const error = await response.json();
        console.error(error);
      }
    }
  });

  _exports.default = _default;
});