define("apollo/pods/components/warehouse-location/multiple-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // TODO: A-12882 Mamy dwa komponenty do pola lokalizacja - ujednolicenie
    init(...args) {
      this._super(...args);

      (true && !(Array.isArray(this.get('inputArray'))) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'inputArray',
        type: 'Array'
      }), Array.isArray(this.get('inputArray'))));
      (true && !(Array.isArray(this.get('options'))) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'options',
        type: 'Array<WarehouseLocationModel>'
      }), Array.isArray(this.get('options'))));
      (true && !(!!this.get('step')) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'step',
        type: 'Step Model'
      }), !!this.get('step')));
    },

    intl: Ember.inject.service(),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    selectedWarehouseLocations: Ember.computed.reads('inputArray'),
    warehouseLocationsOptions: Ember.computed.reads('options'),
    warehouseLocationDisabled: Ember.computed(function () {
      return !this.get('authorityCheckerService').actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION', this.get('step.transport'));
    })
  });

  _exports.default = _default;
});