define("apollo/pods/components/transport/documents-section/component", ["exports", "apollo/mixins/attachment-mixin", "apollo/mixins/document-mixin"], function (_exports, _attachmentMixin, _documentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_attachmentMixin.default, _documentMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    tagName: 'div',
    classNames: ['drop-leave'],

    dragOver(event) {
      event.stopPropagation();
      const dropZone = $('.dropzone');

      if (!dropZone.hasClass('transport-dragover')) {
        dropZone.addClass('transport-dragover');
      }
    },

    dragLeave(event) {
      event.stopPropagation();
      const dropZone = $('.dropzone');

      if (dropZone.hasClass('transport-dragover')) {
        dropZone.removeClass('transport-dragover');
      }
    },

    drop(event) {
      if (!this.get('transport.id')) {
        event.preventDefault();
        const attachments = this.attachments;
        const self = this;
        const dataFromDrop = Ember.A(event.dataTransfer.items);
        const token = this.get('sessionAccount').get('token');
        const files = Ember.A();
        Ember.A([...dataFromDrop]).forEach(data => {
          if (data.kind === 'file') {
            files.pushObject(data.getAsFile());
          }
        });
        files.forEach(function (file) {
          if (!attachments.findBy('name', file.name)) {
            attachments.pushObject(file);
          }
        });

        if (attachments) {
          self.uploadAttachment(this.get('transport.id'), token).then(() => {
            self.attachments.clear();
          });
        }
      }

      const dropZone = $('.dragover');

      if (dropZone.hasClass('dragover')) {
        dropZone.removeClass('transport-dragover');
      }
    },

    actions: {
      showNoAuthorityAlert() {
        alert(this.get('intl').t('common.noAuthority'));
      }

    }
  });

  _exports.default = _default;
});