define("apollo/pods/components/schedule/handle-transport-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    relationsCombinerService: service('transport-relations.relations-combiner'),
    actionName: '',
    scrollPos: 0,
    bindScrolling: function () {
      let onScroll,
          self = this;

      onScroll = function () {
        $(window).scroll(function () {
          let top = $(this).scrollTop();

          if (top > this.scrollPos) {
            self.scrolledDown();
          } else if (top < this.scrollPos) {
            self.scrolledUp();
          }

          this.scrollPos = top;
        });
      };

      $(document).bind('touchmove', onScroll);
    },
    unbindScrolling: function () {
      $(document).unbind('touchmove');
    },
    didInsertElement: function () {
      this.bindScrolling();
    },
    willRemoveElement: function () {
      this.unbindScrolling();
    },
    scrolledDown: function () {
      $('.schedule-transport-alert-mobile').css('margin-bottom', -150);
    },
    scrolledUp: function () {
      $('.schedule-transport-alert-mobile').css('margin-bottom', 0);
    },
    transportToHandle: Ember.computed('warehouseStepToHandle.transport', function () {
      return this.get('warehouseStepToHandle.transport');
    }),
    canDiscardAdviceRestrictions: Ember.computed('warehouseStepToHandle.transport.transportType', function () {
      const transportTypeId = this.get('warehouseStepToHandle.transport.transportType.id');
      return this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId);
    }),
    combinedTransportsToHandle: Ember.computed('warehouseStepToHandle.transport', function () {
      return this.get('relationsCombinerService.combinedTransports');
    }),
    combinedTransportsToHandleLabel: Ember.computed('combinedTransportsToHandle', function () {
      return this.get('relationsCombinerService.combinedTransportsLabel');
    }),
    actions: {
      cancelTransportCreation() {
        this.set('actionName', 'cancelTransportCreation');
        this.sendAction('actionName');
      }

    }
  });

  _exports.default = _default;
});