define("apollo/services/reset-password-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hasToResetPassword(currentUser) {
      return !currentUser.get('resetPasswordIsDisabled') && new Date(currentUser.get('resetPasswordDate')) < new Date();
    }

  });

  _exports.default = _default;
});