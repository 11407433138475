define("apollo/pods/transport-types/settings/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_MANAGE_TRANSPORT_TYPE'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const transportType = this.store.peekRecord('transport-type', params.id);
      return Ember.RSVP.hash({
        transportType,
        defaultLoadCompany: transportType.get('defaultLoadCompanyId') ? this.store.findRecord('company', transportType.get('defaultLoadCompanyId')) : null,
        defaultUnloadCompany: transportType.get('defaultUnloadCompanyId') ? this.store.findRecord('company', transportType.get('defaultUnloadCompanyId')) : null,
        defaultServicemanCompany: transportType.get('defaultServicemanCompanyId') ? this.store.findRecord('company', transportType.get('defaultServicemanCompanyId')) : null
      });
    }
  });

  _exports.default = _default;
});