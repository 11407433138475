define("apollo/pods/components/tax-id-number-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showIsoSelect: Ember.computed('company.country.id', function () {
      return !this.get('company.id') && !this.get('disabled') && this.get('company.country.alternativeIsoCodes.length') > 1;
    }),
    setTaxIdIsoNumberObserver: Ember.observer('company.country.id', function () {
      if (this.get('company.country.alternativeIsoCodes.length') === 1) {
        this.set('company.taxIdIsoNumber', this.get('company.country.alternativeIsoCodes.firstObject'));
      } else {
        this.set('company.taxIdIsoNumber', null);
      }
    }),
    actions: {
      verifyNip: async function () {
        await this.verifyNip();
      },

      changeCountry(countryIso) {
        this.set('company.taxIdIsoNumber', countryIso);
        this.send('verifyNip');
      }

    }
  });

  _exports.default = _default;
});