define("apollo/pods/transaction/modals/courier-errors/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    activeServiceList: [],
    errorCodeList: Ember.computed('model.transport', function () {
      const error = this.get("model.transport.courierOrderError.content");
      const code = error.get("errorCode").split(";");
      const msg = error.get("errorMessage").split(";");
      const map = new Map();
      let index = 0;
      code.forEach(it => {
        map.set(it, msg[index]);
        index++;
      });
      return map;
    }),
    title: Ember.computed('', function () {
      return this.get('intl').t("common.errorList");
    }),
    actions: {
      close: function () {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});