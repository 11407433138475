define("apollo/pods/vehicle-main-section/model", ["exports", "ember-data", "apollo/pods/section/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend({
    truck: belongsTo('truck', {
      async: true,
      deepEmbedded: ['owner']
    }),
    brand: attr('string'),
    truckType: belongsTo('truckType', {
      async: true
    }),
    subcontractor: attr('string'),
    serialNumberOfRecorder: attr('string'),
    validations: {
      truck: {
        relations: ['belongsTo']
      },
      brand: {
        presence: {
          message: 'blank'
        }
      },
      truckType: {
        custom: {
          validation: function (key, value) {
            return !!value.get('id');
          },
          message: 'blank'
        }
      },
      subcontractor: {
        presence: {
          message: 'blank'
        }
      },
      serialNumberOfRecorder: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});