define("apollo/pods/warehouses/add-ramp/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend({
    sessionAccount: service('session-account'),

    model(params) {
      const warehouseId = params.warehouse_id;
      const warehouseZoneId = params.warehouse_zone_id;
      let maxPallet = null;

      if (this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_MAX_PALLET_PER_RAMP') === 'true') {
        maxPallet = this.store.createRecord('maxPallet', {
          quantity: 0
        });
      }

      const ramp = this.store.createRecord('ramp', {
        enabled: true,
        maxPallet
      });
      const roleGroups = this.get('sessionAccount.currentUser.company.roleGroups');
      return Ember.RSVP.hash({
        warehouse: this.store.findRecord('warehouse', warehouseId),
        warehouseZone: this.store.findRecord('warehouseZone', warehouseZoneId),
        ramp,
        roleGroups
      });
    },

    renderTemplate: function () {
      this.render('ramps.create');
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      const ramp = model.ramp;
      const roleGroups = model.roleGroups;
      ramp.setProperties({
        warehouse: model.warehouse,
        warehouseZone: model.warehouseZone,
        idx: model.warehouse.get('enabledRamps.length'),
        roleGroups
      });
      this.controllerFor('ramps.create').setProperties({
        model: ramp
      });
    }
  });

  _exports.default = _default;
});