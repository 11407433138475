define("apollo/pods/contractors-group/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    documentValidations: hasMany('documentValidation', {
      async: true
    }),
    principalCompany: belongsTo('company', {
      async: true
    }),
    groupType: belongsTo('contractorsGroupType', {
      async: true
    }),
    name: attr('string'),
    nameEn: attr('string'),
    shortName: attr('string'),
    shortNameEn: attr('string'),
    activeWhenCountryIn: attr('string'),
    priority: attr('number'),
    isDefault: attr('boolean'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      }
    },
    nameInAppropriateLanguage: Ember.computed('name', 'nameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('name', 'nameEn');
      return this.get(propertyName);
    }).readOnly(),
    shortNameInAppropriateLanguage: Ember.computed('nameInAppropriateLanguage', 'shortName', 'shortNameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('shortName', 'shortNameEn');
      return this.get(propertyName) || this.get('nameInAppropriateLanguage');
    }).readOnly(),
    obligatoryDocuments: Ember.computed('documentValidations.[]', function () {
      return this.get('documentValidations').filterBy('obligatory', true);
    }),
    optionalDocuments: Ember.computed('documentValidations.[]', function () {
      return this.get('documentValidations').filterBy('obligatory', false);
    }),
    idsOfObligatoryDocumentsTypes: Ember.computed('obligatoryDocuments.[]', function () {
      return this.get('obligatoryDocuments').map(d => d.get('documentType.id'));
    }).readOnly(),
    idsOfOptionalDocumentsTypes: Ember.computed('optionalDocuments.[]', function () {
      return this.get('optionalDocuments').map(d => d.get('documentType.id'));
    }).readOnly(),
    obligatoryDocumentsNames: Ember.computed('obligatoryDocuments.[]', function () {
      const self = this;
      return this.get('obligatoryDocuments').map(d => self.get('intl').t('sectionType.' + d.get('documentType.name'))).join(', ');
    }).readOnly(),
    optionalDocumentsNames: Ember.computed('optionalDocuments.[]', function () {
      const self = this;
      return this.get('optionalDocuments').map(d => self.get('intl').t('sectionType.' + d.get('documentType.name'))).join(', ');
    }).readOnly(),
    shouldHaveTheObligatoryDocuments: Ember.computed('obligatoryDocuments', function () {
      return this.get('obligatoryDocuments.length') > 0;
    }).readOnly(),
    shouldHaveTheOptionalDocuments: Ember.computed('optionalDocuments', function () {
      return this.get('optionalDocuments.length') > 0;
    }).readOnly(),
    hasErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length') > 0;
    }).readOnly(),

    /**
     * Określa, czy grupa powinna być widoczna, ale zablokowana do edycji
     */
    shouldBeDisabled: function (partnership) {
      if (!this.get('activeWhenCountryIn')) {
        return false;
      }

      const companyCountry = partnership.get('contractorCompany.country.ibanSymbol');
      return !companyCountry || this.get('activeWhenCountryIn').search(companyCountry) === -1;
    }
  });

  _exports.default = _default;
});