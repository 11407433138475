define("apollo/pods/custom-field-option/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    definition: _emberData.default.belongsTo('customFieldDefinition', {
      async: true,
      inverse: 'options'
    }),
    relatedDefinition: _emberData.default.belongsTo('customFieldDefinition', {
      async: true,
      inverse: null
    }),
    relatedOptions: _emberData.default.hasMany('customFieldOption'),
    name: _emberData.default.attr('string'),
    englishName: _emberData.default.attr('string'),
    transportPanel: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      }
    },
    nameInAppropriateLanguage: function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('name', 'englishName');
      return this.get(propertyName);
    }.property('name', 'englishName'),
    numberOfErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length');
    }),
    allowsDiscrepancyReport: Ember.computed('transportPanel', function () {
      return this.get('transportPanel') === 'DISCREPANCY_REPORT';
    }),
    allowsInspectionPanel: Ember.computed('transportPanel', function () {
      return this.get('transportPanel') === 'INSPECTION';
    })
  });

  _exports.default = _default;
});