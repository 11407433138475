define("apollo/pods/components/custom-popover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    destroyPopovers() {
      this.set('isOpened', false);
      $('.popover').each(function () {
        $(this).popover('destroy');
      });
    },

    showPopover(element, class1, class2) {
      this.destroyPopovers();
      const $el = $(element);
      this.set('isOpened', true); // popover('destroy') wykonuje się asynchronicznie, co jest trochę zaskakujące,
      // i czasem nie zdążył się wykonać, gdy próbowaliśmy utworzyć nowy popover;
      // na razie wydaje mi się, że rozwiązanie z setTimeout jest faktycznie najlepsze
      // http://stackoverflow.com/a/27345242/2224598

      setTimeout(function () {
        const $popover = $el.find(class1).popover({
          html: true,
          content: function () {
            return $el.find(class2).html();
          }
        });
        $el.find(class1).popover("show");

        if ($popover.find(".window-details").length) {
          //dostosowanie położenie elementu dla szczegółów okien czasowych
          const $popover = $('.popover');
          const $arrow = $('.arrow');

          if ($popover.position()) {
            $popover.css('left', $popover.position().left + $arrow.position().left - $popover.outerWidth(true) / 2);
            $arrow.css('left', 200);
          }
        }
      }, 50);
    }

  });

  _exports.default = _default;
});