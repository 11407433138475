define("apollo/pods/components/auction/create-auction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    DEFAULT_AUCTION_FINISH_DELAY_INTERVAL: '2h',
    finishDelayInterval: Ember.computed('', function () {
      const auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
      return auctionSetting ? auctionSetting.get('defaultAuctionFinishDelayInterval') : this.DEFAULT_AUCTION_FINISH_DELAY_INTERVAL;
    }),
    suggestedFinishTime: Ember.computed('finishDelayInterval', function () {
      const delayInterval = this.get('finishDelayInterval');
      const intervalValue = delayInterval.substring(0, delayInterval.length - 1);
      const intervalUnit = delayInterval.substring(delayInterval.length - 1);
      return moment().add(intervalValue, intervalUnit).toDate();
    }),
    actions: {
      showAuctionCreationForm() {
        const self = this;
        const suggestedFinishTime = this.get('suggestedFinishTime');
        const principalCompanyId = localStorage.getItem('principalId');
        const auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
        const symbol = auctionSetting ? auctionSetting.get('defaultCurrency') : 'PLN';
        const carriersGroups = this.get('store').peekAll('contractors-group');
        const currency = this.get('store').peekAll('currency').filterBy('symbol', symbol).get('firstObject');
        this.set('action', 'showModal');
        self.get('store').query('company', {
          principalCompanyId,
          partnershipTypesNames: ['CARRIER', 'OPERATOR'],
          statuses: ['NO_INVITATION', 'ACCEPTED', 'UPDATED', 'BLOCKED'],
          transportId: self.get('transport.id')
        }).then(carriers => {
          const auctionModel = this.get('store').createRecord('auction', {
            currency,
            transport: this.get('transport'),
            plannedFinishTime: suggestedFinishTime,
            carriers,
            carriersGroups
          });
          self.sendAction('action', 'auctions.modals.auction-form', auctionModel);
        });
      }

    }
  });

  _exports.default = _default;
});