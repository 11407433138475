define("apollo/pods/transports/modals/warehouses-to-excel/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    selectedWarehouses: Ember.A([]),
    warehouses: Ember.computed('', function () {
      return this.get('model.warehouses');
    }),
    _downloadRegistryOfPersonsXls: function () {
      const token = this.get('sessionAccount.token');
      const selectedWarehouses = this.get('selectedWarehouses');
      const warehousesAsString = selectedWarehouses.map(w => w.get('id')).join(',');
      const url = _environment.default.serverURL + '/reports/personsRegistry?token=' + token + '&warehousesId=' + warehousesAsString;
      window.open(url, '_blank');
      return this.send('hideModal');
    },
    actions: {
      downloadRegistryOfPersonsXls() {
        Ember.run.once(this, '_downloadRegistryOfPersonsXls');
      },

      cancel() {
        this.set('selectedWarehouses', Ember.A([]));
      }

    }
  });

  _exports.default = _default;
});