define("apollo/pods/components/expandable-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0B04h5HV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[12,\"data-component-name\",[28,[[21,\"componentName\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel-heading clickable\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"togglePanel\"],null]],[9],[0,\"\\n            \"],[7,\"h5\"],[12,\"class\",[28,[[27,\"concat\",[\"panel-title \",[23,[\"titleClass\"]]],null],\" \",[21,\"showErrors\"]]]],[12,\"data-object-id\",[28,[[21,\"objectId\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"customIconClass\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"i\"],[12,\"class\",[28,[[21,\"customIconClass\"],\" margin-right-small\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n                \"],[1,[21,\"title\"],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showNumberOfElements\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"span\"],[9],[0,\" (\"],[1,[21,\"numberOfElements\"],false],[0,\")\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"additionalTitle\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"span\"],[12,\"class\",[27,\"concat\",[\"pull-right \",[23,[\"additionalTitleClass\"]]],null]],[9],[1,[21,\"additionalTitle\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"panel-body small-padding\"],[9],[0,\"\\n                \"],[14,1],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/expandable-panel/template.hbs"
    }
  });

  _exports.default = _default;
});