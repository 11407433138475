define("apollo/services/transport-type-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    async getDeliverySettings(transportType) {
      const transportTypeId = transportType.get('id');

      if (!transportTypeId) {
        return;
      }

      return await this.get('store').queryRecord('delivery-setting', {
        transportTypeId
      });
    }

  });

  _exports.default = _default;
});