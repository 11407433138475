define("apollo/pods/components/transports/search-transports/scan-barcode-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    errors: Ember.A(),

    init() {
      this._super(...arguments);

      this.set('errors', Ember.A([]));
    },

    title: Ember.computed(function () {
      return this.get('intl').t('table.scanBarcode');
    }),
    actions: {
      cancel() {
        this.reset();
      },

      searchTransport(searchText) {
        console.debug('Zeskanowano barcode, wyszukujemy transport :: ' + searchText);
        this.set('model.searchParams.query', searchText);
        this.set('model.searchComponent.queryProxy', searchText);

        this.get('model.searchComponent')._setParamsOnce();

        this.reset();
      }

    },

    reset() {
      this.set('errors', Ember.A([]));
      this.send('hideModal');
    }

  });

  _exports.default = _default;
});