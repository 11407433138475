define("apollo/pods/components/advice-button/create-advice-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htlZMYf2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"btn btn-sm margin-bottom \",[27,\"concat\",[\"advice-button-\",[23,[\"transport\",\"id\"]]],null]]]],[12,\"data-loading-text\",[28,[[27,\"t\",[\"common.pleaseWait\"],null]]]],[12,\"style\",[28,[[23,[\"transport\",\"backgroundColorStyle\"]],\" color: white\"]]],[12,\"disabled\",[27,\"if\",[[23,[\"disabled\"]],[23,[\"disabled\"]],false],null]],[12,\"title\",[21,\"title\"]],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"transport.advice\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"handleCreatingAdvice\",[23,[\"transport\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/advice-button/create-advice-button/template.hbs"
    }
  });

  _exports.default = _default;
});