define("apollo/helpers/setting-translation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    translationService: Ember.inject.service('translation-service'),

    compute(params, nameArgs) {
      const settingClass = nameArgs.settingClass;
      const labelName = nameArgs.labelName;
      const transportType = nameArgs.transportType;
      return this.get('translationService').getTranslation(settingClass, labelName, transportType);
    }

  });

  _exports.default = _default;
});