define("apollo/pods/components/planning/delivery-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCBYDJwg",
    "block": "{\"symbols\":[\"mt\",\"table\",\"body\",\"delivery\",\"header\"],\"statements\":[[4,\"server-table\",null,[[\"data\",\"columns\"],[[23,[\"deliveries\"]],[23,[\"columns\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"borderless-wrapper\"],[9],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"table\"]],\"expected `mt.table` to be a contextual component but found a string. Did you mean `(component mt.table)`? ('apollo/pods/components/planning/delivery-table/template.hbs' @ L5:C11) \"],null]],null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,2,[\"header\"]],\"expected `table.header` to be a contextual component but found a string. Did you mean `(component table.header)`? ('apollo/pods/components/planning/delivery-table/template.hbs' @ L6:C15) \"],null]],[[\"class\"],[\"planning-delivery-table-header\"]],{\"statements\":[[0,\"                \"],[1,[22,5,[\"row-sorting\"]],false],[0,\"\\n                \"],[1,[22,5,[\"row-filtering\"]],false],[0,\"\\n\"]],\"parameters\":[5]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,2,[\"body\"]],\"expected `table.body` to be a contextual component but found a string. Did you mean `(component table.body)`? ('apollo/pods/components/planning/delivery-table/template.hbs' @ L10:C15) \"],null]],null,{\"statements\":[[4,\"each\",[[22,3,[\"visibleContent\"]]],null,{\"statements\":[[0,\"                    \"],[1,[27,\"planning/delivery-table/delivery-row\",null,[[\"delivery\",\"customFieldsList\",\"transportType\"],[[22,4,[]],[23,[\"customFieldsList\"]],[23,[\"transportType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/planning/delivery-table/template.hbs"
    }
  });

  _exports.default = _default;
});