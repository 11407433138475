define("apollo/pods/components/synchronise-carrier-data-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3qT7RoKB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-button-name\",\"synchronise-carriers-data-button\"],[12,\"data-loading-text\",[28,[[27,\"t\",[\"common.pleaseWait\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"common.synchroniseCarriersData\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"synchroniseCarriersData\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/synchronise-carrier-data-button/template.hbs"
    }
  });

  _exports.default = _default;
});