define("apollo/pods/transport-types/delivery-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    deliverySetting: Ember.computed('model.deliverySetting', function () {
      return this.get('model.deliverySetting');
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    actions: {
      save() {
        this.set('errors', []);
        const deliverySetting = this.get('deliverySetting');
        deliverySetting.set('transportType', this.get('transportType'));

        if (!deliverySetting.validate()) {
          return;
        }

        deliverySetting.save().then(() => {
          this.send('refreshModel');
          const msg = this.get('intl').t('common.saveSuccessMessage');
          this.set('successMessage', msg);
          jQuery('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(response => {
          this.set('errors', response.errors);
          jQuery('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      },

      create() {
        if (this.get('deliverySetting')) {
          return;
        }

        const transportType = this.get('transportType');
        this.set('deliverySetting', this.get('store').createRecord('deliverySetting', {
          transportType
        }));
      },

      delete() {
        this.set('errors', []);
        this.get('deliverySetting').destroyRecord().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      cancel() {
        this.get('deliverySetting').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      }

    }
  });

  _exports.default = _default;
});