define("apollo/helpers/warehouse-net-weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, nameArgs) {
      const warehouse = nameArgs.warehouse;
      const weightsArgs = nameArgs.weights;

      if (!warehouse || !weightsArgs) {
        return 'NaN';
      }

      const weights = weightsArgs.filter(w => w.get('warehouse.id') === warehouse.get('id'));

      if (weights.length < 2) {
        return 'NaN';
      } else {
        const firstWeight = weights.shift();
        return Math.abs(firstWeight.get('readOut') - weights.getEach('readOut').reduce((a, b) => a + b, 0));
      }
    }

  });

  _exports.default = _default;
});