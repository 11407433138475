define("apollo/services/polling/notification-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    websocket: Ember.inject.service('websocket'),
    sessionAccount: Ember.inject.service('session-account'),
    sentNotificationService: Ember.inject.service(),
    store: Ember.inject.service(),

    useSockets() {
      console.debug('Creating socket for notifications...');
      const webSocket = this.get('websocket').getSocket();
      const userId = this.get('sessionAccount.currentUser.id');

      if (webSocket && webSocket.get('connected')) {
        console.log('Subscribing to notify topic');
        webSocket.subscribe('/topic/notify/' + userId, payload => {
          const responseBody = JSON.parse(payload.body);
          this.store.pushPayload({
            'sentNotification': responseBody
          });
          this.sentNotificationService.addToTotalItems(1);
        });
      } else {
        setTimeout(() => {
          this.useSockets();
        }, 1500);
      }
    }

  });

  _exports.default = _default;
});