define("apollo/pods/authorities/others/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    activeTab: Ember.computed(function () {
      return this.get('sessionAccount').hasRole("ROLE_MANAGE_USER_ROLE_INVITATIONS") ? 'INVITATION_SETTINGS' : 'NOTIFICATION_SETTINGS';
    }),
    allNotifications: Ember.computed('model.allNotifications.[]', function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('model.allNotifications').filter(n => n.get('principalCompany.id') === principalCompanyId || !n.get('principalCompany.id')).uniqBy('id');
    }),
    proxiedNotifications: Ember.computed('allNotifications', 'model.roleGroupNotifications', function () {
      return this.get('allNotifications').map(n => {
        return Ember.ObjectProxy.create({
          content: n,
          checked: this.get('model.roleGroupNotifications').getEach('id').includes(n.get('id'))
        });
      });
    }),
    actions: {
      setTab(tab) {
        this.set('activeTab', tab);
      }

    }
  });

  _exports.default = _default;
});