define("apollo/pods/ramp/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const attr = _emberData.default.attr;
  const hasMany = _emberData.default.hasMany;
  const belongsTo = _emberData.default.belongsTo;
  const {
    service
  } = Ember.inject;

  var _default = _model.default.extend(_modelValidator.default, {
    sessionAccount: service('session-account'),
    windows: hasMany('timeWindow', {
      async: true
    }),
    warehouse: belongsTo('warehouse', {
      async: true
    }),
    usersWithAccess: hasMany('user', {
      async: true
    }),
    users: hasMany('user', {
      async: true
    }),
    contactPerson: belongsTo('user', {
      async: true
    }),
    warehouseZone: belongsTo('warehouseZone', {
      async: true
    }),
    maxPallet: belongsTo('maxPallet', {
      async: true
    }),
    name: attr('string'),
    shortName: attr('string'),
    idx: attr('number'),
    contactPhoneNumber: attr('string'),
    enabled: attr('boolean'),
    deleteDedicatedWindows: attr('boolean'),
    additionalSmsNotificationInfoPl: attr('string'),
    additionalSmsNotificationInfoEn: attr('string'),
    defaultRoleGroups: attr(),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      warehouseZone: {
        presence: {
          message: 'blank'
        }
      },
      shortName: {
        presence: {
          message: 'blank'
        },
        custom: {
          validation: function (key, value) {
            return !value || value.length <= 10;
          },
          message: 'sizeTooBig'
        }
      },
      idx: {
        numericality: {
          greaterThanOrEqualTo: 0,
          onlyInteger: true,
          message: 'nonPositiveInteger'
        },
        custom: {
          validation: function (key, value, model) {
            return parseInt(value) < model.get('warehouse').get('ramps.length');
          },
          message: 'idxTooBig'
        }
      },
      maxPallet: {
        custom: {
          validation: function (key, value, model) {
            if (!model.get('hasAccessToMaxPallet')) {
              return true;
            }

            return !!model.get('maxPallet.quantity') || model.get('maxPallet.quantity').toString().match(/^[0-9]+$/);
          },
          message: 'positiveNumber'
        }
      }
    },
    orderInWarehouse: function () {
      return this.get('idx') + 1;
    }.property('idx'),
    setIdx: function () {
      if (this.get('isDeleted')) {
        return;
      }

      const orderInWarehouse = this.get('orderInWarehouse');
      this.set('idx', parseInt(orderInWarehouse) - 1);
    }.observes('orderInWarehouse'),
    hasAccessToMaxPallet: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_MAX_PALLET_PER_RAMP') === 'true';
    })
  });

  _exports.default = _default;
});