define("apollo/services/aggregated-authority-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    AGGREGATED_AUTHORITIES_ENDPOINT: '/aggregated-authorities',
    // TODO: Zastąpić przez apolloApiService
    ajaxService: Ember.inject.service('ajax-service'),

    fetch(authorityGroupId, transportTypeId) {
      const params = {
        authorityGroupId,
        transportTypeId
      };
      return this.ajaxService.send(this.AGGREGATED_AUTHORITIES_ENDPOINT, 'GET', params).then(response => {
        return response;
      });
    },

    send(aggregatedAuthoritiesToSave, aggregatedAuthoritiesToRemove) {
      const data = {
        toRemove: aggregatedAuthoritiesToRemove,
        toSave: aggregatedAuthoritiesToSave
      };
      return this.ajaxService.send(this.AGGREGATED_AUTHORITIES_ENDPOINT, 'POST', data, true);
    }

  });

  _exports.default = _default;
});