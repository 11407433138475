define("apollo/pods/announcements/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ["ROLE_MANAGE_ANNOUNCEMENTS"],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const announcement = this.store.createRecord('announcement', {
        dateFrom: moment().startOf('day').toDate(),
        dateTo: moment().add(1, 'month').endOf('day').toDate(),
        location: "ANNOUNCEMENTS_TAB",
        range: "ROLE_GROUP"
      });
      return Ember.RSVP.hash({
        announcement: announcement,
        roleGroups: this.store.peekAll('roleGroup')
      });
    }
  });

  _exports.default = _default;
});