define("apollo/pods/components/transports/transports-table/transport-sorter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NkDiuDOD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"has-role\",[\"ROLE_SORT_TRANSPORT_TABLE\"],null]],null,{\"statements\":[[4,\"unless\",[[23,[\"archive\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[12,\"class\",[21,\"icon\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-sorter/template.hbs"
    }
  });

  _exports.default = _default;
});