define("apollo/pods/auth/activate-account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      hash: {
        refreshModel: true
      }
    },
    model: function (params) {
      return Ember.RSVP.hash({
        hash: params.hash
      });
    },
    renderTemplate: function (controller, model) {
      this.render('auth/activate-account', {
        into: 'application',
        outlet: 'login',
        controller: controller,
        model: model
      });
    }
  });

  _exports.default = _default;
});