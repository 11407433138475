define("apollo/pods/companies/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_COMPANIES_TAB', 'ROLE_OWN_COMPANY_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const companyId = params.company_id;
      const currentUserCompanyId = Number(this.get('sessionAccount.currentUser.company.id'));
      const principalCompanyId = localStorage.getItem('principalId');

      if (Number(companyId) !== currentUserCompanyId && !this.get('sessionAccount').hasRole('ROLE_COMPANIES_TAB')) {
        // użytkownik próbuje podejrzeć cudzą firmę, mimo braku uprawnień
        this.transitionTo('forbidden');
        return;
      }

      return Ember.RSVP.hash({
        company: this.store.findRecord('company', companyId),
        principalContractorsGroupType: this.get('store').query('principal-contractors-group-type', {
          principalCompany: principalCompanyId
        })
      });
    },

    afterModel(model) {
      if (model.company.get('isAuxiliaryPartner')) {
        console.log(`Company ${model.company.get('shortName')} is a partner of one of contractors. Edition is disabled -> redirecting to list of companies.`);
        this.transitionTo('companies.index');
      }
    },

    activate() {
      this.activateCurrentTab('OWN_COMPANY_TAB');
    },

    actions: {
      willTransition() {
        this.get('controller').setProperties({
          errors: null,
          successMessage: null
        });
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});