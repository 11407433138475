define("apollo/services/checkpoint-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    checkpointApiService: Ember.inject.service('api.checkpoint-api-service'),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    dateUtils: Ember.inject.service('date-utils'),
    intl: Ember.inject.service(),

    async confirmAndRefresh(checkpoint, prevalidate) {
      const checkpointId = checkpoint.get('id');
      const executionDate = checkpoint.get('executionDate');
      const checkpointApiService = this.get('checkpointApiService');
      const confirmCheckpointResponse = await checkpointApiService.confirmCheckpoint(checkpoint, prevalidate);

      if (!confirmCheckpointResponse.ok) {
        return confirmCheckpointResponse;
      } // Odświeżamy dane statusu oraz powiązanych transportów
      // TODO: Refaktor - przenieść do osobnych serwisów


      const fetchedRefreshedCheckpoint = await this.get('store').findRecord('checkpoint', checkpointId);
      const fetchedRefreshedTransport = await fetchedRefreshedCheckpoint.get('transport');
      fetchedRefreshedTransport.get('transportType').set('forcedPollRequestTime', new Date().getTime());

      if (fetchedRefreshedTransport.get('isCombinedTransport')) {
        const combinedTransports = await fetchedRefreshedTransport.get('allCombinedTransports');
        const combinedTransportsTransportTypes = combinedTransports.map(t => t.get('transportType'));
        combinedTransportsTransportTypes.forEach(tt => {
          if (tt) {
            tt.set('forcedPollRequestTime', new Date().getTime());
          }
        });
      }

      this.showPunctualityAlert(checkpoint, executionDate);
      return confirmCheckpointResponse;
    },

    reject(checkpoint) {
      const checkpointApiService = this.get('checkpointApiService');
      return checkpointApiService.rejectCheckpoint(checkpoint);
    },

    showPunctualityAlert(checkpoint, executionDate) {
      const punctualityAlert = this.findPunctualityAlert(checkpoint, executionDate || new Date());

      if (punctualityAlert) {
        alert(punctualityAlert);
      }
    },

    findPunctualityAlert(checkpoint, executionDate) {
      const interval = this.get('sessionAccount').getSettingValue('DRIVER_PUNCTUALITY_INTERVAL');
      const showAlert = checkpoint ? checkpoint.get('task.showPunctualityAlertAfterConfirmation') : null;
      const timeWindowStart = checkpoint ? checkpoint.get('step.timeWindow.start') : null;

      if (!interval || !showAlert || !timeWindowStart) {
        return;
      }

      const beforeStart = this.get('dateUtils').calculateDateFromInterval(new Date(executionDate), interval);
      const afterStart = this.get('dateUtils').calculateDateFromInterval(new Date(executionDate), `-${interval}`);

      if (beforeStart < timeWindowStart) {
        return this.get('intl').t('checkpoints.confirmBeforeTime');
      } else if (afterStart > timeWindowStart) {
        return this.get('intl').t('checkpoints.confirmAfterTime');
      } else {
        return null;
      }
    }

  });

  _exports.default = _default;
});