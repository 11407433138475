define("apollo/pods/delivery-setting/adapter", ["exports", "apollo/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      if (query.index) {
        return `${this.urlPrefix()}/delivery-settings/index`;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});