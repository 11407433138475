define("apollo/pods/invitations/handle/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    beforeModel: function () {
      if (this.get('session.isAuthenticated')) {
        const invitationId = this.get('sessionAccount.currentUser.invitation.id');
        this.transitionTo('invitations.show', invitationId);
      }
    },
    model: function (params) {
      let invitation = this.store.queryRecord("invitation", {
        token: params.token
      });
      let countries = this.store.findAll('country');
      let company = this.store.createRecord("company", {
        enabled: true,
        pricingPlan: 'BASIC'
      });
      let user = this.store.createRecord("user", {
        invitation: invitation
      });
      return Ember.RSVP.hash({
        token: params.token,
        invitation: invitation,
        countries: countries,
        user: user,
        company: company
      });
    },
    setupController: function (controller, model) {
      model.company.set('country', model.invitation.get('country'));
      model.company.set('taxIdIsoNumber', model.invitation.get('country.ibanSymbol'));
    },
    renderTemplate: function (controller, model) {
      this.render('invitations/handle', {
        into: 'application',
        outlet: 'unauthenticated',
        controller: controller,
        model: model
      });
    },
    actions: {
      error: function (reason) {
        if (reason.status === 404) {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});