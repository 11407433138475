define("apollo/pods/components/transport/time-window-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    hasWindowStartErrors: Ember.computed('step.{errors.minAdviceDate,timeWindow.errors.start}', function () {
      return this.get('step.timeWindow.errors.start') || this.get('step.errors.minAdviceDate');
    }),
    hasWindowStopErrors: Ember.computed('step.{step.errors.maxAdviceDate,timeWindow.errors.stop}', function () {
      return this.get('step.timeWindow.errors.stop') || this.get('step.errors.maxAdviceDate');
    }),
    timeWindowOffsetInterval: Ember.computed('step.warehouse.id', function () {
      return this.get('step.warehouse.timeWindowOffsetInterval') ? this.get('step.warehouse.timeWindowOffsetInterval') : 15;
    }),
    startCanBeUpdated: Ember.computed('step.transport', function () {
      const transport = this.get('step.transport');

      if (transport.get('periodicTransport.id')) {
        return false;
      }

      return transport.get('content') && transport.get('content').actionCanBePerformed('MOVE_ADVICE_WINDOW');
    }),
    stopCanBeUpdated: Ember.computed('step.transport', function () {
      const transport = this.get('step.transport');

      if (transport.get('periodicTransport.id')) {
        return false;
      }

      const canMoveWindow = transport.get('content') && transport.get('content').actionCanBePerformed('MOVE_ADVICE_WINDOW');
      const canResizeWindow = transport.get('content') && transport.get('content').actionCanBePerformed('RESIZE_ADVICE_WINDOW');
      return canMoveWindow || canResizeWindow;
    }),
    actions: {
      setTimeWindowStart(timeWindow, dateValues) {
        Ember.run.next(() => {
          const minAdviceDate = this.get('step.minAdviceDate');
          const maxAdviceDate = this.get('step.maxAdviceDate');

          if (minAdviceDate && dateValues[0] < minAdviceDate) {
            timeWindow.set('start', minAdviceDate);
          } else if (maxAdviceDate && dateValues[0] > maxAdviceDate) {
            timeWindow.set('start', maxAdviceDate);
          } else {
            if (!timeWindow.get('initialStart')) {
              timeWindow.set('initialStart', timeWindow.get('start'));
            }

            timeWindow.set('start', dateValues[0]);
          }
        });
      },

      setTimeWindowStop(timeWindow, dateValues) {
        Ember.run.next(() => {
          const minAdviceDate = this.get('step.minAdviceDate');
          const maxAdviceDate = this.get('step.maxAdviceDate');

          if (minAdviceDate && dateValues[0] < minAdviceDate) {
            timeWindow.set('stop', minAdviceDate);
          } else if (maxAdviceDate && dateValues[0] > maxAdviceDate) {
            timeWindow.set('stop', maxAdviceDate);
          } else {
            timeWindow.set('stop', dateValues[0]);
          }
        });
      },

      setAdviceRange(timeWindow, dateValues, flatpickrInstance) {
        Ember.run.next(() => {
          if (this.get('step.minAdviceDate')) {
            flatpickrInstance.set('minDate', this.get('step.minAdviceDate'));
          }

          if (this.get('step.maxAdviceDate')) {
            flatpickrInstance.set('maxDate', this.get('step.maxAdviceDate'));
          }
        });
      }

    }
  });

  _exports.default = _default;
});