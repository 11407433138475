define("apollo/utils/objects/AggregatedAuthority", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGroupedAuthorities = getGroupedAuthorities;
  _exports.default = void 0;
  const AggregatedAuthority = Ember.Object.extend({
    authorityId: null,
    authorityName: null,
    roleGroupId: null,
    authorityRestrictionRange: null,
    authorityRestrictionId: null,
    authorityRestrictionExplanation: null,

    fromResponse(response) {
      this.authorityId = response.authorityId;
      this.authorityName = response.authorityName;
      this.roleGroupId = response.roleGroupId;
      this.authorityRestrictionId = response.authorityRestrictionId;
      this.authorityRestrictionRange = response.authorityRestrictionRange;
      this.authorityRestrictionExplanation = response.authorityRestrictionExplanation ? response.authorityRestrictionExplanation.toUpperCase() : null;
      return this;
    },

    toRequest(roleGroupId, authorityId, authorityRestrictionId) {
      this.roleGroupId = roleGroupId;
      this.authorityId = authorityId;
      this.authorityRestrictionId = authorityRestrictionId;
      return this;
    },

    compare(b) {
      return Object.entries(this).toString() === Object.entries(b).toString();
    }

  });
  /**
   *
   * @param {Array<{@link AggregatedAuthority}>} aggregatedAuthorities
   * @returns {Object} groupedByAuthorityNameAndRange
   * @returns {Object} groupedByAuthorityNameAndRange.AUTHORITY_NAME
   * @returns {string} groupedByAuthorityNameAndRange['AUTHORITY_NAME']['AUTHORITY_RESTRICTION_RANGE']
   * @returns {Array<{@link AggregatedAuthority}>} groupedByAuthorityNameAndRange['AUTHORITY_NAME']['AUTHORITY_RESTRICTION_RANGE'][0]
   */

  function getGroupedAuthorities(aggregatedAuthorities) {
    const groupedByAuthorityNameAndRange = Object.groupBy(aggregatedAuthorities, ({
      authorityName
    }) => authorityName);

    for (const prop in groupedByAuthorityNameAndRange) {
      groupedByAuthorityNameAndRange[prop] = Object.groupBy(groupedByAuthorityNameAndRange[prop], ({
        authorityRestrictionRange,
        authorityRestrictionExplanation
      }) => {
        if (authorityRestrictionRange === 'CHECKPOINT_BASED') {
          return `${authorityRestrictionRange}_${authorityRestrictionExplanation.replaceAll(' ', '_').replaceAll('.', '')}`;
        }

        return authorityRestrictionRange;
      });
    }

    return groupedByAuthorityNameAndRange;
  }

  var _default = AggregatedAuthority;
  _exports.default = _default;
});