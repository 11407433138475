define("apollo/pods/components/ramp-schedule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ramp-header'],
    classNameBindings: ['isZoneIdxEven:even'],
    isZoneIdxEven: function () {
      return this.get('zoneIdx') % 2 === 0;
    }.property('zoneIdx'),
    attributeBindings: ['data-schedule-ramp', 'data-zone-name', 'data-ramp-short-name', 'style'],
    'data-schedule-ramp': function () {
      return "" + this.ramp.id;
    }.property('ramp.id'),
    'data-zone-name': function () {
      return this.get('ramp.warehouseZone.name');
    }.property('ramp.warehouseZone.name'),
    'data-ramp-short-name': function () {
      return this.get('ramp.shortName');
    }.property('ramp.shortName'),
    style: function () {
      return `width: ${this.get('width')}%`.htmlSafe();
    }.property('width'),
    moreThan8Columns: function () {
      return this.get('viewedColumns') > 8;
    }.property('viewedColumns'),
    toggleWholeRampWindow: "toggleWholeRampWindow",
    click: function (event) {
      var div = event.target;
      var $div = $(div);
      var date = $div.closest('.day-header').data('schedule-date');
      var time = moment(date, 'YYYY-MM-DD').toDate();
      var rampId = this.ramp.id;
      this.sendAction("toggleWholeRampWindow", time, rampId);
    },
    numberOfPallets: Ember.computed('numberOfPalletsPerRamps', function () {
      const n = this.get('numberOfPalletsPerRamps')[this.get('ramp.id')];
      return n ? n : 0;
    })
  });

  _exports.default = _default;
});