define("apollo/pods/components/companies/registration-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    gusService: Ember.inject.service('gus-service'),
    invitation: Ember.computed('model.invitation', function () {
      return this.get('model.invitation');
    }),
    invitationCompany: Ember.computed('model.invitation.company', function () {
      return this.get('invitation.company');
    }),
    didReceiveAttrs: async function () {
      const self = this;
      this.set('gusHasProblem', false);
      const ibanSymbol = this.get('model.company.country.ibanSymbol');
      const nip = this.get('model.company.taxIdNumber');
      const nipVerificationPromise = this.get('gusService').verifyNip(ibanSymbol, nip);
      nipVerificationPromise.then(response => {
        const existingCompanyName = response.existingCompanyName;
        const gusCompanyName = response.gusCompanyName;
        console.log(`Odpowiedź z GUS: ${response}.`);

        if (existingCompanyName || gusCompanyName) {
          this.set('model.company.name', existingCompanyName != null ? existingCompanyName : gusCompanyName);
          this.set('verifiedByGus', true);
        }
      }).catch(error => {
        console.error(`Problem z połączeniem z GUS: ${error}`);
        self.set('model.company.name', null);
        self.set('verifiedByGus', false);
        self.set('gusHasProblem', true);
      });
    }
  });

  _exports.default = _default;
});