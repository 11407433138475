define("apollo/pods/components/transport/courier/add-packages-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A66TkfHo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-12 text-right\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"form-inline margin-bottom\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n\\n            \"],[7,\"button\"],[11,\"data-button-name\",\"add-package\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[1,[27,\"fa-icon\",[\"plus\"],null],false],[0,\"\\n                \"],[1,[27,\"setting-translation\",null,[[\"settingClass\",\"labelName\",\"transportType\"],[\"courier\",\"add\",[23,[\"transport\",\"transportType\"]]]]],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"addPackage\"]],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"data-field-name\",\"packagesMin\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"transport\",\"errors\",\"courierPackages\"]],\"has-error\"],null]]]],[9],[0,\"\\n                \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"transport\"]],\"courierPackages\"]]],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport/courier/add-packages-section/template.hbs"
    }
  });

  _exports.default = _default;
});