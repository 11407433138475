define("apollo/pods/square-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    translationKey: _emberData.default.attr('string'),
    orderNumberFirstColumnVisibility: _emberData.default.attr('boolean'),
    carRegistrationFirstTableColumnVisibility: _emberData.default.attr('boolean'),
    hdsFirstTableColumnVisibility: _emberData.default.attr('boolean'),
    squareFirstTableColumnVisibility: _emberData.default.attr('boolean'),
    queueFirstTableColumnVisibility: _emberData.default.attr('boolean'),
    squareSecondTableColumnVisibility: _emberData.default.attr('boolean'),
    queueSecondTableColumnVisibility: _emberData.default.attr('boolean'),
    bufferSecondTableColumnVisibility: _emberData.default.attr('boolean'),
    unloadSecondTableColumnVisibility: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});