define("apollo/pods/cmr-setting/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    transportType: _emberData.default.belongsTo('transportType'),
    appendedDocsText: _emberData.default.attr('string'),
    appendedDocsSpecificationNumber: _emberData.default.attr('boolean'),
    appendedDocsSpecificationCustomField: _emberData.default.belongsTo('customFieldDefinition'),
    featuresAndNumbersText: _emberData.default.attr('string'),
    featuresAndNumbersCustomField: _emberData.default.belongsTo('customFieldDefinition'),
    quantityText: _emberData.default.attr('string'),
    quantityCustomField: _emberData.default.belongsTo('customFieldDefinition'),
    packageTypeText: _emberData.default.attr('string'),
    weightGrossCustomField: _emberData.default.belongsTo('customFieldDefinition'),
    senderInstructionText: _emberData.default.attr('string'),
    senderInstructionCustomField: _emberData.default.belongsTo('customFieldDefinition'),
    showPrincipalName: _emberData.default.attr('boolean'),
    showWarehouseName: _emberData.default.attr('boolean'),
    validations: {
      senderInstructionText: {
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            const maxLinesCount = 8;
            const linesCount = value.split('\n').length;
            return linesCount <= maxLinesCount;
          },
          message: 'maxLineCountIs8'
        }]
      }
    }
  });

  _exports.default = _default;
});