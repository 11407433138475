define("apollo/mixins/invitations-mixin", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Mixin.create({
    sessionAccount: service('session-account'),
    actions: {
      accept(id) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + `/invitations/${id}/accept`,
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log(`Invitation ${id} accepted.`);
          self.get('store').findRecord('invitation', id).then(() => {
            self.get('sessionAccount').get('currentUser').reload();
            self.transitionToRoute('invitations.show', id);
          });
        });
      },

      reject(id) {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + `/invitations/${id}/reject`,
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log(`Invitation ${id} rejected.`);
          self.get('store').findRecord('invitation', id).then(() => {
            self.get('sessionAccount').get('currentUser').reload();
            self.transitionToRoute('invitations.show', id);
          });
        });
      },

      delete(invitation) {
        const self = this;
        invitation.destroyRecord().then(() => {
          self.transitionToRoute('invitations');
        });
      }

    }
  });

  _exports.default = _default;
});