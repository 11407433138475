define("apollo/pods/components/temporary-account-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xyR+ur54",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"alert-text\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-clock\"],[9],[10],[0,\"\\n    \"],[1,[27,\"t\",[\"user.temporaryAccountDaysLeft\"],null],false],[0,\": \"],[7,\"b\"],[9],[1,[23,[\"user\",\"temporaryAccountDaysLeft\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/temporary-account-alert/template.hbs"
    }
  });

  _exports.default = _default;
});