define("apollo/pods/components/free-subsequent-spots/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dateUtils: Ember.inject.service('date-utils'),
    intl: Ember.inject.service(),

    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);

      this.set('freeSubsequentSpots', Ember.A());
      this.set('showSearchSpotsButton', false);
    },

    formattedCurrentStart: Ember.computed('', function () {
      return this.get('dateUtils').formatDate(this.get('transport.exceptionInformation.initialStart'));
    }),
    sortedFreeSubsequentSpots: Ember.computed('freeSubsequentSpots.[]', function () {
      return this.get('freeSubsequentSpots').sortBy('ramp.idx');
    }),
    infoAboutCurrentSpot: Ember.computed('', function () {
      return Ember.String.htmlSafe(this.get('intl').t('freeSubsequentSpots.infoAboutCurrentSpot', {
        currentRamp: this.get('transport.exceptionInformation.rampName'),
        currentStart: this.get('formattedCurrentStart')
      }));
    }),
    infoAboutWindowSize: Ember.computed('', function () {
      return Ember.String.htmlSafe(this.get('intl').t('freeSubsequentSpots.infoAboutWindowSize', {
        windowSizeInMinutes: this.get('transport.exceptionInformation.windowSizeInMinutes')
      }));
    }),
    actions: {
      createAdvice(timeWindowCandidate) {
        this.set('freeSubsequentSpots', Ember.A());
        this.handleClickWithSpot(timeWindowCandidate);
      },

      search(transport) {
        this.searchForFreeSubsequentSpots(transport);
      }

    }
  });

  _exports.default = _default;
});