define("apollo/pods/queues/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+rtWRv0E",
    "block": "{\"symbols\":[\"queueObject\",\"resource\",\"square\"],\"statements\":[[7,\"div\"],[11,\"class\",\"queues-tab\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"enabledSquares\",\"length\"]],1],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"squares\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"enabledSquares\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-square \",[27,\"if\",[[27,\"eq\",[[23,[\"selectedSquare\",\"id\"]],[22,3,[\"id\"]]],null],\"active\"],null]]]],[12,\"data-button-name\",[28,[[22,3,[\"name\"]]]]],[9],[0,\"\\n                    \"],[1,[22,3,[\"name\"]],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"setSelectedSquare\",[22,3,[]]]],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"queues\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"queuesArray\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"queue-object\"],[12,\"data-field-name\",[28,[[22,1,[\"queue\",\"nameInAppropriateLanguage\"]]]]],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"queue-name\"],[12,\"data-queue-id\",[28,[[22,1,[\"queue\",\"id\"]]]]],[9],[0,\"\\n                    \"],[1,[22,1,[\"queue\",\"nameInAppropriateLanguage\"]],false],[0,\"\\n                \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[22,1,[\"classificationResources\"]]],null,{\"statements\":[[0,\"                    \"],[1,[27,\"classification-resource/place-assignment\",null,[[\"queue\",\"resource\"],[[22,1,[\"queue\"]],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/queues/index/template.hbs"
    }
  });

  _exports.default = _default;
});