define("apollo/pods/components/documents-applies/document-form/component", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    language: Ember.computed(function () {
      return this.get('intl.locale')[0];
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    section: Ember.computed('document', function () {
      return this.get('document');
    }),
    isEditionDisabled: Ember.computed('section.id', 'section.canBeUpdated', function () {
      if (!this.get('section.id')) {
        return false;
      }

      return !this.get('section.canBeUpdated');
    }),
    documentTypeGroupName: Ember.computed('document.documentType', function () {
      return this.get('document.documentType.groupName');
    }),
    shouldShowDocumentNumber: Ember.computed('document.number', 'document.state', function () {
      const state = this.get('document.state');
      return state !== 'ARCHIVED' || state === 'ARCHIVED' && !!this.get('document.number');
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },

      hideModal() {
        this.set('action', 'hideModal');
        this.sendAction('action');
      },

      setDocumentType(id) {
        const documentType = this.get('documentTypes').findBy('id', id);
        this.get('document').set('documentType', documentType);
      },

      setExpirationDate(dateValues) {
        Ember.run.next(() => {
          this.get('document').set('expirationDate', dateValues[0]);
        });
      },

      setExpirationDateFrom(dateValues) {
        Ember.run.next(() => {
          this.get('document').set('expirationDateFrom', dateValues[0]);
        });
      },

      save() {
        const self = this;
        this.set('savingInProgress', true);

        if (!this.get('document').validate()) {
          console.debug('Document did not pass validation.');
          self.set('savingInProgress', false);
          return;
        }

        this.get('document').save().then(savedDocument => {
          console.log(`Document ${savedDocument.get('id')} has been saved.`);
          self.setProperties({
            savingInProgress: false,
            errors: []
          });
          self.send('hideModal');
          self.send('refreshSection', savedDocument);
        }).catch(response => {
          self.setProperties({
            savingInProgress: false,
            errors: response.errors
          });
        });
      },

      cancel() {
        this.get('document').rollback();
        this.set('errors', []);
      }

    }
  });

  _exports.default = _default;
});