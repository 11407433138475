define("apollo/pods/components/task-confirmation-date-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    showConfirmationDateForm: false,
    today: Ember.computed(function () {
      return new Date();
    }).readOnly(),
    checkpoint: Ember.computed(function () {
      return this.get('firstUnconfirmedCheckpoint');
    }).readOnly(),
    confirmationDateIsMandatory: Ember.computed('checkpoint', function () {
      return this.get('checkpoint.task.userHasToAddCheckpointConfirmationDate');
    }),
    actions: {
      toggleConfirmationDateForm() {
        const showConfirmationDateForm = !this.get('showConfirmationDateForm');
        this.set('showConfirmationDateForm', showConfirmationDateForm);

        if (!showConfirmationDateForm) {
          this.set('firstUnconfirmedCheckpoint.executionDate', null);
        }
      },

      setConfirmationDate(date) {
        this.set('firstUnconfirmedCheckpoint.executionDate', date[0]);
      }

    }
  });

  _exports.default = _default;
});