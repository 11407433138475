define("apollo/pods/users/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    queryParams: {
      globalSearch: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      },
      companyId: {
        refreshModel: true
      }
    },
    model: function (params) {
      params.max = params.max || 10;
      params.page = params.page || 1;
      const self = this;
      const companyId = params.companyId;
      return new Promise(function (resolve) {
        (params ? self.store.query('user', params) : self.store.findAll('user')).then(response => {
          resolve(Ember.RSVP.hash({
            users: response,
            company: companyId ? self.store.findRecord('company', companyId) : null
          }));
        }, () => self.transitionTo('forbidden'));
      });
    },
    setupController: function (controller, model) {
      this.get('controller').setProperties({
        users: model.users,
        company: model.company,
        totalPages: model.users.get('meta.totalPages')
      });
    },

    activate() {
      this.activateCurrentTab("USERS_TAB");
    },

    actions: {
      willTransition(transition) {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });

        if (transition.targetName !== 'users.index') {
          this.get('controller').setProperties({
            globalSearch: '',
            page: 1,
            totalPages: 0,
            queryInput: '',
            filterActive: false
          });
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});