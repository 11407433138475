define("apollo/pods/assortment-groups/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    assortmentGroup: Ember.computed('model', function () {
      return this.get('model');
    }),
    transportTypes: Ember.computed('assortmentGroup', 'sessionAccount.currentUser.transportTypes', function () {
      return this.get('sessionAccount.currentUser.transportTypes');
    }),
    // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
    proxiedTransportTypes: Ember.computed('assortmentGroup.transportTypes', 'transportTypes', function () {
      const self = this;
      return this.get('transportTypes').map(tt => {
        return Ember.ObjectProxy.create({
          content: tt,
          checked: self.get('assortmentGroup.transportTypeIds').includes(tt.get('id'))
        });
      });
    }),
    actions: {
      save: function () {
        const self = this;
        this.get('assortmentGroup').set('adviceFromTime', `${this.get('assortmentGroup.adviceFromHours')}:${this.get('assortmentGroup.adviceFromMinutes')}`);
        this.get('assortmentGroup').set('adviceToTime', `${this.get('assortmentGroup.adviceToHours')}:${this.get('assortmentGroup.adviceToMinutes')}`);
        const checkedTransportTypes = this.get('proxiedTransportTypes').filterBy('checked', true);
        const checkedTransportTypesIds = checkedTransportTypes.map(tt => tt.get('content.id'));
        this.get('assortmentGroup').set('checkedTransportTypesIds', checkedTransportTypesIds);

        if (!this.get('assortmentGroup').validate()) {
          return;
        }

        const confirmText = self.get('intl').t('assortmentGroup.confirmEdition');

        if (self.showConfirmEdition() && !confirm(confirmText)) {
          return;
        }

        this.get('assortmentGroup').save().then(() => {
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('assortmentGroup.warehouse.id'));
        }).catch(response => {
          console.log(`Błąd przy zapisywaniu grupy asortymentowej: ${response.errors}`);
          self.set('errors', response.errors);
        });
      },
      delete: function () {
        const self = this;
        self.get('assortmentGroup').destroyRecord().then(() => {
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('assortmentGroup.warehouse.id'));
        }).catch(response => {
          console.log(`Błąd przy usuwaniu grupy asortymentowej: ${response.errors}`);
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        const warehouseId = this.get('assortmentGroup.warehouse.id');
        this.get('assortmentGroup').unloadRecord();
        this.transitionToRoute('warehouses.edit', warehouseId);
      }
    },
    showConfirmEdition: function () {
      return this.get('assortmentGroup.id') && (this.get('assortmentGroup').changedAttributes().adviceFromTime || this.get('assortmentGroup').changedAttributes().adviceToTime);
    }
  });

  _exports.default = _default;
});