define("apollo/pods/components/sections/document-section/document-section-manager/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isModelObjectEnabled: Ember.computed('modelObject.status', function () {
      return this.get('modelObject.status') !== 'DISABLED';
    }),
    archivedAndDeletedDocuments: Ember.computed('documentType.id', 'truck.documents.@each.id', 'modelObject.documents.@each.{isActive,deleted}', function () {
      const documents = this.get('modelObject.documents').filterBy('documentType.id', this.get('documentType.id'));
      return documents.filter(document => document.isActive === false || document.deleted === true);
    }),
    actions: {
      refresh() {
        this.get('modelObject').reload().then(p => p.get('documents').reload());
      },

      addDocument() {
        const modelObject = this.get('modelObject');
        const documentType = this.get('documentType');
        const suggestedExpirationDate = documentType.get('showExpirationDateTo') ? moment().add(1, 'year').startOf('day').toDate() : null;
        const suggestedExpirationDateFrom = documentType.get('showExpirationDateFrom') ? moment().startOf('day').toDate() : null;
        const documentModel = this.get('store').createRecord('document', {
          truck: modelObject,
          documentType,
          expirationDate: suggestedExpirationDate,
          expirationDateFrom: suggestedExpirationDateFrom // TODO A-6803 Refactor Zgłoszeń po wprowadzeniu Feature -> Weryfikacja środków transportu
          //  currency: currency,
          //  scopeOfInsurance: scopeOfInsurance

        });
        const model = {
          document: documentModel,
          documentType
        };
        this.set('action', 'showModal');
        this.sendAction('action', 'documents.modals.document-form-modal', model);
      },

      showArchived() {
        this.set('action', 'showModal');
        const model = {
          documentType: this.get('documentType'),
          documents: this.get('archivedAndDeletedDocuments')
        };
        this.sendAction('action', 'documents.modals.document-list-modal', model);
      }

    }
  });

  _exports.default = _default;
});