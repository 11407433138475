define("apollo/pods/components/expandable-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set('isOpen', this.get('defaultOpen'));
    },
    // eslint-disable-next-line ember/no-observers
    performActionOnOpen: Ember.observer('isOpen', function () {
      if (!this.get('isOpen')) {
        return;
      }

      if (this.onOpen) {
        this.onOpen();
      }
    }),
    showErrors: Ember.computed('errors', function () {
      if (this.get('errors') && this.get('errors').filter(error => error !== undefined).length > 0) {
        if (!this.isOpen) {
          // eslint-disable-next-line ember/no-side-effects
          this.send('togglePanel');
        }

        return 'has-error';
      } else {
        return '';
      }
    }),
    // eslint-disable-next-line ember/no-observers
    closeSection: Ember.observer('closeAllSections', function () {
      if (this.get('isOpen')) {
        this.set('isOpen', false);
      }
    }),
    actions: {
      togglePanel: function () {
        this.toggleProperty('isOpen');
      }
    }
  });

  _exports.default = _default;
});