define("apollo/pods/transports/archive/controller", ["exports", "apollo/mixins/transports-mixin"], function (_exports, _transportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend(_transportsMixin.default, {
    store: service(),
    sessionAccount: service('session-account'),
    queryParams: ['query', 'start', 'stop', 'dateFilterProperty', 'page'],
    searchParams: function () {
      const query = this.get('query') || '';
      const start = this.get('start') || '';
      const stop = this.get('stop') || '';
      const dateFilterProperty = this.get('dateFilterProperty');

      if (this.get('sessionAccount').isGroupFilterEnabled()) {
        return {
          filterMode: this.get('filterMode') ? this.get('filterMode') : 'user',
          query,
          start,
          stop,
          dateFilterProperty,
          filters: Ember.A()
        };
      }

      return {
        query,
        start,
        stop,
        dateFilterProperty,
        filters: Ember.A()
      };
    }.property('model', 'query', 'start', 'stop', 'filterMode', 'dateFilterProperty'),
    availableTransportTypes: function () {
      return [this.get('model')];
    }.property('model.id'),
    allAvailableTransportTypes: function () {
      const principalId = localStorage.getItem('principalId');
      const company = this.store.peekRecord('company', principalId);
      return company.get('transportTypes').filterBy('canBeSeenInArchiveByRoleGroup');
    }.property('sessionAccount.currentUser.transportTypes.@each'),
    actions: {
      setTransportTypeMode(mode) {
        const transportTypeId = this.get('store').peekAll('transportType').filter(type => {
          return type.get('alias') === mode;
        }).get('firstObject.id');
        this.transitionToRoute('transports.archive', transportTypeId);
      },

      setQueryParams(query, queryOpts, start, stop, dateFilterProperty) {
        this.setProperties({
          query,
          queryOpts,
          start,
          stop,
          dateFilterProperty
        });
      },

      clearQueryParams(dateFilterProperty) {
        this.setProperties({
          query: undefined,
          start: undefined,
          stop: undefined,
          dateFilterProperty
        });
      }

    }
  });

  _exports.default = _default;
});