define("apollo/pods/components/transport/steps-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.send('recalculateStepTypeIdx');
    },

    quantityLoadSum: Ember.computed('transport.steps.@each.quantitySum', function () {
      return this.get('transport.loadSteps').getEach('quantitySum').reduce((a, b) => a + b, 0);
    }),
    quantityUnloadSum: Ember.computed('transport.steps.@each.quantitySum', function () {
      return this.get('transport.unloadSteps').getEach('quantitySum').reduce((a, b) => a + b, 0);
    }),
    showLoadQuantitySum: Ember.computed('transport.showQuantitySection', 'quantityLoadSum', function () {
      return !this.get('transport.showQuantitySection') && this.get('quantityLoadSum') > 0;
    }),
    showUnloadQuantitySum: Ember.computed('transport.showQuantitySection', 'quantityUnloadSum', function () {
      return !this.get('transport.showQuantitySection') && this.get('quantityUnloadSum') > 0;
    }),
    showQuantitySumCheckMessage: Ember.computed('quantityLoadSum', 'quantityUnloadSum', function () {
      if (this.showLoadQuantitySumCheckMessage && this.showUnloadQuantitySumCheckMessage) {
        return this.get('intl').t('step.quantityLoadAndUnloadSumCheckMessage');
      }

      if (this.showLoadQuantitySumCheckMessage) {
        return this.get('intl').t('step.quantityLoadSumCheckMessage');
      }

      if (this.showUnloadQuantitySumCheckMessage) {
        return this.get('intl').t('step.quantityUnloadSumCheckMessage');
      }

      return '';
    }),
    showQuantityLoadAndUnloadSumCheckMessage: Ember.computed('quantityLoadSum', 'quantityUnloadSum', function () {
      return this.get('sessionAccount').getSettingValue('SHOW_QUANTITY_SUM_CHECK_MESSAGE') === 'true';
    }),
    showLoadQuantitySumCheckMessage: Ember.computed('quantityLoadSum', function () {
      return this.get('sessionAccount').getSettingValue('SHOW_QUANTITY_SUM_CHECK_MESSAGE') === 'true' && this.get('quantityLoadSum') > this.get('transport.transportType.quantitySumCheckValue');
    }),
    showUnloadQuantitySumCheckMessage: Ember.computed('quantityUnloadSum', function () {
      return this.get('sessionAccount').getSettingValue('SHOW_QUANTITY_SUM_CHECK_MESSAGE') === 'true' && this.get('quantityUnloadSum') > this.get('transport.transportType.quantitySumCheckValue');
    }),
    weightLoadSum: Ember.computed('transport.loadSteps.@each.weight', function () {
      return this.get('transport.loadSteps').getEach('weight').reduce((a, b) => parseInt(a) + parseInt(b), 0);
    }),
    showLoadWeightSum: Ember.computed('transport.showWeightField', 'weightLoadSum', function () {
      return !this.get('transport.showWeightField') && this.get('weightLoadSum') > 0;
    }),
    weightUnloadSum: Ember.computed('transport.unloadSteps.@each.weight', function () {
      this.get('transport.unloadSteps').getEach('weight').reduce((a, b) => parseInt(a) + parseInt(b), 0);
    }),
    showUnloadWeightSum: Ember.computed('transport.showWeightField', 'weightUnloadSum', function () {
      return !this.get('transport.showWeightField') && this.get('weightUnloadSum') > 0;
    }),
    actions: {
      recalculateStepTypeIdx() {
        this.get('transport.loadSteps').forEach((step, idx) => {
          step.set('stepTypeIdx', idx + 1);
        });
        this.get('transport.unloadSteps').forEach((step, idx) => {
          step.set('stepTypeIdx', idx + 1);
        });
      },

      handleStepsOrder() {
        this.get('transport.sortedSteps').forEach((step, orderInTransport) => {
          console.log(`Nowa pozycja etapu dla firmy ${step.get('company.name')}: ${orderInTransport}}`);
          step.set('orderInTransport', orderInTransport);
        });
        this.send("recalculateStepTypeIdx");
      }

    }
  });

  _exports.default = _default;
});