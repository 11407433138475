define("apollo/pods/components/show-manuals/component", ["exports", "apollo/config/environment", "apollo/pods/components/show-manuals/template", "apollo/pods/components/custom-popover/component"], function (_exports, _environment, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = _component.default.extend({
    layout: _template.default,
    sessionAccount: service('session-account'),

    init() {
      this._super(...arguments);

      this.setManualHref();
      this.setStatuteHref();
    },

    setManualHref: function () {
      const href = this.getHref('MANUAL');
      this.set('manualHref', href);
    },
    setStatuteHref: function () {
      const href = this.getHref('STATUTE');
      this.set('statuteHref', href);
    },
    getHref: function (documentType) {
      const principalId = localStorage.getItem('principalId');
      let hrefPart = '';

      switch (documentType) {
        case 'MANUAL':
          hrefPart = '/files/manual';
          break;

        case 'STATUTE':
          hrefPart = '/statutes/current';
          break;
      }

      return _environment.default.serverURL + hrefPart + '?token=' + this.get('sessionAccount').get('token') + '&principalId=' + principalId;
    },
    hasAccessToApplies: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES') === 'true';
    }),
    linkToAppliesManual: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('LINK_TO_APPLIES_MANUAL');
    }),
    linkToKnowledgeBase: Ember.computed('', function () {
      return this.get('sessionAccount').getParameterValue('LINK_TO_KNOWLEDGE_BASE');
    }),
    actions: {
      showManuals() {
        this.showPopover(this.element, '.show-manuals-button', '.manual-popover');
      }

    }
  });

  _exports.default = _default;
});