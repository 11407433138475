define("apollo/pods/components/transport/detach-all-deliveries/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    modalService: Ember.inject.service('modal-service'),
    processingData: false,
    errors: Ember.A([]),

    init() {
      this._super(...arguments);

      this.set('errors', Ember.A([]));
    },

    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    deliveries: Ember.computed('model.transport.deliveries', function () {
      return this.get('model.transport.deliveries');
    }),
    showSpinner: Ember.computed('processingData', function () {
      return this.get('processingData');
    }),
    actions: {
      async detachAllDeliveries() {
        this.set('processingData', true);
        const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.TRANSPORTS.DETACH_ALL_DELIVERIES, {
          id: this.get('transport.id')
        }, null);
        this.set('processingData', false);

        if (response.ok) {
          this.set('errors', Ember.A([]));
          this.get('modalService').hideModal('main-modal');
          this.get('modalService').hideModal('sub-modal');
        } else {
          const error = await response.json();
          this.set('errors', Ember.A(error.errors));
        }
      },

      cancel() {
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});