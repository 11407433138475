define("apollo/pods/components/driver-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: 'id',
    urlAddress: '/driver/find',
    field: 'driverName',
    options: Ember.A([]),
    suggestions: Ember.computed('inputVal', 'options.[]', function () {
      const inputVal = this.get('inputVal') || '';
      return this.get('options').filter(function (item) {
        if (inputVal.length > 0 && item.driverName && item.driverName.length > 0) {
          return item.driverName.toLowerCase().indexOf(inputVal.toLowerCase()) > -1;
        }

        return item;
      });
    }),
    optionsToMatch: Ember.computed('options.[]', function () {
      const caseInsensitiveOptions = [];
      this.get('options').forEach(function (item) {
        const value = item.driverName;
        caseInsensitiveOptions.push(value);
        caseInsensitiveOptions.push(value.toLowerCase());
      });
      return caseInsensitiveOptions;
    }),
    actions: {
      selectItem: function (item) {
        if (!item) {
          return;
        }

        this.set('selectedFromList', true);
        this.set('selectedValue', item.driverName);
        this.sendAction('selectItem', item);
      }
    }
  });

  _exports.default = _default;
});