define("apollo/pods/transport-types/cmr-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cmrSetting: Ember.computed('model', function () {
      return this.get('model.cmrSetting');
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    customFieldDefinitions: Ember.computed('model.transportType.sortedFieldDefinitions', function () {
      return Ember.A(this.get('transportType.sortedFieldDefinitions'));
    }),
    actions: {
      save() {
        this.set('errors', []);
        const cmrSetting = this.get('cmrSetting');
        cmrSetting.set('transportType', this.get('transportType'));

        if (!cmrSetting.validate()) {
          return;
        }

        cmrSetting.save().then(() => {
          this.send('refreshModel');
          const msg = this.get('intl').t('common.saveSuccessMessage');
          this.set('successMessage', msg);
          jQuery("html, body").animate({
            scrollTop: 0
          }, "slow");
        }).catch(response => {
          this.set('errors', response.errors);
          jQuery("html, body").animate({
            scrollTop: 0
          }, "slow");
        });
      },

      create() {
        if (this.get('cmrSetting')) {
          return;
        }

        const transportType = this.get('transportType');
        this.set('cmrSetting', this.get('store').createRecord('cmr-setting', {
          transportType: transportType
        }));
      },

      delete() {
        this.set('errors', []);
        this.get('cmrSetting').destroyRecord().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      cancel() {
        this.get('cmrSetting').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      },

      setAppendedDocsSpecificationCustomField(cfd) {
        this.set('cmrSetting.appendedDocsSpecificationCustomField', cfd);
      },

      setFeaturesAndNumbersCustomField(cfd) {
        this.set('cmrSetting.featuresAndNumbersCustomField', cfd);
      },

      setQuantityCustomField(cfd) {
        this.set('cmrSetting.quantityCustomField', cfd);
      },

      setWeightGrossCustomField(cfd) {
        this.set('cmrSetting.weightGrossCustomField', cfd);
      },

      setSenderInstructionCustomField(cfd) {
        this.set('cmrSetting.senderInstructionCustomField', cfd);
      }

    }
  });

  _exports.default = _default;
});