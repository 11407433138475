define("apollo/services/classification-resource-pooling-service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    // TODO Zastąpić przez apolloApiService
    ajaxService: Ember.inject.service('ajax-service'),

    init(pollUpdatesInterval, overwrittenHandleResponse) {
      this._super(...arguments);

      this.set('pollUpdatesInterval', pollUpdatesInterval);

      if (overwrittenHandleResponse) {
        this.set('handleResponse', overwrittenHandleResponse);
      } else {
        this.set('handleResponse', this.handleResponse.bind(this));
      }

      this.set('lastPoll', moment());
      this.get('pollUpdates').perform();
    },

    cancel() {
      this.get('pollUpdates').cancelAll();
    },

    pollUpdates: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const pollUpdatesInterval = this.get('pollUpdatesInterval');

      try {
        while (true) {
          yield (0, _emberConcurrency.timeout)(pollUpdatesInterval);
          yield self.getUpdates().then(json => {
            self.handleUpdates(json);
          });
        }
      } catch (e) {
        console.error(e);
      }
    }).restartable(),

    getUpdates() {
      try {
        const self = this;
        const params = {
          lastPoll: moment(self.get('lastPoll')).format()
        };
        return this.get('ajaxService').send('/classification-resources/updates', 'GET', params);
      } catch (e) {
        console.error(e);
      }
    },

    handleUpdates(json) {
      const handleResponse = this.get('handleResponse');
      this.set('lastPoll', json.pollTime);
      handleResponse(json);
    },

    handleResponse(json) {
      const self = this;
      json.classificationResourcesIds.forEach(function (classificationResourceId) {
        self.get('store').findRecord('classification-resource', classificationResourceId, {
          reload: true
        }).then(loadedClassificationResource => {
          if (loadedClassificationResource.get('enabled') === false || loadedClassificationResource.get('state') === loadedClassificationResource.STATE_NAMES.COMPLETED) {
            console.debug(`Object ClassificationResource: ${loadedClassificationResource.get('id')} with state: ${loadedClassificationResource.get('state')} has been removed.`);
            self.store.unloadRecord(loadedClassificationResource);
          } else {
            console.debug(`Object ClassificationResource: ${loadedClassificationResource.get('id')} with state: ${loadedClassificationResource.get('state')} has been refreshed.`);
          }
        });
      });
    }

  });

  _exports.default = _default;
});