define("apollo/pods/components/planning/delivery-table/delivery-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    modalService: Ember.inject.service('modal-service'),
    sessionAccount: Ember.inject.service('session-account'),
    canDeleteDelivery: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_DELETE_DELIVERY');
    }),
    actions: {
      show() {
        const modalPath = this.get('modalService').MODALS.DELIVERIES.CREATE;
        const delivery = this.get('delivery');
        const model = {
          delivery,
          transportType: this.get('transportType'),
          customFields: this.get('customFieldsList')
        };
        this.get('modalService').showModal(modalPath, model);
      },

      deleteDelivery(delivery) {
        delivery.deleteRecord();
        delivery.save();
      }

    }
  });

  _exports.default = _default;
});