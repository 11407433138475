define("apollo/pods/companies/invite/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend({
    sessionAccount: service('session-account'),
    model: function (params) {
      const self = this;
      const origin = window.location.origin + '#';
      const partnershipType = this.get('store').peekAll('partnership-type').filterBy('name', params.partnershipType).get('firstObject');
      const principalCompanyId = localStorage.getItem('principalId');
      const roleGroups = this.store.peekRecord('company', principalCompanyId).get('roleGroups');
      const userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');
      const roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      const companyId = params.company_id;
      return new Promise(function (resolve) {
        self.store.query('company-ramp', {
          companyId: companyId
        }).then(companyRamps => {
          const hasCompanyRamps = !!companyRamps.get('length');
          const defaultCompanyRamps = hasCompanyRamps ? self.get('store').query('ramp', {
            rampIds: companyRamps.getEach('ramp.id')
          }) : null;
          const defaultCompanyWarehouses = hasCompanyRamps ? self.get('store').query('warehouse', {
            warehouseIds: companyRamps.getEach('warehouse.id')
          }) : null;
          resolve(Ember.RSVP.hash({
            invitation: self.store.createRecord('invitation', {
              enabled: true,
              origin: origin,
              partnershipType: partnershipType
            }),
            companyId: companyId,
            partnershipType: partnershipType,
            roleGroups: roleGroups,
            roleGroupInvRestriction: roleGroupInvRestriction,
            defaultCompanyRamps: defaultCompanyRamps,
            defaultCompanyWarehouses: defaultCompanyWarehouses
          }));
        });
      });
    },
    renderTemplate: function () {
      this.render('invitations.create');
    },
    setupController: function (controller, model) {
      if (model.companyId) {
        this.get('store').findRecord('company', model.companyId).then(company => {
          model.invitation.set('company', company);
          model.invitation.set('taxIdNumber', company.get('taxIdNumber'));
          model.invitation.set('country', company.get('country'));
        });
      }

      this.controllerFor('invitations.create').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function () {
        this.controllerFor('invitations.create').setProperties({
          errors: [],
          invitationSuccess: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});