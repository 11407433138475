define("apollo/services/error-service", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    scrollToFirstError(containerSelector = "#main-modal", errorClass = ".has-error") {
      //Dajemy opóźnienie, żeby błędy zdążyły się oznaczyć w formularzu
      setTimeout(() => {
        const container = (0, _jquery.default)(containerSelector);
        const errorFields = (0, _jquery.default)(errorClass).not(".panel-title");

        if (errorFields.length === 0) {
          return;
        }

        const errorField = errorFields.first(); // jeśli pole znajduje się w zwiniętym panelu - rozwijamy go

        const parentPanel = errorField.parents(".panel");

        if (parentPanel.length) {
          const panel = parentPanel.find(".panel-collapse");
          panel.collapse('show');
        }

        container.animate({
          scrollTop: errorField.offset().top - container.offset().top + container.scrollTop()
        }, "slow");
      }, 100);
    }

  });

  _exports.default = _default;
});