define("apollo/pods/components/transport/courier/add-packages-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      addPackage() {
        const courierPackage = this.get('store').createRecord('courierPackage', {
          loadStep: this.get('transport.loadSteps.firstObject'),
          unloadStep: this.get('transport.unloadSteps.firstObject')
        });
        const model = {
          courierPackage,
          transport: this.get('transport')
        };
        this.get('transport.courierPackages').addObject(courierPackage);
        this.set('action', 'showSubModal');
        this.sendAction('action', 'courier.modals.package-form', model);
      }

    }
  });

  _exports.default = _default;
});