define("apollo/pods/company-email-domain/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    domain: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    validations: {
      domain: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});