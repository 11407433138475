define("apollo/pods/planning/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
    },

    customFieldsForDeliveries: Ember.computed('model.customFields.@each.{id,enabled,obligatory,visibleInTable}', function () {
      return this.get('model.customFields').filterBy('id').filterBy('enabled').filterBy('obligatory').filterBy('visibleInTable').sortBy('idx').filter(it => {
        return !it.usedForDeliveries;
      });
    })
  });

  _exports.default = _default;
});