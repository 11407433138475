define("apollo/pods/components/transports/netpc-report-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    isSuperUser: Ember.computed('sessionAccount.isSuperUser', function () {
      return this.get('sessionAccount.isSuperUser');
    }),

    _getUrlAndOpen(dashboardName) {
      const path = this.get('apolloApiService').APOLLO_API.NET_PC.GET_REPORT_URL;
      this.get('apolloApiService').callApolloApi(path, {
        dashboardName
      }).then(response => {
        return response.json();
      }).then(data => {
        const URL = data.url;
        window.open(URL);
      });
    },

    actions: {
      goToReport: function () {
        const dashboard = this.get('dashboardName');
        Ember.run.debounce(this, '_getUrlAndOpen', dashboard || 'Transport_real', 500);
      }
    }
  });

  _exports.default = _default;
});