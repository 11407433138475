define("apollo/pods/components/file-upload/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    action: '',
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    FILE_SIZE_ERROR: 'Uploaded bytes exceed file size',
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement: function () {
      this._super(...arguments);

      const self = this;
      const token = this.get('sessionAccount').get('token');
      const transportId = this.get('transport.id');
      const sectionId = this.get('section.id');
      const properId = transportId || sectionId;
      const isFromDocsModalOrSection = this.get('isFromDocsModal') || this.get('isFromSection');
      const dropZone = this.get('elementZone') || this.element;
      this.$().find('.file-upload-input').attr('data-url', _environment.default.serverURL + this.get('resourceUrl')).fileupload({
        autoUpload: true,
        // dropZone odpowiada za element który ma nasłuchiwać
        // eventu "drop" po przeciągnięciu plików na niego
        dropZone,
        formData: function () {
          return [{
            name: 'transportId',
            value: transportId
          }, {
            name: 'sectionId',
            value: sectionId
          }, {
            name: 'fileType',
            value: self.get('fileType')
          }, {
            name: 'token',
            value: token
          }];
        },
        submit: function (e, data) {
          if (data.files.some(it => it.size === 0)) {
            if (isFromDocsModalOrSection) {
              self.set('action', 'fileUploadFail');
              self.sendAction('action', properId);
            }

            $('.progress').hide();
            alert(self.get('intl').t('errors.emptyFile'));
            return false;
          }

          return true;
        },
        progress: function (e, data) {
          console.debug('File upload in progress..');

          if (isFromDocsModalOrSection) {
            self.set('action', 'fileUploadStarted');
            self.sendAction('action');
          }

          const progressBar = self.$().find('.progress');
          progressBar.show();
          const progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.find('.bar').css('width', progress + '%');
        },
        done: function () {
          if (isFromDocsModalOrSection) {
            self.set('action', 'fileUploadSuccess');
            self.sendAction('action', properId);
          }

          if (!isFromDocsModalOrSection) {
            self.get('transport').reload();
          }

          $('.progress').hide();
        },
        fail: function (jqXHR, textStatus) {
          if (isFromDocsModalOrSection) {
            self.set('action', 'fileUploadFail');
            self.sendAction('action', properId);
          }

          $('.progress').hide();

          if (textStatus.jqXHR.status === 409) {
            alert(self.get('intl').t('errors.fileAlreadyExists'));
            return;
          }

          if (textStatus.jqXHR.status === 413 || textStatus.messages.uploadedBytes === self.FILE_SIZE_ERROR) {
            alert(self.get('intl').t('errors.fileTooLarge'));
            return;
          }

          alert(self.get('intl').t('errors.fileUploadError'));
        }
      });
    },

    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);

      this.$().find('.file-upload-input').fileupload('destroy');
    },

    change: function (e) {
      if (this.get('transport.id')) {
        // walidujemy ilość przesłanych do zlecenia plików
        const maxCountString = this.get('sessionAccount').getSettingValue('MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT');
        const maxCount = maxCountString ? Number(maxCountString) : null;
        const filesCount = e.target.files ? e.target.files.length : 0;

        if (maxCount && maxCount < filesCount) {
          alert(this.get('intl').t('document.maxCountExceeded', {
            maxCount
          }));
          return;
        }
      }

      console.log('change ' + e);
      const event = jQuery.Event('change');
      event.target = e.target;
      this.$().find('.file-upload-input').trigger(event);
    }
  });

  _exports.default = _default;
});