define("apollo/pods/components/slider-component/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inline-block', 'padding-left'],

    didRender() {
      const self = this;
      const sliderDiv = (0, _jquery.default)("#slider");
      const handle = (0, _jquery.default)("#slider-info");
      sliderDiv.slider({
        min: self.get('min'),
        max: self.get('max'),
        value: self.get('initialValue'),
        create: function () {
          handle.text(`${self.get('initialValue')}%`);
        },
        change: function (event, ui) {
          // Przy programistycznym (w kodzie) ustawieniu wartość -- poprzez `jQuery("#slider").slider('option', 'value', x)`
          // -- nie pojawia się `originalEvent`
          const hasBeenSetManually = !!event.originalEvent;
          self.onChangeAction(ui.value, hasBeenSetManually);
          handle.text(`${ui.value}%`);
        }
      });
    },

    actions: {
      reset() {
        this.onResetAction();
      }

    }
  });

  _exports.default = _default;
});