define("apollo/pods/components/schedule/current-hour-frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gagZ3VLC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"current-hour-frame\"],[12,\"style\",[28,[[21,\"weekStyle\"],\"; top: \",[21,\"currentHourFrameTopOffset\"],\"px;\"]]],[9],[0,\"\\n     \\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"bottom-hour-frame\"],[11,\"class\",\"current-hour-frame\"],[12,\"style\",[28,[[21,\"weekStyle\"],\"; top: \",[21,\"currentHourFrameBottomOffset\"],\"px;\"]]],[9],[0,\"\\n     \\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/schedule/current-hour-frame/template.hbs"
    }
  });

  _exports.default = _default;
});