define("apollo/pods/users/queues/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    generalAccess: 'ROLE_ACCESS_QUEUES_ACCESSIBILITY',
    myAccountAccess: 'ROLE_EDIT_MY_QUEUES',
    userAccessService: Ember.inject.service('user-access-service'),
    model: function (params, transition) {
      const canAccess = this.get('userAccessService').canAccess(params.user_id, this.generalAccess, this.myAccountAccess);

      if (canAccess) {
        return Ember.RSVP.hash({
          user: this.store.findRecord('user', params.user_id),
          squares: this.store.findAll('square'),
          queues: this.store.findAll('queue')
        });
      } else {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    actions: {
      willTransition() {
        this.get('controller').setProperties({
          'successMessage': null
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});