define("apollo/services/fresh-widget", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    loadWidget() {
      try {
        const integrationEnabled = _environment.default.APP.integrations.freshWidget;

        if (!integrationEnabled) {
          return console.log(`Integracja z Fresh Widget jest wyłączona.`);
        }

        if (typeof FreshWidget === 'undefined') {
          return console.log(`Nie mamy dostępu do wtyczki FreshWidget, przerywamy inicjację.`);
        }

        console.log(`Inicjujemy wtyczkę Fresh Widget...`);
        FreshWidget.init("", {
          "queryString": "&widgetType=popup&formTitle=Wsparcie+techniczne&submitTitle=Wy%C5%9Blij&submitThanks=Dzi%C4%99kujemy+za+zg%C5%82oszenie&captcha=yes&searchArea=no",
          "utf8": "✓",
          "widgetType": "popup",
          "buttonType": "text",
          "buttonText": "Support",
          "buttonColor": "white",
          "buttonBg": "#f57004",
          "alignment": "2",
          "offset": "80%",
          "submitThanks": "Dziękujemy za zgłoszenie",
          "formHeight": "550px",
          "captcha": "yes",
          "url": "https://logintegra.freshdesk.com",
          "loadOnEvent": "immediate"
        });
      } catch (e) {
        console.error(e);
      }
    }

  });

  _exports.default = _default;
});