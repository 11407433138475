define("apollo/pods/applies-settings/index/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    appliesSetting: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      save() {
        this.set('errors', []);
        this.get('appliesSetting').save().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      create() {
        if (this.get('appliesSetting')) {
          return;
        }

        const principalCompany = this.get('sessionAccount.principalCompany');
        this.set('appliesSetting', this.get('store').createRecord('applies-setting', {
          principalCompany: principalCompany
        }));
      },

      delete() {
        this.set('errors', []);
        this.get('appliesSetting').destroyRecord().then(() => this.handleSuccessResponse(this)).catch(response => this.handleErrorResponse(this, response));
      },

      cancel() {
        this.get('appliesSetting').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }

    }
  });

  _exports.default = _default;
});