define("apollo/pods/authority-restriction/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    CHECKPOINT_BASED_AUTHORITY_RESTRICTIONS: Ember.A(['CHECKPOINT_BASED', 'BEFORE_CHECKPOINT_BASED', 'AFTER_CHECKPOINT_BASED']),
    dateUtils: Ember.inject.service('date-utils'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    authority: belongsTo('authority'),
    task: belongsTo('task'),
    roleGroup: belongsTo('roleGroup'),
    authorityRange: attr('string'),
    assetId: attr('number'),
    pricingPlan: attr('string'),
    timeInterval: attr('string'),
    timeReference: attr('string'),
    validations: {
      authority: {
        presence: {
          message: 'blankSelect'
        }
      },
      authorityRange: {
        presence: {
          message: 'blankSelect'
        }
      },
      task: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.CHECKPOINT_BASED_AUTHORITY_RESTRICTIONS.includes(model.get('authorityRange'))) {
              return true;
            }

            return value && value.get('id');
          },
          message: 'blankSelect'
        }]
      },
      timeReference: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      timeIntervalSign: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      timeIntervalValue: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return value && /[0-9]*/.test(value);
          },
          message: 'number'
        }]
      },
      timeIntervalUnit: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      assetId: {
        custom: [{
          validation: function (key, value, model) {
            return !model.get('authority.assetType') || value;
          },
          message: 'blankSelect'
        }]
      }
    },
    timeIntervalSign: Ember.computed('timeInterval', function () {
      if (!this.get('timeInterval')) {
        return null;
      }

      return this.get('dateUtils').isNegative(this.get('timeInterval')) ? '-' : '+';
    }),
    timeIntervalValue: Ember.computed('timeInterval', function () {
      return this.get('timeInterval') ? this.get('dateUtils').getAbsoluteValue(this.get('timeInterval')).slice(0, -1) : null;
    }),
    timeIntervalUnit: Ember.computed('timeInterval', function () {
      return this.get('timeInterval') ? this.get('dateUtils').getAbsoluteValue(this.get('timeInterval')).slice(-1) : null;
    }),
    conditionIsFulfilled: function (transport) {
      const currentPricingPlan = this.get('sessionAccount.currentUser.company.pricingPlan');

      if (this.get('pricingPlan') && this.get('pricingPlan') !== currentPricingPlan) {
        return false;
      }

      switch (this.get('authorityRange')) {
        case 'UNLIMITED':
          return true;

        case 'ACTIVE':
          return transport.get('active');

        case 'TIME_BASED':
          return this.timeBasedConditionIsFulfilled(transport);

        case 'BEFORE_CHECKPOINT_BASED':
          return this.beforeCheckpointBasedConditionIsFulfilled(transport);

        case 'AFTER_CHECKPOINT_BASED':
          return this.afterCheckpointBasedConditionIsFulfilled(transport);

        case 'ADVICE_RESTRICTION_BASED':
          return this.adviceRestrictionBasedConditionIsFulfilled(transport);

        case 'ACTIVE_AUCTION_BASED':
          return this.activeAuctionBasedConditionIsFulfilled(transport);

        case 'OWN_COMPANY_BASED':
          return this.ownCompanyBasedConditionIsFulfilled(transport);

        case 'NO_ROUTE_OFFER':
          return this.noRouteOfferBasedConditionIsFulfilled(transport);
      }
    },
    timeBasedConditionIsFulfilled: function (transport) {
      const referenceDate = this.findReferenceDate(transport);

      if (!referenceDate) {
        return true;
      }

      const latestPossibleDate = this.get('dateUtils').calculateDateFromInterval(referenceDate, this.get('timeInterval'));
      return latestPossibleDate > new Date();
    },
    findConfirmedLocalTaskIds: function (transport) {
      if (transport.get('id') === null) {
        return [];
      }

      return this.get('store').peekAll('checkpoint').filterBy('transport.id', transport.get('id')).filterBy('executionDate').getEach('task.id');
    },
    beforeCheckpointBasedConditionIsFulfilled: function (transport) {
      const confirmedLocalTaskIds = this.findConfirmedLocalTaskIds(transport);
      const taskId = this.get('task.id');
      return !confirmedLocalTaskIds.includes(taskId);
    },
    afterCheckpointBasedConditionIsFulfilled: function (transport) {
      const confirmedLocalTaskIds = this.findConfirmedLocalTaskIds(transport);
      const taskId = this.get('task.id');
      return confirmedLocalTaskIds.includes(taskId);
    },
    adviceRestrictionBasedConditionIsFulfilled: function (transport) {
      const minAdviceDate = transport.get('minAdviceDate');
      const maxAdviceDate = transport.get('maxAdviceDate');

      if (!minAdviceDate || !maxAdviceDate) {
        return true;
      }

      const earliestWindowStart = transport.get('steps').filterBy('timeWindow.start').getEach('timeWindow.start').sort()[0];
      const latestWindowStop = transport.get('steps').filterBy('timeWindow.stop').getEach('timeWindow.stop').sort().reverse()[0];

      if (!earliestWindowStart || !latestWindowStop) {
        return true;
      }

      return minAdviceDate <= earliestWindowStart && maxAdviceDate >= latestWindowStop;
    },
    activeAuctionBasedConditionIsFulfilled: function (transport) {
      return !transport.get('activeAuction');
    },
    noRouteOfferBasedConditionIsFulfilled: function (transport) {
      const hasRoutes = transport.get('hasConfirmedRouteOffers');
      const autoRouteOfferPassNotAccepted = transport.get('transaction') ? transport.get('lastTransactionAutoPassNotAccepted') : false;
      return !hasRoutes || autoRouteOfferPassNotAccepted;
    },
    ownCompanyBasedConditionIsFulfilled: function (transport) {
      if (!transport.get('id')) {
        return true;
      }

      const transportCreatorCompanyId = transport.get('creatorCompanyId');
      const currentUserCompanyId = this.get('sessionAccount.currentUser.company.id');
      return transportCreatorCompanyId === currentUserCompanyId;
    },
    findReferenceDate: function (transport) {
      switch (this.get('timeReference')) {
        case 'TRANSPORT_DATE_CREATED':
          return transport.get('dateCreated');

        case 'EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE':
        case 'EARLIEST_TIME_WINDOW_START_AFTER_CHANGE':
        case 'EARLIEST_TIME_WINDOW_CURRENT_DATE':
          return transport.get('earliestTimeWindowStart');

        case 'LATEST_TIME_WINDOW_STOP':
          return transport.get('latestTimeWindowStop');

        case 'MIN_ADVICE_DATE':
          {
            const stepsWithAdviceDateRestrictions = transport.get('sortedStep').filterBy('minAdviceDate');
            return stepsWithAdviceDateRestrictions.get('length') > 0 ? stepsWithAdviceDateRestrictions.get('firstObject') : null;
          }
      }
    },
    name: Ember.computed('authority.name', function () {
      return this.get('authority.name');
    }),
    description: Ember.computed('authority.name', 'authorityRangePhrase', function () {
      const actionTypeName = this.get('intl').t(`settings.roleGroups.authorityLabels.${this.get('authority.name')}`);
      const isPossiblePhrase = this.get('authorityRange') === 'BLOCKED' || this.get('authorityRange') === 'CHECKPOINT_BASED' ? '' : this.get('intl').t('settings.roleGroups.actionIsPossible');
      const pricingPlanPhrase = this.get('pricingPlan') ? this.get('intl').t('settings.roleGroups.pricingPlans', {
        pricingPlan: this.get('pricingPlan')
      }) : '';
      const authorityRangePhrase = this.get('authorityRangePhrase');
      return `${actionTypeName} - ${pricingPlanPhrase} ${isPossiblePhrase} ${authorityRangePhrase}`;
    }),
    authorityRangePhrase: Ember.computed('authorityRange', 'task', 'timeReference', 'timeInterval', function () {
      const params = {};

      switch (this.get('authorityRange')) {
        case 'TIME_BASED':
          params.timeInterval = this.get('dateUtils').getAbsoluteValue(this.get('timeInterval'));
          params.afterOrBefore = this.get('dateUtils').isNegative(this.get('timeInterval')) ? this.get('intl').t('settings.roleGroups.dateBeforePhrase') : this.get('intl').t('settings.roleGroups.dateAfterPhrase');
          params.timeReference = this.get('intl').t(`settings.roleGroups.timeReferences.${this.get('timeReference')}`);
          break;

        case 'CHECKPOINT_BASED':
        case 'BEFORE_CHECKPOINT_BASED':
        case 'AFTER_CHECKPOINT_BASED':
          params.checkpointName = this.get('task.checkpointNameInAppropriateLanguage');

          if (this.get('task.masterTask.id')) {
            params.checkpointName += ' :: SUBTASK';
          }

          break;
      }

      let rangePhrase;

      if (this.get('timeReference') === 'EARLIEST_TIME_WINDOW_CURRENT_DATE') {
        if (this.get('dateUtils').isNegative(this.get('timeInterval'))) {
          rangePhrase = 'TIME_BASED_CURRENT_DATE_BEFORE';
        } else {
          rangePhrase = 'TIME_BASED_CURRENT_DATE_AFTER';
        }
      } else {
        rangePhrase = this.get('authorityRange');
      }

      return this.get('intl').t(`settings.roleGroups.authorityRangePhrases.${rangePhrase}`, params);
    }),
    authorityRangePhraseInUpperCase: Ember.computed('authorityRangePhrase', function () {
      return this.get('authorityRangePhrase').toString().toUpperCase();
    }),
    transportType: Ember.computed('authority.assetType', 'assetId', function () {
      const assetType = this.get('authority.assetType');

      if (!assetType) {
        return null;
      }

      const assetId = this.get('assetId');

      switch (assetType) {
        case 'TRANSPORT_TYPE':
          return this.get('store').peekRecord('transport-type', assetId);

        case 'CUSTOM_FIELD':
          return this.get('store').peekRecord('custom-field-definition', assetId).get('transportType');

        default:
          return null;
      }
    }),
    customFieldDefinition: Ember.computed('authority.assetType', 'assetId', function () {
      const assetType = this.get('authority.assetType');
      const assetId = this.get('assetId');

      if (!assetType || assetType !== 'CUSTOM_FIELD' || !assetId) {
        return null;
      }

      return this.get('store').peekRecord('custom-field-definition', assetId);
    })
  });

  _exports.default = _default;
});