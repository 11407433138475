define("apollo/pods/components/transport/discrepancy-report-section/component", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/transport-form-mixin"], function (_exports, _jquery, _environment, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),

    init() {
      this._super(...arguments);

      const transport = this.get('transport');
      this.initializeDiscrepancyReport(transport);
    },

    noDiscrepanciesFoundLabel: Ember.computed('', function () {
      return this.get('intl').t('discrepancyReport.noDiscrepanciesFound');
    }),
    shouldDiscrepancyButtonBeDisabled: Ember.computed('transport.advice.id', 'transport.hasDiscrepancyOptionsActive', function () {
      return !this.get('transport.id') || !this.get('transport.advice.id') || !this.get('transport.hasDiscrepancyOptionsActive');
    }),
    showGenerateDiscrepancyReportButton: Ember.computed('transport.discrepancyReport.id', 'transport.discrepancyReport.canBeGenerateAgain', 'transport.customFields.@each.{value,isDirty}', function () {
      if (this.get('transport.archived')) {
        return false;
      }

      if (!this.get('sessionAccount').hasRole('ROLE_CREATE_DISCREPANCY_PROTOCOL')) {
        return false;
      }

      if (!this.get('transport.discrepancyReport.id')) {
        return true;
      }

      const anyCustomFieldDirty = this.get('transport.customFieldPairs').filter(function (cf) {
        return cf.definition.get('visibleInDiscrepancyReportSection') === true && cf.value && cf.value.get('isDirty');
      });
      return this.get('transport.discrepancyReport.canBeGenerateAgain') || anyCustomFieldDirty;
    }),
    actions: {
      generateDiscrepancyReport(defer) {
        const self = this;
        const transport = this.get('transport');
        const createTransportController = self.get('createTransportController');
        const valid = createTransportController.prepareTransportForSaving(transport);

        if (!valid) {
          defer.resolve();
          return;
        }

        transport.save().then(() => {
          createTransportController.handleSavedTransport(transport, createTransportController);
          const discrepancyReport = self.get('store').createRecord('discrepancy-report', {
            transport: self.get('transport')
          });
          discrepancyReport.save().then(() => {
            defer.resolve();
          });
        }).catch(response => {
          (0, _jquery.default)('.transport-modal').scrollTop(0);
          self.set('errors', response.errors);
        }).finally(() => $('.save-transport-button').button('reset'));
      },

      downloadDiscrepancyReport() {
        const self = this;
        Ember.run.once(this, function () {
          window.open(_environment.default.serverURL + '/discrepancy-reports/' + self.get('transport.discrepancyReport.id') + '/download?token=' + self.get("sessionAccount").get("token"), "_blank");
        });
      }

    }
  });

  _exports.default = _default;
});