define("apollo/pods/components/sections/document-section/document-state-buttons/component", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    isModelObjectEnabled: Ember.computed('modelObject.status', function () {
      return this.get('modelObject.status') !== 'DISABLED';
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});