define("apollo/pods/components/role-group-notification-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service('intl'),
    actions: {
      async save() {
        const roleGroupId = this.get('roleGroup.id');
        const notificationIds = this.get('roleGroupNotifications').filter(n => n.checked).getEach('content.id');
        const data = JSON.stringify({
          roleGroup: {
            id: roleGroupId
          },
          notifications: notificationIds.map(n => {
            return {
              id: n
            };
          })
        });
        this.set('successMessage', null);
        this.set('errors', []);

        try {
          const path = this.get('apolloApiService').APOLLO_API.ROLE_GROUP_NOTIFICATIONS.SAVE;
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: data
          });

          if (response.ok) {
            this.set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      }

    }
  });

  _exports.default = _default;
});