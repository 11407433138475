define("apollo/pods/components/companies/apply-form/insurance-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dragAndDrop: Ember.inject.service('drag-and-drop-service'),
    groupName: Ember.computed('', function () {
      return 'INSURANCE';
    }),
    archivedInsurances: Ember.computed('documentType.id', 'partnership.archivedInsurances', function () {
      return this.get('partnership.archivedInsurances').filterBy('documentType.id', this.get('documentType.id'));
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },

      refresh() {
        this.get('partnership').reload().then(p => p.get('documents').reload());
      },

      showArchived() {
        this.set('action', 'showModal');
        const model = {
          groupName: this.get('groupName'),
          documentType: this.get('documentType'),
          documents: this.get('archivedInsurances')
        };
        this.sendAction('action', 'documents.modals.document-list-modal', model);
      }

    },

    dragOver(event) {
      const element = this.element;
      this.get('dragAndDrop').dragOver(event, element);
    },

    dragLeave(event) {
      const element = this.element;
      this.get('dragAndDrop').dragLeave(event, element);
    },

    drop(event) {
      const element = this.element;
      this.get('dragAndDrop').drop(event, element);
    }

  });

  _exports.default = _default;
});