define("apollo/pods/components/log-group-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HIE8iMFV",
    "block": "{\"symbols\":[\"change\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"bold\"],[9],[0,\"\\n        \"],[1,[23,[\"log\",\"eventLabel\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"log\",\"changes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"log-change\",null,[[\"log\"],[[22,1,[]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/log-group-change/template.hbs"
    }
  });

  _exports.default = _default;
});