define("apollo/pods/components/modals/address-unloading-time/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    tagName: '',
    actions: {
      onCloseHandler: function () {
        if (!this.get('addressUnloadingTime.id')) {
          this.get('addressUnloadingTime').rollbackAttributes();
        }

        this.onClose();
      },

      saveOrUpdate(addressUnloadingTime) {
        if (!addressUnloadingTime.validate()) {
          console.debug(`addressUnloadingTime validation errors ${addressUnloadingTime.errors.map(e => e.message).join(',')}`);
          return;
        }

        addressUnloadingTime.save().then(() => {
          this.onClose();
        });
      }

    }
  });

  _exports.default = _default;
});