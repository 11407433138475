define("apollo/pods/components/advice-handler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nybQJJxw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"showAdviceButton\"]],[23,[\"transport\",\"id\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"disableAdviceForSupplierDueToModeOfTransportation\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"advice-button\",null,[[\"transport\",\"title\",\"disabled\",\"goToWarehouse\",\"openCreateAdviceModal\"],[[23,[\"transport\"]],[27,\"concat\",[[27,\"t\",[\"transport.companyNotQualified\"],null],[27,\"t\",[\"transport.forProcess\"],null]],null],true,[23,[\"goToWarehouse\"]],[23,[\"openCreateAdviceModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"advice-button\",null,[[\"transport\",\"goToWarehouse\",\"openCreateAdviceModal\"],[[23,[\"transport\"]],[23,[\"goToWarehouse\"]],[23,[\"openCreateAdviceModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"isFromTransportModal\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"overflow-ellipsis text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showTrailerInsteadOfTruckRegNum\"]]],null,{\"statements\":[[0,\"                \"],[1,[21,\"adviceTrailerRegistrationNumber\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[21,\"adviceCarRegistrationNumber\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[27,\"and\",[[23,[\"showCreateWindowButton\"]],[23,[\"transport\",\"id\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"advice-button\",null,[[\"transport\",\"goToWarehouse\",\"isSplitAdvice\"],[[23,[\"transport\"]],[23,[\"goToWarehouse\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/advice-handler/template.hbs"
    }
  });

  _exports.default = _default;
});