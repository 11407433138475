define("apollo/pods/warehouses/hd/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend({
    sessionAccount: service('session-account'),
    access: ['ROLE_WAREHOUSE_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },

    model(params) {
      const self = this;
      const currentUser = self.get('sessionAccount').get('currentUser');
      const warehouseId = params.warehouse_id;
      const dateString = params.date_string;
      const date = moment(dateString);
      const warehouses = currentUser.get('warehouses').filter(w => {
        return w.get('company.id') === localStorage.getItem('principalId');
      });
      const ramps = currentUser.get('ramps').filterBy('warehouse.id', warehouseId).sortBy('idx');
      return Ember.RSVP.hash({
        warehouses: warehouses,
        warehouse: self.store.peekRecord('warehouse', warehouseId),
        ramps: ramps,
        delta: Math.min(params.delta, 7),
        dateString: dateString,
        date: date
      });
    },

    renderTemplate: function () {
      this.render('warehouses.schedule', {
        controller: 'warehouses.schedule'
      });
    },
    setupController: function (controller, model) {
      this.controllerFor('warehouses.schedule').setProperties({
        model: model,
        scheduleStartDate: model.date,
        selectedDateString: model.dateString,
        hdMode: true
      });
      this.controllerFor('application').set('hdMode', true);
    },
    activate: function () {
      this.set('timer', this.schedule(this.get('onPoll')));
    },
    deactivate: function () {
      Ember.run.cancel(this.get('timer'));
    },
    schedule: function (f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('timer', this.schedule(f));
      }, this.get('interval'));
    },
    onPoll: function () {
      this.controllerFor('warehouses.schedule').send('onPoll');
    },
    lastPoll: function () {
      const interval = this.get('interval');
      return moment().subtract(interval, 'milliseconds');
    }.property(),
    interval: function () {
      return 10000; // Time between polls (in ms)
    }.property().readOnly(),
    actions: {
      willTransition(transition) {
        const scheduleController = this.controllerFor('warehouses.schedule');
        scheduleController.send('hideModal');
        const willBeHdMode = transition.targetName === 'warehouses.hd';

        if (!willBeHdMode) {
          scheduleController.setProperties({
            hasZoomBeenSetManually: willBeHdMode,
            defaultZoom: undefined
          });
        }

        scheduleController.setProperties({
          info: null,
          errors: null,
          successMessage: null,
          inProgress: false,
          hdMode: willBeHdMode,
          transportToSave: null,
          warehouseStepToHandle: null,
          scheduleClicked: false
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});