define("apollo/pods/partnership-contractors-group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = _emberData.default.Model.extend({
    intl: service(),
    partnership: _emberData.default.belongsTo('partnership', {
      async: true
    }),
    contractorsGroup: _emberData.default.belongsTo('contractorsGroup', {
      async: true
    }),

    /**
     * Określa, czy ma być widoczna
     */
    enabled: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});