define("apollo/pods/components/transports/save-filters-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
        Komponent reprezentujący przełącznik do zarządzania stanem zapamiętywania filtrów.
        Stan przechowywany jest w pamięci lokalnej przeglądarki pod kluczem `cacheFilters`.
        Domyślnie, zapamiętywanie filtrów jest włączone.
     */
    init() {
      this._super(...arguments);

      Ember.run.once(() => {
        if (localStorage.getItem("cacheFilters") === null) {
          localStorage.setItem("cacheFilters", "true");
        }
      });
    },

    cacheFiltersSwitchState: Ember.computed({
      get() {
        return localStorage.getItem("cacheFilters") != null ? localStorage.getItem("cacheFilters") === "true" : "true";
      },

      set(key, value) {
        localStorage.setItem("cacheFilters", value);
        return value;
      }

    }),
    actions: {
      toggleCacheFiltersSwitch: function () {
        this.set('cacheFiltersSwitchState', !this.get('cacheFiltersSwitchState'));
      }
    }
  });

  _exports.default = _default;
});