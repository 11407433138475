define("apollo/pods/components/server-table/filters/date-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YCQkxsu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"padding-bottom: 15px\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"column\",\"filterUsed\"]],\"has-feedback\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"ember-flatpickr\",null,[[\"altFormat\",\"altInput\",\"altInputClass\",\"ariaDateFormat\",\"appendDataInput\",\"date\",\"dateFormat\",\"enableTime\",\"locale\",\"onChange\"],[\"d.m.Y\",true,\"form-control date-chooser\",\"d.m.Y\",true,[27,\"if\",[[23,[\"column\",\"filterString\"]],[23,[\"column\",\"filterString\"]],null],null],\"d.m.Y\",false,[23,[\"sessionAccount\",\"language\"]],[27,\"action\",[[22,0,[]],\"setDate\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"i\"],[11,\"class\",\"clearFilterIcon form-control-feedback fa fa-times-circle\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"column\",\"filterString\"]]],null],\"\"],null]],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/server-table/filters/date-filter/template.hbs"
    }
  });

  _exports.default = _default;
});