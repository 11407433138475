define("apollo/pods/components/price-list/route-offers-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    classNames: ['route-offers-table'],
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    numberOfAllOffers: Ember.computed('offers.length', function () {
      return this.get('offers.length');
    }),
    defaultDisplayLimit: Ember.computed('sessionAccount', function () {
      const DEFAULT_LIMIT = 6;
      const limit = this.get('sessionAccount').getSettingValue('DEFAULT_NUMBER_OF_DISPLAYED_OFFERS') || DEFAULT_LIMIT;
      return parseInt(limit);
    }),
    displayLimit: Ember.computed('defaultDisplayLimit', function () {
      return this.get('defaultDisplayLimit');
    }),
    slicedOffers: Ember.computed('offers', 'displayLimit', function () {
      return this.get('offers').slice(0, this.get('displayLimit'));
    }),
    numberOfDisplayedOffers: Ember.computed('slicedOffers.length', function () {
      return this.get('slicedOffers.length');
    }),
    lengthOfNextSlice: Ember.computed('numberOfAllOffers', 'numberOfDisplayedOffers', 'defaultDisplayLimit', function () {
      const numberOfRemainingOffers = this.get('numberOfAllOffers') - this.get('numberOfDisplayedOffers');
      return Math.min(numberOfRemainingOffers, this.get('defaultDisplayLimit'));
    }),
    showMoreShouldBeVisible: Ember.computed('numberOfAllOffers', 'numberOfDisplayedOffers', function () {
      return this.get('numberOfDisplayedOffers') < this.get('numberOfAllOffers');
    }),
    actions: {
      increaseDisplayLimit() {
        const newLimit = this.get('displayLimit') + this.get('defaultDisplayLimit');
        this.set('displayLimit', newLimit);
      },

      async confirm(offer) {
        console.log(`confirm ${offer}...`);

        if (!confirm(this.get('intl').t('route.offer.confirmation.message'))) {
          return;
        }

        const offerId = offer.id;
        const path = this.get('apolloApiService').APOLLO_API.ROUTE_OFFERS.CONFIRM;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            offerId
          });

          if (response.ok) {
            console.log(` > Potwierdziliśmy ofertę ${offer}`);
            offer.reload();
          } else {
            alert(this.get('intl').t('route.offer.confirmation.failure'));
          }
        } catch (error) {
          alert(this.get('intl').t('common.defaultError'));
        }
      },

      edit(offer) {
        this.openEditRateModal(offer);
      },

      delete(offer) {
        const hasAuth = this.get('sessionAccount').hasAuthorityInTransportType('ROLE_CAN_DELETE_ROUTE_OFFER', offer.get('route.transportType.id'));

        if (!hasAuth) {
          console.error('Użytkownik nie ma uprawnień do usuwania ofert');
          return;
        }

        if (!confirm(this.get('intl').t('route.offer.deleteMessage'))) {
          return;
        }

        offer.deleteRecord();
        offer.save().then(deletedOffer => {
          console.debug(`Usunięto ofertę ${deletedOffer.id}`);
        });
      }

    }
  });

  _exports.default = _default;
});