define("apollo/helpers/fa-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faIcon = faIcon;
  _exports.default = void 0;

  function faIcon([icon, type], namedArgs) {
    const styleType = !type || type === '' ? 'solid' : type;
    const additionalClass = namedArgs.class === undefined ? '' : namedArgs.class;
    return Ember.String.htmlSafe(`<i class="fa-${styleType} fa-${icon} ${additionalClass}"></i>`);
  }

  var _default = Ember.Helper.helper(faIcon);

  _exports.default = _default;
});