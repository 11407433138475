define("apollo/pods/components/aggregated-authority-table/role-group-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isChecked: Ember.computed('', {
      get(_) {
        const roleGroup = this.get('roleGroup');
        const aggregatedAuthorities = this.get('aggregatedAuthorities');
        const roleGroupIdsOfAggregatedAuthorites = aggregatedAuthorities.map(a => a.get('roleGroupId'));
        return roleGroupIdsOfAggregatedAuthorites.includes(Number(roleGroup.get('id')));
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      onToggle() {
        let aggregatedAuthority = this.get('aggregatedAuthorities').find(a => a.get('roleGroupId') === Number(this.get('roleGroup.id')));

        if (!aggregatedAuthority) {
          // W przypadku zaznaczenia checkbox'a podajemy dowolny AR, na którym będziemy jedynie wzorować stworzenie nowego uprawnienia
          aggregatedAuthority = this.get('aggregatedAuthorities.firstObject');
        }

        this.sendAction('onCheckboxToggle', this.get('roleGroup'), aggregatedAuthority, !this.get('isChecked'));
      }

    }
  });

  _exports.default = _default;
});