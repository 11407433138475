define("apollo/pods/components/checkpoint/confirm-status-button/guard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6y2To/5t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-button-name\",\"confirm-checkpoint\"],[11,\"class\",\"btn btn-primary\"],[12,\"disabled\",[27,\"if\",[[27,\"or\",[[23,[\"checkpoint\",\"hasUnsynchronizedWeight\"]],[23,[\"checkpoint\",\"confirmButtonShouldBeDisabled\"]]],null],\"disabled\"],null]],[12,\"style\",[27,\"if\",[[23,[\"checkpoint\",\"altCheckpointColor\"]],[23,[\"checkpoint\",\"altCheckpointColor\"]],[23,[\"transport\",\"backgroundColorStyle\"]]],null]],[12,\"title\",[28,[[27,\"if\",[[23,[\"checkpoint\",\"confirmButtonShouldBeDisabled\"]],[23,[\"checkpoint\",\"checkpointBlockadeInfo\",\"title\"]]],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isButtonDisabled\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,[\"checkpoint\",\"altCheckpointLabel\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],\"confirm\",[23,[\"checkpoint\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/checkpoint/confirm-status-button/guard/template.hbs"
    }
  });

  _exports.default = _default;
});