define("apollo/pods/components/company-nav-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    companyLabel: Ember.computed('company.partnershipWithPrincipal.partnershipType.name', function () {
      return this.get('company.partnershipWithPrincipal.partnershipType.name') || 'COMPANY';
    })
  });

  _exports.default = _default;
});