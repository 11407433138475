define("apollo/pods/components/inspection-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RWqb716m",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"showCancelButton\",\"cancel\",\"classNames\"],[[23,[\"title\"]],true,[27,\"action\",[[22,0,[]],\"cancel\",[23,[\"transport\"]]],null],[23,[\"classes\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"form-horizontal row\"],[9],[0,\"\\n        \"],[1,[27,\"transport/custom-fields-section\",null,[[\"transport\",\"inspectionPanelSection\"],[[23,[\"transport\"]],true]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-footer form-buttons padding-top-big\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"save\",[23,[\"transport\"]]]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.cancel\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/inspection-modal/template.hbs"
    }
  });

  _exports.default = _default;
});