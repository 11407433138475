define("apollo/pods/transaction/modals/choose-carrier/transaction-prices-section/price-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KDfUmkZ4",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-xs-5 control-label\"],[12,\"data-field-name\",[28,[[27,\"if\",[[23,[\"deliveryNumber\"]],\"delivery-number\"],null]]]],[9],[0,\"\\n            \"],[1,[21,\"label\"],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"col-xs-7 no-padding\"],[9],[0,\"\\n            \"],[7,\"div\"],[12,\"class\",[28,[\"col-xs-7 no-left-padding \",[27,\"if\",[[23,[\"error\"]],\"has-error\"],null]]]],[11,\"data-field-name\",\"PRICE\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"type\",\"step\",\"pattern\",\"min\",\"class\",\"value\",\"placeholder\",\"disabled\"],[\"number\",\"5\",\"^[0-9]+\\\\.[0-9][0-9]$\",\"0\",\"form-control\",[23,[\"price\"]],\"0.00\",[23,[\"isPriceDisabled\"]]]]],false],[0,\"\\n                \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"errorModel\"]],[23,[\"errorFieldName\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-xs-5 no-padding pull-right\"],[11,\"data-field-name\",\"CURRENCY\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"selected\",\"options\",\"renderInPlace\",\"searchField\",\"placeholder\",\"disabled\",\"onchange\"],[\"form-control\",[23,[\"selectedCurrency\"]],[23,[\"currencies\"]],true,\"symbol\",[27,\"t\",[\"common.currency\"],null],[23,[\"isCurrencyDisabled\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"selectedCurrency\"]]],null]],null]]],{\"statements\":[[0,\"                    \"],[1,[22,1,[\"symbol\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/transaction/modals/choose-carrier/transaction-prices-section/price-label/template.hbs"
    }
  });

  _exports.default = _default;
});