define("apollo/pods/users/activity/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_ACCESS_USERS_ACTIVITY'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        return this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      params.max = params.max || 10;
      params.page = params.page || 1;
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', params.user_id),
        logs: this.store.query('transportLog', {
          userId: params.user_id,
          max: params.max,
          page: params.page
        })
      });
    },
    actions: {
      loading(transition, originRoute) {
        (0, _jquery.default)("html, body").scrollTop(0);
        return true;
      }

    }
  });

  _exports.default = _default;
});