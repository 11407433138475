define("apollo/pods/components/user-edit-form-outlet/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    isInMyAccount: Ember.computed('user.id', function () {
      return this.get('user.id') === this.get('sessionAccount.currentUser.id');
    }),
    isEditing: Ember.computed('user.id', function () {
      return this.get('user').get('id') !== null;
    }),
    limitedEdition: Ember.computed('user.{id,company.principalInDifferentPrincipalSpace}', function () {
      return this.get('user.id') && this.get('user.company.principalInDifferentPrincipalSpace');
    })
  });

  _exports.default = _default;
});