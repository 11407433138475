define("apollo/pods/components/classification-resource/place-assignment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Vz+IAjD",
    "block": "{\"symbols\":[\"place\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row place-assignment\"],[12,\"data-transport-id\",[28,[[23,[\"resource\",\"transport\",\"id\"]]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"place-assignment-header\"],[12,\"data-field-name\",[28,[[23,[\"resource\",\"carRegistrationNumber\"]]]]],[9],[0,\"\\n        \"],[1,[23,[\"resource\",\"ordinalNumber\"]],false],[0,\" \"],[1,[23,[\"resource\",\"carRegistrationNumber\"]],false],[0,\" \"],[1,[23,[\"resource\",\"classificationCheckpointsString\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"place-assignment-buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"queue\",\"enabledPlaces\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"await-response-button\",null,[[\"class\",\"style\",\"disabled\",\"data-button-name\",\"data-loading-text\",\"text\",\"submit\"],[[27,\"mut\",[[23,[\"buttonClass\"]]],null],[27,\"place-button-style\",null,[[\"resource\",\"place\"],[[23,[\"resource\"]],[22,1,[]]]]],[27,\"if\",[[23,[\"shouldButtonBeDisabled\"]],\"disabled\",\"\"],null],\"assign-place\",[27,\"mut\",[[22,1,[\"shortName\"]]],null],[27,\"mut\",[[22,1,[\"shortName\"]]],null],[27,\"action\",[[22,0,[]],\"assignPlace\",[23,[\"resource\"]],[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/classification-resource/place-assignment/template.hbs"
    }
  });

  _exports.default = _default;
});