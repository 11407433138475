define("apollo/pods/warehouse-locations/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    model: function (params) {
      const warehouseLocation = this.store.findRecord('warehouseLocation', params.id);
      const gates = this.get('store').findAll('gate');
      return Ember.RSVP.hash({
        warehouseLocation,
        gates
      });
    },
    renderTemplate: function () {
      this.render('warehouseLocations.create');
    },
    setupController: function (controller, model) {
      const gates = this.get('store').findAll('gate').then(gates => {
        return gates.filterBy('isEntryGate');
      });
      this.controllerFor('warehouse-locations.create').setProperties({
        warehouseLocation: model,
        gates
      });
    },
    actions: {
      willTransition: function () {
        this.get('controller').setProperties({
          success: false,
          errors: []
        });
        this.get('currentModel').unloadRecord();
        return true;
      }
    }
  });

  _exports.default = _default;
});