define("apollo/pods/components/custom-field-options-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    availableTransportPanels: Ember.computed('model.id', function () {
      return this.get('model.TRANSPORT_PANEL_VALUES');
    }),
    actions: {
      addOption() {
        const option = this.get('store').createRecord('custom-field-option', {});
        this.get('model.options').pushObject(option);
        this.get('model').validate();
      },

      removeOption(option) {
        console.debug(`Removing option ${option.get('name')}..`);
        option.set('enabled', false);
        this.get('model').validate();
      },

      addDependency(option) {
        this.set('action', 'showModal');
        this.sendAction('action', 'components.custom-field-option-dependency-modal', option);
      }

    }
  });

  _exports.default = _default;
});