define("apollo/pods/truck-types/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_MANAGE_TRUCK_TYPES']),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      return Ember.RSVP.hash({
        transportTypes: this.store.findAll('transportType'),
        truckTypes: this.store.findAll('truckType')
      });
    },
    actions: {
      refreshRoute: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});