define("apollo/pods/components/transport/custom-fields-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend({
    store: service(),
    sessionAccount: service('session-account'),
    customFieldsFilters: Ember.computed('transportType.customFieldDefinitions.@each', function () {
      return this.get('transportType.customFieldDefinitions').filterBy('filterInArchive');
    })
  });

  _exports.default = _default;
});