define("apollo/pods/role-group/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    principalCompany: belongsTo('company', {
      async: true
    }),
    notifications: hasMany('notification', {
      async: true
    }),
    transportTypes: hasMany('transportTypes', {
      async: true
    }),
    authorities: hasMany('authority', {
      async: true
    }),
    name: attr('string'),
    canCreateTransportOfType: attr('boolean'),
    canSeeTransportOfType: attr('boolean'),
    namePl: attr('string'),
    nameEn: attr('string'),
    isGuard: attr('boolean'),
    usedForDeliveries: attr('boolean'),
    usedForMarcos: attr('boolean'),
    partnershipType: belongsTo('partnership-type'),
    canBeInvited: attr('boolean'),
    ownCompanyInvitation: attr('boolean'),
    roleGroupToCopyFrom: belongsTo('roleGroup'),
    validations: {
      namePl: {
        presence: {
          message: 'blank'
        }
      },
      nameEn: {
        presence: {
          message: 'blank'
        }
      },
      roleGroupToCopyFrom: {
        custom: [{
          validation(key, model, value) {
            if (model.get('id')) {
              return true;
            }

            return value && value.get('id');
          },

          message: 'blankSelect'
        }]
      }
    },
    nameInAppropriateLanguage: Ember.computed('namePl', 'nameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('namePl', 'nameEn');
      return this.get(propertyName);
    }).readOnly(),
    numericId: Ember.computed('id', function () {
      return Number(this.get('id'));
    }).readOnly(),
    authoritiesIds: Ember.computed('authorities.[]', function () {
      return this.hasMany('authorities').ids();
    }),
    announcementAttributeName: Ember.computed('name', function () {
      return `announcement-role-group-${this.get('name')}`;
    }),
    announcementLabel: Ember.computed('nameInAppropriateLanguage', function () {
      return this.get('nameInAppropriateLanguage');
    })
  });

  _exports.default = _default;
});