define("apollo/pods/warehouse/serializer", ["exports", "ember-data", "apollo/mixins/serializer-mixin"], function (_exports, _emberData, _serializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _serializerMixin.default, {
    attrs: {
      address: {
        embedded: 'save'
      }
    },
    serializeBelongsTo: function (record, json, relationship) {
      const key = relationship.key;
      const embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === 'save') {
        const data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = embeddedRecord.belongsTo(deepKey).id;
            }
          });
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});