define("apollo/pods/places/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      return this.store.createRecord('place');
    },
    actions: {
      willTransition() {
        this.get('controller').setProperties({
          success: null,
          errors: []
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});