define("apollo/services/transport-relations/relations-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentTransports: Ember.A([]),

    addRelation(transport) {
      this.currentTransports.pushObject(transport);
    },

    removeRelation(transport) {
      this.currentTransports.removeObject(transport);
    },

    findExisting(transport) {
      return this.currentTransports.find(relation => {
        return relation.get('id') === transport.get('id');
      });
    },

    clear() {
      while (this.currentTransports.length > 0) {
        this.currentTransports.pop();
      }
    }

  });

  _exports.default = _default;
});