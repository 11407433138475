define("apollo/pods/components/confirm-statuses/guard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/XmkhDpf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"checkpoint\",\"isBlocked\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[12,\"title\",[23,[\"checkpoint\",\"blockadeReason\"]]],[9],[1,[27,\"t\",[\"checkpoint.blockTextShort\"],[[\"name\"],[[23,[\"checkpoint\",\"blockadeRequester\",\"name\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"checkpoint\",\"canBeRejected\"]],\"margin-left\"],null]]]],[9],[0,\"\\n        \"],[1,[27,\"checkpoint/confirm-status-button\",null,[[\"checkpoint\",\"renderInTable\"],[[23,[\"transport\",\"firstUnconfirmedCheckpoint\"]],true]]],false],[0,\"\\n        \"],[1,[27,\"checkpoint/reject-status-button\",null,[[\"checkpoint\"],[[23,[\"checkpoint\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"checkpoint/rejection-date-text\",null,[[\"checkpoint\"],[[23,[\"transport\",\"firstUnconfirmedCheckpoint\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/confirm-statuses/guard/template.hbs"
    }
  });

  _exports.default = _default;
});