define("apollo/pods/authority-restrictions/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authoritiesIndexController: Ember.inject.controller('authorities/index'),
    aggregatedAuthoritiesIndexController: Ember.inject.controller('authorities/aggregate'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    // TODO: Zastąpić przez apolloApiService
    ajaxService: Ember.inject.service('ajax-service'),
    tasksArray: Ember.computed('model.transportType', function () {
      return [...this.get('model.transportType.workflow.sortedTasks')];
    }),
    cfArray: Ember.computed('model.transportType', function () {
      return [...this.get('model.transportType.sortedFieldDefinitions')];
    }),
    tasksToSave: Ember.computed('model.authorityRestriction.{id,task}', function () {
      if (this.get('model.authorityRestriction.id')) {
        return [this.get('model.authorityRestriction.task')];
      } else {
        return [];
      }
    }),
    cfsToSave: Ember.computed('model.authorityRestriction.{id,customFieldDefinition}', function () {
      if (this.get('model.authorityRestriction.id')) {
        return [this.get('model.authorityRestriction.customFieldDefinition')];
      } else {
        return [];
      }
    }),
    errors: Ember.A([]),
    saveSingle: false,
    AUTHORITY_RANGES: Ember.A(['UNLIMITED', 'ACTIVE', 'CHECKPOINT_BASED', 'BEFORE_CHECKPOINT_BASED', 'AFTER_CHECKPOINT_BASED', 'TIME_BASED', 'ADVICE_RESTRICTION_BASED', 'ACTIVE_AUCTION_BASED', 'NO_ROUTE_OFFER', 'OWN_COMPANY_BASED']),
    TIME_REFERENCES: Ember.A(['TRANSPORT_DATE_CREATED', 'EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE', 'EARLIEST_TIME_WINDOW_START_AFTER_CHANGE', 'LATEST_TIME_WINDOW_STOP', 'EARLIEST_TIME_WINDOW_CURRENT_DATE']),
    SIGN_OPTIONS: Ember.A(['-', '+']),
    UNIT_OPTIONS: Ember.A(['s', 'm', 'h', 'd']),
    actionTypes: Ember.computed('sessionAccount.isSuperUser', function () {
      const authorities = this.get('store').peekAll('authority').filter(a => a.get('assetType'));

      if (this.get('sessionAccount.isSuperUser')) {
        return authorities;
      }

      return authorities.filter(a => this.get('sessionAccount.actionTypes').includes(a.get('name')));
    }),
    allRequiredFieldsCompleted: Ember.computed('model.authorityRestriction.{authority,authorityRange,timeInterval,timeReference,task}', function () {
      if (!this.get('model.authorityRestriction.authority') || !this.get('model.authorityRestriction.authorityRange')) {
        return false;
      }

      switch (this.get('model.authorityRestriction.authorityRange')) {
        case 'BEFORE_CHECKPOINT_BASED':
        case 'AFTER_CHECKPOINT_BASED':
          return this.get('model.authorityRestriction.task');

        case 'TIME_BASED':
          return this.get('model.authorityRestriction.timeInterval') && this.get('model.authorityRestriction.timeReference');
      }

      return true;
    }),
    // eslint-disable-next-line ember/no-observers
    setTimeInterval: Ember.observer('model.authorityRestriction.{timeIntervalSign,timeIntervalValue,timeIntervalUnit}', function () {
      let sign = this.get('model.authorityRestriction.timeIntervalSign');
      const value = this.get('model.authorityRestriction.timeIntervalValue');
      const unit = this.get('model.authorityRestriction.timeIntervalUnit');

      if (!sign || !value || !unit) {
        return;
      }

      if (sign === '+') {
        sign = '';
      }

      this.get('model.authorityRestriction').set('timeInterval', `${sign}${value}${unit}`);
    }),
    customFieldActionType: Ember.computed('model.authorityRestriction.authority', function () {
      return ['ACCESS_CUSTOM_FIELD', 'UPDATE_CUSTOM_FIELD'].includes(this.get('model.authorityRestriction.authority.name'));
    }),
    dependsOnSpecificAsset: Ember.computed('model.authorityRestriction.authority.assetType', function () {
      const assetType = this.get('model.authorityRestriction.authority.assetType');
      return assetType && assetType !== 'TRANSPORT_TYPE';
    }),
    findAssetOptions: Ember.computed('model.{authorityRestriction.authority.assetType,transportType.id}', function () {
      const assetType = this.get('model.authorityRestriction.authority.assetType');

      if (!assetType) {
        return [];
      }

      const transportTypeId = this.get('model.transportType.id');

      switch (assetType) {
        case 'CUSTOM_FIELD_DEFINITION':
          return this.get('store').peekAll('custom-field-definition').filter(cf => cf.get('transportType.id') === transportTypeId);

        default:
          return [];
      }
    }),
    multiSelect: Ember.computed('model.multiSelect', function () {
      return this.get('model.multiSelect');
    }),
    roleGroups: Ember.computed(function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);
    }),
    prepareAuthorityRestrictionToSave: function (ar) {
      if (!ar) {
        ar = this.get('model.authorityRestriction');
      }

      const authorityRestriction = ar;

      if (authorityRestriction.get('authority.assetType') === 'TRANSPORT_TYPE' && !authorityRestriction.get('assetId')) {
        const ttId = this.get('model.transportType.id');
        authorityRestriction.set('assetId', ttId);
      }

      if (!authorityRestriction.validate()) {
        console.log('Authority restriction nie przeszło walidacji..');
        return false;
      }

      return authorityRestriction;
    },
    actions: {
      setAssetId: function (item) {
        if (item && item.id) {
          this.set('model.authorityRestriction.assetId', item.id);
        }
      },
      save: function () {
        const self = this;
        const tasksToSave = this.get('tasksToSave');
        const cfsToSave = this.get('cfsToSave');
        const roleGroups = this.get('model.roleGroups');
        const multiSelect = this.get('multiSelect');
        const roleGroupAuthorityRestrictionsList = Ember.A();

        if (cfsToSave.length > 0 && cfsToSave.get(0) !== null && !this.get('saveSingle')) {
          self.send('saveMultipleCfs', cfsToSave);
          self.set('cfsToSave', []);
          return;
        }

        if (tasksToSave.length > 0) {
          return self.send('saveMultipleTasks', tasksToSave);
        }

        const authorityRestriction = self.prepareAuthorityRestrictionToSave();

        if (!authorityRestriction) {
          return;
        }

        if (multiSelect) {
          roleGroups.forEach(rg => {
            roleGroupAuthorityRestrictionsList.pushObject(rg.get('id'));
          });
          const data = {
            authorityRestriction,
            roleGroupIds: roleGroupAuthorityRestrictionsList
          };
          const url = '/authority-restrictions/aggregatedAuthorityRestrictions';
          self.get('ajaxService').send(url, 'POST', data, true).then(() => {
            const msg = self.get('intl').t('settings.roleGroups.successUpdate');
            self.get('aggregatedAuthoritiesIndexController').set('errors', '');
            self.get('aggregatedAuthoritiesIndexController').set('successMessage', msg);
            self.transitionToRoute('authorities.aggregate');
          }).catch(response => {
            self.get('aggregatedAuthoritiesIndexController').set('errors', response.responseJSON.errors);
            self.get('aggregatedAuthoritiesIndexController').set('successMessage', '');
            self.transitionToRoute('authorities.aggregate');
          });
        } else {
          const roleGroupId = authorityRestriction.get('roleGroup.id');
          const oldId = this.get('model.authorityRestriction.id');
          const url = `/authority-restrictions/${oldId || ''}`;
          const method = oldId ? 'PUT' : 'POST';
          this.get('ajaxService').send(url, method, authorityRestriction.serialize(), true).then(response => {
            authorityRestriction.unloadRecord();
            self.get('store').pushPayload(response);
            self.setProperties({
              success: true,
              errors: []
            });
            const msg = self.get('intl').t('settings.roleGroups.successUpdate');
            self.get('authoritiesIndexController').set('successMessage', msg);
            self.transitionToRoute('authorities.index', roleGroupId);
          }).catch(response => {
            self.set('errors', response.errors);
          });
        }
      },
      saveMultipleCfs: function (cfsToSave) {
        const self = this;
        this.set('saveSingle', true);
        cfsToSave.forEach(cf => {
          self.send('setAssetId', cf);
          self.send('save');
        });
        this.set('saveSingle', false);
      },
      saveMultipleTasks: function (tasksToSave) {
        const self = this;
        let roleGroupId = '';
        const preparedAuthorityRestrictionsToSave = tasksToSave.map(task => {
          const oAR = self.get('model.authorityRestriction');
          const ar = self.store.createRecord('authority-restriction', {
            task,
            authorityRange: oAR.get('authorityRange'),
            authority: oAR.get('authority'),
            roleGroup: oAR.get('roleGroup'),
            assetId: oAR.get('assetId')
          });
          return self.prepareAuthorityRestrictionToSave(ar);
        });

        if (!preparedAuthorityRestrictionsToSave) {
          return;
        }

        const promiseFuncs = preparedAuthorityRestrictionsToSave.map(authorityRestriction => () => {
          roleGroupId = authorityRestriction.get('roleGroup.id');
          const oldId = this.get('model.authorityRestriction.id');
          const url = `/authority-restrictions/${oldId || ''}`;
          const method = oldId ? 'PUT' : 'POST';
          return self.get('ajaxService').send(url, method, authorityRestriction.serialize(), true);
        });
        this.get('ajaxService').sequence(promiseFuncs).then(response => {
          self.get('store').pushPayload(response);
          self.setProperties({
            success: true,
            errors: []
          });
          const msg = self.get('intl').t('settings.roleGroups.successUpdate');
          self.get('authoritiesIndexController').set('successMessage', msg);
          self.get('store').peekAll('authority-restriction').filterBy('id', null).forEach(ar => {
            ar.unloadRecord();
          });
          const oldId = this.get('model.authorityRestriction.id');

          if (oldId) {
            const oldAr = self.get('store').peekRecord('authority-restriction', oldId);

            if (oldAr) {
              oldAr.unloadRecord();
            }
          }

          self.transitionToRoute('authorities.index', roleGroupId);
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        if (this.get('model.multiSelect')) {
          this.transitionToRoute('authorities.aggregate');
        } else {
          const roleGroupId = this.get('model.authorityRestriction.roleGroup.id');
          this.get('model.authorityRestriction').unloadRecord();
          this.set('saveMultipleCfs', []);
          this.set('cfsToSave', []);
          this.transitionToRoute('authorities.index', roleGroupId);
        }
      },

      delete() {
        const self = this;
        const authorityRestriction = this.get('model.authorityRestriction');
        const roleGroupId = authorityRestriction.get('roleGroup.id');
        authorityRestriction.destroyRecord().then(a => {
          const msg = self.get('intl').t('settings.roleGroups.successDelete');
          self.get('authoritiesIndexController').set('successMessage', msg);
          self.transitionToRoute('authorities.index', roleGroupId);
        }).catch(response => {
          self.set('errors', response.errors);
        });
      }

    }
  });

  _exports.default = _default;
});