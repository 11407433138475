define("apollo/pods/users/create/controller", ["exports", "apollo/mixins/persistence-mixin", "apollo/mixins/ramps-access-mixin", "apollo/mixins/queues-access-mixin", "apollo/mixins/user-utils-mixin"], function (_exports, _persistenceMixin, _rampsAccessMixin, _queuesAccessMixin, _userUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _rampsAccessMixin.default, _userUtilsMixin.default, _queuesAccessMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    usersIndexController: Ember.inject.controller('users/index'),
    usersShowController: Ember.inject.controller('users/show'),
    errors: Ember.A([]),
    user: Ember.computed('model.user', function () {
      return this.get('model.user');
    }),
    isEditing: Ember.computed('user.id', function () {
      return this.get('user').get('id') !== null;
    }),
    isInMyAccount: Ember.computed('user.id', function () {
      return this.get('user.id') === this.get('sessionAccount.currentUser.id');
    }),
    roleGroups: Ember.computed('user.company.partnershipType', function () {
      const invitationRestriction = this.get('model.roleGroupInvRestriction');

      if (!invitationRestriction) {
        return [];
      }

      const properPartnershipTypeId = this.get('user.company.partnershipType.id');
      return invitationRestriction.filter(ir => ir.get('invitedRoleGroup.partnershipType.id') === properPartnershipTypeId).map(ir => ir.get('invitedRoleGroup'));
    }),
    canEditRoleGroup: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_EDIT_ROLE_GROUP');
    }),
    showUserGroupsSection: Ember.computed('user.company.{isPrincipal,userGroup.length}', function () {
      return !this.get('user.company.isPrincipal') && this.get('user.company.customUserGroupsExists') || this.get('sessionAccount').isGroupFilterEnabled() || this.get('sessionAccount').isGroupFilterEnabledForUser(this.get('user'));
    }),
    actions: {
      setPhonePrefix(phonePrefix) {
        this.set('user.mobilePhoneNumberPrefix', phonePrefix);
      },

      setCompany(item) {
        const self = this;
        this.store.findRecord('company', item.id).then(company => {
          self.get('user').setProperties({
            company,
            roleGroup: company.get('defaultRoleGroup')
          });
        });
      },

      setRoleGroup(group) {
        this.get('user').setProperties({
          oldRoleGroup: this.get('user').get('roleGroup'),
          roleGroup: group
        });
      },

      setUserGroup(group) {
        this.get('user').setProperties({
          oldUserGroup: this.get('user').get('userGroup'),
          userGroup: group
        });
      },

      save: function () {
        const self = this;
        const user = this.get('user');
        if (!user.validate()) return;
        user.save().then(function (user) {
          self.set('errors', []);
          const msg = self.get('intl').t('user.accountUpdated');

          if (self.get('isEditing')) {
            if (self.get('isInMyAccount')) {
              user.setProperties({
                changePassword: false,
                password: '',
                newPassword: '',
                repeatedNewPassword: ''
              });
              self.set('successMessage', msg);
              self.transitionToRoute('users.edit', user.get('id'));
            } else {
              self.get('usersShowController').set('successMessage', msg);
              self.transitionToRoute('users.show', user.get('id'));
            }
          } else {
            self.get('usersIndexController').set('successMessage', msg);
            self.transitionToRoute('users.index');
          }
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        this.get('errors').clear();

        if (!this.get('isEditing')) {
          this.get('user').unloadRecord();
          window.history.back();
        } else {
          const user = this.get('user');
          user.rollbackAttributes();

          if (user.get('oldRoleGroup')) {
            user.setProperties({
              roleGroup: user.get('oldRoleGroup'),
              oldRoleGroup: null
            });
          }

          if (user.get('oldUserGroup')) {
            user.setProperties({
              userGroup: user.get('oldUserGroup'),
              oldUserGroup: null
            });
          }

          window.history.back();
        }
      },

      delete() {
        const self = this;
        const userModel = this.get('user');
        const msg = self.get('intl').t('user.deletedUser');
        userModel.destroyRecord().then(u => {
          console.log(`Poprawnie usunęliśmy użytkownika ${u.get('name')}.`);
          self.get('usersIndexController').set('successMessage', msg);
          self.transitionToRoute('users.index');
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      async disable() {
        const self = this;
        const user = this.get('user').get('name');
        const userId = this.get('user').get('id');
        const msg = self.get('intl').t('user.disabledUser');
        const path = this.get('apolloApiService').APOLLO_API.USER.DISABLE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            userId
          });

          if (response.ok) {
            console.log(`Poprwanie wyłączyliśmy użytkownika ${user}`);
            self.get('usersIndexController').set('successMessage', msg);
            self.transitionToRoute('users.index');
          } else {
            const error = await response.json();
            self.set('errors', error.errors);
          }
        } catch (error) {
          self.set('errors', [this.get('intl').t('common.defaultError')]);
        }
      },

      toggleChangePasswordAndSetProperty: function () {
        this.toggleProperty('user.changePassword');
        this.get('user').setProperties({
          password: '',
          newPassword: '',
          repeatedNewPassword: ''
        });
      }
    }
  });

  _exports.default = _default;
});