define("apollo/pods/algorithm-settings/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    warehouseZone: Ember.computed('model.id', function () {
      return this.get('model.warehouseZone');
    }),
    selectedContractorsGroup: Ember.computed('contractorsGroups.length', function () {
      return this.get('contractorsGroups.firstObject');
    }),
    contractorsGroups: Ember.computed('warehouseZome.algorithmSettings.@each.contractorsGroup', function () {
      return this.get('warehouseZone.algorithmSettings').getEach('contractorsGroup').uniqBy('id').sortBy('name').filter(cg => cg.get('content'));
    }),
    algorithmSettings: Ember.computed('model.algorithmSettings', 'warehouseZone.algorithmSettings.@each.contractorsGroupId', 'selectedContractorsGroup.id', function () {
      const self = this;
      return this.get('model.algorithmSettings').filter(as => {
        return as.get('contractorsGroup.id') === self.get('selectedContractorsGroup.id');
      });
    })
  });

  _exports.default = _default;
});