define("apollo/pods/documents/modals/document-list-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "df3PlwY5",
    "block": "{\"symbols\":[\"document\"],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"subtitle\",\"classNames\"],[[23,[\"title\"]],[23,[\"subtitle\"]],\"document-modal\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"documents\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"padding-bottom\"],[9],[0,\"\\n            \"],[1,[27,\"documents-applies/document-form\",null,[[\"document\",\"documentTypes\"],[[22,1,[]],[23,[\"model\",\"documentTypes\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.close\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/documents/modals/document-list-modal/template.hbs"
    }
  });

  _exports.default = _default;
});