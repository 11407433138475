define("apollo/pods/temporary-account-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    user: Ember.computed('sessionAccount.currentUser', function () {
      return this.get('sessionAccount.currentUser');
    })
  });

  _exports.default = _default;
});