define("apollo/pods/courier-pricing-error/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    errorCode: _emberData.default.attr('string'),
    errorMessage: _emberData.default.attr('string'),
    errorType: _emberData.default.attr('string'),
    courierPricing: _emberData.default.belongsTo('courierPricing'),
    validations: {}
  });

  _exports.default = _default;
});