define("apollo/pods/components/table-columns/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    transportTypes: Ember.computed('model.transportTypes.[]', function () {
      return this.get('model.transportTypes');
    }),
    showTableColumnSection: Ember.computed('selectedTransportType', function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_COLUMN_VISIBILITY');
    })
  });

  _exports.default = _default;
});