define("apollo/pods/queues/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actions: {
      save() {
        const self = this;
        this.get('model').validate();

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').save().then(queue => {
          console.debug(`Queue ${queue.get('name')} saved.`);
          self.setProperties({
            errors: []
          });
          const msg = self.get('intl').t('queues.updateSuccessMessage', {
            queueName: this.get('model').get('name')
          });
          self.set('successMessage', msg);
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      disable() {
        const self = this;
        const model = this.get('model');
        const queueId = this.get('model.id');
        const jsonBody = JSON.stringify(model);
        const path = this.get('apolloApiService').APOLLO_API.QUEUES.DISABLE;
        this.get('apolloApiService').callApolloApi(path, {
          queueId
        }, {
          body: jsonBody
        }).then(() => {
          model.set('enabled', false);
          model.get('places').forEach(place => place.set('enabled', false));
          self.transitionToRoute('squares.edit', self.get('model.square'));
        });
      },

      enable() {
        const self = this;
        const model = this.get('model');
        const queueId = this.get('model.id');
        const jsonBody = JSON.stringify(model);
        const path = this.get('apolloApiService').APOLLO_API.QUEUES.ENABLE; // TODO Skorzystać z ember-data

        this.get('apolloApiService').callApolloApi(path, {
          queueId
        }, {
          body: jsonBody
        }).then(() => {
          model.set('enabled', true);
          model.get('places').forEach(place => place.set('enabled', true));
          self.transitionToRoute('squares.edit', self.get('model.square'));
        });
      },

      cancel() {
        const square = this.get('model.square');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('squares.edit', square);
      }

    }
  });

  _exports.default = _default;
});