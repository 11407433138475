define("apollo/services/gus-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    // TODO Rozwikłać i zastąpić przez apolloApiService
    verifyNip: async function (ibanSymbol, nip) {
      if (!nip) {
        return null;
      }

      const preparedNip = this.prepareNip(nip, ibanSymbol);
      const response = await this.get('ajaxService').send(`/gus/verify?nip=${preparedNip}&ibanSymbol=${ibanSymbol}`);

      if (response.error) {
        throw new Error(response.error);
      }

      return response || null;
    },
    prepareNip: function (nip, ibanSymbol) {
      const preparedNip = nip.toUpperCase().trim();

      if (preparedNip.startsWith(ibanSymbol)) {
        return preparedNip.replace(ibanSymbol, '');
      }

      return preparedNip;
    }
  });

  _exports.default = _default;
});