define("apollo/pods/products/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    intl: service(),
    indexController: Ember.inject.controller('products/index'),
    units: Ember.computed('', function () {
      return ['KG', 'LITERS', 'PIECES'];
    }),
    actions: {
      save() {
        if (!this.get('model').validate()) {
          return;
        }

        const self = this;
        this.get('model').set('enabled', true);
        const isUpdating = !!this.get('model.id');
        this.get('model').save().then(() => {
          const messageKey = isUpdating ? 'product.updated' : 'product.saved';
          const msg = self.get('intl').t(messageKey, {
            index: this.get('model.index')
          });
          self.get('indexController').set('successMessage', msg);
          self.transitionToRoute('products.index');
        });
      },

      disable() {
        const self = this;
        this.get('model').set('enabled', false);
        this.get('model').save().then(() => {
          const msg = self.get('intl').t('product.disabled', {
            index: this.get('model.index')
          });
          self.get('indexController').set('successMessage', msg);
          self.transitionToRoute('products.index');
        });
      },

      cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('products.index');
      }

    }
  });

  _exports.default = _default;
});