define("apollo/pods/components/auction/create-auction-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oNr+JbDR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-sm vertical-margin-small\"],[11,\"data-button-name\",\"auction-button\"],[12,\"style\",[23,[\"transport\",\"backgroundColorStyle\"]]],[9],[0,\"\\n    \"],[1,[27,\"setting-translation\",null,[[\"labelName\",\"settingClass\",\"transportType\"],[\"buttonLabel\",\"auction\",[23,[\"transport\",\"transportType\"]]]]],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"showAuctionCreationForm\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/auction/create-auction-button/template.hbs"
    }
  });

  _exports.default = _default;
});