define("apollo/models/custom-field-pair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Model używany do powiązania custom-field-definition z odpowiadającym custom-field-value.
   * Wykorzystywany w widokach zawierających custom-fieldy
   * @class CustomFieldPair
   * @extends EmberObject
   * @property {Model} definition - ember model custom-field-definition
   * @property {Model} value - ember model custom-field-value
   */
  const CustomFieldPair = Ember.Object.extend({
    definition: null,
    value: null
  });
  var _default = CustomFieldPair;
  _exports.default = _default;
});