define("apollo/utils/objects/groupedLog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    dateCreated: '',
    actor: '',
    userActivityTransportLabel: '',
    eventLabel: '',
    changes: Ember.A([]),
    classNamesNotToGroup: Ember.A(['Checkpoint', 'File', 'TransportTransportRelation', 'ARTR']),
    keyIdentifier: Ember.computed('actor', 'eventLabel', 'className', function () {
      let objectId = this.get('transportId') || this.get('persistedObjectId');

      if (this.get('className') === 'EmailAdvice') {
        objectId = this.get('persistedObjectId');
      }

      return `${this.get('actor')}-${this.get('eventLabel')}-${this.get('className')}-${objectId}-${this.get('customFieldDefinitionId')}`;
    }),
    isMatch: function (otherLog) {
      // To samo zdarzenie - jeśli ta sama osoba, ten sam typ i:
      // - dotyczy tej samej wersji obiektu (jeśli dysponujemy ta informacją),
      // - zostało wykonane w odstępie max 3 sekund (jeśli nie mamy wersji obiektu)
      if (this.get('classNamesNotToGroup').includes(otherLog.get('className'))) {
        return;
      }

      return this.get('keyIdentifier') === otherLog.get('keyIdentifier') && (!Ember.isEmpty(this.get('persistedObjectVersion')) && this.get('persistedObjectVersion') === otherLog.get('persistedObjectVersion') && this.get('eventName') === otherLog.get('eventName') || Ember.isEmpty(this.get('persistedObjectVersion')) && Math.abs(moment(this.get('dateCreated')).diff(moment(otherLog.get('dateCreated')), 'seconds')) < 3);
    }
  });

  _exports.default = _default;
});