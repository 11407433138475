define("apollo/pods/components/aggregated-authority-table/component", ["exports", "apollo/utils/objects/AggregatedAuthority"], function (_exports, _AggregatedAuthority) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    aggregatedAuthorityService: Ember.inject.service('aggregated-authority-service'),

    init() {
      this._super(...arguments);

      Ember.run.debounce(this, this._fetchDataAndSetAuthorities, 500);
    },

    _fetchDataAndSetAuthorities: async function () {
      if (this.get('authorities.length') > 0) {
        return;
      }

      this.set('isLoading', true);
      const authoritiesResponse = await this.aggregatedAuthorityService.fetch(this.get('authorityGroupId'), this.get('transportTypeId'));
      const aggregatedAuthorities = authoritiesResponse.aggregatedAuthorities.map(response => {
        return _AggregatedAuthority.default.create().fromResponse(response);
      });
      const groupedAggregatedAuthorities = (0, _AggregatedAuthority.getGroupedAuthorities)(aggregatedAuthorities);
      this.set('groupedAggregatedAuthorities', groupedAggregatedAuthorities);
      this.set('isLoading', false);
    },
    roleGroups: Ember.computed('', function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.store.peekRecord('company', principalCompanyId).get('roleGroups');
    }),
    isLoading: Ember.computed('', function () {
      return false;
    }),
    groupedAggregatedAuthorities: Ember.computed('', {
      get(_) {
        return null;
      },

      set(key, value) {
        return value;
      }

    }),
    aggregatedAuthoritiesToSave: Ember.A(),
    aggregatedAuthoritiesToRemove: Ember.A(),
    showConditions: Ember.computed('', function () {
      return this.transportTypeId !== ('' || undefined);
    }),
    actions: {
      onCheckboxToggle(roleGroup, aggregatedAuthority, value) {
        const checkboxWasSet = value === true;
        const checkboxWasUnset = value === false;

        const aggregatedAuthorityToRequest = _AggregatedAuthority.default.create().toRequest(roleGroup.get('id'), aggregatedAuthority.get('authorityId'), aggregatedAuthority.get('authorityRestrictionId'));

        if (checkboxWasSet) {
          this.get('aggregatedAuthoritiesToSave').pushObject(aggregatedAuthorityToRequest);
        }

        if (checkboxWasUnset) {
          const foundDuplicateInAuthoritiesToSaveIndex = this.get('aggregatedAuthoritiesToSave').findIndex(authorityToSave => {
            return authorityToSave.compare(aggregatedAuthorityToRequest);
          });

          if (foundDuplicateInAuthoritiesToSaveIndex !== -1) {
            // Usuń z authoritiesToSave jeśli już istnieje
            this.get('aggregatedAuthoritiesToSave').splice(foundDuplicateInAuthoritiesToSaveIndex, 1);
            return;
          }

          this.get('aggregatedAuthoritiesToRemove').pushObject(aggregatedAuthorityToRequest);
        }
      },

      save() {
        const self = this;
        this.set('isLoading', true);
        this.aggregatedAuthorityService.send(this.get('aggregatedAuthoritiesToSave'), this.get('aggregatedAuthoritiesToRemove')).then(_ => {
          self._fetchDataAndSetAuthorities();

          self.set('aggregatedAuthoritiesToSave', Ember.A());
          self.set('aggregatedAuthoritiesToRemove', Ember.A());
        }).catch(_ => {// TODO Obsługa błędu
        });
      }

    }
  });

  _exports.default = _default;
});