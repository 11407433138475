define("apollo/pods/components/checkpoint/rejection-date-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init(...args) {
      this._super(...args);

      (true && !(!!this.get('checkpoint')) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'checkpoint',
        type: 'Checkpoint Model'
      }), !!this.get('checkpoint')));
    },

    tagName: '',
    intl: Ember.inject.service(),
    checkpointRejectionDateShouldBeVisible: Ember.computed('checkpoint.{canBeRejected,rejectionDate}', function () {
      return !!this.get('checkpoint.task.canBeRejectedByRole') && !!this.get('checkpoint.rejectionDate');
    })
  });

  _exports.default = _default;
});