define("apollo/pods/components/checkpoint/weight-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uWtybVFw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-horizontal row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group form-group-padding\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-xs-6 control-label\"],[9],[0,\"\\n            \"],[1,[23,[\"checkpoint\",\"task\",\"checkpointNameInAppropriateLanguage\"]],false],[0,\" - \"],[1,[27,\"t\",[\"transport.weightSection.weight\"],null],false],[0,\" [\"],[1,[27,\"t\",[\"transport.weightSection.unit\"],null],false],[0,\"]\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"col-xs-6 \",[27,\"if\",[[23,[\"checkpoint\",\"currentWeight\",\"errors\",\"readOut\"]],\"has-error\"],null]]]],[12,\"data-field-name\",[28,[\"weight-\",[23,[\"checkpoint\",\"warehouse\",\"name\"]],\"-\",[23,[\"checkpoint\",\"task\",\"checkpointNameInAppropriateLanguage\"]]]]],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"disabled\",\"placeholder\",\"required\",\"value\"],[\"number\",\"form-control\",[23,[\"canEditWeight\"]],\"0,00\",true,[23,[\"weight\",\"readOut\"]]]]],false],[0,\"\\n            \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"weight\"]],\"readOut\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/checkpoint/weight-input/template.hbs"
    }
  });

  _exports.default = _default;
});