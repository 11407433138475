define("apollo/pods/transport-types/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    errors: [],
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    }),
    defaultLoadCompany: Ember.computed('model.defaultLoadCompany', function () {
      return this.get('model.defaultLoadCompany');
    }),
    defaultUnloadCompany: Ember.computed('model.defaultUnloadCompany', function () {
      return this.get('model.defaultUnloadCompany');
    }),
    defaultServicemanCompany: Ember.computed('model.defaultServicemanCompany', function () {
      return this.get('model.defaultServicemanCompany');
    }),
    actions: {
      defaultAocCheckAction() {
        const tt = this.get('transportType');

        if (tt.get('isAmountOfCoverVisible')) {
          tt.set('isAmountOfCoverVisible', false);
          tt.get('defaultAmountOfCover').content.destroyRecord().then(tt.set('defaultAmountOfCover', null));
        } else {
          tt.set('isAmountOfCoverVisible', true);

          if (!this.get('transportType.defaultAmountOfCover.id')) {
            const aoc = this.store.createRecord('amountOfCover', {
              amount: 0.0,
              currency: null
            });
            tt.set('defaultAmountOfCover', aoc);
          }
        }
      },

      setCompany(property, item) {
        this.get('store').findRecord('company', item.id).then(company => {
          this.set(property, company);
          this.set(`transportType.${property}Id`, company.get('id'));
        });
      },

      removeCompany(property) {
        this.set(property, null);
        this.set(`transportType.${property}Id`, null);
      },

      save() {
        const self = this;
        const transportType = this.get('transportType');

        if (!transportType.validate()) {
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
          return;
        }

        if (transportType.get('defaultAmountOfCover.amount')) {
          if (!transportType.get('defaultAmountOfCover.content').validate()) {
            return;
          }
        }

        this.set('errors', []);
        transportType.save().then(function () {
          self.setProperties({
            success: true,
            errors: []
          });
          const msg = self.get('intl').t('settings.transportTypes.settingsSaveSuccess');
          self.set('successMessage', msg);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(response => {
          self.set('errors', response.errors);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      },

      cancel() {
        this.get('model.transportType').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }

    }
  });

  _exports.default = _default;
});