define("apollo/pods/queue-status/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    poolingService: Ember.inject.service('classification-resource-pooling-service'),
    access: ['ROLE_QUEUE_STATUS_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      return Ember.RSVP.hash({
        classificationResources: this.store.findAll('classification-resource'),
        squares: this.store.findAll('square'),
        queues: this.store.findAll('queue')
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      this.get('controller').setProperties({
        model: model
      });
      this.get('poolingService').init(this.pollUpdatesInterval());
    },

    resetController() {
      this._super(...arguments);

      this.get('poolingService').cancel();
    },

    activate() {
      this.activateCurrentTab('QUEUE_STATUS_TAB');
    },

    pollUpdatesInterval() {
      const pollIntervalString = this.get('sessionAccount').getSettingValue('QUEUES_TAB_POLL_INTERVAL');
      return pollIntervalString ? parseInt(pollIntervalString) : 10000;
    }

  });

  _exports.default = _default;
});