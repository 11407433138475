define("apollo/pods/components/transports/transports-table/transport-row/guard-checkpoint-fields/guard-checkpoint-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xf9P80AK",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"confirm-statuses\",null,[[\"transport\",\"checkpoint\",\"asPlainTemplate\",\"confirm\",\"showModal\",\"isFromGuardTable\",\"currentGuardCheckpointsIds\",\"confirmedGuardCheckpoints\"],[[23,[\"checkpoint\",\"transport\"]],[23,[\"checkpoint\"]],[23,[\"asPlainTemplate\"]],\"confirm\",\"showModal\",true,[23,[\"uniqueTaskGuardCheckpointsIds\"]],[23,[\"allConfirmedTaskGuardCheckpoints\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/transports-table/transport-row/guard-checkpoint-fields/guard-checkpoint-button/template.hbs"
    }
  });

  _exports.default = _default;
});