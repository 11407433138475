define("apollo/pods/components/address-add-new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      handleAddressOnPasteEvent(step, event) {
        const company = step.get('company');
        const clipboardData = (event.originalEvent.clipboardData || window.clipboardData).getData('text/plain');

        if (!company || !clipboardData) {
          return;
        }

        try {
          /*
              Adres 1
              Adres 2 (Opcjonalny)
              Ulica                 ==>   ['Adres 1', 'Adres 2', 'Ulica', 'Kod pocztowy Miasto']
              Kod pocztowy Miasto
           */
          const addressComponents = clipboardData.trim().split('\n').filter(value => value.trim() !== '');
          const isThreeLinesFormat = addressComponents.length === 3;
          const recipient = addressComponents[0].trim() + (isThreeLinesFormat ? '' : ' ' + addressComponents[1].trim());
          const street = addressComponents[2 - (isThreeLinesFormat ? 1 : 0)].trim();
          const postalAndCity = addressComponents[3 - (isThreeLinesFormat ? 1 : 0)].trim();
          const postalLastIndex = /[0-9] *[a-zA-Z]/.exec(postalAndCity).index;
          const postal = postalAndCity.slice(0, postalLastIndex + 1);
          const city = postalAndCity.slice(postalLastIndex + 1).trim();
          step.set('address', this.get('store').createRecord('address', {
            company,
            recipient,
            street,
            postal,
            city
          }));
          event.stopPropagation();
          event.preventDefault();
        } catch (err) {
          console.log('Nie udało się przetworzyć podanego adresu');
        }
      }

    }
  });

  _exports.default = _default;
});