define("apollo/pods/components/transaction/create-transaction-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YcablKH6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-sm vertical-margin-small\"],[12,\"data-loading-text\",[28,[[27,\"t\",[\"common.pleaseWait\"],null]]]],[11,\"data-field-name\",\"transfer-button\"],[12,\"style\",[23,[\"transport\",\"backgroundColorStyle\"]]],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"transaction.pass\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"createTransactionAndOpenChooseCarrierModal\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transaction/create-transaction-button/template.hbs"
    }
  });

  _exports.default = _default;
});