define("apollo/pods/gates/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    gate: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      save: function () {
        const self = this;
        const gate = this.get('gate');

        if (!gate.validate()) {
          return;
        }

        gate.save().then(() => {
          self.setProperties({
            errors: []
          });
          this.transitionToRoute('settings.index');
        }).catch(response => {
          console.log(`Błąd przy zapisywaniu bramy: ${response.errors}`);
          self.set('errors', response.errors);
        });
      },
      delete: function () {
        const self = this;
        self.get('gate').destroyRecord().then(() => {
          self.setProperties({
            errors: []
          });
          this.transitionToRoute('settings.index');
        }).catch(response => {
          console.log(`Błąd przy usuwaniu bramy: ${response.errors}`);
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        this.get('gate').unloadRecord();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});