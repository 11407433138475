define("apollo/pods/components/checkpoint/confirm-status-button/component", ["exports", "apollo/mixins/transport-form-mixin", "apollo/models/checkpoint-confirmation-modal-params"], function (_exports, _transportFormMixin, _checkpointConfirmationModalParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    init(...args) {
      this._super(...args);

      (true && !(!!this.get('checkpoint')) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'checkpoint',
        type: 'Checkpoint Model'
      }), !!this.get('checkpoint')));
    },

    tagName: '',
    layoutName: Ember.computed(function () {
      return this.get('renderInTable') ? 'components/checkpoint/confirm-status-button/guard' : 'components/checkpoint/confirm-status-button/default';
    }),
    action: 'showModal',
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    transport: Ember.computed.reads('checkpoint.transport'),
    sessionAccount: Ember.inject.service('session-account'),
    checkpointService: Ember.inject.service('checkpoint-service'),
    weightService: Ember.inject.service('weight-service'),
    checkpointNameInAppropriateLanguage: Ember.computed.reads('checkpoint.step.warehouse.nameInAppropriateLanguage'),
    checkpointLabel: Ember.computed('checkpoint.{name,altCheckpointLabel}', function () {
      return this.get('checkpoint.task.weighingIncluded') ? this.get('checkpoint.altCheckpointLabel') : this.get('checkpoint.name');
    }),
    isButtonDisabled: Ember.computed({
      get() {
        return false;
      },

      set(key, value) {
        return value;
      }

    }),

    toggleButtonState() {
      this.toggleProperty('isButtonDisabled');
    },

    actions: {
      async confirm(checkpoint) {
        this.set('action', 'showModal');
        this.toggleButtonState();
        const checkpointModel = await checkpoint;

        if (!checkpointModel.validate()) {
          this.toggleButtonState();
          return;
        }

        try {
          const response = await this.get('checkpointService').confirmAndRefresh(checkpointModel);

          if (checkpointModel.get('task.weighingIncluded') && checkpointModel.get('step.warehouse.weighingIncluded') && checkpointModel.get('transport.hasAutoWeight')) {
            await this.get('weightService').automaticWeighing(checkpointModel);
          }

          if (response.ok) {
            this.set('errors', []);
            this.set('action', 'hidePopup'); // TODO 0335-deprecate-send-action https://github.com/emberjs/rfcs/blob/master/text/0335-deprecate-send-action.md

            this.sendAction('action');
            return;
          }

          const errors = await response.json();

          if (!errors.type && !(errors.type === 'DATA_REQUIRED')) {
            this.set('errors', [errors.errors]);
            return;
          }

          const componentParams = _checkpointConfirmationModalParams.default.create({
            checkpointModel,
            weight: null,
            isOverweight: errors.errors.includes('OVERWEIGHT')
          }); // TODO: Refaktor "odświeżania" cf-ów przez initializeCustomFields


          await this.initializeCustomFields(checkpoint.get('transport'));

          if (errors.errors.includes('WEIGHT_REQUIRED')) {
            const newWeight = this.createWeightRecord(checkpointModel);
            componentParams.set('weight', newWeight);
          }

          if (errors.errors.includes('CUSTOM_MODAL_REQUIRED')) {
            const modalName = checkpoint.get('task.customModalComponentName') || checkpoint.get('task.DEFAULT_CUSTOM_MODAL_COMPONENT_NAME');
            return this.sendAction('action', 'components.task-custom-modals.' + modalName, checkpoint);
          }

          this.sendAction('action', 'checkpoint-confirmation-modal', componentParams);
        } catch (e) {
          console.error(e);
        } finally {
          this.toggleButtonState();
        }
      },

      showModal(templateName, checkpoint) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, checkpoint);
      }

    },

    createWeightRecord(checkpointModel) {
      return this.get('store').createRecord('weight', {
        task: checkpointModel.get('task'),
        warehouse: checkpointModel.get('step.warehouse'),
        transport: checkpointModel.get('transport'),
        enabled: true,
        dateCreated: new Date(),
        confirmator: this.get('sessionAccount.currentUser')
      });
    }

  });

  _exports.default = _default;
});