define("apollo/pods/components/checkpoint-blockade-reason-modal/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errors: Ember.A(),
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    step: Ember.computed('checkpoint.step', function () {
      return this.get('checkpoint.step.content');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      const transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.{id,name}', function () {
      return `${this.get('intl').t('checkpoint.blockLabel')}: ${this.get('checkpoint.name')}`;
    }),
    alertText: Ember.computed('checkpoint.name', function () {
      return `Czy na pewno chcesz zablokować status: ${this.get('checkpoint.name')}`;
    }),
    isDuringConfirmation: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').CONFIRM;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    actions: {
      async block(checkpoint) {
        const self = this;
        checkpoint.set('blockadeRequester', this.get('sessionAccount.currentUser'));

        if (!checkpoint.validate()) {
          console.debug(checkpoint.get('errors'));
          return;
        }

        const path = this.get('apolloApiService').APOLLO_API.CHECKPOINTS.BLOCK;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: checkpoint.get('id')
          }, {
            body: JSON.stringify(checkpoint)
          });

          if (response.ok) {
            self.set('errors', []);
            console.log('Zablokowaliśmy status :: ' + checkpoint);
            checkpoint.reload();
            self.reset(checkpoint);
          } else {
            console.debug('Błąd podczas blokowania statusu');
            self.reset(checkpoint);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },

      cancel(checkpoint) {
        this.reset(checkpoint);
      }

    },

    reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }

  });

  _exports.default = _default;
});