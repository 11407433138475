define("apollo/pods/components/tasks-sortable-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NHsPJYTi",
    "block": "{\"symbols\":[\"tg\",\"task\"],\"statements\":[[4,\"each\",[[23,[\"proxiedTaskGroups\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[[27,\"concat\",[\"task.group.\",[22,1,[\"content\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"sortable-objects\",null,[[\"sortableObjectList\",\"sortEndAction\",\"enableSort\"],[[22,1,[\"tasks\"]],[27,\"action\",[[22,0,[]],\"sortEndAction\"],null],true]],{\"statements\":[[4,\"each\",[[22,1,[\"tasks\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"tasks-sortable-table/task-sortable-row\",null,[[\"task\",\"taskReferences\"],[[22,2,[]],[22,1,[\"references\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"workflows.noTasks\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row form-buttons\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"fa-icon\",[\"plus\"],null],false],[0,\" \"],[1,[27,\"t\",[\"workflows.addTask\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"addTask\",[22,1,[\"content\"]],[22,1,[\"tasks\",\"length\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/tasks-sortable-table/template.hbs"
    }
  });

  _exports.default = _default;
});