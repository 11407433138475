define("apollo/pods/algorithm-settings/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service("session-account"),
    access: ['ROLE_SETTINGS_TAB'],
    queryParams: {
      warehouseZoneId: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const warehouseZone = this.store.findRecord('warehouseZone', params.warehouseZoneId);
      const algorithmSettings = this.store.query('algorithmSetting', {
        warehouseZoneId: params.warehouseZoneId
      });
      return Ember.RSVP.hash({
        warehouseZone: warehouseZone,
        algorithmSettings: algorithmSettings
      });
    },
    actions: {
      willTransition: function () {
        this.get('controller').setProperties({
          errors: [],
          successMessage: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});