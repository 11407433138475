define("apollo/pods/algorithm-settings/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_WAREHOUSE'],
    queryParams: {
      warehouseZoneId: {
        refreshModel: true
      },
      contractorsGroupId: {
        refreshModel: true
      }
    },
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const warehouseZone = this.store.peekRecord('warehouse-zone', params.warehouseZoneId);
      const contractorsGroup = params.contractorsGroupId ? this.store.peekRecord('contractorsGroup', params.contractorsGroupId) : null;
      return this.store.createRecord('algorithm-setting', {
        warehouseZone: warehouseZone,
        contractorsGroup: contractorsGroup
      });
    },
    renderTemplate: function () {
      this.render('algorithmSettings.edit');
    },
    setupController: function (controller, model) {
      this.controllerFor('algorithmSettings.edit').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function () {
        this.controllerFor('algorithmSettings.edit').setProperties({
          success: false,
          errors: []
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});