define("apollo/services/drag-and-drop-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    dragOver(event, element) {
      event.stopPropagation();
      const elementClassList = element.classList;

      if (!elementClassList.contains('documents-dragover-zone')) {
        elementClassList.add('documents-dragover-zone');
      }
    },

    dragLeave(event, element) {
      event.stopPropagation();
      const elementClassList = element.classList;
      elementClassList.remove('documents-dragover-zone');
    },

    drop(event, element) {
      const elementClassList = element.classList;

      if (elementClassList.contains('documents-dragover-zone')) {
        elementClassList.remove('documents-dragover-zone');
      }
    }

  });

  _exports.default = _default;
});