define("apollo/pods/components/radio-button-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ruuU4r72",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-group\"],[11,\"data-toggle\",\"buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-default \",[27,\"if\",[[27,\"eq\",[[23,[\"property\"]],[22,1,[]]],null],\"active checked\"],null]]]],[12,\"disabled\",[21,\"disabled\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"setProperty\",[22,1,[]]],null]],[9],[0,\"\\n            \"],[7,\"input\"],[11,\"name\",\"options\"],[12,\"id\",[22,1,[]]],[11,\"autocomplete\",\"off\"],[12,\"disabled\",[21,\"disabled\"]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n            \"],[1,[27,\"t\",[[27,\"concat\",[[23,[\"translationKeyPrefix\"]],[22,1,[]]],null]],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/radio-button-group/template.hbs"
    }
  });

  _exports.default = _default;
});