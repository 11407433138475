define("apollo/pods/authorities/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_SETTINGS_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const roleGroup = this.store.peekRecord('roleGroup', params.id);
      const userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');

      if (roleGroup.get('name') === 'SUPER_USER' && userRoleGroup.get('name') !== 'SUPER_USER') {
        return this.transitionTo('forbidden');
      }

      return Ember.RSVP.hash({
        roleGroup,
        transportTypes: this.store.findAll('transportType').then(data => {
          return data.sortBy('idx');
        }),
        authorityRestrictions: this.store.query('authorityRestriction', {
          roleGroupId: roleGroup.get('id')
        }),
        roleGroupAuthorities: this.store.query('roleGroupAuthority', {
          roleGroupId: roleGroup.get('id')
        }),
        authorityGroups: this.store.findAll('authorityGroup')
      });
    }
  });

  _exports.default = _default;
});