define("apollo/pods/checkpoint-rejection-modal/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin", "apollo/models/checkpoint-confirmation-modal"], function (_exports, _transportsMixin, _adviceUtilsMixin, _checkpointConfirmationModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    checkpointService: Ember.inject.service('checkpoint-service'),
    checkpointApiService: Ember.inject.service('api.checkpoint-api-service'),
    weightService: Ember.inject.service('weight-service'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    errors: Ember.A(),
    selectedWarehouseLocations: Ember.A([]),
    checkpoint: Ember.computed.reads('model.checkpoint'),
    isLoading: false,
    step: Ember.computed.reads('checkpoint.step'),
    transport: Ember.computed.reads('checkpoint.transport'),
    title: Ember.computed('checkpoint.id', function () {
      return `${this.get('intl').t('checkpoints.confirm')}: ${this.get('checkpoint.name')}`;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    visibleCustomFieldsPairsWithCurrentTaskBlockade: Ember.computed('firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade', function () {
      const self = this;
      const customFieldsPairsWithCurrentTaskBlockade = self.get('firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade');
      return customFieldsPairsWithCurrentTaskBlockade.filter(pair => {
        return !pair.value.value && !pair.value.option || self.shouldBeVisible(pair);
      });
    }),
    classes: Ember.computed('visibleCustomFieldsPairsWithCurrentTaskBlockade.length', function () {
      const customFieldsLength = this.get('visibleCustomFieldsPairsWithCurrentTaskBlockade.length');
      return customFieldsLength > Number.parseInt(this.get('customFieldSplitValue')) ? 'super-wide-modal' : '';
    }),
    customFieldSplitValue: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CUSTOM_FIELD_SPLIT_VALUE');
    }),
    shouldBeVisible: function (pair) {
      return pair.value.get('isVisibleBasedOnDefinition') && pair.value.get('isVisibleBasedOnAuthorityRestrictions');
    },
    toggleLoading: function () {
      this.set('isLoading', !this.get('isLoading'));
    },
    actions: {
      async rejectCheckpoint(checkpoint) {
        try {
          const customFieldValues = this.get('visibleCustomFieldsPairsWithCurrentTaskBlockade').map(cfPair => cfPair.get('value'));

          if (customFieldValues.length && customFieldValues.map(cfv => cfv.validate()).some(isValid => isValid === false)) {
            return;
          }

          const customFieldValuesSerialized = customFieldValues.map(cfv => cfv.serialize({
            includeId: true
          }));

          const modalDataModel = _checkpointConfirmationModal.default.create({
            checkpoint: checkpoint.get('id'),
            customFieldValues: customFieldValuesSerialized
          });

          const savedDataResponse = await this.get('checkpointApiService').saveModalData(modalDataModel);

          if (!savedDataResponse.ok) {
            this.set('errors', [this.get('intl').t('common.defaultError')]);
            return;
          }

          const rejectResponse = await this.get('checkpointService').reject(checkpoint);

          if (!rejectResponse.ok) {
            this.set('errors', [this.get('intl').t('common.defaultError')]);
            return;
          }

          await checkpoint.reload();
          this.send('hideModal');
        } catch (e) {
          console.error(e);
        }
      },

      cancel(checkpoint) {
        this.reset(checkpoint);
      }

    },

    reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }

  });

  _exports.default = _default;
});