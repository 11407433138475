define("apollo/pods/route-custom-field/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    definition: belongsTo('customFieldDefinition', {
      async: true
    }),
    option: belongsTo('customFieldOption', {
      async: true
    }),
    route: belongsTo('route', {
      async: true
    }),
    value: attr('string'),
    editionDisabled: Ember.computed('route.id', function () {
      return !this.get('route.transportType.content').findPositiveAuthorityRestrictionsByActionNameAndCustomFieldDefinition('UPDATE_CUSTOM_FIELD', this.get('definition.id')).length > 0;
    }),
    validations: {
      option: {
        custom: [{
          validation: function (key, value) {
            return value && value.get('id');
          },
          message: 'blankSelect'
        }]
      }
    }
  });

  _exports.default = _default;
});