define("apollo/pods/courier-pricing/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    courierCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    enabled: _emberData.default.attr('boolean'),
    pricingSuccesses: _emberData.default.hasMany('courierPricingSuccess', {
      async: true
    }),
    pricingErrors: _emberData.default.hasMany('courierPricingError', {
      async: true
    }),
    validations: {}
  });

  _exports.default = _default;
});