define("apollo/services/authority/authority-checker-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),

    actionCanBePerformed(actionType, transport) {
      const transportTypeId = transport.get('transportType.id');
      const authorityRestrictions = this.findPositiveAuthorityRestrictionsByActionType(actionType, transportTypeId);
      return authorityRestrictions.get('length') !== 0 && authorityRestrictions.every(ar => ar.conditionIsFulfilled(transport));
    },

    findPositiveAuthorityRestrictionsByActionType(actionType, assetId) {
      try {
        (true && !(!!assetId) && Ember.assert('assetId musi być zdefiniowany!', !!assetId));
        const currentPricingPlan = this.get('sessionAccount.currentUser.company.pricingPlan');
        return this.get('sessionAccount.authorityRestrictions').filter(ar => {
          const referenceId = Number(assetId);

          if (ar.get('assetId') !== referenceId) {
            return false;
          }

          if (ar.get('pricingPlan') && ar.get('pricingPlan') !== currentPricingPlan) {
            return false;
          }

          const authority = this.get('sessionAccount.currentUser.roles').find(a => a.get('name') === actionType);
          return authority && ar.get('authority.id') === authority.get('id');
        });
      } catch (e) {
        console.error(e);
      }
    }

  });

  _exports.default = _default;
});