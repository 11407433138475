define("apollo/services/weight-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),
    weightApiService: Ember.inject.service('api.weight-api-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    async automaticWeighing(checkpoint) {
      const self = this;
      const parsedReadOut = await this.fetchAndParseWeight();

      if (!parsedReadOut) {
        alert(self.get('intl').t('transport.weightSection.errors.cannotGetWeight'));
      } // próbujemy zapisać nawet z błędem przy parsowaniu parsedReadOut, uruchamia mechanizm sychronizujący wagę (synchronizeWeights)


      await this.saveNewWeight(checkpoint, parsedReadOut);
    },

    async fetchAndParseWeight() {
      try {
        const fetchResponse = await this.weightApiService.fetchWeight();
        const parsedReadOut = this.weightApiService.parseFetchedWeight(fetchResponse);
        const readOutInKilos = parsedReadOut * 1000;
        return readOutInKilos;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    async saveOverweightNote(checkpoint) {
      return this.get('store').createRecord('note', {
        transport: checkpoint.get('transport'),
        creator: this.get('sessionAccount').get('currentUser'),
        note: this.get('intl').t('transport.weightSection.overweightNote'),
        createdAt: new Date(),
        autoGenerated: true
      }).save();
    },

    async saveNewWeight(checkpoint, parsedReadOut) {
      const self = this;
      const currentWeight = checkpoint.get('currentWeight');

      if (currentWeight) {
        currentWeight.set('enabled', false);
      }

      const weightObject = self.store.createRecord('weight', {
        task: checkpoint.get('task'),
        warehouse: checkpoint.get('step.warehouse'),
        transport: checkpoint.get('transport'),
        enabled: true,
        readOut: parsedReadOut,
        dateCreated: new Date(),
        confirmator: self.get('sessionAccount.currentUser')
      });
      return await weightObject.save().then(() => {
        console.debug('Zapisaliśmy wagę: ' + weightObject.get('readOut'));
        checkpoint.get('transport').then(transport => {
          transport.get('weights').reload();
        });
        return true;
      }).catch(response => {
        console.debug(`Serwer zwrócił błąd ${response} - zapisujemy wagę w localStorage..`);
        const key = 'weight_'.concat(weightObject.get('task.id'), '_', weightObject.get('transport.id'));
        localStorage[key] = JSON.stringify(weightObject);

        if (currentWeight) {
          currentWeight.set('enabled', false);
        }

        checkpoint.set('hasUnsynchronizedWeight', true); // TODO Refaktor - rozłączyć zależność z metodą synchronizeWeights przez usunięcie weightSynchronizingActivated

        if (!self.get('weightSynchronizingActivated')) {
          self.set('weightSynchronizingActivated', true);
          return Ember.run.later(self, function () {
            self.synchronizeWeights();
          }, self.get('interval'));
        }

        return false;
      });
    },

    synchronizeWeights() {
      const weightKeys = [];
      const weightObjects = [];
      const self = this;

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key.startsWith('weight')) {
          weightKeys.pushObject(key);
          weightObjects.pushObject(localStorage[key]);
        }
      }

      if (weightObjects.length === 0) {
        return;
      }

      this.weightApiService.callSynchronizeWeights(weightObjects).then(() => {
        console.debug('Zsynchronizowaliśmy wagi');
        weightObjects.forEach(weightString => {
          const weight = JSON.parse(weightString);
          self.store.findRecord('transport', weight.transport).then(transport => {
            transport.get('weights').reload();
            transport.get('checkpoints').filterBy('hasUnsynchronizedWeight').forEach(checkpoint => {
              checkpoint.set('hasUnsychronizedWeight', false);
            });
            transport.get('weights').filter(w => !w.get('id')).forEach(w => {
              w.destroyRecord();
            });
          });
        });
        weightKeys.forEach(key => {
          localStorage.removeItem(key);
        }); // TODO Refaktor - rozłączyć zależność z metodą saveNewWeight przez usunięcie weightSynchronizingActivated

        self.set('weightSynchronizingActivated', false);
      }).catch(response => {
        console.debug('Nie udało się przesłać wagi - planujemy następną próbę');
        return Ember.run.later(this, function () {
          this.synchronizeWeights();
        }, 10000);
      });
    }

  });

  _exports.default = _default;
});