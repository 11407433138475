define("apollo/pods/components/notification-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    userAccessService: Ember.inject.service('user-access-service'),
    disableNotificationEditing: Ember.computed('user.id', function () {
      const userId = this.get('user.id');
      return !this.get('userAccessService').canAccess(userId, 'ROLE_EDIT_USERS_NOTIFICATION', 'ROLE_EDIT_MY_NOTIFICATION');
    }),
    notificationsTransportTypes: Ember.computed('allNotifications', function () {
      if (!this.get('allNotifications')) {
        return [];
      }

      let notificationArray = Ember.A(this.get('allNotifications').map(n => n.get('transportType')).uniqBy('id').sort((a, b) => {
        const nameA = a.get('nameInAppropriateLanguage');
        const nameB = b.get('nameInAppropriateLanguage');

        if (nameA === nameB) {
          return 0;
        } else if (Ember.isEmpty(nameA)) {
          return 1;
        } else if (Ember.isEmpty(nameB)) {
          return -1;
        } else {
          return nameA.localeCompare(nameB);
        }
      }));
      notificationArray.concat(this.get('allNotifications').map(a => {
        return a.get('transportType') === null;
      }));
      return notificationArray;
    }),
    actions: {
      setGroupMode(notification, mode) {
        if (!this.setGroupMode) {
          return;
        }

        this.setGroupMode(notification, mode);
      }

    }
  });

  _exports.default = _default;
});