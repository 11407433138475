define("apollo/controllers/application", ["exports", "apollo/config/environment", "jquery", "ember-concurrency", "apollo/app"], function (_exports, _environment, _jquery, _emberConcurrency, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service('intl'),
    appTabActiveService: Ember.inject.service('application-tab-active-service'),
    languageService: Ember.inject.service('language-service'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    transportsController: Ember.inject.controller('transports/index'),
    weightService: Ember.inject.service(),
    hdMode: false,

    init() {
      this._super(...arguments);

      this.get('websocket').getSocket();
    },

    initAppTabActiveService: Ember.on('init', function () {
      this.get('appTabActiveService').initialize();
    }),
    initAnnouncementCounterUpdates: function () {
      if (!this.get('sessionAccount').hasRole('ROLE_ANNOUNCEMENTS_TAB')) {
        return;
      }

      this.set('pollAnnouncementsUpdatesInterval', this.get('sessionAccount').getSettingValue('ANNOUNCEMENTS_COUNTER_POLL_INTERVAL') || 60000);
      this.set('announcementsLastPoll', moment());
      this.get('pollAnnouncementCounter').perform();
    },
    initLocalDelta: Ember.on('init', function () {
      // inicjalizujemy domyślną liczbę dni w harmonogramie wyciągając ją z localStorage
      const delta = localStorage.getItem('delta');

      if (delta) {
        this.set('localDelta', delta);
      }
    }),
    pollAnnouncementCounter: (0, _emberConcurrency.task)(function* () {
      const pollUpdatesInterval = this.get('pollAnnouncementsUpdatesInterval');

      try {
        while (true) {
          yield (0, _emberConcurrency.timeout)(pollUpdatesInterval);
          yield this.refreshAnnouncementCounter();
        }
      } catch (e) {
        console.error(e);
      }
    }).restartable(),
    refreshAnnouncementCounter: function () {
      const self = this;
      const path = this.get('apolloApiService').APOLLO_API.ANNOUNCEMENTS.UPDATES;
      this.get('apolloApiService').callApolloApi(path).then(response => response.json()).then(json => {
        self.set('sessionAccount.currentUser.unreadAnnouncementsCount', json.unreadAnnouncementsCount);
      });
    },
    principal: Ember.computed(function () {
      const principalId = localStorage.getItem('principalId');
      const principalCompany = this.get('store').peekRecord('company', principalId);

      if (!principalCompany) {
        console.debug('Principal company not found - invalidating session..');
        this.send('invalidateSession');
        return;
      }

      console.log(`Principal company is set to ${principalCompany.get('name')}.`);
      return principalCompany;
    }),
    transportTypeAvailableInArchive: Ember.computed('principal.transportTypes.[]', function () {
      console.log('principal.id:' + this.get('principal.id')); // to nie jest do niczego potrzebne tylko do rozwiązania A-1472

      const availableTransportTypes = this.get('principal.transportTypes').filterBy('canBeSeenInArchiveByRoleGroup');
      return availableTransportTypes.get('firstObject');
    }),
    isAuthPage: Ember.computed('target.currentRouteName', function () {
      const currentRoute = this.getActiveRoute();
      return currentRoute.search('auth') > -1;
    }),
    getPrincipalAliasFromLocalStorage: function () {
      try {
        return localStorage.getItem('principalAlias') || window.name; // window.name - w przypadku iframe'ów
      } catch (e) {
        if (e instanceof DOMException) {
          console.error(e);
          return window.name;
        }

        throw e;
      }
    },
    loginBackgroundStyle: Ember.computed(function () {
      const principalAlias = this.getPrincipalAliasFromLocalStorage();
      let backgroundSize = 'cover';
      let backgroundColor = 'white';

      if (principalAlias === 'neuca') {
        backgroundSize = 'initial';
        backgroundColor = '#83d0f5';
      }

      const backgroundImage = `${_environment.default.serverURL}/loginPage/background?companyAlias=${principalAlias}`;
      return Ember.String.htmlSafe(`background-image: url(${backgroundImage}); background-size: ${backgroundSize}; background-color: ${backgroundColor};`);
    }),
    canPlanTransports: Ember.computed('principal.transportTypes.@each.canBeCreatedByRoleGroup', 'sessionAccount.currentUser.statuteAccepted', function () {
      if (!this.get('sessionAccount.currentUser.statuteAccepted')) {
        return false;
      }

      return this.get('principal').get('transportTypes').findBy('canBeCreatedByRoleGroup', true);
    }),
    showPrincipalSwitch: Ember.computed('sessionAccount.principalsWhereUserHasRole.length', function () {
      return this.get('sessionAccount.principalsWhereUserHasRole.length') > 1;
    }),
    today: Ember.computed(function () {
      if (this.get('numberOfDaysInSchedule') === 7) {
        const shift = this.get('sessionAccount').getSettingValue('WEEK_SCHEDULE_START_DATE_SHIFT');
        return moment(new Date()).add(shift || 0, 'days').toDate();
      }

      return new Date();
    }).readOnly(),
    warehouseDate: Ember.computed('today', 'createTransportController.dateOfAdvice', 'scheduleController.transportToHandle', function () {
      const transport = this.get('scheduleController.transportToHandle');
      return transport && this.get('createTransportController.dateOfAdvice') ? this.get('createTransportController.dateOfAdvice') : this.get('today');
    }),
    defaultWarehouse: Ember.computed('sessionAccount.currentUser.warehouse', 'scheduleController.transportToHandle', function () {
      const transport = this.get('scheduleController.transportToHandle');
      const transportWarehouse = transport ? transport.get('warehouse') : undefined;
      return transportWarehouse || this.get('sessionAccount.currentUser.warehouse');
    }),
    setLocalDelta: function (delta) {
      if (!delta) {
        return;
      } // Ustawiamy w localStorage ostatni wybrany widok harmonogramu


      localStorage.setItem('delta', `${delta}`); // Ustawiamy w zmiennej controllera, żeby móc na niej słuchać

      this.set('localDelta', delta);
    },
    numberOfDaysInSchedule: Ember.computed('localDelta', 'defaultWarehouse', function () {
      const delta = this.get('localDelta');

      if (delta) {
        return Number(delta);
      }

      const defaultWarehouseId = this.get('defaultWarehouse.id');
      let count = 0;
      this.get('sessionAccount.currentUser.ramps').map(ramp => {
        const warehouseId = ramp.get('warehouse.id');

        if (warehouseId === defaultWarehouseId) {
          count = count + 1;
        }
      });
      return count > 3 ? 1 : 7;
    }),
    shouldDisplayAllTypes: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('SHOULD_RENDER_ALL_TRANSPORT_TYPES') === 'true';
    }),

    /**
     * Ustawia się w momencie wybrania typu transportu w tabeli
     */
    transportTypeModeToRedirectTo: Ember.computed('transportTypeMode', 'transportTypeAvailableInArchive.alias', function () {
      if (this.get('transportTypeMode')) {
        return this.get('transportTypeMode');
      }

      const transportTypeFromLocalStorage = localStorage.getItem('LastVisitedTransportType');
      const transportTypes = this.get('store').peekAll('transportType');
      const isTransportTypeFromLocalStorageInAvalibleTransportTypes = transportTypes.filterBy('canBeSeenByRoleGroup').any(tt => tt.alias === transportTypeFromLocalStorage);

      if (isTransportTypeFromLocalStorageInAvalibleTransportTypes || transportTypeFromLocalStorage === 'ALL') {
        return transportTypeFromLocalStorage;
      }

      const transportTypeToRedirectTo = transportTypes ? transportTypes.filterBy('canBeSeenByRoleGroup').get('firstObject.alias') : undefined;
      return this.get('shouldDisplayAllTypes') ? 'ALL' : transportTypeToRedirectTo;
    }),
    transportTypeModeForPriceList: Ember.computed('sessionAccount.currentUser', function () {
      const availableType = this.get('store').peekAll('transportType').find(transportType => {
        return transportType.canBeSeenByRoleGroup && transportType.allowsPriceList;
      });
      return availableType ? availableType.get('alias') : 'UNAVAILABLE';
    }),
    transportTypeModeForPlanning: Ember.computed('sessionAccount.currentUser', function () {
      const availableType = this.get('store').peekAll('transportType').find(transportType => {
        return transportType.canBeSeenByRoleGroup && transportType.usedForDeliveries;
      });
      return availableType ? availableType.get('alias') : 'UNAVAILABLE';
    }),
    maxRowsInPriceListTable: Ember.computed('currentRouteName', function () {
      return localStorage.getItem('MAX_ROWS_IN_PRICE_LIST_TABLE') || 10;
    }),
    initResizeObserver: Ember.on('init', function () {
      $(window).on('resize', this.resize.bind(this));
    }),
    resize: function () {
      this.set('windowSize', $(window).width());
    },

    /**
     * Sekcja 'Więcej' w panelu nawigacyjnym powinna się pojawić, jeśli użytkownik ma dostęp do co najmniej 6 zakładek
     * oraz rozmiar ekranu jest odpowiednio mały
     */
    responsiveView: Ember.computed('sessionAccount.currentUser.roleNames.length', 'windowSize', function () {
      const numberOfTabs = this.get('sessionAccount.currentUser.roleNames').filter(rn => {
        // Nazwy uprawnień związanych z zakładkami kończą się na '_TAB',
        // za wyjątkiem zakładki 'Admin' -- która jest zależna od uprawnienia 'ROLE_SUPER_USER'
        return /_TAB$/.test(rn) || rn === 'ROLE_SUPER_USER';
      }).length;
      const minScreenWidth = 1200;
      const maxScreenWidth = 1750;
      console.debug(`Użytkownik ma dostęp do ${numberOfTabs} uprawnień związanych z zakładkami.`);
      return numberOfTabs >= 6 && $(window).width() > minScreenWidth && $(window).width() < maxScreenWidth;
    }),
    printView: Ember.computed('routesAsPrintView', 'target.currentRouteName', function () {
      return this.get('routesAsPrintView').includes(this.get('target.currentRouteName'));
    }),
    checkWeightsToSynchronize: Ember.on('init', function () {
      return Ember.run.later(() => this.get('weightService').synchronizeWeights(), 5000);
    }),
    footer: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('FOOTER');
    }),
    hyperlinkToLogoOnNavbar: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('HYPERLINK_TO_LOGO_ON_NAVBAR');
    }),
    logoOnNavBar: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('LOGO_ON_NAVBAR');
    }),
    getActiveRoute: function () {
      return this.get('currentRouteName');
    },
    transportsTabLabel: Ember.computed('', function () {
      const properKey = this.get('languageService').findProperLanguageVersion('TRANSPORTS_LIST_LABEL', 'TRANSPORTS_LIST_LABEL_EN');
      const setting = this.get('sessionAccount').getSettingValue(properKey);
      return setting || this.get('intl').t('tabs.transports');
    }),
    currentYear: Ember.computed('', function () {
      return new Date().getFullYear();
    }),
    actions: {
      collapseNavbar() {
        $('.navbar-collapse').collapse('hide');
      },

      invalidateSession() {
        console.log('User logged out.');
        const self = this;
        const isSocketOpen = this.get('websocket') ? this.get('websocket').getSocket().connected : false;

        if (isSocketOpen) {
          const socket = this.get('websocket').getSocket();
          socket.disconnect();
        }

        this.get('session').invalidate().then(() => {
          /*
              A-10687 Czyszczenie data store ember'a przy wylogowaniu użytkownika
              Zdecydowałem się na window.location do czyszczenia data store, danych w kontrolerach, serwisach
              i route'ach. Automaty odrzuciły rozwiązania z store.unloadAll() i App.reset()
           */
          window.location = '/';
        });
      },

      /**
       * Do serwera zostanie wysłana informacja o zmianie pryncypała
       * a po odświeżeniu strony ustawione zostanie nowe ciasteczko
       * oraz lista uprawnień, powiadomień, itp. użytkownika w przestrzeni wybranego pryncypała.
       *
       * @param principal
       */
      changePrincipalSpace(principal) {
        console.debug(`Ustawiamy pryncypała ${principal.get('name')}..`);
        const self = this;
        const username = this.get('sessionAccount.currentUser.username');
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/users/' + username + '/currentPrincipal/' + principal.get('id'),
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          localStorage.removeItem('principalId');
          localStorage.removeItem('principalAlias');
          self.get('transportsController').set('content', null);
          self.send('goToTransportTab');
        }, function (response) {
          self.set('errors', response.errors);
        });
      },

      goToTransportTab() {
        this.send('goToTab', 'transports');
      }

    }
  });

  _exports.default = _default;
});