define("apollo/pods/components/authority-table/authority-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    customFieldActionType: Ember.computed('authority.actionType', function () {
      return ['ACCESS_CUSTOM_FIELD', 'UPDATE_CUSTOM_FIELD'].includes(this.get('authority.name'));
    }),
    actions: {
      delete: function (roleGroupAuthority) {
        this.set('action', 'delete');
        this.sendAction('action', roleGroupAuthority);
      }
    }
  });

  _exports.default = _default;
});