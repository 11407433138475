define("apollo/pods/components/periodic-transport-form/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    frequencyOptions: ['DAILY', 'WEEKLY', 'MONTHLY'],
    setCheckedDays: Ember.on('init', function () {
      if (!this.get('transport.id')) {
        return;
      }

      const currentDaysString = this.get('transport.periodicTransport.dayNumbers') ? this.get('transport.periodicTransport.dayNumbers') : "";
      currentDaysString.split(",").filter(idx => idx).forEach(idx => Ember.set(this.findDay(idx), 'checked', true));
    }),
    daysOfWeek: Ember.computed(function () {
      const days = [];

      for (let i = 1; i <= 7; i++) {
        days.push({
          idx: i,
          checked: false
        });
      }

      return days;
    }),
    editionDisabled: Ember.computed('transport.id', function () {
      return this.get('transport.id');
    }),

    updateDayNumbers(dayIdx, newChecked) {
      const currentDaysString = this.get('transport.periodicTransport.dayNumbers') ? this.get('transport.periodicTransport.dayNumbers') : "";
      const currentDays = new Set(currentDaysString.split(",").filter(x => x));

      if (newChecked) {
        currentDays.add(`${dayIdx}`);
      } else {
        currentDays.delete(`${dayIdx}`);
      }

      this.set('transport.periodicTransport.dayNumbers', [...currentDays].sort().join(","));
    },

    findDay: function (idx) {
      return this.get('daysOfWeek').find(d => Number(d.idx) === Number(idx));
    },
    actions: {
      checkDay(idx) {
        const day = this.findDay(idx);
        const newChecked = !day.checked;
        Ember.set(day, 'checked', newChecked);
        this.updateDayNumbers(day.idx, newChecked);
      },

      setDate(propertyName, date) {
        this.setDateOfPeriodicAdvice(date, propertyName);
      }

    }
  });

  _exports.default = _default;
});