define("apollo/pods/delivery-setting/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    attrs: {
      availableStackingFactors: {
        serialize: false,
        deserialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});