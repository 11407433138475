define("apollo/pods/components/companies/apply-form/history-section/partnership-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ib/OqAGk",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"t\",[[27,\"concat\",[\"partnership.logs.\",[23,[\"log\",\"event\"]]],null]],null],false],[0,\":\\n\"],[4,\"if\",[[23,[\"log\",\"sectionName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"bold\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[[27,\"concat\",[\"sectionType.\",[23,[\"log\",\"sectionName\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"log\",\"subject\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"bold\"],[9],[0,\"\\n        \"],[1,[23,[\"log\",\"subject\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"log\",\"oldData\"]]],null,{\"statements\":[[0,\"    :: \"],[7,\"span\"],[11,\"class\",\"old-value\"],[9],[1,[23,[\"log\",\"oldData\"]],false],[10],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-long-arrow-right\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"new-value\"],[9],[1,[23,[\"log\",\"newData\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"log\",\"newData\"]]],null,{\"statements\":[[0,\"    :: \"],[7,\"span\"],[11,\"class\",\"new-value\"],[9],[1,[23,[\"log\",\"newData\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/companies/apply-form/history-section/partnership-log/template.hbs"
    }
  });

  _exports.default = _default;
});