define("apollo/pods/document-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    groupName: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    translationKey: _emberData.default.attr('string'),
    ibanRestriction: _emberData.default.attr('string'),
    showNumber: _emberData.default.attr('boolean'),
    showExpirationDateFrom: _emberData.default.attr('boolean'),
    showExpirationDateTo: _emberData.default.attr('boolean'),
    showAmount: _emberData.default.attr('boolean'),
    showScope: _emberData.default.attr('boolean'),
    canBeRejected: _emberData.default.attr('boolean'),
    invalidDocumentBlocksAdvice: _emberData.default.attr('boolean'),
    isRequiredForModeOfTransportation: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});