define("apollo/pods/documents/modals/document-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XHDgwkKn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"subtitle\",\"classNames\",\"cancel\"],[[23,[\"title\"]],[23,[\"subtitle\"]],\"document-modal\",[27,\"action\",[[22,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"documents-applies/document-form\",null,[[\"document\",\"documentTypes\",\"modal\"],[[23,[\"document\"]],[23,[\"model\",\"documentTypes\"]],[23,[\"modal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/documents/modals/document-modal/template.hbs"
    }
  });

  _exports.default = _default;
});