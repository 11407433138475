define("apollo/transforms/isodate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if (serialized) {
        return moment(serialized).toDate();
      }

      return serialized;
    },
    serialize: function (deserialized) {
      if (deserialized) {
        return moment(deserialized).format();
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});