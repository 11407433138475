define("apollo/pods/announcements/manage/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    sessionAccount: Ember.inject.service('session-account'),
    access: ["ROLE_MANAGE_ANNOUNCEMENTS"],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      params.max = params.max || 25;
      params.page = params.page || 1;
      params.location = "ANNOUNCEMENTS_MANAGEMENT";
      return this.store.query('announcement', params);
    },
    setupController: function (controller, model) {
      this.get('controller').setProperties({
        model: model,
        totalPages: model.get('meta.totalPages')
      });
    }
  });

  _exports.default = _default;
});