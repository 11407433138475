define("apollo/mixins/persistence-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO!! A-12801
  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),

    /**
     * Ember-data nie potrafi automatycznie usunąć obiektów z relacji, jeśli te zostaną
     * usunięte po stronie serwera - musimy więc to zrobić ręcznie.
     * Poniższa metoda zapyta serwer o aktualne relacje dla podanego modelu i usunie te
     * obiektu, które już nie istnieją.
     *
     * Uwaga: serwer musi potrafić obsłużyć konkretne zapytanie ajax.
     *
     * @param modelName - nazwa modelu; np. 'transport' lub 'timeWindow'
     * @param modelId - id modelu
     * @param childModelName - nazwa modelu relacji zdefiniowana jako DS.hasMany; np. 'checkpoint' lub 'timeWindow'
     */
    unloadDeletedRecords(modelName, modelId, childModelName) {
      const self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        let store = self.store;

        if (!store.peekRecord) {
          store = self.get('store');
        }

        const model = store.peekRecord(modelName, modelId);

        if (!model) {
          resolve();
        } // TODO: Jakaś mądrzejsza pluralizacja, jeśli będzie potrzeba.


        const relationship = childModelName + 's';
        model.get(relationship).then(currentRecords => {
          self.get('ajaxService').send(`/${modelName}s/` + modelId + `/${relationship}`, 'GET').then(function (json) {
            currentRecords.map(r => {
              return parseInt(r.id);
            }).forEach(currentId => {
              if (!json[relationship].filterBy('id', currentId).length) {
                const r = store.peekRecord('checkpoint', currentId);
                model.get(relationship).removeObject(r);
                r.destroyRecord().then(() => {
                  r.unloadRecord();
                  console.debug(`Unloaded ${childModelName} #${r.id} from ${modelName} #${modelId}.`);
                });
              }
            });
            resolve();
          });
        });
      });
    },

    /**
     * Ember-data czasem wczytuje do sesji zduplikowane rekordy w przypadku relacji has-many.
     * Poniższa metoda usuwa nadmiarowe rekordy.
     *
     * @param parentModel
     * @param relationshipName
     */
    removeDuplicateRecords(parentModel, relationshipName) {
      const ids = [];
      const duplicatedModels = [];
      parentModel.get(relationshipName).forEach(m => {
        if (ids.includes(m.id)) {
          duplicatedModels.push(m);
        } else {
          ids.push(m.id);
        }
      });
      duplicatedModels.forEach(r => {
        console.debug(`Deleted duplicated record: #${r.id}`);
        parentModel.get(relationshipName).removeObject(r);
      });
    },

    // https://stackoverflow.com/questions/4465244/compare-2-json-objects
    deepEquals: function (o1, o2) {
      const self = this;

      if (o1 === null || o2 === null) {
        return;
      }

      const k1 = Object.keys(o1).sort();
      const k2 = Object.keys(o2).sort();

      if (k1.length !== k2.length) {
        return false;
      }

      return k1.map(function (keyPair) {
        if (typeof o1[keyPair] === 'object' && typeof o2[keyPair] === 'object') {
          return self.deepEquals(o1[keyPair], o2[keyPair]);
        } else {
          if (o1[keyPair] !== o2[keyPair]) {
            return false;
          }

          return o1[keyPair] === o2[keyPair];
        }
      });
    },

    /**
     * Metoda, która zapamiętuje "brudne" custom field option
     * (podczas przeładowywania transportu zmiany były usuwane)
     */
    getPreviousCustomFieldValuesMap: function (transportId) {
      const prevCustomFieldValues = [];
      const transport = this.get('store').peekRecord('transport', transportId);

      if (transport === null) {
        return prevCustomFieldValues;
      }

      transport.get('customFields').forEach(cf => {
        prevCustomFieldValues.push({
          id: cf.get('id'),
          isSelect: cf.get('definition.isSelectType'),
          option: cf.get('option'),
          value: cf.get('value'),
          prevValue: cf.get('prevValue'),
          isDirty: cf.get('hasDirtyAttributes'),
          lastUpdated: cf.get('lastUpdated')
        });
      });
      return prevCustomFieldValues;
    },

    /**
     * Metoda, która przywraca usunięte podczas przeładowywania
     * custom field options
     */
    markDirtyCustomFieldValues: function (transport, prevCustomFieldValues, newCustomFieldValues) {
      console.debug(`Próbujemy przywrócić usunięte podczas przeładowywania (np. okna czasowego) custom fields w transporcie ${transport.get('logString')}...`);
      const currentCustomFields = transport.get('customFields');
      const newCustomFields = currentCustomFields.filter(ccf => !prevCustomFieldValues.map(pcf => pcf.id).includes(ccf.get('id')));
      prevCustomFieldValues.forEach(dcf => {
        const cf = currentCustomFields.find(cf => cf.get('id') && cf.get('id') === dcf.id);

        if (!cf) {
          const prevValueString = dcf.isSelect ? dcf.option ? dcf.option.get('nameInAppropriateLanguage') : '-' : dcf.value;
          this.setCfMessageAndPrevValue(cf, 'formChanges.valueRemoved', {
            value: prevValueString
          }, prevValueString, true);
          return;
        }

        const propertyName = dcf.isSelect ? 'option' : 'value';
        const prevValue = dcf.isSelect ? dcf.option ? dcf.option.get('id') : null : dcf.value;
        const correspondingValue = newCustomFieldValues.find(x => parseInt(x.id) === parseInt(dcf.id));
        const newValue = dcf.isSelect ? cf.get('option.id') : correspondingValue ? correspondingValue.value : '';
        const hasBeenUpdated = dcf.lastUpdated !== cf.get('lastUpdated');
        const valueChanged = prevValue !== newValue;

        if (!valueChanged) {
          return;
        }

        const prevValueString = dcf.isSelect ? dcf.option ? dcf.option.get('nameInAppropriateLanguage') : '-' : dcf.value;
        const newValueString = dcf.isSelect ? cf.get('option.nameInAppropriateLanguage') : newValue;

        if (dcf.isDirty) {
          // przypadek, kiedy użytkownik zmieni wartość w CF i przyjdzie aktualizacja z serwera dotycząca tego pola:
          // - przywracamy zmiany wprowadzone przez użytkownika
          // - ustawiamy komunikat o równoczesnej edycji
          this.setCfMessageAndPrevValue(cf, 'formChanges.dirtyValueChanged', {
            yourValue: prevValueString,
            newValue: newValueString
          }, prevValueString, hasBeenUpdated);

          if (hasBeenUpdated) {
            cf.set('transport.sureToSaveMessage', true);
          }

          cf.set(propertyName, dcf[propertyName]);
        } else {
          // przypadek, kiedy przyszła aktualizacja pola z serwera - ustawiamy komunikat z informacją
          this.setCfMessageAndPrevValue(cf, 'formChanges.valueChanged', {
            oldValue: prevValueString,
            newValue: newValueString
          }, prevValueString, hasBeenUpdated);
        }
      });
      newCustomFields.forEach(ncf => {
        // nie mieliśmy tego CF, ale przyszedł update z serwera i ktoś go uzupełnił
        const newValue = ncf.get('definition.isSelectType') ? ncf.get('option.nameInAppropriateLanguage') : ncf.get('value');
        this.setCfMessageAndPrevValue(ncf, 'formChanges.newValue', {
          value: newValue
        }, null, true);
      });
    },
    setCfMessageAndPrevValue: function (cf, msgCode, msgArgs, prevValueString, hasBeenUpdated) {
      if (!cf || !hasBeenUpdated) {
        return;
      }

      const message = this.get('intl').t(msgCode, msgArgs);
      cf.set('prevValue', prevValueString);
      cf.set('changeMessage', message);
    },

    /**
     * Metoda, która usuwa stare obiekty `step` po edycji
     * -> ember-data nie nadpisuje stepów po otrzymaniu odpowiedzi z serwera tylko dodaje nowe, więc stare usuwamy ręcznie
     */
    handleStepsUpdate(transport) {
      const stepsCopy = transport.get('steps').slice(0);
      stepsCopy.forEach(function (s) {
        s.set('didWarehouseChange', false);

        if (s !== undefined && s.get('address') !== undefined && s.get('address.content')) {
          if (!s.get('address.id') && s.get('address').unloadRecord) {
            console.log(`Unloading redundant advice for newly saved transport ${transport.get('id')}..`);
            s.get('address').unloadRecord();
          }
        }

        if (s !== undefined && !s.get('id') && s.unloadRecord) {
          console.debug(`Unloading redundant step for newly saved transport ${transport.get('id')}..`);
          transport.get('steps').removeObject(s);
          s.unloadRecord();
        }

        s.get('stepWarehouseLocations').forEach(swh => {
          if (swh !== undefined && !swh.get('id') && !swh.get('isFilled') && swh.unloadRecord) {
            swh.unloadRecord();
          }
        });
      });
    },

    removeUnsavedTimeWindows(transport) {
      if (!transport.get('timeWindows.length')) {
        return;
      }

      const timeWindowsCopy = transport.get('timeWindows').slice(0);
      timeWindowsCopy.forEach(function (tw) {
        if (tw !== undefined && !tw.get('id') && tw.unloadRecord) {
          console.debug(`Unloading redundant timeWindow for transport ${transport.get('id')}..`);
          tw.get('step').set('timeWindow', null);
          transport.get('timeWindows').removeObject(tw);
          tw.unloadRecord();
        }
      });
    }

  });

  _exports.default = _default;
});