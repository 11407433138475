define("apollo/pods/settings/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    success: false,
    errors: [],
    intl: Ember.inject.service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    actions: {
      save: function () {
        var self = this;
        var setting = this.get('model');

        if (setting.get('settingValueType') === 'boolean') {
          setting.set('value', $('#settingCheckbox').is(':checked'));
        }

        setting.save().then(function () {
          self.set('success', true);
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      delete: function () {
        this.get('model').destroyRecord().then(() => {
          this.get('settingsIndexController').set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          this.transitionToRoute('settings.index');
        }).catch(response => {
          this.set('errors', response.errors);
        });
      },
      cancel: function () {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});