define("apollo/pods/tasks/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    errors: [],
    ASSOCIATIONS: ['WAREHOUSE', 'CONTRACTOR', 'WAREHOUSE_AND_CONTRACTOR'],
    TASK_GROUP_IDENTIFIER: Object.freeze({
      TASK_GROUP_PREPARATION: 0,
      TASK_GROUP_STEPS: 1,
      TASK_GROUP_ARCHIVING: 2,
      TASK_GROUP_DELIVERY: 100
    }),
    movingDelayedTransportToArchiveActive: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT');
    }),
    automaticGenerateDocumentActive: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('AUTOMATIC_GENERATE_DOCUMENT');
    }),
    customFieldDefinitions: Ember.computed('model.workflow.transportType', function () {
      return this.get('model.workflow.transportType').get('customFieldDefinitions');
    }),
    selectedCustomFieldDefinition: Ember.computed('model.customFieldOption', function () {
      return this.get('model.customFieldOption.definition');
    }),
    customFieldOptions: Ember.computed('customFieldDefinitions', 'selectedCustomFieldDefinition', function () {
      return this.get('selectedCustomFieldDefinition').get('options');
    }),
    shouldShowArtrOption: Ember.computed('model.taskGroupIdentifier', function () {
      const groupId = this.get('model.taskGroupIdentifier');
      return !(groupId === this.get('TASK_GROUP_IDENTIFIER.TASK_GROUP_PREPARATION') || groupId === this.get('TASK_GROUP_IDENTIFIER.TASK_GROUP_ARCHIVING'));
    }),
    actions: {
      save: function () {
        const self = this;
        const taskModel = this.get('model');

        if (!taskModel.validate()) {
          return;
        }

        taskModel.save().then(function (task) {
          self.set('errors', []);
          self.transitionToRoute('workflows.edit', self.get('model.workflow.id'));
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        this.transitionToRoute('workflows.edit', this.get('model.workflow.id'));
      },

      disable(task) {
        const self = this;
        task.deleteRecord();
        task.save().then(() => {
          self.set('errors', []);
          self.transitionToRoute('workflows.edit', self.get('model.workflow.id'));
        }).catch(response => {
          self.set('errors', response.errors);
        });
      }

    }
  });

  _exports.default = _default;
});