define("apollo/pods/companies/allowed-domains/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['lastUpdated'],
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errors: [],
    successMessage: null,
    companyEmailDomainsList: [],
    companyEmailDomainsListObserver: Ember.observer('model.companyEmailDomains.@each.id', function () {
      this.set('companyEmailDomainsList', []);
      this.get('model.companyEmailDomains').forEach(ced => this.get('companyEmailDomainsList').pushObject(ced));
    }),
    company: Ember.computed('model.company.id', function () {
      return this.get('model.company');
    }),
    showSaveButton: Ember.computed('companyEmailDomainsList.@each.id', 'companyEmailDomainsList.length', function () {
      return this.get('companyEmailDomainsList').filter(ced => !ced.get('id')).length > 0;
    }),
    actions: {
      async save() {
        const cedsToSave = this.get('companyEmailDomainsList').filter(ced => !ced.get('id'));

        if (cedsToSave.some(ced => !ced.validate())) {
          return;
        }

        const path = this.get('apolloApiService').APOLLO_API.COMPANY_EMAIL_DOMAINS.SAVE;
        const jsonBody = JSON.stringify(cedsToSave.map(ced => ced.serialize()));

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: jsonBody
          });

          if (response.ok) {
            this.set('successMessage', this.get('intl').t('company.allowedDomains.successSaveMsg'));
            this.set('lastUpdated', new Date());
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },

      delete(companyEmailDomain) {
        if (!companyEmailDomain.get('id')) {
          this.get('companyEmailDomainsList').removeObject(companyEmailDomain);
          companyEmailDomain.unloadRecord();
        } else {
          if (!confirm(this.get('intl').t('common.sure'))) {
            return;
          }

          companyEmailDomain.destroyRecord().then(() => {
            this.set('successMessage', this.get('intl').t('company.allowedDomains.successDeleteMsg'));
          }).catch(response => {
            this.set('errors', response.errors);
          });
        }
      },

      add() {
        const company = this.get('company');
        this.get('companyEmailDomainsList').pushObject(this.get('store').createRecord('companyEmailDomain', {
          company
        }));
      }

    }
  });

  _exports.default = _default;
});