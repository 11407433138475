define("apollo/pods/components/checkpoint/weight-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init(...args) {
      this._super(...args);

      (true && !(!!this.get('checkpoint')) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'checkpoint',
        type: 'Checkpoint Model'
      }), !!this.get('checkpoint')));
      (true && !(!!this.get('weight')) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'weight',
        type: 'Weight Model'
      }), !!this.get('weight')));
    },

    intl: Ember.inject.service(),
    weightService: Ember.inject.service('weight-service'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    canEditWeight: Ember.computed('transport.hasAutoWeight', function () {
      if (this.get('checkpoint.transport.hasAutoWeight')) {
        return true;
      } else {
        return !this.get('authorityCheckerService').actionCanBePerformed('ROLE_EDIT_WEIGHT', this.get('checkpoint.transport'));
      }
    })
  });

  _exports.default = _default;
});