define("apollo/pods/components/related-to-labels-plain/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yubej7+0",
    "block": "{\"symbols\":[\"transport\"],\"statements\":[[4,\"if\",[[23,[\"transport\",\"isCombinedTransport\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"transport\",\"allCombinedTransports\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\"],[9],[1,[22,1,[\"relatedToLabel\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/related-to-labels-plain/template.hbs"
    }
  });

  _exports.default = _default;
});