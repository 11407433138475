define("apollo/pods/components/checkpoint/confirm-status-button/default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "68qxQZYD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"checkpoint\",\"task\",\"canBeConfirmedByRole\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"disabled\",[28,[[27,\"if\",[[27,\"or\",[[23,[\"isButtonDisabled\"]],[23,[\"checkpoint\",\"confirmButtonShouldBeDisabled\"]]],null],\"disabled\"],null]]]],[11,\"class\",\"btn btn-sm btn-primary btn-wide\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isButtonDisabled\"]]],null,{\"statements\":[[0,\"            \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"checkpoints.confirm\"],null],false],[0,\": \"],[1,[21,\"checkpointLabel\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"checkpoint\",\"step\",\"warehouse\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\"],[11,\"class\",\"small\"],[9],[0,\"\\n                    (\"],[1,[21,\"checkpointNameInAppropriateLanguage\"],false],[0,\")\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"checkpoint\",\"step\",\"company\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\"],[11,\"class\",\"small\"],[9],[0,\"\\n                    (\"],[1,[23,[\"checkpoint\",\"step\",\"company\",\"shortName\"]],false],[1,[27,\"if\",[[23,[\"checkpoint\",\"step\",\"city\"]],[27,\"concat\",[\", \",[23,[\"checkpoint\",\"step\",\"city\"]]],null],\"\"],null],false],[0,\")\\n                \"],[10],[0,\"\\n            \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[22,0,[]],\"confirm\",[23,[\"checkpoint\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"statuses.waitingForConfirmation\"],null],false],[0,\": \"],[1,[23,[\"checkpoint\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/checkpoint/confirm-status-button/default/template.hbs"
    }
  });

  _exports.default = _default;
});