define("apollo/pods/components/price-list/routes-table/route/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    actions: {
      toggleOffers(route) {
        route.toggleProperty('hasVisibleOffers');
      },

      showRouteForm(route) {
        console.log(`Wyświetlamy formularz trasy ${route.get('id')}...`);
        this.set('action', 'showModal');
        this.sendAction('action', 'routes.modals.route-form.show', route);
      },

      openAddNewRateModal(route) {
        this.set('action', 'showModal');
        const routeOffer = this.get('store').createRecord('route-offer', {
          route
        });
        const model = {
          routeOffer,
          route
        };
        this.sendAction('action', 'price-list.modals.rate-form', model);
      },

      openEditRateModal(routeOffer) {
        this.set('action', 'showModal');
        const route = routeOffer.get('route');
        const model = {
          routeOffer,
          route
        };
        this.sendAction('action', 'price-list.modals.rate-form', model);
      },

      deleteRoute(route) {
        route.deleteRecord();
        route.save();
      }

    }
  });

  _exports.default = _default;
});