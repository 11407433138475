define("apollo/pods/components/custom-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwJViXIX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[28,[[21,\"modalId\"]]]],[11,\"class\",\"modal\"],[11,\"role\",\"dialog\"],[11,\"tabindex\",\"-1\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"modal-dialog \",[21,\"classNames\"]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"yieldWithLayout\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[12,\"data-field-name\",[28,[[23,[\"transportType\",\"alias\"]]]]],[9],[0,\"\\n                    \"],[7,\"h3\"],[11,\"class\",\"bold col-xs-12\"],[9],[0,\"\\n                        \"],[1,[21,\"title\"],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showCancelButton\"]]],null,{\"statements\":[[0,\"                            \"],[7,\"i\"],[11,\"class\",\"fa fa-times pull-right clickable\"],[11,\"data-button-name\",\"close\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"subtitle\"]]],null,{\"statements\":[[0,\"                            \"],[7,\"p\"],[9],[0,\"\\n                                \"],[7,\"small\"],[9],[0,\"\\n                                    \"],[1,[21,\"subtitle\"],false],[0,\"\\n                                \"],[10],[0,\"\\n                            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n\\n                \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n                    \"],[14,1],[0,\"\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/custom-modal/template.hbs"
    }
  });

  _exports.default = _default;
});