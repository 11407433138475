define("apollo/pods/file-sets/status/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    buttonTimeout: false,
    isDownloadDisabled: Ember.computed('model.state', function () {
      return !(this.get('model.state') === 'READY_TO_DOWNLOAD');
    }),
    currentStateInfo: Ember.computed('model.state', function () {
      if (this.get('model.state') === 'ERROR') {
        return this.get('intl').t('fileSet.stateDesc.ERROR');
      } else if (this.get('model.state') === 'READY_TO_DOWNLOAD') {
        return this.get('intl').t('fileSet.stateDesc.READY_TO_DOWNLOAD');
      } else if (this.get('model.state') === 'PROCESSING') {
        return this.get('intl').t('fileSet.stateDesc.PROCESSING');
      } else {
        return this.get('intl').t('fileSet.stateDesc.NEW');
      }
    }),
    actions: {
      async download(fileSetId) {
        const BUTTON_DISABLED_TIMEOUT_MS = 1000;
        this.toggleProperty('buttonTimeout');
        const path = this.get('apolloApiService').APOLLO_API.FILE_SET.DOWNLOAD;
        const response = await this.get('apolloApiService').callApolloApi(path, {
          id: fileSetId
        });

        if (!response.ok) {
          this.toggleProperty('buttonTimeout');
          return;
        }

        const fileContent = await response.blob();
        const link = document.createElement('a');
        const url = URL.createObjectURL(fileContent);
        link.href = url;
        link.download = `${fileSetId}.zip`;
        link.click();
        URL.revokeObjectURL(url);
        Ember.run.later(this, function () {
          this.toggleProperty('buttonTimeout');
        }, BUTTON_DISABLED_TIMEOUT_MS);
      }

    }
  });

  _exports.default = _default;
});