define("apollo/pods/components/companies/apply-form/insurance-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9z1/q/cG",
    "block": "{\"symbols\":[\"d\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"partnership\",\"activeInsurances\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"documentType\",\"id\"]],[23,[\"documentType\",\"id\"]]],null]],null,{\"statements\":[[0,\"            \"],[1,[27,\"documents-applies/document-form\",null,[[\"document\",\"documentType\"],[[22,1,[]],[23,[\"documentType\"]]]]],false],[0,\"\\n\\n            \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-12 form-buttons padding-top margin-bottom\"],[9],[0,\"\\n        \"],[1,[27,\"documents-applies/show-document-button\",null,[[\"partnership\",\"documentType\",\"groupName\"],[[23,[\"partnership\"]],[23,[\"documentType\"]],[23,[\"groupName\"]]]]],false],[0,\"\\n\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.refresh\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"refresh\"]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"archivedInsurances\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"document.INSURANCE.showArchived\"],null],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"showArchived\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/companies/apply-form/insurance-section/template.hbs"
    }
  });

  _exports.default = _default;
});