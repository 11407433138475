define("apollo/pods/index-quantity/model", ["exports", "ember-data", "ember-data-copyable", "apollo/mixins/model-validator"], function (_exports, _emberData, _emberDataCopyable, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, {
    intl: service(),
    index: _emberData.default.belongsTo('index', {
      async: false
    }),
    indexQuantityType: _emberData.default.belongsTo('indexQuantityType', {
      async: false
    }),
    quantity: _emberData.default.attr('number'),
    unit: _emberData.default.attr('string'),
    validations: {
      quantity: {
        numericality: {
          allowBlank: true,
          greaterThanOrEqualTo: 0,
          message: 'invalidNumber'
        },
        custom: [{
          validation: function (key, value, model) {
            return !!value || !model.get('indexQuantityType.obligatory') || model.get('incompleteLoadingRulesNotMet');
          },
          message: 'obligatory'
        }, {
          validation: function (key, value, model) {
            if (!!value || !model.get('indexQuantityType.conditionallyObligatory')) {
              return true;
            } // Jeśli typ transportu ma jakieś typy indeksów z opcją `conditionallyObligatory`,
            // to chociaż jedna taka wielkość musi zostać uzupełniona.


            const transportType = model.get('index.transport.transportType');
            const conditionallyObligatoryIndexQuantityTypes = transportType.get('conditionallyObligatoryIndexQuantityTypes');

            if (!conditionallyObligatoryIndexQuantityTypes.get('length')) {
              return true;
            }

            return !!model.get('index.quantities').filterBy('indexQuantityType.conditionallyObligatory').filterBy('quantity').get('length');
          },
          message: function (key, value, self) {
            const transportType = self.get('index.transport.transportType');
            const conditionallyObligatoryIndexQuantityTypes = transportType.get('conditionallyObligatoryIndexQuantityTypes');
            const names = conditionallyObligatoryIndexQuantityTypes.getEach('nameInAppropriateLanguage').join(', ');
            return self.get('intl').t('index-quantity.errors.quantity.conditionallyObligatory', {
              names: names
            }).toString();
          }
        }]
      },
      unit: {
        custom: [{
          validation: function (key, value, model) {
            return !!value || !model.get('indexQuantityType.requiresUnit');
          },
          message: 'blankSelect'
        }]
      }
    },
    numberOfErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length');
    }).readOnly(),
    incompleteLoadingRulesNotMet: Ember.computed('indexQuantityType.enabledForIncompleteLoading', 'index.transport.incompleteLoading', function () {
      return this.get('indexQuantityType.enabledForIncompleteLoading') && !this.get('index.transport.incompleteLoading');
    }),
    customSerialize: function () {
      return Object.assign(this.serialize(), {
        id: this.get('id')
      });
    }
  });

  _exports.default = _default;
});