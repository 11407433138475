define("apollo/mixins/form-utils-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),

    clearBootstrapError(el) {
      $(el).closest(".has-error").removeClass("has-error");
    },

    erpIdentifierColumnLabel: Ember.computed('column-names', function () {
      const properSetting = this.get('languageService').findProperLanguageVersion('ERP_IDENTIFIER_LABEL', 'ERP_IDENTIFIER_LABEL_EN');
      return this.get('sessionAccount').getSettingValue(properSetting);
    }),
    altIdentifierColumnLabel: Ember.computed('column-names', function () {
      const properSetting = this.get('languageService').findProperLanguageVersion('ALT_IDENTIFIER_LABEL', 'ALT_IDENTIFIER_LABEL_EN');
      return this.get('sessionAccount').getSettingValue(properSetting);
    }),
    contractorNumberLabel: Ember.computed('column-names', function () {
      const properSetting = this.get('languageService').findProperLanguageVersion('CONTRACTOR_NUMBER_LABEL', 'CONTRACTOR_NUMBER_LABEL_EN');
      return this.get('sessionAccount').getSettingValue(properSetting);
    }),
    warehouseZoneLabel: Ember.computed('column-names', function () {
      const properKey = this.get('languageService').findProperLanguageVersion('WAREHOUSE_ZONE_LABEL', 'WAREHOUSE_ZONE_LABEL_EN');
      const setting = this.get('sessionAccount').getSettingValue(properKey);
      return setting ? setting : this.get('intl').t('warehouseZone.label');
    }),

    setDateOfPeriodicAdvice(date, propertyName) {
      if (this.get('transport.periodicTransportActive')) {
        this.set(propertyName, date[0]);
      }
    }

  });

  _exports.default = _default;
});