define("apollo/pods/components/phone-prefix/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedPhonePrefix: Ember.computed('advice.driverMobilePhonePrefix', 'existingPhonePrefix', function () {
      const defaultPrefix = this.get('store').peekAll('country').find(country => country.ibanSymbol === 'PL');
      const existingPrefix = this.get('store').peekAll('country').find(country => country.phonePrefix === this.get('existingPhonePrefix'));
      return existingPrefix || defaultPrefix;
    }),
    disabled: Ember.computed(function () {
      return this.get('advice.disabled') || this.get('shouldBeDisabled');
    }),
    phonePrefixes: Ember.computed(function () {
      const sortedPhonePrefixes = this.get('store').peekAll('country').map(country => {
        return {
          phonePrefix: country.phonePrefix,
          ibanSymbol: country.ibanSymbol
        };
      }).sortBy('ibanSymbol');
      const polishPhonePrefix = sortedPhonePrefixes.find(element => element.ibanSymbol === 'PL');
      const index = sortedPhonePrefixes.indexOf(polishPhonePrefix); // chcemy mieć polski kierunkowy na pierwszym miejscu

      sortedPhonePrefixes.splice(index, 1);
      sortedPhonePrefixes.unshift(polishPhonePrefix);
      return sortedPhonePrefixes;
    }),

    searchForPhonePrefix(term, obj) {
      const phonePrefixes = obj.options;
      return phonePrefixes.filter(element => {
        return element.ibanSymbol.includes(term) || element.phonePrefix.includes(term);
      });
    },

    actions: {
      setPhonePrefix: function (phonePrefix) {
        this.set('selectedPhonePrefix', phonePrefix);
        this.setPhonePrefix(phonePrefix.phonePrefix);
      }
    }
  });

  _exports.default = _default;
});