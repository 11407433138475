define("apollo/pods/warehouse-locations/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    model: function (params) {
      const warehouse = this.get('store').peekRecord('warehouse', params.warehouse_id);
      const warehouseLocation = this.store.createRecord('warehouseLocation', {
        enabled: true,
        warehouse
      });
      const gates = this.get('store').findAll('gate');
      return Ember.RSVP.hash({
        warehouseLocation,
        gates
      });
    }
  });

  _exports.default = _default;
});