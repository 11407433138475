define("apollo/pods/statistics-report/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reportWidth: '100%',
    reportHeight: '1000',
    reportTitle: Ember.computed('report.dashboardName', function () {
      return this.get('report.dashboardName') + '_STATISTICS_REPORT';
    }),
    report: Ember.computed('model.{statisticsReports.firstObject,currentReport}', function () {
      return this.get('model.currentReport') || this.get('model.statisticsReports.firstObject');
    }),
    url: Ember.computed('report.url', function () {
      return this.get('report.url');
    }),
    noReportsAvailable: Ember.computed('model.statisticsReports{[],length}', function () {
      return !this.get('model.statisticsReports') || this.get('model.statisticsReports.length') === 0;
    })
  });

  _exports.default = _default;
});