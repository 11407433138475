define("apollo/pods/components/planning/planned-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LqeoaiVt",
    "block": "{\"symbols\":[\"transport\"],\"statements\":[[4,\"each\",[[23,[\"currentTransports\"]]],null,{\"statements\":[[4,\"draggable-object-target\",null,[[\"action\"],[[27,\"action\",[[22,0,[]],\"addDelivery\",[22,1,[]]],null]]],{\"statements\":[[0,\"        \"],[1,[27,\"planning/planned-panel/transport-panel\",null,[[\"transport\",\"transportType\"],[[22,1,[]],[23,[\"transportType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[7,\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"transportType\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-info pull-right\"],[11,\"style\",\"margin-top: -6px\"],[9],[1,[27,\"t\",[\"common.add\"],null],false],[0,\" \"],[1,[27,\"t\",[\"common.order\"],null],false],[3,\"action\",[[22,0,[]],\"openModal\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/planning/planned-panel/template.hbs"
    }
  });

  _exports.default = _default;
});