define("apollo/helpers/websocket-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ObjectProxy.extend({
    subscriptions: null,
    connected: Ember.computed(function () {
      return this.client ? this.client.connected : false;
    }),

    init() {
      this._super(...arguments);
      /*
          Przedstawia mapę subskrypcji, gdzie kluczem jest endpoint a wartością obiekt typu subscription:
          subscriptions = {
              {string} topic: {subscription} subscription
          }
       */


      this.subscriptions = {};
    },

    /*
        Metoda pozwala zrezygnować z wybranej subskrypcji
         @param {string} topic Jest to endpoint, który nie chcemy już dłużej subskrybować
        @param {object} headers Są to opcjonalne nagłówki, które zostaną przekazane do natywnego websocket'u
                               @see https://www.npmjs.com/package/webstomp-client
     */
    unsubscribe(topic, headers = {}) {
      if (!this.subscriptions[topic]) {
        console.debug('Taka subskrypcja nie istnieje, więc nie możemy jej usunąć.');
        return;
      }

      this.subscriptions[topic].unsubscribe(headers);
      delete this.subscriptions[topic];
    },

    /*
        Metoda pozwala zrezygnować z wielu subskrypcji jednocześnie
         @param {string} pattern Jest to regex, który zostanie użyty do znalezienia aktywnej subskrypcji
        @param {object} headers Są to opcjonalne nagłówki, które zostaną przekazane do natywnego websocket'u
                               @see https://www.npmjs.com/package/webstomp-client
     */
    unsubscribeAny(pattern, headers = {}) {
      const regex = new RegExp(pattern);
      const allActiveTopics = Object.keys(this.subscriptions);
      const topicsToUnsubscribe = allActiveTopics.filter(topic => {
        return regex.test(topic);
      });
      topicsToUnsubscribe.forEach(topic => {
        this.unsubscribe(topic, headers);
      });
    },

    /*
       @param {string} topic Jest to endpoint, który chcemy zasubskrybować
       @param {function} callback Jest to funkcja typu callback, która wywoła się po poprawnym zasubskrybowaniu tematu
       @param {object} headers Są to opcjonalne nagłówki, które zostaną przekazane do natywnego websocket'u
                               @see https://www.npmjs.com/package/webstomp-client
     */
    subscribe(topic, callback, headers = {}) {
      // TODO: Ponowienie próby subskrypcji gdy nie jesteśmy jeszcze połączeni za pomocą websocketa
      if (!this._isWebSocketOpen()) {
        console.debug('Połączenie nie zostało otwarte, więc nie możemy subskrybować tego tematu.');
        return;
      }

      if (this.subscriptions[topic]) {
        console.debug('Użytkownik już subskrybuje ten temat');
        return;
      }

      const subscription = this.client.subscribe(topic, message => {
        callback.call(this, message);
      }, headers);
      this.subscriptions[topic] = subscription;
    },

    /*
       @param {string} destination Jest to endpoint, na który wyślemy wiadomość
       @param {string} message Jest to wiadomość, która zostanie przekazana do wysłania przez natywny websocket
       @param {object} headers Są to opcjonalne nagłówki, które zostaną przekazane do natywnego websocket'u
                                @see https://www.npmjs.com/package/webstomp-client
     */
    send(destination, message, headers = {}) {
      if (!this._isWebSocketOpen()) {
        console.debug('Połączenie nie zostało otwarte, więc nie możemy wysłać wiadomości.');
        return;
      }

      this.client.send(destination, message, headers);
    },

    disconnect() {
      if (!this._isWebSocketOpen()) {
        console.debug('Połączenie nie zostało otwarte, więc nie możemy zamknąć połączenia.');
        return;
      }

      this.client.disconnect();
    },

    _isWebSocketOpen() {
      if (!this.client) {
        return false;
      }

      return this.client.connected;
    }

  });

  _exports.default = _default;
});