define("apollo/pods/components/authorities-nav-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oxiwNogt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12 text-right padding-bottom\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n\\n\"],[4,\"link-to\",[\"users.index\",[27,\"query-params\",null,[[\"type\"],[[23,[\"roleGroup\",\"name\"]]]]]],[[\"class\"],[\"btn btn-default\"]],{\"statements\":[[0,\"                \"],[1,[27,\"t\",[\"settings.roleGroups.usersList\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"authorities.index\",[23,[\"roleGroup\",\"id\"]]],[[\"class\"],[\"btn btn-info\"]],{\"statements\":[[0,\"                \"],[1,[27,\"t\",[\"settings.roleGroups.permissions\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n            \"],[7,\"span\"],[11,\"data-button-name\",\"OTHERS_TAB\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authorities.others\",[23,[\"roleGroup\",\"id\"]]],[[\"class\"],[\"btn btn-info\"]],{\"statements\":[[0,\"                    \"],[1,[27,\"t\",[\"common.settings\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/authorities-nav-header/template.hbs"
    }
  });

  _exports.default = _default;
});