define("apollo/mixins/step-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    findPackageTypeTransportTypes: function (transportTypeId) {
      return this.get('store').peekAll('packageTypeTransportType').filter(i => i.get('transportType.id') === transportTypeId);
    },
    handleStepPackageTypesForTransport: function (transport) {
      this.handleStepPackageTypes(transport.get('steps'), transport.get('transportType.id'));
    },
    handleStepPackageTypesForStep: function (step) {
      this.handleStepPackageTypes([step], step.get('transport.transportType.id'));
    },
    handleStepPackageTypes: function (steps, transportTypeId) {
      const packageTypeTransportTypes = this.findPackageTypeTransportTypes(transportTypeId);
      const contractorSteps = steps.filter(s => !s.get('ownedByPrincipal'));
      const warehouseSteps = steps.filter(s => s.get('ownedByPrincipal'));
      packageTypeTransportTypes.forEach(pttt => {
        if (pttt.get('visibleInContractorStep')) {
          contractorSteps.forEach(s => this.createStepPackageType(s, pttt));
        }

        if (pttt.get('visibleInWarehouseStep')) {
          warehouseSteps.forEach(s => this.createStepPackageType(s, pttt));
        }
      });
    },
    createStepPackageType: function (step, packageTypeTransportType) {
      const spt = this.get('store').createRecord('stepPackageType', {
        step: step,
        packageType: packageTypeTransportType.get('packageType'),
        multiplier: packageTypeTransportType.get('multiplier')
      });
      step.get('stepPackageTypes').pushObject(spt);
    }
  });

  _exports.default = _default;
});