define("apollo/pods/announcements/create/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    announcementsManageController: Ember.inject.controller('announcements/manage'),
    applicationController: Ember.inject.controller('application'),
    AVAILABLE_RANGES: ["ROLE_GROUP", "COMPANY"],
    companies: [],

    init() {
      this._super(...arguments);

      const principalCompanyId = localStorage.getItem('principalId');
      this.get('store').query('company', {
        principalCompanyId: principalCompanyId
      }).then(companies => {
        this.set('companies', companies);
        this.set('companiesLoaded', true);
      });
    },

    rangeObjectsLoaded: Ember.computed('announcement.range', 'companiesLoaded', function () {
      return this.get('announcement.range') === "ROLE_GROUP" || this.get('companiesLoaded');
    }),
    announcement: Ember.computed('model.announcement', function () {
      return this.get('model.announcement');
    }),
    manageEnglishVersion: function () {
      if (!this.get('announcement.hasEnglishVersion')) {
        this.set('announcement.subjectEn', null);
        this.set('announcement.msgContentEn', null);
      }
    },
    roleGroups: Ember.computed('model.roleGroups', function () {
      return this.get('model.roleGroups');
    }),
    rangeObjects: Ember.computed('announcement.range', 'companies', 'roleGroups', function () {
      return this.get('announcement.range') === "COMPANY" ? this.get('companies') : this.get('roleGroups');
    }),
    proxiedRangeObjects: Ember.computed.map('rangeObjects', function (o) {
      return Ember.ObjectProxy.create({
        content: o,
        checked: false
      });
    }),
    proxiedCheckedRangeObjects: Ember.computed.filterBy('proxiedRangeObjects', 'checked', true),
    checkedRangeObjects: Ember.computed.mapBy('proxiedCheckedRangeObjects', 'content'),
    rangePropertyName: Ember.computed('announcement.range', function () {
      return this.get('announcement.range') === "COMPANY" ? "companies" : "roleGroups";
    }),
    rangeObjectErrors: Ember.computed('rangePropertyName', 'announcement.errors.[]', function () {
      return this.get(`announcement.errors.${this.get('rangePropertyName')}`);
    }),
    setProxiedObjects: Ember.observer('model', 'rangePropertyName', 'rangeObjectsLoaded', function () {
      const self = this;
      const propertyName = this.get('rangePropertyName');
      Ember.run.once(this, function () {
        const announcementObjects = self.get(`announcement.${propertyName}`) ? self.get(`announcement.${propertyName}`).getEach('id') : [];
        const proxiedObjects = self.get('proxiedRangeObjects');
        proxiedObjects.forEach(function (o) {
          const checked = announcementObjects.includes(o.get('id'));
          o.set('checked', checked);
        });
      });
    }),
    //https://jsfiddle.net/alvaroAV/svvz7tkn/
    isIE: Ember.computed('', function () {
      const ua = window.navigator.userAgent;
      return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    }),
    handleContentOnIE: function () {
      this.setMsgContent("#msg-content-field > div.pell-content", "msgContent");
      this.setMsgContent("#msg-content-en-field > div.pell-content", "msgContentEn");
    },
    setMsgContent: function (selector, propertyName) {
      const msgContent = (0, _jquery.default)(selector);

      if (msgContent) {
        this.set(`announcement.${propertyName}`, msgContent.html());
      }
    },
    actions: {
      toggleAnnouncementType() {
        const newType = this.get('announcement.type') === "PRINCIPAL" ? "ADMIN" : "PRINCIPAL";
        this.set('announcement.type', newType);
      },

      toggleAnnouncementInEnglish() {
        this.get('announcement').toggleProperty('hasEnglishVersion');
      },

      setDate(propertyName, date) {
        this.set(`announcement.${propertyName}`, date[0]);
      },

      save() {
        this.set('errors', null);
        const announcement = this.get('announcement');
        const button = (0, _jquery.default)("button[data-button-name='announcement-save']");
        announcement.get('roleGroups').clear();
        announcement.get('companies').clear();
        const rangeProperty = this.get('rangePropertyName');
        this.get('checkedRangeObjects').forEach(o => {
          announcement.get(rangeProperty).pushObject(o);
        });

        if (this.get('isIE')) {
          this.handleContentOnIE();
        }

        if (!announcement.validate()) {
          button.button('reset');
          return;
        }

        this.manageEnglishVersion();
        announcement.save().then(() => {
          this.get("announcementsManageController").set('successMessage', this.get('intl').t("common.saveSuccessMessage"));
          this.get('applicationController').refreshAnnouncementCounter();
          this.transitionToRoute("announcements.manage");
        }).catch(response => {
          this.set('errors', response.errors);
        }).finally(() => {
          button.button('reset');
        });
      },

      delete() {
        this.get('announcement').destroyRecord().then(() => {
          this.get("announcementsManageController").set('successMessage', this.get('intl').t("common.saveSuccessMessage"));
          this.get('applicationController').refreshAnnouncementCounter();
          this.transitionToRoute("announcements.manage");
        }).catch(response => {
          this.set('errors', response.errors);
        });
      },

      cancel() {
        this.get('announcement').rollbackAttributes();
        this.transitionToRoute("announcements.manage");
      }

    }
  });

  _exports.default = _default;
});