define("apollo/pods/components/transport/messages-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9e0qpq+r",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"canNotEditAfterProcessStarts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"alert alert-info\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"alert\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"canNotEditBecauseOfOtherContractors\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"transport.canNotEditBecauseOfOtherContractors\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"transport.canNotEditAfterProcessStarts\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"errors-alert\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport/messages-section/template.hbs"
    }
  });

  _exports.default = _default;
});