define("apollo/pods/companies/modals/edit-address/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    store: service(),
    sessionAccount: service('session-account'),
    showVoivodeshipField: Ember.computed('model.country.ibanSymbol', function () {
      return this.get('sessionAccount').getSettingValue('SHOW_COMPANY_VOIVODESHIP_FIELD') === 'true' && this.get('model.country.ibanSymbol') === 'PL';
    }),
    countries: Ember.computed('', function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),

    _handleSuccessfullSave() {
      this.set('errors', []);
      this.send('refreshModel');
      this.send('hideModal');
      this.setProperties({
        errors: [],
        model: null
      });
    },

    actions: {
      async update() {
        const self = this;
        const address = this.get('model');

        if (address.get('id')) {
          const newAddress = await address.copy(true);
          newAddress.set('idToDisable', address.get('id'));
          newAddress.save().then(() => {
            self._handleSuccessfullSave();
          }).catch(response => {
            self.set('errors', response.errors);
          });
        } else {
          address.save().then(() => {
            self._handleSuccessfullSave();
          }).catch(response => {
            self.set('errors', response.errors);
          });
        }
      },

      cancel() {
        console.debug('Zamykamy okienko edycji adresu...');

        if (!this.get('model.id')) {
          this.get('model').unloadRecord();
        } else if (this.get('model')) {
          this.get('model').rollbackAttributes();
        }

        this.setProperties({
          errors: [],
          model: null
        });
      }

    }
  });

  _exports.default = _default;
});