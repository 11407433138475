define("apollo/pods/components/planning/modals/add-transport/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    store: service(),
    deliveryService: service(),
    intl: service(),
    dummyTransport: Ember.computed('model.dummyTransport', function () {
      return this.get('model.dummyTransport');
    }),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes');
    }),
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    }),
    customFields: Ember.computed('model.customFields', function () {
      const definitions = this.get('model.customFields');
      const pairs = [];

      for (let i = 0; i < definitions.get('length'); i++) {
        const definition = definitions.objectAt(i);
        const transport = this.get('dummyTransport');
        const value = this.store.createRecord('customFieldValue', {
          definition,
          transport,
          option: definition.get('defaultOption')
        });
        transport.get('customFields').addObject(value);
        pairs[i] = {
          value,
          definition
        };
      }

      return pairs;
    }),
    unloadObsoleteObjectsAndCloseModal: async function () {
      this.get('customFields').forEach(cf => {
        if (cf.value) {
          cf.value.destroyRecord();
        }
      });
      this.get('dummyTransport').destroyRecord();
      this.send('hideModal');
    },
    actions: {
      save() {
        if (!this.get('dummyTransport.customFields').every(cf => cf.validate())) {
          this.set('errors', Ember.A([this.get('intl').t('errors.blankSelect')]));
          return;
        }

        this.deliveryService.createNewBasicTransport(this.get('dummyTransport'));
        this.set('errors', Ember.A([]));
        this.unloadObsoleteObjectsAndCloseModal();
      },

      cancel() {
        this.unloadObsoleteObjectsAndCloseModal();
      }

    }
  });

  _exports.default = _default;
});