define("apollo/pods/drivers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qPwpvkbc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n\\n    \"],[1,[27,\"company-nav-header\",null,[[\"company\"],[[23,[\"model\",\"company\"]]]]],false],[0,\"\\n\\n    \"],[1,[27,\"drivers/drivers-list\",null,[[\"company\",\"drivers\"],[[23,[\"model\",\"company\"]],[23,[\"model\",\"drivers\"]]]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/drivers/index/template.hbs"
    }
  });

  _exports.default = _default;
});