define("apollo/pods/square/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    name: _emberData.default.attr('string'),
    idx: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    queues: _emberData.default.hasMany('queue', {
      async: true
    }),
    enabled: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      idx: {
        presence: {
          message: 'blank'
        }
      }
    },
    enabledQueuesForCurrentUser: Ember.computed('sessionAccount.currentUser.ramps', function () {
      return this.get('sessionAccount.currentUser.queues').filterBy('square.id', this.get('id'));
    })
  });

  _exports.default = _default;
});