define("apollo/pods/components/log-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uqn1LuBB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"mode\"]],\"USER\"],null]],null,{\"statements\":[[0,\"    \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,[\"log\",\"actor\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/log-label/template.hbs"
    }
  });

  _exports.default = _default;
});