define("apollo/pods/sent-notification/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTSerializer
  } = _emberData.default;

  var _default = RESTSerializer.extend({
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const {
        currentPage,
        pageSize,
        totalPages,
        totalItems
      } = payload[0].metaData;
      const metaData = {
        currentPage,
        pageSize,
        totalPages,
        totalItems
      };
      const normalizedPayload = {
        'sentNotifications': payload[0].data,
        'meta': metaData
      };
      return this.normalizeArrayResponse(store, primaryModelClass, normalizedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});