define("apollo/services/api/weight-api-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    weightUrl: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('WEIGHT_URL');
    }),
    weightSelector: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('WEIGHT_SELECTOR');
    }),
    isMock: Ember.computed('weightUrl', function () {
      return this.get('weightUrl').includes('api/weights/weightMock');
    }),

    parseFetchedWeight(fetchResponse) {
      const selector = this.get('weightSelector');

      if (this.get('isMock')) {
        return Number(fetchResponse[selector]);
      } else {
        const html = $.parseHTML(fetchResponse);
        const weightHTML = $(html).find(selector).html();
        const weightText = weightHTML.split(' ')[0];
        return Number(weightText);
      }
    },

    async fetchWeight() {
      if (this.get('isMock')) {
        console.debug(`Fetching mock weight from Apollo API ${this.get('weightUrl')}`);
        const path = this.apolloApiService.APOLLO_API.WEIGHTS.MOCK;
        const response = await this.apolloApiService.callApolloApi(path);
        return await response.json();
      } else {
        console.debug(`Fetching weight from third party API ${this.get('weightUrl')}`);
        const requestOptions = {
          method: 'GET',
          cache: 'no-cache'
        };
        const response = await fetch(this.get('weightUrl'), requestOptions);
        return await response.text();
      }
    },

    callSynchronizeWeights(weightObjects) {
      const path = this.apolloApiService.APOLLO_API.WEIGHTS.SYNCHRONIZE_WEIGHTS;
      return this.apolloApiService.callApolloApi(path, null, {
        body: JSON.stringify(weightObjects)
      });
    }

  });

  _exports.default = _default;
});