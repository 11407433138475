define("apollo/initializers/moment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    moment.updateLocale('pl', {
      calendar: {
        sameDay: '[dziś o] LT',
        nextDay: '[jutro o] LT',
        nextWeek: 'dddd [o] LT',
        lastDay: '[wczoraj o] LT',
        lastWeek: 'L HH:mm',
        sameElse: 'L HH:mm'
      }
    });

    try {
      moment.tz.setDefault('Europe/Warsaw');
    } catch (e) {
      console.error(e);
    }
  }

  var _default = {
    name: 'moment',
    initialize: initialize
  };
  _exports.default = _default;
});