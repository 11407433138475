define("apollo/pods/components/iframe-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSI2PId8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\"],[12,\"id\",[28,[[21,\"title\"],\"_IFRAME\"]]],[12,\"title\",[28,[[21,\"title\"]]]],[12,\"width\",[28,[[21,\"width\"]]]],[12,\"height\",[28,[[21,\"height\"]]]],[12,\"src\",[21,\"url\"]],[9],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/iframe-component/template.hbs"
    }
  });

  _exports.default = _default;
});