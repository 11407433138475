define("apollo/helpers/match-translation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, nameArgs) {
      const self = this;
      const key = nameArgs.key;
      const propertyName = nameArgs.propertyName;
      return function (option, searchTerm) {
        if (!option) {
          return -1;
        }

        const propertyKey = option.get(propertyName);
        const translation = self.get('intl').t(`${key}.${propertyKey}`).toLowerCase();
        return translation.indexOf(searchTerm.toLowerCase());
      };
    }

  });

  _exports.default = _default;
});