define("apollo/pods/components/toggle-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    setInsuranceExpanse: Ember.on('init', function () {
      if (!this.get('partnership') || !this.get('documentType')) {
        return;
      }

      const activeInsurance = this.get('partnership.documents').filter(document => document.get('documentType.id') === this.get('documentType.id'));
      const approvedInsurance = activeInsurance.filter(document => document.get('state') === document.get('STATES.ACCEPTED'));

      if (activeInsurance.get('length') === 0 || activeInsurance.get('length') !== approvedInsurance.get('length')) {
        this.set('shouldBeExpanded', true);
      } else {
        this.set('shouldBeExpanded', false);
      }
    })
  });

  _exports.default = _default;
});