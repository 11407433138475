define("apollo/pods/warehouse-locations/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    warehouseLocation: Ember.computed('model.warehouseLocation', function () {
      return this.get('model.warehouseLocation');
    }),
    gates: Ember.computed('model.gates', function () {
      return this.get('model.gates').filterBy('isEntryGate');
    }),
    actions: {
      save: function () {
        const self = this;
        this.get('warehouseLocation').save().then(() => {
          self.setProperties({
            errors: []
          });
          const wh = self.get('warehouseLocation.warehouse');
          wh.reload();
          self.transitionToRoute('warehouses.edit', wh.get('id'));
        }).catch(response => {
          console.log(`Błąd przy zapisywaniu lokalizacji: ${response.errors}`);
          self.set('errors', response.errors);
        });
      },
      delete: function () {
        const self = this;
        self.get('warehouseLocation').destroyRecord().then(() => {
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('warehouseLocation.warehouse.id'));
        }).catch(response => {
          console.log(`Błąd przy usuwaniu lokalizacji: ${response.errors}`);
          self.set('errors', response.errors);
        });
      },
      cancel: function () {
        const warehouseId = this.get('warehouseLocation.warehouse.id');
        this.get('warehouseLocation').unloadRecord();
        this.transitionToRoute('warehouses.edit', warehouseId);
      }
    }
  });

  _exports.default = _default;
});