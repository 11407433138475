define("apollo/pods/role-group-authority/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    roleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    authority: _emberData.default.belongsTo('authority'),
    validations: {
      authority: {
        presence: {
          message: 'blankSelect'
        }
      }
    },
    idx: Ember.computed('authority.idx', function () {
      return this.get('authority.idx');
    }),
    group: Ember.computed('authority.group', function () {
      return this.get('authority.group');
    }),
    name: Ember.computed('authority.name', function () {
      return this.get('authority.name');
    }),
    description: Ember.computed('authority.description', function () {
      return this.get('authority.description');
    }),
    canBeManaged: Ember.computed('authority.canBeManaged', function () {
      return this.get('authority.canBeManaged');
    })
  });

  _exports.default = _default;
});