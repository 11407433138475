define("apollo/pods/components/address-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: 'id',
    urlAddress: '/addresses/find',
    field: 'city',
    options: [],
    suggestions: function () {
      return this.get('options');
    }.property('options.@each'),
    actions: {
      selectItem: function (item) {
        this.set('selectedFromList', true);
        this.set('selectedValue', item.recipient);
        this.sendAction('selectItem', item);
      }
    }
  });

  _exports.default = _default;
});