define("apollo/pods/components/transports/choose-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    tagName: 'div',
    classNames: ['pull-right', 'text-right', 'horizontal-container'],
    actionName: '',
    scrollPage: 'scrollPage',
    transportTypeModes: Ember.computed('transportTypes.@each.name', function () {
      return ['ALL', ...this.get('transportTypes').filterBy('canBeSeenInArchiveByRoleGroup').getEach('alias')];
    }),
    transportTypesCanBeSeenInArchiveByRoleGroup: Ember.computed('transportTypes.[]', function () {
      return this.get('transportTypes').filterBy('canBeSeenInArchiveByRoleGroup');
    }),
    allTransportTypesLoaded: Ember.computed('transportTypes.@each.loadingCompleted', 'transportTypeMode', function () {
      return this.get('transportTypes').filterBy('loadingCompleted').get('length') === this.get('transportTypes').get('length');
    }),
    actions: {
      scrollPage: function (transportTypeId) {
        this.sendAction('scrollPage', transportTypeId);
      },

      setTransportTypeMode(mode) {
        this.set('actionName', 'setTransportTypeMode');
        this.sendAction('actionName', mode);
      },

      saveTransportAliasTypeToLocalStorage(alias) {
        localStorage.setItem('LastVisitedTransportType', alias);
      }

    }
  });

  _exports.default = _default;
});