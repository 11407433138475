define("apollo/pods/components/square-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    canManageQueuesAccessibility: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_QUEUES_ACCESSIBILITY');
    }),
    actions: {
      toggleSquare: function (square) {
        this.get('squares').forEach(s => {
          if (s.get('id') === square.get('id')) {
            s.set('checked', !s.get('checked'));
            const isSquareChecked = s.get('checked');
            s.get('proxiedQueues').forEach(r => r.set('checked', isSquareChecked));
          }
        });
      }
    }
  });

  _exports.default = _default;
});