define("apollo/pods/file-sets/status/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function (params) {
      return this.store.findRecord('file-set', params.id);
    },
    afterModel: function (model) {
      // Prosty mechanizm przeładowania modelu. Job przetwarzający pliki uruchamia się po kilku sekundach,
      // więc status przetwarzanych plików powinien zmienić się po krótkim opóźnieniu.
      const REFRESH_MODEL_TIMEOUT_MS = 5000;
      const currentState = model.get('state');

      if (currentState === 'NEW' || currentState === 'PROCESSING') {
        Ember.run.later(this, function () {
          this.refresh();
        }, REFRESH_MODEL_TIMEOUT_MS);
      }
    }
  });

  _exports.default = _default;
});