define("apollo/pods/authorities/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authoritiesIndexController: Ember.inject.controller('authorities/index'),
    aggregatedAuthoritiesIndexController: Ember.inject.controller('authorities/aggregate'),
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errors: [],
    roleGroups: Ember.computed(function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);
    }),
    selectedAuthority: Ember.computed('model.selectedAuthority', 'model.roleGroupAuthority.authority', function () {
      return this.get('model.roleGroupAuthority.authority') || this.get('model.selectedAuthority');
    }),
    multiSelect: Ember.computed('model.multiSelect', function () {
      return this.get('model.multiSelect');
    }),
    roleGroupAuthority: Ember.computed('model.roleGroupAuthority', function () {
      return this.get('model.roleGroupAuthority');
    }),
    actions: {
      save: async function () {
        const self = this;
        const roleGroupAuthority = this.get('roleGroupAuthority');
        const roleGroups = this.get('model.roleGroups');
        const multiSelect = this.get('multiSelect');
        const roleGroupAuthorityList = Ember.A();

        if (multiSelect) {
          roleGroups.forEach(rg => {
            roleGroupAuthorityList.pushObject(rg.get('id'));
          });
          const data = JSON.stringify({
            roleGroupIds: roleGroupAuthorityList,
            authorityId: self.get('model.selectedAuthority.id')
          });

          try {
            const path = this.get('apolloApiService').APOLLO_API.ROLE_GROUP_AUTHORITIES.SAVE_AGGREGATED_ROLE_GROUP_AUTHORITIES;
            const response = await this.get('apolloApiService').callApolloApi(path, null, {
              body: data
            });

            if (response.ok) {
              const msg = self.get('intl').t('settings.roleGroups.successUpdate');
              self.get('aggregatedAuthoritiesIndexController').set('errors', '');
              self.get('aggregatedAuthoritiesIndexController').set('successMessage', msg);
              self.transitionToRoute('authorities.aggregate');
            } else {
              const error = await response.json();
              self.get('aggregatedAuthoritiesIndexController').set('errors', error.errors);
              self.get('aggregatedAuthoritiesIndexController').set('successMessage', '');
              self.transitionToRoute('authorities.aggregate');
            }
          } catch (error) {
            self.get('aggregatedAuthoritiesIndexController').set('errors', [this.get('intl').t('common.defaultError')]);
            self.transitionToRoute('authorities.aggregate');
            console.error(error);
          }
        } else {
          if (!roleGroupAuthority.validate()) {
            console.log('RoleGroupAuthority nie przeszło walidacji..');
            return;
          }

          roleGroupAuthority.save().then(roleGroupAuthority => {
            self.setProperties({
              success: true,
              errors: []
            });
            const msg = self.get('intl').t('settings.roleGroups.successUpdate');
            self.get('authoritiesIndexController').set('successMessage', msg);
            self.transitionToRoute('authorities.index', roleGroupAuthority.get('roleGroup.id'));
          }).catch(response => {
            self.set('errors', response.errors);
          });
        }
      },
      cancel: function () {
        if (this.get('model.multiSelect')) {
          this.transitionToRoute('authorities.aggregate');
        } else {
          const roleGroupId = this.get('model.roleGroupAuthority.roleGroup.id');
          this.get('model.roleGroupAuthority').unloadRecord();
          this.transitionToRoute('authorities.index', roleGroupId);
        }
      },
      setRoleGroupAuthority: function (authority) {
        this.set('authorityId', authority.get('id'));

        if (this.get('model.multiSelect')) {
          this.set('model.selectedAuthority', authority);
        }

        if (this.get('model.roleGroupAuthority')) {
          this.set('model.roleGroupAuthority.authority', authority);
        }
      }
    }
  });

  _exports.default = _default;
});