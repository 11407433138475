define("apollo/pods/components/sections/upload-file/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    // Sztywno ustawiamy tutaj TRUE żeby przycisk zawsze był widoczny w sekcji, wartość ta jest
    // zmieniana w template przy wyświetlaniu dokumentów archiwalnych `document-form/template`
    showAddFileButton: Ember.computed('', function () {
      return true;
    }),
    actions: {
      fileUploadStarted: function () {},
      fileUploadSuccess: function () {
        const section = this.get('section.content') ? this.get('section.content') : this.get('section');
        const modelName = section.get('constructor.modelName') ? section.get('constructor.modelName') : section.get('_internalModel.modelName');
        this.get('store').findRecord(modelName, section.get('id'), {
          reload: true
        });

        if (section && section.get('truck.id')) {
          section.get('truck').then(t => t.reload());
        }
      },
      fileUploadFail: function () {},
      deleteFile: function (file) {
        const msg = this.get('intl').t('file.confirmDeletion');

        if (!confirm(msg)) {
          return;
        }

        const self = this;
        file = file.get('content') ? file.get('content') : file;
        file.destroyRecord().then(() => {
          self.get('section.content').reload();
        });
      }
    }
  });

  _exports.default = _default;
});