define("apollo/pods/user/serializer", ["exports", "ember-data", "apollo/mixins/serializer-mixin"], function (_exports, _emberData, _serializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _serializerMixin.default, {
    attrs: {
      userNotifications: {
        embedded: 'save'
      }
    }
  });

  _exports.default = _default;
});