define("apollo/pods/components/sections/document-section/document-section-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uyl6zhgy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-12 form-buttons margin-bottom\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isModelObjectEnabled\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-button-name\",\"add-document\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[9],[10],[0,\" \"],[1,[27,\"t\",[[27,\"concat\",[\"documentType.\",[23,[\"documentType\",\"translationKey\"]],\".add\"],null]],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"addDocument\"]],[10],[0,\"\\n\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-button-name\",\"refresh-documents\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.refresh\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"refresh\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"has-role\",[\"ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-button-name\",\"show-archived-documents\"],[11,\"type\",\"button\"],[9],[0,\" \"],[1,[27,\"t\",[[27,\"concat\",[\"documentType.\",[23,[\"documentType\",\"translationKey\"]],\".showArchived\"],null]],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"showArchived\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/sections/document-section/document-section-manager/template.hbs"
    }
  });

  _exports.default = _default;
});