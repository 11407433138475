define("apollo/mixins/attachment-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    uploadAttachment: function (id, token) {
      const self = this;
      const attachments = this.get('attachments');
      const transport = this.get('store').findRecord('transport', id);

      if (Ember.isEmpty(attachments)) {
        return;
      }

      const fileNames = attachments.map(a => a.name);
      const promises = attachments.map(a => this.readFile(a));
      Ember.RSVP.Promise.all(promises).then(files => {
        const data = JSON.stringify({
          transportId: id,
          files: {
            result: files
          },
          filesNames: fileNames
        });
        this.get('apolloApiService').callApolloApi(self.get('apolloApiService').APOLLO_API.FILES.SAVE, null, {
          body: data
        }).then(() => transport.reload());
      });
    },
    readFile: function (file) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = error => reject(error);
      });
    },

    deleteFile(fileId) {
      console.log(`deleteFile: ${fileId}`);
      this.get('store').findRecord('file', fileId, {
        reload: true
      }).then(f => {
        f.destroyRecord();
      });
    },

    actions: {
      addFiles: function () {
        const attachments = this.get('attachments'); // obiekty plików są przechowywane w inpucie w tym przypadki w inpucie o id `upload-files`
        // pliki zwracane są w formie `FileList`, która działa podobnie do tablicy ale nie działają
        // na niej metody emberowe dlatego po niej iterujemy i wrzucamy pliki do emberowej tablicy

        const filesFromInput = $('#upload-files')[0].files;
        const files = Ember.A();

        for (const file of filesFromInput) {
          files.pushObject(file);
        }

        files.forEach(function (file) {
          if (!attachments.findBy('name', file.name)) {
            attachments.pushObject(file);
          }
        });
      },
      removeFile: function (file) {
        const attachments = this.get('attachments');
        this.set('attachments', attachments.rejectBy('name', file.name));
      },

      removeExistingFile(file) {
        console.log('removeExistingFile..');
        this.get('existingFilesToRemove').push(file.get('id'));
        (0, _jquery.default)('#file-' + file.get('id')).css('text-decoration', 'line-through');
      },

      generateXLS() {
        this.send('hideModal');
        alert(this.get('intl').t('transportsToExcel.XLSGenerateMessage'));
        const url = '/reports/transports?token=' + this.get('sessionAccount').get('token');
        const params = {
          // do przemyślenia czy cały ten obiekt nie powinien być wymieniany między komponentami zamiast tylko searchParams
          query: this.get('searchParams.query'),
          archived: this.get('archived'),
          exportType: this.get('exportType'),
          exportActiveOrders: this.get('exportActiveOrders'),
          transportTypeIds: this.get('checkedTransportTypes').getEach('id').join(','),
          principalId: localStorage.getItem('principalId'),
          start: this.get('searchParams.start') ? moment(this.get('searchParams.start')).format() : '',
          stop: this.get('searchParams.stop') ? moment(this.get('searchParams.stop')).format() : '',
          dateFilterProperty: this.get('searchParams.dateFilterProperty'),
          filters: JSON.stringify(this.get('searchParams.filters')) // TODO: rozszyfrować działanie i zrezygnować z ajaxService

        };
        this.get('ajaxService').send(url, 'GET', params).then(() => {}).catch(response => {
          if (response.status === 404) {
            alert(this.get('intl').t('transportsToExcel.errors.XLSGenerateEmailErrorMessage'));
            return;
          }

          if (response.status === 504) {
            return;
          }

          if (response.status === 400) {
            alert(this.get('intl').t('transportsToExcel.errors.DaysLimitExceededMessage'));
          }

          alert(this.get('intl').t('transportsToExcel.errors.XLSGenerateErrorMessage'));
        });
      }

    }
  });

  _exports.default = _default;
});