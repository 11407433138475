define("apollo/pods/components/time-window-tooltip-arrival-dates/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    async init() {
      this._super(...arguments);

      await this.get('transportToHandle');
    },

    unloadSteps: Ember.computed('transportToHandle', function () {
      const transport = this.get('transportToHandle');
      const steps = transport.get('steps');
      return steps.filterBy('isUnload');
    })
  });

  _exports.default = _default;
});