define("apollo/initializers/hide-popovers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    const destroyPopovers = function () {
      $("[data-toggle='popover']").popover('destroy');
    };

    $('html').on('mouseup', function (e) {
      if (!$(e.target).closest('.popover').length && $(e.target).data('toggle') !== 'popover') {
        destroyPopovers();
      }
    });
    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        // Esc key event
        if (!$(e.target).closest('.popover').length) {
          destroyPopovers();
        }
      }
    });
  }

  var _default = {
    name: 'hide-popovers',
    initialize
  };
  _exports.default = _default;
});