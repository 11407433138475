define("apollo/pods/components/task-custom-modals/default-modal/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    errors: Ember.A(),
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    step: Ember.computed('checkpoint.step', function () {
      return this.get('checkpoint.step.content');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      const transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.{id,name}', function () {
      return `${this.get('intl').t('checkpoints.confirmStatus')}: ${this.get('checkpoint.name')}`;
    }),
    alertText: Ember.computed('checkpoint.name', function () {
      return `Czy na pewno chcesz zablokować status: ${this.get('checkpoint.name')}`;
    }),
    isDuringConfirmation: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').CONFIRM;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    actions: {
      confirmCheckpoint(checkpoint) {
        const self = this;
        this.send('confirm', checkpoint);
        self.reset(checkpoint);
      },

      cancel(checkpoint) {
        this.reset(checkpoint);
      }

    },

    reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }

  });

  _exports.default = _default;
});