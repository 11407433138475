define("apollo/pods/artr-api-log/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    url: _emberData.default.attr('string'),
    httpMethod: _emberData.default.attr('string'),
    response: _emberData.default.attr('string'),
    request: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    requestType: _emberData.default.attr('string')
  });

  _exports.default = _default;
});