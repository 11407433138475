define("apollo/pods/algorithm-setting/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    warehouseZone: _emberData.default.belongsTo('warehouseZone', {
      async: true
    }),
    contractorsGroup: _emberData.default.belongsTo('contractorsGroup', {
      async: true
    }),
    cargoIntervalStop: _emberData.default.attr('number'),
    processTimeInMinutes: _emberData.default.attr('number'),
    contractorsGroupId: Ember.computed('contractorsGroup.id', function () {
      return this.get('contractorsGroup.id');
    })
  });

  _exports.default = _default;
});