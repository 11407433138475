define("apollo/pods/components/notification-bell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sentNotificationService: Ember.inject.service(),
    tagName: '',
    classNames: Ember.A(['dropdown']),
    storedNotifications: Ember.computed(function () {
      return this.store.peekAll('sentNotification');
    }),
    sortedNotifications: Ember.computed('storedNotifications.length', function () {
      return this.get('storedNotifications').sortBy('time').reverse();
    }),
    totalNotificationsCount: Ember.computed('storedNotifications.length', function () {
      return this.sentNotificationService.totalItems;
    }),
    notificationsCurrentPage: Ember.computed('storedNotifications.[]', function () {
      return this.sentNotificationService.currentPage;
    }),
    notificationsPerPage: Ember.computed(function () {
      return this.sentNotificationService.DEFAULT_PAGE_SIZE;
    }),
    hasMoreNotifications: Ember.computed('storedNotifications.length', function () {
      return this.get('storedNotifications.length') < this.get('totalNotificationsCount');
    }),
    actions: {
      markAsRead(notificationModel) {
        this.sentNotificationService.markAsRead(notificationModel);
      },

      toggleShowNotifications() {
        this.toggleProperty('showNotifications');
      },

      showOlder() {
        this.sentNotificationService.loadNotifications(this.sentNotificationService.nextPage());
      },

      showAll() {// TODO
      }

    }
  });

  _exports.default = _default;
});