define("apollo/pods/user-groups/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    userGroupsIndexController: Ember.inject.controller('user-groups/index'),
    errors: [],
    userGroup: Ember.computed('model.userGroup', function () {
      return this.get('model.userGroup');
    }),
    canDeleteUserGroup: Ember.computed('userGroup.{id,mainGroup}', function () {
      return this.get('userGroup.id') && !this.get('userGroup.mainGroup');
    }),
    handleSuccess: function () {
      const successMsg = this.get('intl').t('common.saveSuccessMessage');
      this.get('userGroupsIndexController').set('successMessage', successMsg);
      this.transitionToRoute('user-groups.index', this.get('userGroup.company.id'));
    },
    handleFailure: function (response) {
      this.set('errors', response.errors);
    },
    actions: {
      save() {
        this.get('userGroup').save().then(() => this.handleSuccess()).catch(response => this.handleFailure(response));
      },

      delete() {
        this.get('userGroup').destroyRecord().then(() => this.handleSuccess()).catch(response => this.handleFailure(response));
      },

      cancel() {
        this.transitionToRoute('user-groups.index', this.get('userGroup.company.id'));
      }

    }
  });

  _exports.default = _default;
});