define("apollo/pods/components/template-loop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "92u7lyI2",
    "block": "{\"symbols\":[\"index\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"times\"]]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/template-loop/template.hbs"
    }
  });

  _exports.default = _default;
});