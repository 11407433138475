define("apollo/services/delivery-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    showTransportCreatedToast(delivery) {
      const weight = this.sessionAccount.getSettingValue('MIN_WEIGHT_TO_CREATE_TRANSPORT');

      if (!weight || isNaN(weight) || Number(delivery.get('grossWeight') || 0) < weight) {
        return;
      }

      this.actionProgressToast.showSuccessToast(this.intl.t('delivery.weightAutoCreated', {
        deliveryNumber: delivery.get('deliveryNumber'),
        weight: weight + ' kg'
      }));
    },

    createNewBasicTransport(transport) {
      const values = transport.get('customFields').map(a => {
        return a.serialize();
      });
      this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.CREATE_BASIC_TRANSPORT, null, {
        body: JSON.stringify({
          values,
          transportTypeId: transport.get('transportType.id')
        })
      });
    },

    handleUpdatedDeliveryPackaging(delivery) {
      if (!delivery.get('packaging.length')) {
        return;
      }

      delivery.get('packaging').then(pck => {
        const cpCopy = pck.slice(0);
        cpCopy.forEach(cp => {
          if (cp !== undefined && !cp.get('id')) {
            console.debug(`Unloading redundant delivery packaging for delivery ${delivery.get('id')}..`);
            delivery.get('packaging').removeObject(cp);
            cp.unloadRecord();
          } else {
            cp.reload();
          }
        });
      });
    }

  });

  _exports.default = _default;
});