define("apollo/pods/components/warehouse/warehouse-location-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    whLocations: Ember.computed('model.warehouse.warehouseLocations', function () {
      return this.get('warehouse.warehouseLocations').sortBy('namePl');
    })
  });

  _exports.default = _default;
});