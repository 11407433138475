define("apollo/pods/components/checkpoint/reject-status-button/component", ["exports", "apollo/mixins/transport-form-mixin"], function (_exports, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    init(...args) {
      this._super(...args);

      (true && !(!!this.get('checkpoint')) && Ember.assert(this.get('intl').t('debug.requiredArgAndType', {
        name: 'checkpoint',
        type: 'Checkpoint Model'
      }), !!this.get('checkpoint')));
    },

    tagName: '',
    intl: Ember.inject.service(),
    action: 'showModal',
    checkpoint: Ember.computed.reads('checkpointToReject'),
    checkpointService: Ember.inject.service('checkpoint-service'),

    confirmRejection() {
      const msg = this.get('intl').t('checkpoint.rejectConfirm');
      return confirm(msg);
    },

    actions: {
      async rejectCheckpoint(checkpoint) {
        const checkpointModel = await checkpoint;
        const componentParams = {
          checkpoint: checkpointModel
        };
        await this.initializeCustomFields(checkpoint.get('transport'));

        if (checkpoint.get('hasCustomFieldsBlockade')) {
          this.sendAction('action', 'checkpoint-rejection-modal', componentParams);
          return;
        }

        if (!this.confirmRejection()) {
          return;
        }

        this.get('checkpointService').reject(checkpoint);
        checkpoint.reload();

        if (!this.get('isFromGuardTable')) {
          this.sendAction('hide');
        }
      }

    }
  });

  _exports.default = _default;
});