define("apollo/pods/components/address-add-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KblG6SHm",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"paste\",\"type\",\"class\",\"value\",\"placeholder\"],[[27,\"action\",[[22,0,[]],\"handleAddressOnPasteEvent\",[23,[\"step\"]]],null],\"text\",\"form-control\",[23,[\"step\",\"address\",\"recipient\"]],[27,\"t\",[\"address.recipient\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/address-add-new/template.hbs"
    }
  });

  _exports.default = _default;
});