define("apollo/pods/components/statistics-report-nav-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e3Nb8A49",
    "block": "{\"symbols\":[\"report\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12 text-left padding-bottom\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"reports-nav-overflow\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"statisticsReports\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-info \",[27,\"if\",[[27,\"eq\",[[23,[\"activeReport\"]],[22,1,[\"nameInCurrentLanguage\"]]],null],\"active\"],null]]]],[9],[0,\"\\n                    \"],[1,[22,1,[\"nameInCurrentLanguage\"]],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"setCurrentReport\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/statistics-report-nav-header/template.hbs"
    }
  });

  _exports.default = _default;
});