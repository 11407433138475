define("apollo/pods/settings/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    success: false,
    errors: [],
    setting: '',
    settings: Ember.computed('', function () {
      const settings = [];
      const settingsNames = this.get('model.settingsOptions');
      settingsNames.forEach(name => {
        settings.push({
          name,
          translation: this.get('intl').t(`settings.desc.${name}`)
        });
      });
      return settings;
    }),
    actions: {
      save: function () {
        const setting = this.get('model.setting');

        if (!setting.validate()) {
          return;
        }

        setting.save().then(() => {
          this.set('success', true);
          this.get('settingsIndexController').set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          this.transitionToRoute('settings.index');
        }).catch(response => this.set('errors', response.errors));
      },
      cancel: function () {
        this.get('model.setting').rollbackAttributes();
        this.transitionToRoute('settings.index');
      },
      setSettingName: function (setting) {
        this.set('setting', setting);
        this.set('model.setting.name', setting.name);
      }
    }
  });

  _exports.default = _default;
});