define("apollo/pods/components/task-custom-modals/mowi-conditional-tasks-modal/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    checkpointService: Ember.inject.service('checkpoint-service'),
    selectedWarehouseLocations: Ember.A([]),
    errors: Ember.A(),
    isLoading: false,
    getYesNoFields: Ember.computed('', function () {
      return [this.get('intl').t('common.yes'), this.get('intl').t('common.no')];
    }).readOnly(),
    selectedZone: Ember.computed('model.nextZone', function () {
      return this.get('model.nextZone') ? this.get('intl').t('common.yes') : this.get('intl').t('common.no');
    }),
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    step: Ember.computed('checkpoint.step', function () {
      return this.get('checkpoint.step.content');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      const transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.{id,name}', function () {
      return `${this.get('intl').t('checkpoints.confirmStatus')}: ${this.get('checkpoint.name')}`;
    }),
    sortedSubTasks: Ember.computed('checkpoint.task.{masterTask.subTasks.@each.idxInGroup,subTasks.@each.idxInGroup}', function () {
      if (this.get('checkpoint.task.subTasks.length') === 0 && this.get('checkpoint.task.masterTask')) {
        return this.get('checkpoint.task.masterTask.subTasks').filterBy('enabled').sortBy('idxInGroup');
      }

      return this.get('checkpoint.task.subTasks').filterBy('enabled').sortBy('idxInGroup');
    }),
    actions: {
      async confirmCheckpoint(checkpoint) {
        this.toggleProperty('isLoading');
        const step = this.get('step');
        const locations = this.get('selectedWarehouseLocations');
        const errorMsg = this.get('intl').t('errors.blankSelect');

        if (!step) {
          this.set('errors', [errorMsg]);
          return console.log(`WarehouseLocation :: ${errorMsg}`);
        }

        try {
          if (this.get('model.nextZone')) {
            if (!locations) {
              this.set('errors', [errorMsg]);
              this.toggleProperty('isLoading');
              return console.log(`WarehouseLocation :: ${errorMsg}`);
            }

            const path = this.get('apolloApiService').APOLLO_API.STEP_WAREHOUSE_LOCATION.SAVE;
            const promises = locations.map(wl => {
              const whl = JSON.stringify({
                warehouseLocation: wl.id,
                step: step.get('id')
              });
              return this.get('apolloApiService').callApolloApi(path, null, {
                body: whl
              });
            });
            const savedLocationsResponses = await Promise.all(promises);

            if (!savedLocationsResponses.every(response => response.ok)) {
              console.error('Błąd przy dodawaniu lokalizacji !');
              this.reset(checkpoint);
              this.toggleProperty('isLoading');
              return;
            }
          } else {
            checkpoint.set('skipGeneratingSubtasks', true);
          }

          await this.get('checkpointService').confirmAndRefresh(checkpoint, false);
          this.set('errors', []);
          checkpoint.set('isDuringAction', true);
          this.toggleProperty('isLoading');
        } catch (e) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(e);
          this.toggleProperty('isLoading');
        } finally {
          this.reset(checkpoint);
        }
      },

      cancel(checkpoint) {
        this.reset(checkpoint);
      },

      setNextZoneFlag: function (nextZoneFlag) {
        if (nextZoneFlag === this.get('intl').t('common.yes')) {
          this.set('model.nextZone', true);
        } else {
          this.set('model.nextZone', false);
        }
      }
    },

    reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.set('selectedWarehouseLocations', Ember.A([]));
      this.send('hideModal');
    }

  });

  _exports.default = _default;
});