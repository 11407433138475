define("apollo/pods/ramps/create/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    warehousesCreateController: Ember.inject.controller('warehouses/create'),
    showDeleteDedicatedWindowsCheckbox: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED') === 'true' || this.get('sessionAccount').getSettingValue('AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED') === 'true';
    }),
    defaultRoleGroups: Ember.computed(function () {
      return this.get('model.roleGroups') ? this.get('model.roleGroups').getEach('id') : Ember.A([]);
    }),
    actions: {
      save() {
        const self = this;
        this.get('model').validate();
        const defaultRoleGroups = this.get('defaultRoleGroups');

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').set('defaultRoleGroups', defaultRoleGroups);
        this.get('model').save().then(function (m) {
          m.get('warehouse').then(w => {
            w.reload();
            self.get('sessionAccount').get('currentUser').reload();
            self.get('sessionAccount').get('currentUser').get('ramps').reload();
            self.setProperties({
              errors: [],
              success: true
            });
            self.transitionToRoute('ramps.edit', m.id);
          });
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      disable() {
        const self = this;
        const model = this.get('model');
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + '/ramps/' + model.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          const warehouse = model.get('warehouse');
          model.set('enabled', false);
          warehouse.then(w => {
            w.reload();
            self.get('sessionAccount').get('currentUser').reload();
            self.get('sessionAccount').get('currentUser').get('ramps').reload();
            const msg = self.get('intl').t('ramp.disabledMessage', {
              rampName: model.get('name')
            });
            self.get('warehousesCreateController').set('successMessage', msg);
            model.unloadRecord();
            this.transitionToRoute('warehouses.edit', warehouse);
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },

      enable() {
        const self = this;
        const model = this.get('model');
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + '/ramps/' + model.get('id') + '/enable',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          const warehouse = model.get('warehouse');
          model.set('enabled', true);
          warehouse.then(w => {
            w.reload();
            self.get('sessionAccount').get('currentUser').reload();
            self.get('sessionAccount').get('currentUser').get('ramps').reload();
            const msg = self.get('intl').t('ramp.enabledMessage', {
              rampName: model.get('name')
            });
            self.get('warehousesCreateController').set('successMessage', msg);
            this.transitionToRoute('warehouses.edit', warehouse);
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },

      cancel() {
        const warehouse = this.get('model').get('warehouse');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('warehouses.edit', warehouse);
      },

      setDefaultRoleGroups(roleGroup) {
        const defaultRoleGroups = this.get('defaultRoleGroups');
        const newRoleGroup = roleGroup.get('id');

        if (defaultRoleGroups.includes(newRoleGroup)) {
          // odznaczenie checkbox'a
          const newDefaultRoleGroups = [...defaultRoleGroups].filter(defaultRoleGroupId => defaultRoleGroupId !== newRoleGroup);
          this.set('defaultRoleGroups', newDefaultRoleGroups);
          return;
        }

        const newDefaultRoleGroups = [...new Set([...defaultRoleGroups, newRoleGroup])];
        this.set('defaultRoleGroups', newDefaultRoleGroups);
      }

    }
  });

  _exports.default = _default;
});