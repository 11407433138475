define("apollo/pods/components/transport-and-deliveries-custom-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    deliveriesCfs: Ember.computed('deliveries', function () {
      const dcfs = this.get('deliveries').getEach('customFieldPairs');
      return dcfs.flat().sortBy('idx');
    }),
    currentTransportCf: Ember.computed('transportCfPairs', function () {
      const currentDefinition = this.get('definition');
      return this.get('transportCfPairs').find(tcfp => tcfp.get('definition.id') === currentDefinition.id);
    })
  });

  _exports.default = _default;
});