define("apollo/pods/transport-type-schedule-setting/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    transportType: belongsTo('transportType'),
    displayDeliveryNumber: attr('boolean'),
    displayDeliveryIncoterms: attr('boolean'),
    displayDeliveryUnloadAddress: attr('boolean'),
    displayDeliveryUnloadDate: attr('boolean')
  });

  _exports.default = _default;
});