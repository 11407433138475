define("apollo/pods/mode-of-transportation/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    access: ['ROLE_MODE_OF_TRANSPORTATION_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const self = this;
      const trucks = self.store.query('truck', {
        companyId: self.get('sessionAccount.currentUser.company.id'),
        isModeOfTransportation: true,
        enabled: true,
        principalCompanyId: localStorage.getItem('principalId')
      });
      return Ember.RSVP.hash({
        trucks: trucks
      });
    },

    activate() {
      this.activateCurrentTab('MODE_OF_TRANSPORTATION_TAB');
    }

  });

  _exports.default = _default;
});