define("apollo/pods/transports/modals/create-similar-multiple/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    DEFAULT_MAXIMUM_NUMBER_OF_ORDERS_TO_COPY: '30',
    saving: Ember.computed('model.saving', function () {
      return this.get('model.saving');
    }),
    title: Ember.computed('', function () {
      return this.get('intl').t('transport.createSimilarMultiple');
    }),
    actions: {
      createCopies: async function () {
        const maxValue = this.get('sessionAccount').getSettingValue('MAXIMUM_NUMBER_OF_ORDERS_TO_COPY') ? this.get('sessionAccount').getSettingValue('MAXIMUM_NUMBER_OF_ORDERS_TO_COPY') : this.get('DEFAULT_MAXIMUM_NUMBER_OF_ORDERS_TO_COPY');
        const transport = this.get('model.transport');
        const count = this.get('model.count');

        if (count === '' || Number(count) > Number(maxValue)) {
          const msg = this.get('intl').t('transport.tooManyCopies', {
            maxValue
          });
          this.set('errors', [msg]);
          return;
        } else {
          this.set('errors', []);
        }

        const params = JSON.stringify({
          count,
          id: transport.get('id')
        });
        this.set('model.saving', true);
        const path = this.get('apolloApiService').APOLLO_API.TRANSPORTS.CREATE_MULTIPLE_COPIES;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: params
          });

          if (response.ok) {
            this.set('model.saving', false);
            this.send('hideModal');
          } else {
            const error = await response.json();
            this.set('model.saving', false);
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('model.saving', false);
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },
      close: function () {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});