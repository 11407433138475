define("apollo/pods/statistics-report/model", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    dashboardName: attr('string'),
    plDashboardLabel: attr('string'),
    enDashboardLabel: attr('string'),
    csDashboardLabel: attr('string'),
    deDashboardLabel: attr('string'),
    url: attr('string'),
    principalCompany: belongsTo('company', {
      async: true
    }),
    nameInCurrentLanguage: Ember.computed('intl.locale.firstObject', function () {
      const browserLanguage = this.get('intl.locale.firstObject');

      switch (browserLanguage) {
        case 'en':
          return this.get('enDashboardLabel');

        case 'cs':
          return this.get('csDashboardLabel');

        case 'de':
          return this.get('deDashboardLabel');

        case 'pl':
        default:
          return this.get('plDashboardLabel');
      }
    })
  });

  _exports.default = _default;
});