define("apollo/pods/indexes/modals/indexes-details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    intl: service(),
    apolloApiService: service('api.apollo-api-service'),
    successMessage: null,
    errors: [],
    transport: Ember.computed('model', function () {
      return this.get('model');
    }),
    indexSetting: Ember.computed('transport.transportType.indexSetting', function () {
      return this.get('transport.transportType.indexSetting');
    }),
    modalTitle: Ember.computed('indexSetting', function () {
      return this.get('indexSetting').getTranslation('sectionLabel');
    }),
    columns: Ember.computed('', function () {
      return ['index', 'quantities'];
    }),
    canSaveIndexes: Ember.computed(function () {
      if (!this.get('transport.content')) {
        return false;
      }

      return this.get('transport.content').actionCanBePerformed('EDIT_INDEX');
    }),
    actions: {
      cancel() {
        this.set('errors', []);
        this.set('successMessage', null);
        const transportModel = this.get('transport');

        if (transportModel.get('indexes.length')) {
          transportModel.get('indexes').forEach(index => {
            if (index) {
              index.rollback();
              index.get('quantities').forEach(q => q.rollback());
            }
          });
        }
      },

      async save() {
        const quantities = [];
        this.get('transport.enabledIndexes').forEach(i => {
          i.get('quantities').filter(q => q.get('indexQuantityType.editableViaSchedule')).forEach(q => {
            quantities.push(q);
          });
        });

        if (quantities.some(q => !q.validate())) {
          console.debug('Błędy podczas walidacji indeksów!');
          return;
        }

        const jsonBody = JSON.stringify(quantities.map(q => q.customSerialize()));
        const transportId = this.get('transport.id');
        const path = this.get('apolloApiService').APOLLO_API.INDEX_QUANTITIES.SAVE; // TODO Skorzystać z ember-data

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: jsonBody
          }, {
            transportId
          });

          if (response.ok) {
            this.set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
        }
      }

    }
  });

  _exports.default = _default;
});