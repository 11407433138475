define("apollo/pods/components/show-statuses/component", ["exports", "apollo/config/environment", "jquery", "apollo/mixins/checkpoint-mixin", "apollo/mixins/transport-form-mixin"], function (_exports, _environment, _jquery, _checkpointMixin, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointMixin.default, _transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    action: 'confirm',
    showToggleablePopover: false,
    popoverDelay: 10,
    canSeeRevokeButtons: Ember.computed('transport.{completed,archived}', function () {
      return !(this.get('transport.completed') || this.get('transport.archived'));
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    firstMandatoryCheckpoint: Ember.computed('transport.checkpoints.[]', function () {
      return this.get('transport.firstMandatoryCheckpoint');
    }),
    actions: {
      toggleStatusesPopup() {
        this.toggleProperty('showToggleablePopover');
      },

      block(checkpoint) {
        console.debug('Otwieramy modal do blokowania statusów dla statusu :: ' + checkpoint);
        this.showBlockadeReasonModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').BLOCK);
      },

      async unblock(checkpoint) {
        console.debug('Odblokowujemy status :: ' + checkpoint);
        const path = this.get('apolloApiService').APOLLO_API.CHECKPOINTS.UNBLOCK;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: checkpoint.get('id')
          });

          if (response.ok) {
            console.debug('Odblokowaliśmy status :: ' + checkpoint);
            checkpoint.reload();
          } else {
            console.debug('Nie udało się odbłokować statusu...');
          }
        } catch (error) {
          console.debug('Nie udało się odbłokować statusu...');
        }
      },

      showStatuses() {
        const self = this;
        const token = this.get('sessionAccount').get('token');
        this.get('store').query('customFieldValue', {
          transportId: self.get('transport.id')
        }).then(cfv => {
          self.get('transport').set('customFields', cfv);
        }); // TODO - Przerobić na apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + self.get('transport.id') + '/checkpoints',
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.checkpoints.forEach(function (checkpoint) {
            const c = self.get('store').peekRecord('checkpoint', checkpoint.id);

            if (!self.get('transport.checkpoints').includes(c)) {
              console.debug(`Pushing checkpoint ${c.get('id')} into transport ${self.get('transport.id')}..`);
              self.get('transport.checkpoints').pushObject(c);
            }
          });
          self.set('showPopover', true);
        });
      },

      confirm(checkpoint) {
        this.confirm(checkpoint);
      },

      revoke(checkpoint, hide) {
        this.revoke(checkpoint);
        hide();
      },

      showModal(templateName, checkpoint) {
        this.showModal(templateName, checkpoint);
      }

    }
  });

  _exports.default = _default;
});