define("apollo/pods/components/transport/custom-fields-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RAzv98wc",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[4,\"if\",[[27,\"gt\",[[23,[\"transportType\",\"customFieldFiltersInTable\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"form-inline\"],[9],[0,\"\\n        \"],[7,\"h5\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"custom-field-definition.filterLabel\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\\n\"],[4,\"each\",[[23,[\"transportType\",\"customFieldFiltersInTable\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\"],[12,\"data-filter-name\",[27,\"concat\",[\"cf-\",[22,1,[\"englishNameTrimmed\"]]],null]],[9],[0,\"\\n                \"],[1,[27,\"transports/transports-table/transport-filters/filter\",null,[[\"searchParams\",\"filterKeys\",\"placeholder\",\"transportType\"],[[23,[\"searchParams\"]],[27,\"concat\",[\"cfd_\",[22,1,[\"id\"]]],null],[22,1,[\"nameInAppropriateLanguage\"]],[23,[\"transportType\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport/custom-fields-filters/template.hbs"
    }
  });

  _exports.default = _default;
});