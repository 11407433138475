define("apollo/pods/components/auction/bid-auction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    customFields: Ember.computed('auction.transport.customFields.@each.visibleInOfferForm', function () {
      return this.get('auction.transport.customFields').filterBy('visibleInOfferForm');
    }),
    disableForSupplier: Ember.computed('transport.active', 'transport.transportType.forcedPollRequestTime', 'transport.activeAuction', function () {
      return this.get('sessionAccount').get('currentUser').get('company.partnershipType.name') === 'SUPPLIER';
    }),
    actions: {
      showOfferForm() {
        if (!this.get('auction.transport.id')) {
          return;
        }

        this.get('store').query('customFieldValue', {
          transportId: this.get('auction.transport.id')
        }).then(cfv => {
          const auction = this.get('auction');
          auction.get('transport').set('customFields', cfv);
          const suggestedPrice = auction.get('suggestedPriceForLoggedCompany');
          const offerModel = this.get('store').createRecord('offer', {
            auction: auction,
            price: suggestedPrice
          });
          const model = {
            offer: offerModel,
            customFields: this.get('customFields')
          };
          this.set('action', 'showModal');
          this.sendAction('action', 'offers.modals.offer-form', model);
        });
      }

    }
  });

  _exports.default = _default;
});