define("apollo/helpers/company-disabled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    amountOfCoverService: Ember.inject.service(),
    intl: Ember.inject.service(),
    DISABLED_TAG: '<i class="fa fa-ban"></i>',
    INSURANCE_TOO_LOW_TAG: function (aocTitle) {
      return `<i class="fa fa-thumbs-down" title="${aocTitle}"></i>`;
    },

    _getIconTag(transport, company) {
      let iconTag;
      let aocNotEnough;
      let aocTitle;

      if (transport.get('transportType.checkAmountOfCover')) {
        if (!company.get('activeOCPInsurance.amount') && !company.get('activeOCSInsurance.amount')) {
          aocNotEnough = true;
          aocTitle = this.intl.t('companyDisabledIcon.bothInsurancesMissing');
        } else {
          aocNotEnough = !this.amountOfCoverService.hasEnoughAmountOfCover(transport, company);

          if (aocNotEnough) {
            const aocTitleSuffix = company.get('activeOCPInsurance.amount') ? 'OCP: ' + company.get('activeOCPInsurance.amount') + ' ' + company.get('activeOCPInsurance.currency.symbol') : 'OCS: ' + company.get('activeOCSInsurance.amount') + ' ' + company.get('activeOCSInsurance.currency.symbol');
            aocTitle = this.intl.t('companyDisabledIcon.insuranceAmountTooLow') + aocTitleSuffix;
          }
        }
      }

      if (company.get('disabled') && aocNotEnough) {
        iconTag = `${this.DISABLED_TAG} ${this.INSURANCE_TOO_LOW_TAG(aocTitle)}`;
      } else if (company.get('disabled')) {
        iconTag = this.DISABLED_TAG;
      } else if (aocNotEnough) {
        iconTag = this.INSURANCE_TOO_LOW_TAG(aocTitle);
      } else {
        iconTag = '';
      }

      return iconTag;
    },

    compute(params) {
      const company = params[0];
      const transport = params[1];

      if (!transport.get('id') || !company.get('id')) {
        return;
      }

      return Ember.String.htmlSafe(this._getIconTag(transport, company));
    }

  });

  _exports.default = _default;
});