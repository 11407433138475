define("apollo/pods/components/transport/get-couriers-quotes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8RR7qGiT",
    "block": "{\"symbols\":[\"courierQuotes\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"row \",[27,\"if\",[[23,[\"hideCouriersQuotesSection\"]],\"hidden\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"form-group \",[27,\"if\",[[23,[\"transport\",\"firstStep\",\"errors\",\"weight\"]],\"has-error\"],null]]]],[9],[0,\"\\n            \"],[7,\"label\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"asPlainTemplate\"]],\"col-xs-5\",\"col-md-5 col-xs-12\"],null],\" control-label\"]]],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"transport.getCourierQuotes\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"asPlainTemplate\"]],\"col-xs-7\",\"col-md-7 col-xs-12\"],null]]]],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"disabled\",\"renderInPlace\",\"selected\",\"options\",\"onchange\"],[\"form-control\",[23,[\"disableEdition\"]],true,[23,[\"getCouriersQuotesValue\"]],[23,[\"getCouriersQuotesFields\"]],[27,\"action\",[[22,0,[]],\"setCouriersQuotes\"],null]]],{\"statements\":[[0,\"                    \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport/get-couriers-quotes/template.hbs"
    }
  });

  _exports.default = _default;
});