define("apollo/pods/documents/modals/document-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    document: Ember.computed('model.document', function () {
      return this.get('model.document');
    }),
    modal: true,
    title: Ember.computed('document.id', 'document.typeName', function () {
      const typeLabel = this.get('intl').t(`sectionType.${this.get('document.typeName')}`);

      if (this.get('document.id')) {
        return typeLabel;
      } else {
        return this.get('intl').t(`document.${this.get('document.groupName')}.new`, {
          typeLabel: typeLabel
        });
      }
    }),
    subtitle: Ember.computed('document.id', 'document.number', function () {
      return this.get('document.id') ? this.get('document.number') : null;
    }),
    actions: {
      cancel() {
        if (!this.get('document.id')) {
          this.get('document').unloadRecord();
        } else {
          this.get('document').rollback();
        }
      }

    }
  });

  _exports.default = _default;
});