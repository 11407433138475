define("apollo/pods/queues/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),

    createDummyClassificationResource() {
      return Ember.Object.create({
        transport: null
      });
    },

    sortProperties: ['stateIdx:asc', 'lastUpdated:asc'],
    sortedClassificationResources: Ember.computed.sort('model.classificationResources', 'sortProperties'),
    enabledSquares: Ember.computed('model.squares.@each.enabled', function () {
      return this.get('model.squares').filterBy('enabled');
    }),
    queuesArray: Ember.computed('selectedSquare', 'model.classificationResources.@each.state', function () {
      const self = this;
      const queuesArray = Ember.A();

      if (!self.get('selectedSquare.queues')) {
        return;
      }

      const queues = self.get('selectedSquare.queues').filter(q => self.get('sessionAccount.currentUser.accessibleQueues').getEach('id').includes(q.get('id')));
      queues.forEach(queue => {
        let classificationResources = this.get('sortedClassificationResources').filter(cr => cr.get('queue.id') === queue.get('id'));
        classificationResources.pushObject(self.createDummyClassificationResource());
        this.setAdditionalProperties(classificationResources);
        const obj = Ember.Object.create({
          queue: queue,
          classificationResources: classificationResources
        });
        queuesArray.pushObject(obj);
      });
      return queuesArray;
    }),

    setAdditionalProperties(classificationResources) {
      classificationResources.forEach((cr, index) => {
        cr.set('ordinalNumber', `${++index}.`);
        cr.set('active', false);
      });
      let firstWithoutPlace = classificationResources.find(cr => {
        return cr.get('place.id') === undefined;
      });

      if (firstWithoutPlace) {
        firstWithoutPlace.set('active', true);
      }
    },

    actions: {
      setSelectedSquare(square) {
        this.set('selectedSquare', square);
      }

    }
  });

  _exports.default = _default;
});