define("apollo/pods/invitations/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_INVITATIONS_TAB'],
    selectedType: 'WAITING',
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const self = this;
      return Ember.RSVP.hash({
        selectedType: self.get('selectedType'),
        invitations: self.store.query('invitation', {
          type: self.get('selectedType')
        })
      });
    },

    activate() {
      this.activateCurrentTab('INVITATIONS_TAB');
    },

    actions: {
      refreshModel(type) {
        this.set('selectedType', type);
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});