define("apollo/pods/components/role-group-notification-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9gsFmatj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-control-static\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"settings.roleGroups.notificationSection\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"successMessage\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"success-message\",null,[[\"text\"],[[23,[\"successMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"errors-alert\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\\n    \"],[1,[27,\"notification-section\",null,[[\"allNotifications\",\"notifications\",\"showUserGroupsSection\",\"roleGroup\"],[[23,[\"allNotifications\"]],[23,[\"roleGroupNotifications\"]],false,[23,[\"roleGroup\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"row pull-right\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"data-button-name\",\"send\"],[11,\"type\",\"submit\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.save\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"settings.index\"],[[\"class\"],[\"btn btn-default\"]],{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"common.back\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/role-group-notification-settings/template.hbs"
    }
  });

  _exports.default = _default;
});