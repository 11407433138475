define("apollo/pods/auction-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    // Ustawienia ogólne
    translationKey: _emberData.default.attr('string'),
    // Formularz informacji o trwającej aukcji
    showFinishAuctionButton: _emberData.default.attr('boolean'),
    showPassToCarrierButton: _emberData.default.attr('boolean'),
    // Formularz wystawiania aukcji
    defaultCurrency: _emberData.default.attr('string'),
    showCompanyGroupsSection: _emberData.default.attr('boolean'),
    showBlockedCompaniesSection: _emberData.default.attr('boolean'),
    requireMaxPrice: _emberData.default.attr('boolean'),
    requireMinPrice: _emberData.default.attr('boolean'),
    showPriceRangeFields: _emberData.default.attr('boolean'),
    selectAllCarriersByDefault: _emberData.default.attr('boolean'),
    // Formularz składania ofert
    canAcceptWithMinPrice: _emberData.default.attr('boolean'),
    showDescriptionSection: _emberData.default.attr('boolean'),
    requireMinOfferReductionSizeValidation: _emberData.default.attr('boolean'),
    showRevokeOfferButton: _emberData.default.attr('boolean'),
    showQuantityFieldInOfferForm: _emberData.default.attr('boolean'),
    requireReasonIfNotBestOfferIsChosen: _emberData.default.attr('boolean'),
    exportAuctionInfoToExcel: _emberData.default.attr('boolean'),
    showStepDataInOfferForm: _emberData.default.attr('boolean'),
    minOfferReductionSize: _emberData.default.attr('number'),
    defaultAuctionFinishDelayInterval: _emberData.default.attr('string'),
    auctionProlongationOffsetInterval: _emberData.default.attr('string'),
    reminderTimeAboutAuction: _emberData.default.attr('string'),
    addFileToTransportAfterOfferAcceptationName: _emberData.default.attr('string'),
    addFileToTransportAfterOfferAcceptation: _emberData.default.attr('boolean'),
    newAuctionEmailFileName: _emberData.default.attr('string'),
    auctionEndedWithoutOffersEmailFileName: _emberData.default.attr('string'),
    shouldPassToCarrierAfterAuction: _emberData.default.attr('boolean'),
    auctionEmailSubjectPl: _emberData.default.attr('string'),
    auctionEmailSubjectEn: _emberData.default.attr('string')
  });

  _exports.default = _default;
});