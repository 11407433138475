define("apollo/pods/components/time-window-tooltip-arrival-dates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o/967hXI",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"unloadSteps\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[27,\"format-date\",[[22,1,[\"arrivalDate\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/time-window-tooltip-arrival-dates/template.hbs"
    }
  });

  _exports.default = _default;
});