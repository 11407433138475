define("apollo/pods/authorities/others/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    model: function (params) {
      const principalCompanyId = localStorage.getItem('principalId');
      const roleGroup = this.store.peekRecord('roleGroup', params.id);
      return Ember.RSVP.hash({
        roleGroup,
        transportTypes: this.store.peekAll('transportType').filterBy('company.id', principalCompanyId),
        transportTypeRoleGroupTableColumns: this.store.query('transportTypeRoleGroupTableColumn', {
          roleGroup: roleGroup.get('id')
        }),
        roleGroupInvRestriction: this.store.query('role-group-invitation-restriction', {
          roleGroup: roleGroup.get('id')
        }),
        allNotifications: this.store.findAll('notification'),
        roleGroupNotifications: this.store.query('notification', {
          roleGroupId: roleGroup.get('id')
        }),
        roleGroupStatisticsReports: this.store.query('role-group-statistics-report', {
          roleGroupId: roleGroup.get('id')
        }),
        allStatisticsReports: this.store.query('statistics-report', {
          fetchAll: true
        })
      });
    }
  });

  _exports.default = _default;
});