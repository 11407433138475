define("apollo/pods/advice/modals/create-advice/controller", ["exports", "apollo/mixins/advice-utils-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/transports-mixin", "jquery"], function (_exports, _adviceUtilsMixin, _persistenceMixin, _transportsMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_adviceUtilsMixin.default, _persistenceMixin.default, _transportsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    relationsCombinerService: Ember.inject.service('transport-relations.relations-combiner'),
    dateToPerformAction: '',
    actionPlanned: false,
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    hasAnyWindows: Ember.computed('model.transport', function () {
      return this.get('model.transport.timeWindows').filter(tw => tw.id !== null).length > 0;
    }),
    errors: Ember.computed('model.errors', function () {
      return this.get('model.errors') ? this.get('model.errors') : [];
    }),
    title: Ember.computed('transport.{title,internalIndex,isRelatedTransportInTransportCombining,transportRelationIdentifier}', function () {
      if (this.get('transport.transportRelationIdentifier') && this.get('transport.isRelatedTransportInTransportCombining')) {
        return this.get('transport.title') + ' #' + this.get('transport.transportRelationIdentifier');
      }

      return this.get('transport.title') + ' #' + this.get('transport.internalIndex');
    }),
    showSearchSpotsButton: Ember.computed('model.showSearchSpotsButton', function () {
      return this.get('model.showSearchSpotsButton');
    }),
    // eslint-disable-next-line ember/no-observers
    setRequiresAcceptanceOfTermsOfCondition: Ember.observer('model.advice', function () {
      if (!this.get('model.advice.id')) {
        return;
      }

      const principalCompanyId = localStorage.getItem('principalId');
      const s = this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', 'SHOW_TERMS_OF_CONDITIONS_ACCEPTED').get('firstObject');
      this.get('model.advice').set('requiresAcceptanceOfTermsOfCondition', s.get('value') === 'true');
    }),
    initialStart: Ember.computed('model.initialStart', function () {
      return this.get('model.initialStart');
    }),
    initialStartFormatted: Ember.computed('model.initialStart', function () {
      return moment(this.get('model.initialStart')).format('DD.MM.YYYY HH:mm');
    }),
    hasUnsavedTimeWindow: Ember.computed('transport', function () {
      return this.get('transport.timeWindows').filter(tw => {
        return !tw.get('id');
      }).get('length') > 0;
    }),
    hasErrors: Ember.computed('model.errors.length', function () {
      return this.get('model.errors.length') > 0;
    }),
    currentWarehouseInError: Ember.computed('transport.exceptionInformation.whId', function () {
      return this.get('transport.exceptionInformation.whId');
    }),
    combinedTransports: Ember.computed('transport', function () {
      return this.get('relationsCombinerService.combinedTransports');
    }),
    combinedTransportsLabel: Ember.computed('combinedTransports', function () {
      return this.get('relationsCombinerService.combinedTransportsLabel');
    }),
    actions: {
      handleClickWithSpot(timeWindowCandidate) {
        const self = this;
        self.send('createAdviceAndTimeWindow', Ember.RSVP.defer(), timeWindowCandidate);
      },

      createAdviceAndTimeWindow(defer, timeWindowCandidate) {
        (0, _jquery.default)('#main-modal').data('skip-cancel-action', true);
        const self = this;
        const transport = self.get('store').peekRecord('transport', self.get('transport.id'));
        const advice = transport.get('advice');
        let editedTimeWindow = transport.get('editedTimeWindow');

        if (timeWindowCandidate) {
          editedTimeWindow = transport.get('timeWindows').filter(tw => {
            return Number(tw.get('ramp.warehouse.id')) === timeWindowCandidate.ramp.warehouse.id;
          }).get('firstObject');
          editedTimeWindow.set('start', timeWindowCandidate.start);
          editedTimeWindow.set('stop', timeWindowCandidate.stop);
          editedTimeWindow.set('ramp', self.get('store').peekRecord('ramp', timeWindowCandidate.ramp.id));
          console.debug(`Wybrana data awizacji: ${timeWindowCandidate.start}`);
        }

        transport.set('isDuringAddingOfAdvice', true);
        const firstUnconfirmedCheckpoint = transport.get('firstUnconfirmedCheckpoint') || transport.get('firstUnconfirmedCheckpoint.content');

        if (firstUnconfirmedCheckpoint.get('id')) {
          firstUnconfirmedCheckpoint.set('isDuringAction', true);
        }

        if (advice) {
          transport.set('advice', self.removeWhiteSpacesAndSetUpperCase(advice));
        }

        transport.validate();

        if (self.get('transport.hasErrors')) {
          transport.set('isDuringAddingOfAdvice', false);

          if (firstUnconfirmedCheckpoint.get('id')) {
            firstUnconfirmedCheckpoint.set('isDuringAction', false);
          }

          defer.resolve();
          return;
        }

        const updateAllFutureTransportsAndWindows = this.updateAllFuturePeriodicTransports(transport);
        transport.set('updateAllFuturePeriodicTransports', updateAllFutureTransportsAndWindows);
        transport.set('updateAllFuturePeriodicWindows', updateAllFutureTransportsAndWindows);
        transport.save().then(() => {
          console.log(`Advice and window for transport ${self.get('transport.id')} has been saved.`);
          const adviceContent = transport.get('advice.content');

          if (adviceContent) {
            adviceContent.saveTrackerChanges();
          }

          transport.set('isDuringAddingOfAdvice', false);

          if (firstUnconfirmedCheckpoint.get('id')) {
            firstUnconfirmedCheckpoint.set('isDuringAction', false);
          }

          self.unloadRedundantTimeWindows(transport);
          transport.reload().then(() => self.handleTransportReload(transport, self.get('scheduleController')));
          self.send('hideModal');
          const timeWindowStart = transport.isCombinedTransport ? moment(editedTimeWindow.start).format('HH:mm') : moment(transport.get('earliestTimeWindowStart')).format('HH:mm');
          self.get('scheduleController').setProperties({
            info: self.get('intl').t('warehouse.adviceHasBeenSavedInfo') + timeWindowStart,
            warehouseStepToHandle: null
          });
          self.set('model.errors', []);
          defer.resolve();

          if (transport.get('transportType.adviceSeparatedFromWindow')) {
            console.debug('Nie przekierowuje do harmonogramu po dodaniu awizacji');
            return;
          }

          if (editedTimeWindow) {
            self.send('refreshRoute');
          }
        }).catch(response => {
          const errors = response.errors;
          const responseMeta = errors ? errors[0].meta : null;
          const exceptionType = responseMeta ? responseMeta.exceptionType : null;

          if (responseMeta) {
            const exceptionInformation = Ember.ObjectProxy.create({
              initialStart: responseMeta.initialStart,
              windowSizeInMinutes: responseMeta.windowSizeInMinutes,
              rampName: responseMeta.rampName,
              whId: responseMeta.warehouseId
            });
            transport.set('exceptionInformation', exceptionInformation);
          }

          this.setProperties({
            hasErrors: self.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)
          });
          self.set('model.errors', errors);
          transport.set('isDuringAddingOfAdvice', false);

          if (firstUnconfirmedCheckpoint.get('id')) {
            firstUnconfirmedCheckpoint.set('isDuringAction', false);
          }

          defer.resolve();
        });
      },

      cancel() {
        console.debug('Creation of advice cancelled.');
        const self = this;
        self.set('model.errors', []);
        self.get('scheduleController').set('warningMessage', null);
        self.get('transport.advice.content').rollback();
        const editedWindow = self.get('scheduleController.editedWindow');

        if (editedWindow && editedWindow.content) {
          // Anulacja podczas edycji wybranego okna spowoduje jedynie przywrócenie okna na poprzednie miejsce
          editedWindow.content.restorePlace();
        } else {
          // Anulacja podczas tworzenia awizacji spowoduje wyczyszczenie wszystkich niezapisanych okien i przeniesienie do magazynu pierwszego takiego okna
          const transport = self.get('transport');
          const unsavedTimeWindows = transport.get('timeWindows').filter(tw => {
            return !tw.get('id');
          });
          self.removeUnsavedTimeWindows(transport);
          const stepsWithUnsavedTimeWindow = self.get('transport.stepsWithWarehouse').filter(step => {
            return !step.get('timeWindow.id');
          });
          stepsWithUnsavedTimeWindow.forEach(step => {
            step.set('timeWindow', null);
          });
          transport.set('isDuringAddingOfAdvice', false);

          if (stepsWithUnsavedTimeWindow.get('length') > 1) {
            // Zapamiętujemy czas pierwszego okna, by móc przenieść użytkownika do odpowiedniego dnia
            const firstStart = unsavedTimeWindows.get('firstObject.start');
            const warehouse = stepsWithUnsavedTimeWindow.get('firstObject.warehouse');
            const scheduleDateString = moment(firstStart).format('YYYY-MM-DD');
            console.debug(`Zapisywanie awizacji do transportu ${self.get('transport.id')} zostało przerwane. Przenosimy się do magazynu ${warehouse.get('name')} w dniu ${scheduleDateString}..`);
            self.transitionToRoute('warehouses.schedule', warehouse.get('id'), scheduleDateString, '1');
          }
        }
      },

      close: function () {
        this.send('hideModal');
      },

      performSaveAt(date) {
        this.executeAt(date, () => {
          $('.save-advice-button').click();
          this.set('actionPlanned', false);
        });
        this.set('actionPlanned', true);
      }

    },
    executeAt: function (timeString, func) {
      const time = moment(timeString).toDate();
      const currentTime = new Date().getTime();
      setTimeout(func, time - currentTime);
      return true;
    }
  });

  _exports.default = _default;
});