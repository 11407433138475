define("apollo/pods/components/transport/courier-invoice-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    data: null,
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),

    init() {
      this._super(...arguments);

      const transport = this.get('transport');

      if (transport.get('courierInvoice').content === null) {
        let invoice = this.get('store').createRecord('courierInvoice', {
          transport: this.get('transport')
        });
        transport.set("courierInvoice", invoice);
        this.get('transport').set('courierInvoice', invoice);
      }
    },

    disableEditionOfCourierInvoiceFields: Ember.computed('', function () {
      return !this.get('sessionAccount').hasRole('EDIT_COURIER_INVOICE_SECTION');
    }),
    actions: {
      setDate(propertyName, date) {
        const transport = this.get('transport');

        if (!date) {
          console.debug('brak daty');
          return;
        }

        transport.set("courierInvoice.content.date", date[0]);
      },

      fileUploadSuccess: function (transportId) {
        this.set('action', 'fileUploadSuccess');
        this.sendAction('action', transportId);
      },

      deleteFile() {
        const transport = this.get('transport');
        transport.set("courierInvoice.content.base64", null);
      },

      upload: function (event) {
        const self = this;
        const reader = new FileReader();
        const file = event.target.files[0];
        let invoiceData;

        reader.onload = () => {
          invoiceData = reader.result.split(',')[1];
          self.set('transport.courierInvoice.content.base64', invoiceData);
          self.set('transport.courierInvoice.content.fileName', file.name);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  });

  _exports.default = _default;
});