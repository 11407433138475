define("apollo/pods/components/transport/get-couriers-quotes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),

    init() {
      this._super(...arguments); // inicjalizujemy wartość dla getCouriersQuotesValue po otwarciu formularza


      const courierQuoteValueToBool = this.get('getCouriersQuotesValue') === this.get('intl').t('common.yes');
      this.set('transport.getCouriersQuotes', courierQuoteValueToBool);
    },

    hideCouriersQuotesSection: Ember.computed('transport.transportType.id', function () {
      const transportTypeId = this.get('transport.transportType.id');
      const courierSetting = this.get('sessionAccount').getCourierSetting(transportTypeId);

      if (!courierSetting) {
        return true;
      }

      return !courierSetting.get('isGetCourierQuotesFieldVisibility');
    }),
    // eslint-disable-next-line ember/require-return-from-computed
    disableEdition: Ember.computed('transport', function () {
      if (this.get('transport.id') == null) {
        return false;
      }

      if (this.get('transport.getCouriersQuotes')) {
        return true;
      }
    }),
    getCouriersQuotesFields: Ember.computed('', function () {
      return [this.get('intl').t('common.yes'), this.get('intl').t('common.no')];
    }),
    getCouriersQuotesValue: Ember.computed('transport', {
      get(key) {
        // Jeśli istnieje wartość w getCouriersQuotes
        if (this.get('transport.getCouriersQuotes') !== undefined) {
          return this.get('transport.getCouriersQuotes') ? this.get('intl').t('common.yes') : this.get('intl').t('common.no');
        } // Jeśli transport jest stworzony i nie istnieje wartość w getCouriersQuotes
        // Służy to do obsługi historycznych transportów, które mogą mieć NULL'e w polu getCouriersQuotes


        if (this.get('transport.id') && this.get('transport.getCouriersQuotes') === undefined) {
          return this.get('intl').t('common.no');
        } // Gdy transport nie istnieje bierzemy wartość na podstawie shouldGetCourierQuotesByDefault


        const transportTypeId = this.get('transport.transportType.id');
        const courierSetting = this.get('sessionAccount').getCourierSetting(transportTypeId);
        return courierSetting ? courierSetting.get('shouldGetCourierQuotesByDefault') ? this.get('intl').t('common.yes') : this.get('intl').t('common.no') : this.get('intl').t('common.no');
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      setCouriersQuotes: function (value) {
        if (value === this.get('intl').t('common.yes')) {
          this.set('transport.getCouriersQuotes', true);
          this.set('getCouriersQuotesValue', this.get('intl').t('common.yes'));
        } else if (value === this.get('intl').t('common.no')) {
          this.set('transport.getCouriersQuotes', false);
          this.set('getCouriersQuotesValue', this.get('intl').t('common.no'));
        }
      }
    }
  });

  _exports.default = _default;
});