define("apollo/pods/components/transaction/auto-transaction-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H7ByZfp2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"button-with-confirmation\",null,[[\"class\",\"text\",\"dataButtonName\",\"disabled\",\"style\",\"onConfirm\"],[\"btn btn-sm vertical-margin-small\",[27,\"t\",[\"transaction.autoPassConfirmation\"],null],\"auto-pass-transfer-button\",[23,[\"disableAutoPassButton\"]],[27,\"if\",[[23,[\"disableAutoPassButton\"]],\"background-color:lightgray;\",\"background-color:orange;color:snow;\"],null],[27,\"action\",[[22,0,[]],\"createTransactionAndStartAutoPass\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"transaction.autoPass\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transaction/auto-transaction-button/template.hbs"
    }
  });

  _exports.default = _default;
});