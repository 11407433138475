define("apollo/models/checkpoint-confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CheckpointConfirmationModal
   * @extends EmberObject
   * @property {number} checkpoint - Checkpoint's id we want to confirm
   * @property {number[]} warehouseLocations - Information about different locations within the warehouse.
   * @property {Model} weight - The weight readout information associated with the checkpoint.
   * @property {Model} customFieldValues - Custom field values associated with the checkpoint confirmation.
   */
  const CheckpointConfirmationModal = Ember.Object.extend({
    checkpoint: null,
    weight: null,
    warehouseLocations: null,
    customFieldValues: null
  });
  var _default = CheckpointConfirmationModal;
  _exports.default = _default;
});