define("apollo/pods/gates/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function (params) {
      return this.store.findRecord('gate', params.id);
    },
    renderTemplate: function () {
      this.render('gates.create');
    },
    setupController: function (controller, model) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor('gates.create').setProperties({
        model
      });
    },
    actions: {
      willTransition: function () {
        this.get('controller').setProperties({
          success: false,
          errors: []
        });
        this.get('currentModel').unloadRecord();
        return true;
      }
    }
  });

  _exports.default = _default;
});