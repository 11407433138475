define("apollo/pods/components/custom-field-option-dependency-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    modalTitle: Ember.computed('', function () {
      return this.get('intl').t('custom-field-option.editDependencies');
    }),
    availableDefinitions: Ember.computed('model.id', function () {
      const self = this;
      return this.get('store').peekAll('custom-field-definition').filter(cfd => {
        return cfd.get('type') === 'select' && self.get('model.definition.transportType.id') === cfd.get('transportType.id') && self.get('model.definition.id') !== cfd.get('id');
      });
    }),
    actions: {
      close() {
        this.send('hideModal');
      },

      cancel() {
        this.get('model').rollback();
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});