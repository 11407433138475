define("apollo/pods/components/transports/transports-table/transport-title/component", ["exports", "apollo/mixins/attachment-mixin", "apollo/config/environment"], function (_exports, _attachmentMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend(_attachmentMixin.default, {
    store: service(),
    sessionAccount: service('session-account'),
    relationsTrackerService: service('transport-relations.relations-tracker'),
    tagName: 'div',
    classNames: Ember.A(['row', 'h2']),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    searchParams: {},
    // rzeczywista zmienna propagowana po całym systemie
    action: '',
    canGenerateExcel: Ember.computed('sessionAccount.currentUser.roleNames', function () {
      return ['ROLE_ADVICE_EXCEL', 'ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT', 'ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT'].some(role => this.get('sessionAccount').hasRole(role));
    }),
    disableGenerateExcelButton: Ember.computed('searchParams', 'searchParams.filters.@each.filterValue', function () {
      if (this.get('archive') === false) {
        return false;
      } else {
        const searchParams = this.get('searchParams');
        let disableExcelButton = true;
        const startDate = searchParams.start;
        const stopDate = searchParams.stop;
        const areDatesMandatory = this.get('sessionAccount').getSettingValue('MANDATORY_DATES_RANGE_TO_GENERATE_EXCEL') === 'true';

        if (areDatesMandatory && !(startDate || stopDate)) {
          disableExcelButton = true;
          return disableExcelButton;
        }

        for (const prop in searchParams) {
          if (searchParams[prop] && searchParams[prop] !== '' && searchParams[prop].length !== 0 && prop !== 'filterMode') {
            disableExcelButton = false;
          }
        }

        return disableExcelButton;
      }
    }),
    percentageOfLoadedRow: Ember.computed('numberOfRenderedRows', 'numberOfTransports', function () {
      if (!this.get('numberOfTransports')) {
        return null;
      }

      return Math.round(this.get('numberOfRenderedRows') / this.get('numberOfTransports') * 100);
    }),
    showPercentage: Ember.computed('numberOfTransports', 'percentageOfLoadedRow', function () {
      return this.get('numberOfTransports') && this.get('percentageOfLoadedRow') < 100;
    }),
    availableTransportTypes: Ember.computed('sessionAccount.currentUser.transportTypes.[]', function () {
      const userTransportTypes = this.get('sessionAccount.currentUser.transportTypes');
      const principalId = localStorage.getItem('principalId');
      const company = this.get('store').peekRecord('company', principalId);
      return company.get('transportTypes').filter(tt => {
        return userTransportTypes.includes(tt);
      });
    }),
    refreshButtonHidden: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CAN_REFRESH_MANUALLY') !== 'true';
    }),
    _downloadRegistryOfPersonsXls: function (warehouse) {
      const token = this.get('sessionAccount.token');
      const url = _environment.default.serverURL + '/reports/personsRegistry?token=' + token + '&warehousesId=' + warehouse.get('id');
      return window.open(url, '_blank');
    },
    actions: {
      poll: function () {
        this.set('action', 'poll');
        this.sendAction('action');
      },

      openExportXlsModal() {
        const availableTransportTypes = this.get('availableTransportTypes');
        const checkedTransportTypes = [];
        checkedTransportTypes.pushObject(this.get('transportType'));
        this.set('action', 'showModal');
        this.sendAction('action', 'transports.modals.transport-types-to-excel', {
          availableTransportTypes,
          checkedTransportTypes,
          archived: this.get('archive'),
          searchParams: this.get('searchParams')
        });
      },

      downloadRegistryOfPersonsXls() {
        const self = this;
        const warehouses = this.get('sessionAccount.currentUser.warehouses');

        if (warehouses.length > 1) {
          self.set('action', 'showModal');
          self.sendAction('action', 'transports.modals.warehouses-to-excel', {
            warehouses
          });
          return;
        }

        Ember.run.once(self, '_downloadRegistryOfPersonsXls', warehouses[0]);
      },

      scrollToClosestTransport() {
        this.set('action', 'scrollToClosestTransport');
        this.sendAction('action');
      },

      clearFilters() {
        this.set('action', 'clearFilters');
        this.sendAction('action');
      },

      openRelationsModal() {
        this.set('action', 'openRelationsModal');
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});