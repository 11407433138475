define("apollo/pods/reset-password/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend({
    sessionAccount: service('session-account'),
    beforeModel: function () {
      this.get('sessionAccount').set('passwordExpired', true);
    }
  });

  _exports.default = _default;
});