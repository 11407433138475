define("apollo/pods/workers/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_COMPANIES_TAB', 'ROLE_OWN_COMPANY_TAB'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function (params) {
      const companyId = params.companyId;
      const principalCompanyId = localStorage.getItem('principalId');
      params.max = params.max || 10;
      params.page = params.page || 1;
      params.principalCompanyId = principalCompanyId;
      return new Promise(resolve => {
        this.store.query('worker', params).then(response => {
          resolve(Ember.RSVP.hash({
            workers: response,
            company: this.store.findRecord('company', companyId),
            principalCompanyId
          }));
        }, () => {
          this.transitionTo('forbidden');
        });
      });
    },
    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});