define("apollo/pods/components/checkpoint-blockade-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dpYuBftK",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[7,\"div\"],[11,\"class\",\"blockade-info\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"margin-top inline-block\"],[9],[0,\"\\n        \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"fields\"]]],null,{\"statements\":[[0,\"                \"],[7,\"li\"],[11,\"class\",\"text-left no-padding\"],[9],[0,\"\\n                    \"],[1,[22,1,[]],false],[0,\"\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/checkpoint-blockade-message/template.hbs"
    }
  });

  _exports.default = _default;
});