define("apollo/pods/partnership/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    sessionAccount: service('session-account'),
    intl: service(),
    STATUS: {
      NO_INVITATION: "NO_INVITATION",
      WAITING: "WAITING",
      REGISTRATION_IS_IN_PROGRESS: 'REGISTRATION_IS_IN_PROGRESS',
      AWAITING_VERIFICATION: "AWAITING_VERIFICATION",
      ACCEPTED: "ACCEPTED",
      REJECTED: "REJECTED",
      UPDATED: "UPDATED",
      BLOCKED: "BLOCKED"
    },
    principalCompany: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null
    }),
    contractorCompany: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null,
      deepEmbedded: ['headquarter']
    }),
    invitation: _emberData.default.belongsTo('invitation', {
      async: true
    }),
    partnershipType: _emberData.default.belongsTo('partnershipType', {
      async: true
    }),
    parentPartnership: _emberData.default.belongsTo('partnership', {
      async: true,
      inverse: null
    }),
    mainSection: _emberData.default.belongsTo('mainSection', {
      async: true
    }),
    secondarySection: _emberData.default.belongsTo('secondarySection', {
      async: true
    }),
    antiCorruptionSection: _emberData.default.belongsTo('antiCorruptionSection', {
      async: true
    }),
    documents: _emberData.default.hasMany('documents', {
      async: true
    }),
    partnershipContractorsGroups: _emberData.default.hasMany('partnershipContractorsGroup', {
      async: true
    }),
    blockades: _emberData.default.hasMany('blockade', {
      async: true,
      inverse: null
    }),
    termsOfService: _emberData.default.belongsTo('termsOfService', {
      async: true,
      inverse: 'partnership'
    }),
    type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    contractorNumber: _emberData.default.attr('string'),
    contractorShortName: _emberData.default.attr('string'),
    patron: _emberData.default.belongsTo('user', {
      async: true,
      inverse: null
    }),
    registrationCompleted: _emberData.default.attr('boolean'),
    verificationRequestSent: _emberData.default.attr('boolean'),
    sendRequestForVerification: _emberData.default.attr('boolean'),
    lastUpdatedSectionDate: _emberData.default.attr('isodate'),
    dateCreated: _emberData.default.attr('date'),
    adviceVerification: _emberData.default.attr('boolean'),
    adviceVerificationInterval: _emberData.default.attr('string'),
    allowsPeriodicTransport: _emberData.default.attr('boolean'),
    defaultRampAccessExists: _emberData.default.attr('boolean'),
    representativeName: _emberData.default.attr('string'),
    representativeEmail: _emberData.default.attr('string'),
    representativePhoneNumber: _emberData.default.attr('string'),
    representativePhoneNumberPrefix: _emberData.default.attr('string', {
      defaultValue: '48'
    }),
    additionalDetails: _emberData.default.attr('string'),
    transportLimit: _emberData.default.attr('number'),
    contractorCompletedTransportsCount: _emberData.default.attr('number'),
    contractorTransportsInProgressCount: _emberData.default.attr('number'),
    transportsCreatedCount: _emberData.default.attr('number'),
    isTransportLimitExceeded: _emberData.default.attr('boolean'),
    validations: {
      principalCompany: {
        custom: [{
          validation: function (key, value) {
            return value && value.get('id');
          },
          message: 'blank'
        }]
      },
      contractorCompany: {
        custom: [{
          validation: function (key, value) {
            return value && value.get('id');
          },
          message: 'blank'
        }]
      },
      contractorShortName: {
        custom: [{
          validation: function (key, value) {
            return Ember.isNone(value) || value.toString().length < 255;
          },
          message: 'contractorShortNameTooLong'
        }]
      },
      sendRequestForVerification: {
        custom: [{
          validation: function (key, value, model) {
            //Walidacja obowiązuje tylko w momencie wysłania prośby o weryfikację
            if (!value) {
              return true;
            }

            let validate = true;

            if (!model.get('mainSection.content').validate()) {
              validate = false;
            }

            if (model.get('contractorCompany.shouldHaveRegon') && !model.get('mainSection.regonFile.content')) {
              validate = false;
              model.get('errors').add('regonFile', model.get('intl').t('main-section.errors.regonFile'));
            }

            if (model.get('contractorCompany.shouldHaveKrs') && !model.get('mainSection.krsFile.content')) {
              validate = false;
              model.get('errors').add('krsFile', model.get('intl').t('main-section.errors.krsFile'));
            }

            if (!model.get('mainSection.nipFile.content')) {
              validate = false;
              model.get('errors').add('nipFile', model.get('intl').t('main-section.errors.nipFile'));
            }

            const appliesSetting = model.get('sessionAccount').getAppliesSetting();

            if (appliesSetting && appliesSetting.get('createAntiCorruptionSection') && model.get('antiCorruptionSection.signedFiles.length') < 1) {
              validate = false;
              model.get('errors').add('antiCorruptionFiles', model.get('intl').t('anti-corruption-section.errors.signedFiles'));
            }

            if (!model.get('termsOfService.isConfirmed')) {
              validate = false;
              model.get('errors').add('unchecked', model.get('intl').t('terms-of-service.errors.unchecked'));
            }

            return validate;
          },
          message: ''
        }]
      },
      adviceVerificationInterval: {
        custom: [{
          validation: function (key, value, model) {
            // Walidacja pola 'adviceVerificationInterval' tylko w przypadku zaznaczonego checkbox'a 'adviceVerification'
            if (model.get('adviceVerification')) {
              return value && value.length >= 2 && value.toString().match(/^-?(\d+(d|h|m|s))+$/);
            }

            return true;
          },
          message: 'intervalFormatError'
        }]
      },
      representativeEmail: {
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return /^\S+@\S+\.\S+$/.test(value);
          },
          message: 'email'
        }]
      },
      representativePhoneNumber: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            if (model.get('representativePhoneNumberPrefix') === '48') {
              return /^[0-9]+$/.test(value) && value.length >= 9;
            }

            return /^[0-9]+$/.test(value);
          },
          message: 'regex'
        }]
      },
      representativePhoneNumberPrefix: {
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return value.length <= 3;
          },
          message: 'blank'
        }]
      },
      additionalDetails: {
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            return value.length <= 1000;
          },
          message: 'length'
        }]
      },
      transportLimit: {
        numericality: {
          allowBlank: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: Number.MAX_VALUE,
          message: 'invalidNumber'
        }
      }
    },
    isAuxiliary: Ember.computed('type', function () {
      return this.get('type') === 'AUXILIARY';
    }),
    contractorsGroups: Ember.computed('partnershipContractorsGroups.@each', 'partnershipContractorsGroups.@each.enabled', function () {
      return this.get('partnershipContractorsGroups').filterBy('enabled').getEach('contractorsGroup');
    }),
    contractorsGroupsIds: Ember.computed('contractorsGroups.@each.id', function () {
      return this.get('contractorsGroups').getEach('id');
    }),
    contractorsGroupTypeIds: Ember.computed('contractorsGroups.@each', 'contractorsGroups.@each.groupTypeId', function () {
      return this.get('contractorsGroups').getEach('groupTypeId');
    }),
    activeDocuments: Ember.computed('documents.@each.id', 'documents.@each.isActive', function () {
      return this.get('documents').filterBy('id').filterBy('isActive');
    }),
    rejectedDocuments: Ember.computed('documents.@each.id', 'documents.@each.isRejected', function () {
      return this.get('documents').filterBy('id').filterBy('isRejected');
    }),
    updatedDocuments: Ember.computed('activeDocuments.@each.hasBeenUpdated', function () {
      return this.get('activeDocuments').filterBy('hasBeenUpdated');
    }),
    activeLicences: Ember.computed('activeDocuments.@each.isLicence', function () {
      return this.get('activeDocuments').filterBy('isLicence');
    }),
    activeInsurances: Ember.computed('activeDocuments.@each.isInsurance', function () {
      return this.get('activeDocuments').filterBy('isInsurance');
    }),
    archivedDocuments: Ember.computed('documents.@each.id', 'documents.@each.isActive', function () {
      return this.get('documents').filterBy('id').filterBy('isActive', false);
    }),
    archivedLicences: Ember.computed('archivedDocuments.@each.isLicence', function () {
      return this.get('archivedDocuments').filterBy('isLicence');
    }),
    archivedInsurances: Ember.computed('archivedDocuments.@each.isInsurance', function () {
      return this.get('archivedDocuments').filterBy('isInsurance');
    }),
    hasUnapprovedLicences: Ember.computed('activeLicences', function () {
      const activeLicences = this.get('activeLicences');

      if (activeLicences.get('length') === 0) {
        return false;
      }

      const approvedLicenses = activeLicences.filter(licence => {
        return licence.get('state') === licence.get('STATES.ACCEPTED');
      });
      return activeLicences.get('length') !== approvedLicenses.get('length');
    }),
    hasNoActiveLicences: Ember.computed('activeLicences', function () {
      return this.get('activeLicences.length') === 0;
    }),
    idsOfActiveDocumentsTypes: Ember.computed('activeDocuments.@each.length', function () {
      return this.get('activeDocuments').map(d => d.get('documentType.id'));
    }),
    statusIcon: Ember.computed('status', function () {
      switch (this.get('status')) {
        case 'NO_INVITATION':
          return 'ban';

        case 'REGISTRATION_IS_IN_PROGRESS':
          return 'key';

        case 'WAITING':
          return 'hourglass-start';

        case 'AWAITING_VERIFICATION':
          return 'hourglass-start';

        case 'UPDATED':
          return 'warning';

        case 'ACCEPTED':
          return 'check-circle';

        case 'REJECTED':
          return 'frown';

        case 'BLOCKED':
          return 'minus-circle';
      }
    }),
    statusClass: Ember.computed('status', function () {
      if (['NO_INVITATION', 'WAITING', 'REJECTED', 'BLOCKED'].includes(this.get('status'))) {
        return 'errors';
      }

      if (['ACCEPTED'].includes(this.get('status'))) {
        return 'success';
      }

      if (['REGISTRATION_IS_IN_PROGRESS', 'AWAITING_VERIFICATION'].includes(this.get('status'))) {
        return 'info';
      }

      if (['UPDATED'].includes(this.get('status'))) {
        return 'warning';
      }
    }),
    principalCompanyId: Ember.computed('principalCompany.id', function () {
      return this.get('principalCompany.id');
    })
  });

  _exports.default = _default;
});