define("apollo/pods/components/announcements-nav-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KFP8OwYY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showNavbar\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"data-button-name\",\"announcements-button\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"announcements.index\"],[[\"class\"],[\"btn btn-info\"]],{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"announcements.label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"has-role\",[\"ROLE_MANAGE_ANNOUNCEMENTS\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"data-button-name\",\"manage-announcements-button\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"announcements.manage\",[27,\"query-params\",null,[[\"max\",\"page\"],[10,1]]]],[[\"class\"],[\"btn btn-info\"]],{\"statements\":[[0,\"                \"],[1,[27,\"t\",[\"announcements.manage\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/announcements-nav-header/template.hbs"
    }
  });

  _exports.default = _default;
});