define("apollo/pods/workers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed('', function () {
      const columns = [{
        propertyName: 'name',
        title: this.get('intl').t('worker.name'),
        className: 'column-name'
      }, {
        propertyName: 'phoneNumber',
        title: this.get('intl').t('worker.phoneNumber.WORKERS'),
        className: 'column-name'
      }, {
        propertyName: 'trained',
        title: this.get('intl').t('worker.trained'),
        className: 'column-trained',
        filterWithSelect: true,
        predefinedFilterOptions: [{
          value: 'true',
          label: this.get('intl').t('common.yes')
        }, {
          value: 'false',
          label: this.get('intl').t('common.no')
        }]
      }];
      return columns;
    }),
    actions: {
      openAddWorkerModal: function (worker) {
        if (!worker) {
          const principalCompanyId = localStorage.getItem('principalId');
          const principalCompany = this.get('store').peekRecord('company', principalCompanyId);
          worker = this.get('store').createRecord('worker', {
            company: this.get('model.company'),
            enabled: true,
            principalCompany
          });
        }

        this.send('showModal', 'companies.modals.add-worker', worker);
      },
      delete: function (worker) {
        const self = this;
        worker.destroyRecord().then(() => {
          self.setProperties({
            errors: []
          });
        }).catch(response => {
          console.log(`Błąd przy usuwaniu pracownika: ${response.errors}`);
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});