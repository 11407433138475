define("apollo/pods/components/dummy-input-plug/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    range: Ember.computed('rows', function () {
      let range = parseInt(this.get('rows'));

      if (range === 1) {
        return range;
      } else {
        range -= 2;
      }

      return range < 0 ? 0 : range;
    }),
    numberOfInputs: Ember.computed('rows', function () {
      let numberOfInputs = parseInt(this.get('rows'));
      return numberOfInputs < 0 ? 0 : numberOfInputs;
    })
  });

  _exports.default = _default;
});