define("apollo/mixins/transport-form-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    openPanel: panelClass => {
      const transportPanel = (0, _jquery.default)(`.${panelClass}`);

      if (transportPanel.hasClass('collapsed') && !transportPanel.hasClass('in')) {
        transportPanel.collapse('show');
      }
    },

    initializeDiscrepancyReport(transport) {
      const discrepancyReportId = transport.get('discrepancyReport.id');

      if (discrepancyReportId) {
        this.get('store').findRecord('discrepancyReport', discrepancyReportId);
      }
    },

    // TODO: Refaktor "odświeżania" cf-ów przez initializeCustomFields
    initializeCustomFields(transport) {
      const self = this;
      console.debug(`Inicjalizujemy custom fields dla transportu ${transport.get('logString')}...`);
      return self.get('store').query('customFieldValue', {
        transportId: transport.get('id')
      }).then(customFields => {
        self.removeCustomFields(transport, customFields);
      }).then(() => {
        self.createMissingCustomFieldValues(transport);
      }).then(() => {
        transport.get('customFields').forEach(cf => cf.set('changeMessage', null));
      });
    },

    /*
     * Usuwa z transportu custom field-y, które nie przyszły z backendu, ponieważ zostały usunięte podczas edycji transportu (nie podano ich wartości)
     */
    removeCustomFields: function (transport, customFields) {
      // Tworzymy kopię customFields, ponieważ nie możemy usuwać elementów z listy,
      // po której iterujemy (czyli bezpośrednio z transport.get('customFields'))
      const customFieldsCopy = transport.get('customFields').slice(0); // eslint-disable-next-line array-callback-return

      customFieldsCopy.map(cf => {
        if (!customFields.includes(cf)) {
          // console.debug(`Usuwamy z transportu ${transport.get('logString')} custom field ${cf.get('logString')}...`);
          transport.get('customFields').removeObject(cf);
        }
      });
    },

    /*
     * tworzy obiekty customFieldValue dla transportu, jeżeli jeszcze nie istnieją
     * (sytuacja występuje dla transportów utworzonych przed dodaniem nowego customFieldDefinition lub gdy nie uzupełniono wartości customField-u)
     */
    createMissingCustomFieldValues: function (transport) {
      const self = this;
      const currentPairs = transport ? transport.get('customFieldPairs') : this.get('transport.customFieldPairs');
      currentPairs.forEach(pair => {
        const definition = pair.definition;

        if (definition.get('usedForDeliveries')) {
          return;
        }

        if (!pair.value) {
          console.debug(`Dodajemy do transportu ${transport.get('logString')} puste/nowe custom field ${definition.get('name')}...`);
          self.get('store').createRecord('customFieldValue', {
            transport,
            definition
          });
        }
      });
    }
  });

  _exports.default = _default;
});