define("apollo/pods/components/transports/transports-table/transport-row/guard-checkpoint-fields/guard-checkpoint-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      confirm(checkpoint) {
        this.set('action', 'confirm');
        this.sendAction('action', checkpoint);
      },

      reject(checkpoint) {
        this.set('action', 'reject');
        this.sendAction('action', checkpoint);
      },

      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});