define("apollo/pods/manual-notification/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    transport: belongsTo('transport', {
      async: true
    }),
    notification: belongsTo('notification', {
      async: true
    }),
    enabled: attr('boolean')
  });

  _exports.default = _default;
});