define("apollo/helpers/document-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, nameArgs) {
      const documentTypeName = nameArgs.documentTypeName;

      if (documentTypeName && documentTypeName.search('LICENCE') > -1) {
        return 'fa fa-id-card';
      }

      switch (documentTypeName) {
        case 'INSURANCE_OCS':
        case 'SUMMER_MAPPING':
        case 'WINTER_MAPPING':
          return 'fa fa-file-text';

        case 'INSURANCE_OC':
          return 'fa fa-file';

        default:
          return 'fa fa-file-text';
      }
    }

  });

  _exports.default = _default;
});