define("apollo/pods/combining-relations-wizard/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    relationsCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    intl: Ember.inject.service(),
    tagName: '',

    _validateWizard(viewModel) {
      const checkedExistingRelations = viewModel.existingRelations.filter(this._filterOnlyCheckedTransports);
      const checkedWithoutRelations = viewModel.withoutRelations.filter(this._filterOnlyCheckedTransports);

      if (checkedWithoutRelations.length < 1 || checkedExistingRelations.length < 1) {
        return false;
      }

      return true;
    },

    _filterOnlyCheckedTransports(relationWizardViewModel) {
      return relationWizardViewModel.isChecked;
    },

    actions: {
      combineRelations() {
        const existingRelations = this.get('model.viewModel.existingRelations');
        const withoutRelations = this.get('model.viewModel.withoutRelations');
        const checkedTransports = existingRelations.concat(withoutRelations).filter(this._filterOnlyCheckedTransports);

        if (!this._validateWizard(this.get('model.viewModel'))) {
          this.set('errors', [this.get('intl').t('relationsWizard.errors.atLeastOneOfAKind')]);
          return;
        }

        const relation = {
          masters: [checkedTransports.shift().get('transport')],
          slaves: checkedTransports.map(viewModel => viewModel.get('transport')),
          type: 'TRANSPORT_COMBINING',
          relation: this.get('model.transportRelation')
        };
        const combiningErrors = this.get('relationsCombiner').combine(relation);

        if (combiningErrors && combiningErrors.length > 0) {
          return this.set('errors', combiningErrors);
        }
      },

      cancel() {
        history.back();
      }

    }
  });

  _exports.default = _default;
});