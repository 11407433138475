define("apollo/mixins/serializer-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serializeIntoHash: function (data, type, record, options) {
      const object = this.serialize(record, options);

      for (let key in object) {
        data[key] = object[key];
      }
    },
    serializeHasMany: function (record, json, relationship) {
      const key = relationship.key;
      const hasManyRecords = record.hasMany(key);

      if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === "save") {
        json[key] = [];
        hasManyRecords.forEach(function (item) {
          let recordData = item.serialize({
            includeId: true
          });

          if (relationship.options.deepEmbedded) {
            relationship.options.deepEmbedded.forEach(function (deepKey) {
              if (item.belongsTo(deepKey)) {
                const deepRecord = item.belongsTo(deepKey);

                if (deepKey === "company" && !deepRecord.attr('name')) {
                  return true;
                }

                recordData[deepKey] = deepRecord.serialize({
                  includeId: true
                });
              }
            });
          }

          json[key].push(recordData);
        });
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});