define("apollo/pods/user/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // noinspection JSLint
  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    ramps: _emberData.default.hasMany('ramp', {
      inverse: 'users'
    }),
    queues: _emberData.default.hasMany('queue', {
      inverse: 'users'
    }),
    workflows: _emberData.default.hasMany('workflow', {
      async: true
    }),
    roles: _emberData.default.hasMany('role', {
      async: true
    }),
    tableColumns: _emberData.default.hasMany('tableColumn', {
      async: true
    }),
    userNotifications: _emberData.default.hasMany('userNotification', {
      async: true
    }),
    transportTypes: _emberData.default.hasMany('transportType', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    roleGroup: _emberData.default.belongsTo('roleGroup', {
      async: true
    }),
    userGroup: _emberData.default.belongsTo('userGroup', {
      async: true
    }),
    whoDeleted: _emberData.default.belongsTo('user', {
      async: true
    }),
    invitation: _emberData.default.belongsTo('invitation', {
      async: true,
      inverse: 'registeredAccount'
    }),
    invitationCreator: _emberData.default.attr('string'),
    invitationDate: _emberData.default.attr('isodate'),
    lastLoginDate: _emberData.default.attr('isodate'),
    localAdmin: _emberData.default.attr('boolean'),
    ldapUser: _emberData.default.attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    waitingInvitationsCount: _emberData.default.attr('number'),
    unreadAnnouncementsCount: _emberData.default.attr('number'),
    username: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    password: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    mobilePhoneNumber: _emberData.default.attr('string'),
    mobilePhoneNumberPrefix: _emberData.default.attr('string'),
    occupation: _emberData.default.attr('string'),
    workHours: _emberData.default.attr('string'),
    newPassword: _emberData.default.attr('string'),
    repeatedNewPassword: _emberData.default.attr('string'),
    changePassword: _emberData.default.attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    confirmedTermsOfProvidingServices: _emberData.default.attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    accountExpired: _emberData.default.attr('boolean'),
    accountLocked: _emberData.default.attr('boolean'),
    temporaryAccount: _emberData.default.attr('boolean'),
    passwordExpired: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('date'),
    dateDeleted: _emberData.default.attr('date'),
    resetPasswordDate: _emberData.default.attr('date'),
    resetPasswordIsDisabled: _emberData.default.attr('boolean'),
    statuteAccepted: _emberData.default.attr('boolean'),
    maxRowsInCompaniesTable: _emberData.default.attr('number'),
    selectedNotifications: Ember.computed('userNotifications.@each.notification', function () {
      return this.get('userNotifications').getEach('notification');
    }),
    warehouses: function () {
      return this.get('ramps').filterBy('enabled').getEach('warehouse').filterBy('enabled').filterBy('company.id', localStorage.getItem('principalId')).uniqBy('id').sortBy('idx');
    }.property('ramps.@each.warehouse', 'ramps.@each'),
    accessibleWarehouses: function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_RAMPS')) {
        return this.store.peekAll('warehouse').filterBy('enabled').sortBy('idx');
      }

      return this.get('warehouses').sortBy('idx');
    }.property('warehouses.@each'),
    accessibleRamps: function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_RAMPS')) {
        return this.store.peekAll('ramp').filterBy('enabled');
      }

      return this.get('ramps');
    }.property('ramps.@each'),
    accessibleSquares: function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_QUEUES')) {
        return this.store.peekAll('square').filterBy('enabled').sortBy('idx');
      }

      return this.get('squares').sortBy('idx');
    }.property('squares.@each'),
    accessibleQueues: function () {
      if (this.get('sessionAccount').hasRole('ROLE_ACCESS_ALL_QUEUES')) {
        return this.store.peekAll('queue').filterBy('enabled');
      }

      return this.get('queues');
    }.property('queues.@each'),
    warehouse: function () {
      return this.get('warehouses').get('firstObject');
    }.property('warehouses.firstObject'),
    rampsIds: function () {
      return this.get('ramps').map(r => r.get('id'));
    }.property('ramps.@each.id'),
    queuesIds: Ember.computed('queues.@each.id', function () {
      return this.get('queues').getEach('id');
    }),
    squares: Ember.computed('queues.@each.square', 'queues.@each', function () {
      return this.get('queues').filterBy('enabled').getEach('square').filterBy('enabled').filterBy('company.id', localStorage.getItem('principalId')).uniqBy('id').sortBy('idx');
    }),
    squaresIds: Ember.computed('squares.@each', function () {
      return this.get('squares').getEach('id');
    }),
    roleNames: function () {
      return this.get('roles').map(function (r) {
        return r.get('name');
      });
    }.property('roles.length', 'roles.@each.name'),
    isPrincipal: function () {
      return this.get('company').get('isPrincipal');
    }.property('company.isPrincipal'),
    canDiscardAdviceRestrictions: function (transportTypeId) {
      if (!transportTypeId) {
        return false;
      }

      const transportType = this.get('store').peekRecord('transportType', transportTypeId);
      const positiveAuthorityRestrictions = transportType.findPositiveAuthorityRestrictionsByActionTypes(['ADD_TIME_WINDOW', 'ADD_ADVICE', 'RESIZE_ADVICE_WINDOW', 'MOVE_ADVICE_WINDOW']);
      return positiveAuthorityRestrictions.get('length') !== 0 && positiveAuthorityRestrictions.every(ar => {
        return ar.get('authorityRange') !== 'ADVICE_RESTRICTION_BASED';
      });
    },
    canDiscardAssortmentGroupRestrictions: function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP');
    },
    accountStatus: Ember.computed('enabled', 'accountExpired', function () {
      if (!this.get('enabled')) {
        return 'DELETED';
      }

      if (this.get('temporaryAccount')) {
        return 'TEMPORARY';
      }

      if (this.get('accountExpired')) {
        return 'EXPIRED';
      }

      return 'ACTIVE';
    }),
    temporaryAccountDaysLeft: Ember.computed('dateCreated', function () {
      const activePeriod = this.get('sessionAccount').getParameterValue('TEMPORARY_ACCOUNT_ACTIVE_PERIOD_IN_DAYS');
      const endOfPeriod = moment(this.get('dateCreated')).add(activePeriod, 'days').startOf('day');
      let daysLeft = endOfPeriod.diff(moment().startOf('day'), 'days');

      if (daysLeft < 0) {
        daysLeft = 0;
      }

      return daysLeft;
    }),
    validations: {
      mobilePhoneNumber: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return true;
            }

            return /^\d+$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('user.errors.mobilePhoneNumberRegexError');
          }
        }, {
          validation: function (key, value, model) {
            if (!value || model.get('mobilePhoneNumberPrefix') !== '48') {
              return true;
            }

            return value.length === 9;
          },
          message: function (key, value, model) {
            return model.get('intl').t('user.errors.mobilePhoneNumberLengthError');
          }
        }]
      }
    }
  });

  _exports.default = _default;
});