define("apollo/pods/components/companies/apply-form/licences-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XYmJ5hX+",
    "block": "{\"symbols\":[\"d\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"partnership\",\"activeLicences\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"documents-applies/document-form\",null,[[\"document\",\"documentTypes\"],[[22,1,[]],[23,[\"documentTypes\"]]]]],false],[0,\"\\n\\n        \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-12 form-buttons margin-bottom\"],[9],[0,\"\\n        \"],[1,[27,\"documents-applies/show-document-button\",null,[[\"partnership\",\"documentTypes\",\"groupName\"],[[23,[\"partnership\"]],[23,[\"documentTypes\"]],[23,[\"groupName\"]]]]],false],[0,\"\\n\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"common.refresh\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"refresh\"]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"partnership\",\"archivedLicences\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"type\",\"button\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"document.LICENCE.showArchived\"],null],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"showArchived\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/companies/apply-form/licences-section/template.hbs"
    }
  });

  _exports.default = _default;
});