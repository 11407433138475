define("apollo/pods/components/log-api-request/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/gniUkcJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card card-body\"],[9],[0,\"\\n    \"],[7,\"pre\"],[11,\"id\",\"log-api-request\"],[9],[0,\"\"],[1,[21,\"formattedLog\"],false],[0,\"    \"],[10],[0,\"\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/log-api-request/template.hbs"
    }
  });

  _exports.default = _default;
});