define("apollo/pods/dialogs/window-details-dialog/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    deliveries: Ember.computed('model.transport.deliveries', function () {
      return this.get('model.transport').get('deliveries');
    }),
    uniqDeliveriesIncoterms: Ember.computed('deliveries', function () {
      const deliveries = this.get('deliveries');
      return deliveries.map(d => {
        return d.incoterms;
      }).uniq();
    })
  });

  _exports.default = _default;
});