define("apollo/pods/components/warehouse-zone-nav-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tf08uX6J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"warehouseZone\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-12 text-right padding-bottom\"],[9],[0,\"\\n            \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"warehouseZones.edit\",[23,[\"warehouseZone\"]]],[[\"class\"],[\"btn btn-info\"]],{\"statements\":[[0,\"                    \"],[1,[27,\"t\",[\"warehouseZone.settings\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"link-to\",[\"algorithm-settings.index\",[27,\"query-params\",null,[[\"warehouseZoneId\"],[[23,[\"warehouseZone\",\"id\"]]]]]],[[\"class\"],[\"btn btn-info\"]],{\"statements\":[[0,\"                    \"],[1,[27,\"t\",[\"warehouseZone.algorithmSettings\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/warehouse-zone-nav-header/template.hbs"
    }
  });

  _exports.default = _default;
});