define("apollo/pods/components/transaction/auto-transaction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS: '3h',
    disableAutoPassButton: Ember.computed('transport.activeAuction', 'transport.transaction.id', function () {
      return this.get('transport.transaction.id') || this.get('transport.lastTransactionAutoPassNotAccepted');
    }),
    timeToAcceptTransport: Ember.computed('', 'TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS', 'TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASSTIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS', function () {
      const setting = this.sessionAccount.getSettingValue('TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS');
      return setting || this.TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS;
    }),
    actions: {
      createTransactionAndStartAutoPass() {
        console.log('Startujemy z automatycznym przekazaniem...');
        const timeToAcceptTransport = this.timeToAcceptTransport;
        const intervalValue = timeToAcceptTransport.substring(0, timeToAcceptTransport.length - 1);
        const intervalUnit = timeToAcceptTransport.substring(timeToAcceptTransport.length - 1);
        const expectedConfirmationDate = moment(new Date()).add(intervalValue, intervalUnit).toDate();
        const expectedConfirmationTime = moment(expectedConfirmationDate).format('HH:mm');
        const carrierMustAcceptTransport = true;
        const transaction = this.store.createRecord('transaction', {
          transport: this.transport,
          expectedConfirmationDate,
          expectedConfirmationTime,
          carrierMustAcceptTransport,
          autoRouteOfferPass: true
        });
        transaction.save().then(() => {
          console.log('Proces automatycznego przekazywania do przewoźników rozpoczęty');
        }).catch(response => {
          console.error('Wystąpił błąd przy starcie automatycznego przekazywania :: ' + response.errors);
          alert(response.errors);
          transaction.destroyRecord();
        });
      }

    }
  });

  _exports.default = _default;
});