define("apollo/pods/components/show-courier-price-details/component", ["exports", "apollo/pods/components/custom-popover/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    priceBrutto: Ember.computed('', function () {
      const priceSum = this.get('courier').price_sum_netto + this.get('courier').price_sum_vat;
      return priceSum.toFixed(2);
    }),
    dhlExpressPriceBrutto: Ember.computed('', function () {
      const courier = this.get('courier');
      const priceSum = courier.price_base_vat + courier.price_base + courier.price_dhl_premium + courier.price_es + courier.price_fuel;
      return priceSum.toFixed(2);
    }),
    actions: {
      showPriceDetails() {
        if (!this.get('isOpened')) {
          this.showPopover(this.element, '.show-price-details', '.price-details-popover');
        } else {
          this.destroyPopovers();
        }
      }

    }
  });

  _exports.default = _default;
});