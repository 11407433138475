define("apollo/models/schedule-action-select-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ScheduleActionSelectModel = Ember.Object.extend({
    start: null,
    stop: null,
    rampId: null,
    selectedCompany: null,
    transportTypes: null,
    showDedicatedWindowButton: Ember.computed('selectedCompany', function () {
      return !!this.selectedCompany;
    }),
    showBlockadeWindowButton: Ember.computed('selectedCompany', function () {
      return !this.selectedCompany;
    })
  });
  var _default = ScheduleActionSelectModel;
  _exports.default = _default;
});