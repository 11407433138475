define("apollo/pods/country/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    ibanSymbol: attr('string'),
    alternativeIsoCodes: attr('list'),
    name: attr('string'),
    phonePrefix: attr('string'),
    searchFieldCountry: function () {
      return this.get('name') + this.get('ibanSymbol');
    }.property(''),
    isWithoutPostalCode: attr('boolean'),
    voivodeships: attr('list')
  });

  _exports.default = _default;
});