define("apollo/pods/groups-settings/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    actions: {
      save: function () {
        const self = this;
        const principalContractorGroupType = this.get('model.principalContractorGroupTypes');
        principalContractorGroupType.forEach(pcgt => {
          pcgt.save().then(() => {
            self.set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
            self.set('errors', []);
            scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }).catch(error => {
            console.error(error);
          });
        });
      }
    }
  });

  _exports.default = _default;
});