define("apollo/pods/components/announcements-nav-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pull-right'],
    sessionAccount: Ember.inject.service('session-account'),
    showNavbar: Ember.computed(function () {
      return this.get('sessionAccount').hasRole("ROLE_MANAGE_ANNOUNCEMENTS");
    })
  });

  _exports.default = _default;
});