define("apollo/pods/forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fZKQcCTO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"centered-section\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[27,\"t\",[\"wrongUrl\"],null],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/forbidden/template.hbs"
    }
  });

  _exports.default = _default;
});