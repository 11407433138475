define("apollo/services/time-window-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    store: Ember.inject.service(),

    getFirstPossibleWindowStart(step, transport, selectedDate) {
      const warehouseId = step.get('warehouse.id');
      const self = this;
      return new Promise(function (resolve) {
        const data = {
          transportCommand: transport.serialize()
        };
        data.warehouse = {
          id: warehouseId
        };

        if (selectedDate) {
          data.selectedDate = selectedDate;
        }

        if (step.get('minAdviceDate')) {
          data.minDate = moment(step.get('minAdviceDate')).format();
          data.maxDate = moment(step.get('maxAdviceDate')).format();
        }

        const jsonBody = JSON.stringify(data);
        const path = self.get('apolloApiService').APOLLO_API.TRANSPORTS.FIRST_POSSIBLE_START;
        self.get('apolloApiService').callApolloApi(path, null, {
          body: jsonBody
        }).then(function (response) {
          return response.json();
        }).then(data => {
          resolve(data);
        });
      });
    },

    saveWindow(ramp, company, start, stop, userGroup) {
      const state = company || userGroup ? 'state-dedicated' : 'state-disabled';
      const windowData = {
        ramp,
        company,
        start,
        stop,
        state,
        userGroup,
        initialStart: start,
        firstSavedStart: start
      };
      const window = this.store.createRecord('timeWindow', windowData);
      return window.save();
    }

  });

  _exports.default = _default;
});