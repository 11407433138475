define("apollo/pods/transaction/serializer", ["exports", "ember-data", "apollo/mixins/serializer-mixin"], function (_exports, _emberData, _serializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _serializerMixin.default, {
    serialize(snapshot, options) {
      const json = this._super(...arguments);

      const resultJson = {};
      resultJson.transaction = json;
      const deliveries = snapshot.record.get('transport.deliveries');
      resultJson.deliveries = deliveries.map(d => d.serialize());
      return resultJson;
    }

  });

  _exports.default = _default;
});