define("apollo/pods/components/trim-long-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elipsis: '(...)',
    tooLong: Ember.computed('text.length', 'maxLength', function () {
      return this.get('text.length') > this.get('maxLength');
    }),
    spacedText: Ember.computed('text', function () {
      if (!this.get('text')) {
        return '';
      }

      return this.insertSpaces(this.get('text'));
    }),
    trimmedText: Ember.computed('spacedText', 'maxLength', function () {
      if (!this.get('spacedText')) {
        return '';
      }

      return this.get('spacedText').substring(0, this.get('maxLength')) + this.get('elipsis');
    }),
    insertSpaces: function (text) {
      let result = text;
      ['\\.', ',', ';'].forEach(breakChar => {
        const regex = new RegExp(`${breakChar}(?=[^\s])`, 'g');
        result = result.replace(regex, `${breakChar} `);
      });
      return result;
    }
  });

  _exports.default = _default;
});