define("apollo/pods/components/classification-resource/place-assignment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    store: Ember.inject.service(),
    shouldButtonBeDisabled: Ember.computed('resource.id', function () {
      return this.get('resource.place.id') || !this.get('resource.active');
    }),
    buttonClass: Ember.computed('resource.id', function () {
      if (this.get('resource.state') || !this.get('resource.transport') || this.get('shouldButtonBeDisabled')) {
        return 'btn dark-font';
      }

      return 'btn';
    }),

    sendAssignPlace(resource, place) {
      resource.set('place', place);
      const assignPlaceData = JSON.stringify({
        classificationResource: resource,
        checkpointToConfirm: resource.get('transport.firstUnconfirmedCheckpoint.id')
      });
      const path = this.get('apolloApiService').APOLLO_API.CLASSIFICATION_RESOURCES.ASSIGN_PLACE;
      this.get('apolloApiService').callApolloApi(path, null, {
        body: assignPlaceData
      }).then(() => {
        resource.reload();
        $('.assign-place-button').button('reset');
      });
    },

    sendReserveClassificationResource(resource) {
      const path = this.get('apolloApiService').APOLLO_API.CLASSIFICATION_RESOURCES.RESERVE;
      this.get('apolloApiService').callApolloApi(path, null, {
        body: JSON.stringify(resource)
      }).then(response => {
        return response.json();
      }).then(data => {
        this.store.findRecord('classification-resource', data.classificationResource.id);
        $('.assign-place-button').button('reset');
      });
    },

    actions: {
      assignPlace(resource, place) {
        const self = this;
        const isDummyResource = resource.get('transport') === null;

        if (isDummyResource) {
          resource = {
            enabled: true,
            queue: place.get('queue.id'),
            place: place.get('id')
          };
          self.sendReserveClassificationResource(resource);
        } else {
          self.sendAssignPlace(resource, place);
        }
      }

    }
  });

  _exports.default = _default;
});