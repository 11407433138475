define("apollo/mixins/ramps-access-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    //todo: nie lepiej byłoby przerobić to na component?
    sessionAccount: Ember.inject.service('session-account'),
    warehouses: Ember.computed('sessionAccount.currentUser.accessibleWarehouses.@each', 'model.defaultCompanyWarehouses.@each', function () {
      return !this.get('model.defaultCompanyWarehouses') || this.get('SHOW_ALL_WAREHOUSES') ? this.get('sessionAccount.currentUser.accessibleWarehouses') : this.get('model.defaultCompanyWarehouses').uniqBy('id');
    }),
    // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
    proxiedWarehouses: Ember.computed('warehouses.@each', 'proxiedRamps.@each', function () {
      const self = this;
      const defaultChecked = this.get("CHECKBOXES_DEFAULT_CHECKED") !== undefined ? this.get("CHECKBOXES_DEFAULT_CHECKED") : true;
      const defaultCompanyWarehouses = this.get('model.defaultCompanyWarehouses');
      return this.get('warehouses').map(w => {
        const proxiedRamps = self.get('proxiedRamps').filter(r => {
          return r.get('warehouse.id') === w.get('id');
        });
        return Ember.ObjectProxy.create({
          content: w,
          proxiedRamps: proxiedRamps,
          checked: this.get('model.defaultCompanyWarehouses') && this.get('SHOW_ALL_WAREHOUSES') ? this.get('model.defaultCompanyWarehouses').getEach('id').includes(w.get('id')) : defaultChecked
        });
      });
    }),
    accessibleRamps: Ember.computed('sessionAccount.currentUser.accessibleRamps.@each', 'model.defaultCompanyRamps.@each', function () {
      return !this.get('model.defaultCompanyRamps') || this.get('SHOW_ALL_WAREHOUSES') ? this.get('sessionAccount.currentUser.accessibleRamps') : this.get('model.defaultCompanyRamps');
    }),
    proxiedRamps: Ember.computed.map('accessibleRamps', function (model) {
      const defaultChecked = this.get("CHECKBOXES_DEFAULT_CHECKED") !== undefined ? this.get("CHECKBOXES_DEFAULT_CHECKED") : true;
      return Ember.ObjectProxy.create({
        content: model,
        checked: this.get('model.defaultCompanyWarehouses') && this.get('SHOW_ALL_WAREHOUSES') ? false : defaultChecked
      });
    }),
    proxiedCheckedRamps: Ember.computed.filterBy('proxiedRamps', 'checked', true),
    checkedRamps: Ember.computed.mapBy('proxiedCheckedRamps', 'content'),
    actions: {
      toggleWarehouse: function (warehouse) {
        this.get('proxiedWarehouses').forEach(w => {
          if (w.get('id') === warehouse.get('id')) {
            w.set('checked', !w.get('checked'));
            const isWarehouseChecked = w.get('checked');
            w.get('proxiedRamps').forEach(r => r.set('checked', isWarehouseChecked));
          }
        });
      }
    }
  });

  _exports.default = _default;
});