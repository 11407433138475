define("apollo/services/polling/schedule-service", ["exports", "apollo/mixins/schedule-mixin", "apollo/mixins/persistence-mixin"], function (_exports, _scheduleMixin, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_scheduleMixin.default, _persistenceMixin.default, {
    store: Ember.inject.service(),
    websocket: Ember.inject.service(),
    // TODO: Zastąpić przez apolloApiApollo
    ajaxService: Ember.inject.service('ajax-service'),

    useSockets(warehouseId, context) {
      const schedule = context;
      const self = this;
      schedule.setWindows();
      const webSocket = this.get('websocket').getSocket();
      webSocket.subscribe(`/topic/schedule/update/${warehouseId}`, message => {
        // TODO To jest do przeobienia jeśli zdecydujemy się używać websocketów "na pełen etat"...
        const timeWindowId = JSON.parse(message.body); // Jeśli tego nie zrobimy to obiekt warehouse w ScheduleMixin będzie undefined i metoda setPalletSummary rzuci błąd
        // ogólnie do przerobienia jak będziemy dalej chcieli używać websocketów

        const warehouse = self.get('store').peekRecord('warehouse', warehouseId);
        self.set('warehouse', warehouse);
        const cachedWindow = self.get('store').peekRecord('time-window', timeWindowId);

        if (cachedWindow && cachedWindow.isDeleted) {
          // A-9667
          // W momencie usuwania blokady próba pobrania modelu z backendu przez findRecord wywalała błąd:
          // Attempted to handle event 'loadingData' on <time-window> while in state root.deleted.inFlight
          // Dlatego przed zrobieniem findRecord sprawdzamy, czy obiekt który chcemy pobrać istnieje już w store,
          // i jeśli istnieje i jest przeznaczony do usuniecia, przerywamy operacje.
          return;
        }

        self.get('store').findRecord('time-window', timeWindowId, {
          reload: true
        }).then(window => {
          if (!window.get('isBlocked')) {
            schedule.get('shownDays').forEach(day => {
              this.setPalletSummary(day);
              this.setNumberOfWindows(day);
            });
          }

          const openTransportId = schedule.get('openTransportId');

          if (window.get('transport.id')) {
            console.debug(`Refreshing window ${window.get('logString')} for transport ${window.get('transport').get('logString')}..`);
          } else {
            console.debug(`Refreshing blockade window ${window.get('logString')}..`);
          }

          const deleted = window.get('deleted');

          if (deleted) {
            schedule.get('windows').removeObject(window);
            console.log(`Removed window #${window.id}.`);
          } else if (!window.get('isSaving')) {
            window.setProperties({
              start: moment(window.get('data.start')).toDate(),
              stop: moment(window.get('data.stop')).toDate(),
              ramp: schedule.store.peekRecord('ramp', window.get('ramp.id'))
            });
            window.get('step').then(function (step) {
              if (step) {
                const transportIdToFetch = window.get('relatedTransport.id') ? window.get('relatedTransport.id') : window.get('transport.id');
                self.get('store').findRecord('transport', transportIdToFetch).then(function (transport) {
                  const dirtyCustomFieldOptions = openTransportId ? self.getPreviousCustomFieldValuesMap(transport.get('id')) : [];
                  transport.reload().then(function (reloadedTransport) {
                    console.debug(`Unloading checkpoints from transport ${window.get('transport.id')}..`);
                    self.unloadDeletedRecords('transport', window.get('transport.id'), 'checkpoint');
                    transport.get('indexes').forEach(index => {
                      if (index.get('id') && index.get('currentState.stateName') !== 'root.loading') {
                        index.reload();
                      }
                    });

                    if (dirtyCustomFieldOptions && openTransportId === transport.get('id')) {
                      self.markDirtyCustomFieldValues(transport, dirtyCustomFieldOptions, reloadedTransport.get('customFields'));
                    }

                    console.debug(`Odświeżyliśmy okno czasowe:: #${window.id} / ${moment(window.get('start')).format('DD.MM.YYYY HH:mm')}`); // Dane wyświetlane w oknach (awizacja, indeksy) wysyłane są z serwera
                    // w mapie okna, musimy wymusić odświeżenie danych w komponencie okna
                    // dopiero po otrzymaniu aktualnych danych o transporcie.

                    window.set('lastUpdated', new Date());
                    self.replaceTimeWindow(window);
                  });
                });
              } else {
                schedule.replaceTimeWindow(window);
              }
            });
          }
        }).catch(e => {
          console.error(e);
        });
      });
    },

    usePolling(params, context) {
      const warehouseId = params.warehouseId;
      const currentRampsIds = params.currentRampsIds;
      const schedule = context;
      const self = this;

      try {
        const lastPollFormatted = moment(schedule.get('lastPoll')).format();
        this.get('ajaxService').send(`/warehouses/${warehouseId}/updates`, 'GET', {
          lastPoll: lastPollFormatted,
          start: moment(schedule.get('scheduleStartDate')).format(),
          stop: moment(schedule.get('scheduleEndDate')).format()
        }).then(json => {
          if (json.timeWindowIds.length) {
            const warehouse = self.get('store').peekRecord('warehouse', warehouseId);
            self.set('warehouse', warehouse);
            schedule.get('shownDays').forEach(day => {
              self.setPalletSummary(day);
              self.setNumberOfWindows(day);
            });
          }

          const openTransportId = schedule.get('openTransportId');
          json.timeWindowIds.forEach(function (windowId) {
            self.get('store').findRecord('timeWindow', windowId, {
              reload: true
            }).then(function (window) {
              if (!currentRampsIds.includes(window.get('ramp.id').toString())) {
                return;
              }

              if (window.get('transport.id')) {
                console.debug(`Refreshing window ${window.get('logString')} for transport ${window.get('transport').get('logString')}..`);
              } else {
                console.debug(`Refreshing blockade window ${window.get('logString')}..`);
              }

              const deleted = window.get('deleted');

              if (deleted) {
                schedule.get('windows').removeObject(window);
                console.log(`Removed window #${window.id}.`);
              } else if (!window.get('isSaving')) {
                window.setProperties({
                  start: moment(window.get('data.start')).toDate(),
                  stop: moment(window.get('data.stop')).toDate(),
                  ramp: schedule.store.peekRecord('ramp', window.get('ramp.id'))
                });
                window.get('step').then(function (step) {
                  if (step) {
                    const transportIdToFetch = window.get('relatedTransport.id') ? window.get('relatedTransport.id') : window.get('transport.id');
                    self.get('store').findRecord('transport', transportIdToFetch).then(function (transport) {
                      const dirtyCustomFieldOptions = openTransportId ? self.getPreviousCustomFieldValuesMap(transport.get('id')) : [];
                      transport.reload().then(function (reloadedTransport) {
                        console.debug(`Unloading checkpoints from transport ${window.get('transport.id')}..`);
                        self.unloadDeletedRecords('transport', window.get('transport.id'), 'checkpoint');
                        transport.get('indexes').forEach(index => {
                          if (index.get('id') && index.get('currentState.stateName') !== 'root.loading') {
                            index.reload();
                          }
                        });

                        if (dirtyCustomFieldOptions && openTransportId === transport.get('id')) {
                          self.markDirtyCustomFieldValues(transport, dirtyCustomFieldOptions, reloadedTransport.get('customFields'));
                        }

                        console.debug(`Odświeżyliśmy okno czasowe:: #${window.id} / ${moment(window.get('start')).format('DD.MM.YYYY HH:mm')}`); // Dane wyświetlane w oknach (awizacja, indeksy) wysyłane są z serwera
                        // w mapie okna, musimy wymusić odświeżenie danych w komponencie okna
                        // dopiero po otrzymaniu aktualnych danych o transporcie.

                        window.set('lastUpdated', new Date());
                        self.replaceTimeWindow(window);
                      });
                    });
                  } else {
                    self.replaceTimeWindow(window);
                  }
                });
              }
            });
          });
          const pollTime = json.pollTime;
          schedule.set('lastPoll', pollTime);
        });
      } catch (e) {
        console.error(e);
      }
    }

  });

  _exports.default = _default;
});