define("apollo/pods/components/transports/netpc-report-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I9cw6Vfn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isSuperUser\"]]],null,{\"statements\":[[0,\"    \"],[7,\"form\"],[11,\"class\",\"fa-pull-right\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[23,[\"dashboardName\"]],\"form-control\",\"Dashboard Name\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-button-name\",\"netpc-report\"],[9],[0,\"\\n            Raport NetPC\\n        \"],[3,\"action\",[[22,0,[]],\"goToReport\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transports/netpc-report-button/template.hbs"
    }
  });

  _exports.default = _default;
});