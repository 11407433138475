define("apollo/pods/transport-types/edit/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    actions: {
      cancel() {
        this.transitionToRoute('settings.index');
      },

      sortEndAction() {
        let dataToSend = [];
        this.get('model.sortedFieldDefinitions').forEach((cfd, idx) => {
          cfd.set('idx', idx);
          dataToSend.pushObject({
            id: cfd.get('id'),
            idx: cfd.get('idx')
          });
        });
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        Ember.$.ajax({
          url: _environment.default.serverURL + '/custom-field-definitions/update-ordering',
          type: 'PUT',
          data: JSON.stringify(dataToSend),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(() => {
          console.log('Zedytowano kolejność custom fieldów');
        });
      }

    }
  });

  _exports.default = _default;
});