define("apollo/pods/transport-types/package-type-settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errors: [],
    packageTypeTransportTypes: Ember.computed('model.packageTypeTransportTypes', function () {
      return this.get('model.packageTypeTransportTypes').sortBy('idx');
    }),
    referencePackage: Ember.computed('model.packageTypeTransportTypes', function () {
      let refPckg = null;
      const pt = this.get('store').peekAll('packageTypeTransportType').find(pt => pt.get('referencePackage'));

      if (pt) {
        refPckg = pt.get('packageType');
      }

      return refPckg;
    }),
    selectedPackageTypeTransportType: Ember.computed('packageTypeTransportTypes.{firstObject,length}', function () {
      return this.get('packageTypeTransportTypes.firstObject');
    }),
    actions: {
      setPackageTypeTransportType(pttt) {
        this.set('selectedPackageTypeTransportType', pttt);
        this.set('errors', []);
        this.set('successMessage', null);
      },

      add() {
        const pttt = this.get('store').createRecord('packageTypeTransportType', {
          transportType: this.get('model.transportType')
        });
        this.get('packageTypeTransportTypes').pushObject(pttt);
        this.set('selectedPackageTypeTransportType', pttt);
      },

      cancel(pttt) {
        this.get('packageTypeTransportTypes').removeObject(pttt);
        pttt.unloadRecord();
        this.set('selectedPackageTypeTransportType', this.get('packageTypeTransportTypes.firstObject'));
        this.set('errors', []);
        this.set('successMessage', null);
      },

      delete(pttt) {
        const ptttId = pttt.get('id');
        pttt.destroyRecord().then(() => {
          const objectToRemove = this.get('packageTypeTransportTypes').find(pttt => pttt.get('id') === ptttId);
          this.get('packageTypeTransportTypes').removeObject(objectToRemove);
          this.set('selectedPackageTypeTransportType', this.get('packageTypeTransportTypes.firstObject'));
        }).catch(response => {
          this.set('errors', response.errors);
        });
      }

    }
  });

  _exports.default = _default;
});