define("apollo/pods/components/by-whom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HQA1rua",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"person\",\"id\"]],[23,[\"sessionAccount\",\"currentUser\",\"id\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"common.byYou\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"common.by\"],null],false],[0,\" \"],[1,[23,[\"person\",\"name\"]],false],[0,\" \"],[1,[27,\"t\",[\"common.fromCompany\"],null],false],[0,\" \"],[1,[23,[\"person\",\"company\",\"shortName\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/by-whom/template.hbs"
    }
  });

  _exports.default = _default;
});