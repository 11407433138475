define("apollo/pods/role-groups/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_ROLE_GROUP'],
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function () {
      const principalCompanyId = localStorage.getItem('principalId');
      const principalCompany = this.store.peekRecord('company', principalCompanyId);
      const isSuperAdmin = this.get('sessionAccount').get('currentUser.roleGroup.name') === 'SUPER_USER';
      const roleGroup = this.store.createRecord('roleGroup', {
        principalCompany: principalCompany
      });
      let roleGroupsToCopyFrom = this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId).filter(roleGroup => roleGroup.get('name') !== 'E-MAIL_ADVICE');

      if (!isSuperAdmin) {
        roleGroupsToCopyFrom = roleGroupsToCopyFrom.filter(roleGroup => roleGroup.get('name') !== 'SUPER_USER');
      }

      const partnershipTypes = this.store.peekAll('partnershipType');
      return Ember.RSVP.hash({
        roleGroup: roleGroup,
        roleGroupsToCopyFrom: roleGroupsToCopyFrom,
        partnershipTypes: partnershipTypes
      });
    },
    actions: {
      willTransition: function () {
        const controllerRoleGroupModel = this.controller.get('model.roleGroup');
        this.controllerFor('role-groups.create').setProperties({
          errors: []
        });

        if (controllerRoleGroupModel) {
          controllerRoleGroupModel.unloadRecord();
        }

        console.log('Użytkownik cofnął stronę, anulujemy process tworzenia nowej roli...');
        return true;
      }
    }
  });

  _exports.default = _default;
});