define("apollo/models/checkpoint-confirmation-modal-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents a model passed for checkpoint-confirmation-modal component.
   * @class CheckpointConfirmationModal
   * @extends EmberObject
   * @property {Model} checkpoint - The model checkpoint being confirmed.
   * @property {Model} weight - The weight information associated with the checkpoint.
   * @property {boolean} isOverWeight - Indicates whether the weight exceeds the limit.
   */
  const CheckpointConfirmationModal = Ember.Object.extend({
    checkpoint: null,
    weight: null,
    isOverWeight: null
  });
  var _default = CheckpointConfirmationModal;
  _exports.default = _default;
});