define("apollo/pods/secondary-section/model", ["exports", "ember-data", "apollo/pods/section/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelValidator.default, {
    bankFiles: _emberData.default.hasMany('file', {
      async: true
    }),
    othersFiles: _emberData.default.hasMany('file', {
      async: true
    }),
    partnership: _emberData.default.belongsTo('partnership', {
      async: true,
      deepEmbedded: ['contractorCompany']
    }),
    bankPatternFileENG: _emberData.default.belongsTo('file', {
      async: true
    }),
    bankPatternFilePL: _emberData.default.belongsTo('file', {
      async: true
    }),
    internalNotes: _emberData.default.attr('string'),
    bankAccountNumber: _emberData.default.attr('string'),
    validations: {
      'contractorNumber': {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('partnership.sessionAccount.currentUser.isPrincipal')) {
              return model.get('partnership.contractorNumber');
            }

            return true;
          },
          message: 'blank'
        }]
      },
      bankAccountNumber: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});