define("apollo/pods/components/amount-of-cover-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    currencies: Ember.computed(function () {
      return this.get('store').peekAll('currency');
    }),
    aocAmount: Ember.computed('amountOfCover.amount', {
      get() {
        return this.get('amountOfCover.amount') || 0.0;
      },

      set(key, value) {
        this.set('amountOfCover.amount', value);
        return value;
      }

    }),
    aocCurrency: Ember.computed('amountOfCover.currency', {
      get() {
        return this.get('amountOfCover.currency');
      },

      set(key, value) {
        this.set('amountOfCover.currency', value);
        return value;
      }

    })
  });

  _exports.default = _default;
});