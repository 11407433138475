define("apollo/pods/warehouses/combined-schedule/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor('warehouses.schedule').setProperties({
        warehouseStepToHandle: this.store.peekRecord('step', params.step_id)
      });
      this.transitionTo('warehouses.schedule', params.warehouse_id, params.date_string, params.delta);
    }

  });

  _exports.default = _default;
});