define("apollo/pods/components/related-to-labels-has-to-wait/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DnwTdX10",
    "block": "{\"symbols\":[\"transport\",\"transportLabel\",\"transport\"],\"statements\":[[1,[21,\"title\"],false],[0,\"\\n\"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isMasterTransport\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"relatedTransports\"]]],null,{\"statements\":[[4,\"if\",[[22,3,[\"completed\"]]],null,{\"statements\":[[0,\"                \"],[7,\"s\"],[11,\"href\",\"#\"],[9],[0,\"\\n                    \"],[1,[22,3,[\"relatedToLabel\"]],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"showTransportModal\",[22,3,[\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n                    \"],[1,[22,3,[\"relatedToLabel\"]],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"showTransportModal\",[22,3,[\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3]},null],[4,\"each\",[[23,[\"relatedTransportsLabelsOnly\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,[\"masterTransports\"]]],null,{\"statements\":[[0,\"            \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n                \"],[1,[22,1,[\"relatedToLabel\"]],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"showTransportModal\",[22,1,[\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/related-to-labels-has-to-wait/template.hbs"
    }
  });

  _exports.default = _default;
});