define("apollo/pods/truck/model", ["exports", "ember-data", "apollo/mixins/model-validator", "apollo/utils/validators/registration-number-validator"], function (_exports, _emberData, _modelValidator, _registrationNumberValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    dateUtils: Ember.inject.service('date-utils'),
    intl: Ember.inject.service(),
    STATUS: {
      AWAITING_VERIFICATION: 'AWAITING_VERIFICATION',
      ACCEPTED: 'ACCEPTED',
      UPDATED: 'UPDATED',
      REJECTED: 'REJECTED',
      BLOCKED: 'BLOCKED',
      DISABLED: 'DISABLED',
      DOCUMENT_ADDED: 'DOCUMENT_ADDED'
    },
    owner: _emberData.default.belongsTo('company', {
      async: true
    }),
    whoVerified: _emberData.default.belongsTo('user', {
      async: true
    }),
    truckType: _emberData.default.belongsTo('truck-type', {
      async: true
    }),
    vehicleMainSection: _emberData.default.belongsTo('vehicle-main-section', {
      async: true
    }),
    registrationNumber: _emberData.default.attr('string'),
    trailer: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    hds: _emberData.default.attr('boolean'),
    isModeOfTransportation: _emberData.default.attr('boolean'),
    verificationCompleted: _emberData.default.attr('boolean'),
    documents: _emberData.default.hasMany('documents', {
      async: true
    }),
    status: _emberData.default.attr('string'),
    verificationDate: _emberData.default.attr('isodate'),
    enabled: _emberData.default.attr('boolean'),
    lastUpdated: _emberData.default.attr('isodate'),
    validations: {
      registrationNumber: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function (key, value) {
            return !value || _registrationNumberValidator.default.validate(value);
          },
          message: 'regex'
        }]
      },
      trailer: {
        custom: [{
          validation: function (key, value) {
            return !value || _registrationNumberValidator.default.validate(value);
          },
          message: 'regex'
        }]
      }
    },
    acceptedDocuments: Ember.computed('documents.@each.{id,isAccepted}', function () {
      return this.get('documents').filterBy('id').filterBy('isAccepted');
    }),
    activeDocuments: Ember.computed('documents.@each.{id,isActive,expirationDate,blocksAdviceWhenInvalid,state}', function () {
      return this.get('documents').filterBy('id').filterBy('isActive');
    }),
    archivedDocuments: Ember.computed('documents.@each.{id,isActive}', function () {
      return this.get('documents').filterBy('id').filterBy('isActive', false);
    }),
    lastSensorCalibrationCertificate: Ember.computed('activeDocuments.@each.id', function () {
      return this.findLastDocumentWithType('SENSOR_CALIBRATION_CERTIFICATE', 'expirationDate');
    }),
    lastSummerMapping: Ember.computed('activeDocuments.@each.id', function () {
      return this.findLastDocumentWithType('SUMMER_MAPPING', 'expirationDate');
    }),
    lastWinterMapping: Ember.computed('activeDocuments.@each.id', function () {
      return this.findLastDocumentWithType('WINTER_MAPPING', 'expirationDate');
    }),
    lastSensorCalibrationCertificateDate: Ember.computed('activeDocuments.@each.id', function () {
      return this.findLastDocumentDateWithType('SENSOR_CALIBRATION_CERTIFICATE', 'expirationDate');
    }),
    lastSummerMappingDate: Ember.computed('activeDocuments.@each.id', function () {
      return this.findLastDocumentDateWithType('SUMMER_MAPPING', 'expirationDate');
    }),
    lastWinterMappingDate: Ember.computed('activeDocuments.@each.id', function () {
      return this.findLastDocumentDateWithType('WINTER_MAPPING', 'expirationDate');
    }),
    statusName: Ember.computed('status', function () {
      const key = 'modeOfTransportation.status.'.concat(this.get('status'));
      return this.get('intl').t(key);
    }),

    findLastDocumentDateWithType(type, sortProperty) {
      const lastDocument = this.findLastDocumentWithType(type, sortProperty);
      return lastDocument ? lastDocument.get(sortProperty) : null;
    },

    findLastDocumentWithType(type, sortProperty) {
      return this.get('activeDocuments').filter(dt => {
        return dt.get('name') === type && dt.get('state') === this.STATUS.ACCEPTED;
      }).sortBy(sortProperty).get('lastObject');
    },

    disabledReason: Ember.computed('statusName', 'activeDocuments.@each.state', 'cannotBeChosenInfo', function () {
      const stateLabel = this.get('intl').t('modeOfTransportation.disabledReason.label', {
        status: this.get('statusName')
      });
      const activeDocuments = this.get('activeDocuments');
      const rejectedSections = activeDocuments.filter(d => d.get('state') === this.STATUS.REJECTED).map(d => d.get('translatedName'));
      const blockedSections = activeDocuments.filter(d => d.get('state') === this.STATUS.BLOCKED).map(d => d.get('translatedName'));
      const rejectedLabel = rejectedSections.get('length') > 0 ? '\n' + this.get('intl').t('modeOfTransportation.disabledReason.rejectedSections', {
        rejectedSections: rejectedSections.join(', ')
      }) : '';
      const blockedLabel = blockedSections.get('length') > 0 ? '\n' + this.get('intl').t('modeOfTransportation.disabledReason.blockedSections', {
        blockedSections: blockedSections.join(', ')
      }) : '';
      const cannotBeChosenInfo = this.get('cannotBeChosenInfo') || '';
      return stateLabel + rejectedLabel + blockedLabel + cannotBeChosenInfo;
    }),
    documentBlockingAdvice: Ember.computed('activeDocuments.@each.blocksAdviceWhenInvalid', 'activeDocuments.@each.expirationDate', function () {
      const documents = this.get('activeDocuments').filter(d => {
        return d.get('blocksAdviceWhenInvalid') && d.get('state') === this.STATUS.ACCEPTED;
      });
      const documentTypes = this.get('activeDocuments').getEach('name').uniq();
      const consideredDocuments = [];

      for (let i = 0; i < documentTypes.length; i++) {
        const documentsWithType = documents.filter(d => d.get('name') === documentTypes[i]);
        const documentWithMaxDate = documentsWithType.sortBy('expirationDate').get('lastObject');
        consideredDocuments.push(documentWithMaxDate);
      }

      return consideredDocuments.sortBy('expirationDate').get('firstObject');
    }),
    minValidityDateOfDocumentsBlockingAdvice: Ember.computed('documentBlockingAdvice', 'documentBlockingAdvice.expirationDate', function () {
      return this.get('documentBlockingAdvice.expirationDate');
    }),
    truckCannotBeChosenInAdvice: Ember.computed('minValidityDateOfDocumentsBlockingAdvice', 'tmpTransportDate', function () {
      const minValidityDate = this.get('minValidityDateOfDocumentsBlockingAdvice');
      const maxTransportDate = this.get('tmpTransportDate');
      return minValidityDate < maxTransportDate;
    }),

    /**
     * Musimy to nazwać `disabled`, żeby power-select blokował tę opcję
     */
    disabled: Ember.computed('status', 'truckCannotBeChosenInAdvice', function () {
      return [this.get('STATUS.REJECTED'), this.get('STATUS.UPDATED'), this.get('STATUS.BLOCKED')].includes(this.get('status')) || this.get('truckCannotBeChosenInAdvice');
    }),
    cannotBeChosenInfo: Ember.computed('disabled', 'minValidityDateOfDocumentsBlockingAdvice', 'documentBlockingAdvice.translatedName', 'tmpTransportDate', function () {
      if (!this.get('disabled')) {
        return '';
      }

      const minValidityDate = moment(this.get('minValidityDateOfDocumentsBlockingAdvice')).format('DD.MM.YYYY');
      const blockingDocument = this.get('documentBlockingAdvice.translatedName');
      const maxTransportDate = moment(this.get('tmpTransportDate')).format('DD.MM.YYYY');
      return '\n' + this.get('intl').t('modeOfTransportation.disabledReason.blockingDocument', {
        minValidityDate,
        blockingDocument,
        maxTransportDate
      });
    }),
    statusIcon: Ember.computed('status', function () {
      switch (this.get('status')) {
        case this.get('STATUS').AWAITING_VERIFICATION:
          return 'hourglass-start';

        case this.get('STATUS').UPDATED:
          return 'warning';

        case this.get('STATUS').ACCEPTED:
          return 'check-circle';

        case this.get('STATUS').REJECTED:
          return 'frown';

        case this.get('STATUS').BLOCKED:
          return 'minus-circle';

        case this.get('STATUS').DISABLED:
          return 'ban';
      }
    }),
    statusClass: Ember.computed('status', function () {
      if ([this.get('STATUS').REJECTED, this.get('STATUS').BLOCKED].includes(this.get('status'))) {
        return 'errors';
      }

      if ([this.get('STATUS').ACCEPTED].includes(this.get('status'))) {
        return 'success';
      }

      if ([this.get('STATUS').AWAITING_VERIFICATION].includes(this.get('status'))) {
        return 'info';
      }

      if ([this.get('STATUS').UPDATED].includes(this.get('status'))) {
        return 'warning';
      }

      if ([this.get('STATUS').DISABLED].includes(this.get('status'))) {
        return 'text-dark padding-left';
      }
    })
  });

  _exports.default = _default;
});