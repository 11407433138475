define("apollo/pods/components/auction/auction-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lmVNdtlp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"padding-bottom\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bold\"],[9],[0,\"\\n        \"],[1,[27,\"format-date\",[[23,[\"auction\",\"dateCreated\"]]],null],false],[0,\" -\\n\\n        \"],[7,\"span\"],[11,\"class\",\"no-wrap\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"auction\",\"actualFinishTime\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"format-date\",[[23,[\"auction\",\"actualFinishTime\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[27,\"t\",[\"auction.plannedFinishTimeAbbr\"],null],false],[0,\" \"],[1,[27,\"format-date\",[[23,[\"auction\",\"plannedFinishTime\"]],\"DD.MM.YYYY HH:mm:ss\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"auction\",\"hasBeenProlongated\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"small\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"auction.finishTimeChanged\"],[[\"date\"],[[27,\"format-date\",[[23,[\"auction\",\"initialFinishTime\"]]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"small\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"auction.priceRange\"],null],false],[0,\":\\n\\n\"],[4,\"if\",[[23,[\"auction\",\"minPrice\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,[\"auction\",\"minPrice\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"common.notSpecified\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n        - \"],[1,[23,[\"auction\",\"maxPrice\"]],false],[0,\"\\n\\n        \"],[1,[23,[\"auction\",\"currency\",\"symbol\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/auction/auction-header/template.hbs"
    }
  });

  _exports.default = _default;
});