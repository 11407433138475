define("apollo/utils/objects/Filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FilterBuilder {
    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    setSelectType() {
      this.type = 'select';
      return this;
    }

    setDateType() {
      this.type = 'date';
      return this;
    }

    setDateRangeType() {
      this.type = 'date-range';
      return this;
    }

    setCustomFieldType() {
      this.type = 'custom-field';
      return this;
    }

    setUseArchive() {
      this.useArchive = true;
      return this;
    }

    setName(name) {
      this.name = name;
      return this;
    }

    setNamePrefix(prefix) {
      this.prefix = prefix;
      return this;
    }

    setValue(value) {
      this.value = value;
      return this;
    }

    setKeys(keys) {
      this.keys = keys;
      return this;
    }

    setSelectOptionsType(selectOptionsType) {
      this.selectOptionsType = selectOptionsType;
      return this;
    }

    shouldNotBeRendered() {
      this.shouldBeRendered = false;
      return this;
    }

    setClass(cssClass) {
      this.cssClass = cssClass;
      return this;
    }

    build() {
      return Filter.create({
        prefix: this.prefix !== undefined ? this.prefix : '',
        name: this.name !== undefined ? this.name : '',
        keys: this.keys,
        type: this.type,
        selectOptionsType: this.selectOptionsType,
        useArchive: this.useArchive !== undefined ? this.useArchive : false,
        shouldBeRendered: this.shouldBeRendered !== undefined ? this.shouldBeRendered : true,
        cssClass: this.cssClass !== undefined ? this.cssClass : ''
      });
    }

  }

  const Filter = Ember.Object.extend({
    /*
        Nazwa filtra. Domyślnie pusty string.
    */
    name: '',

    /*
        Prefiks do nazwy filtra. Domyślnie pusty string.
    */
    prefix: '',

    /*
        Pełna nazwa filtra - prefix + name.
    */
    fullName: Ember.computed(function () {
      return this.get('prefix') + this.get('name');
    }),

    /*
        Dodatkowe klasy css.
    */
    cssClass: '',

    /*
        Obecna wartość filtra.
    */
    value: Ember.computed({
      get() {
        if (this.get('cacheable')) {
          return this.get('cachedValue');
        }

        return null;
      },

      set(key, value) {
        if (this.get('cacheable')) {
          this.set('cachedValue', value);
        }

        return value;
      }

    }),

    /*
        Klucze wykorzystywane na serwerze do filtrowania transportów.
    */
    keys: null,

    /*
        Typ filtra:
            - custom-field
            - select
            - date
    */
    type: Ember.computed({
      get() {
        if (this.get('cacheable')) {
          return this.get('cachedType');
        }

        return null;
      },

      set(key, value) {
        if (this.get('cacheable')) {
          this.set('cachedType', value);
        }

        return value;
      }

    }),

    /*
        Określa czy wartość filtra zostanie zapisana w pamięci lokalnej przeglądarki użytkownika.
        Domyślnie `true`.
    */
    cacheable: Ember.computed({
      get() {
        if (localStorage.getItem('cacheFilters') === 'false') {
          return false;
        }

        return true;
      },

      set(key, value) {
        return value;
      }

    }),

    /*
        W przypadku typu filtra `select` ta zmienna określa to, jakie wartości powinny być wyświetlone na liście wyboru filtra.
    */
    selectOptionsType: null,

    /*
        Określa czy przekazujemy `archive` jako parametr do komponentu filter.
        Domyślnie `false`.
        UWAGA: Nie do końca rozumiem, do czego służy parametr `archive`,
        ale bez niego nie działają selecty (nie wyświetlamy listy wartości).
    */
    useArchive: false,

    /*
        Określa czy wyświetlamy pole filtra. W przypadku `false` w miejsce filtra wpisujemy &nbsp.
        Domyślnie `true`.
    */
    shouldBeRendered: true,
    cachedValue: Ember.computed({
      get() {
        const dataType = localStorage.getItem(this.get('fullName') + '_TYPE');
        const value = localStorage.getItem(this.get('fullName'));

        if (dataType === 'date-range' && value) {
          // localStorage obsługuje tylko stringi, więc trzeba wartość z cache przerobić na array
          // data-range zawsze ma dwie wartości, nawet jeśli wybierzemy dwa razy tę samą datę.
          return value.split(',');
        }

        return value;
      },

      set(key, value) {
        if (value == null || value === '') {
          return localStorage.removeItem(this.get('fullName'));
        }

        return localStorage.setItem(this.get('fullName'), value);
      }

    }),
    cachedType: Ember.computed({
      get() {
        return localStorage.getItem(this.get('fullName') + '_TYPE');
      },

      set(key, value) {
        if (value == null || value === '') {
          return localStorage.removeItem(this.get('fullName') + '_TYPE');
        }

        return localStorage.setItem(this.get('fullName') + '_TYPE', value);
      }

    })
  });
  var _default = FilterBuilder;
  _exports.default = _default;
});