define("apollo/mixins/document-mixin", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    showAddDocumentButton: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ADD_DOCUMENTS');
    }),
    showDeleteDocumentButton: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('DELETE_DOCUMENTS');
    }),
    actions: {
      downloadDiscrepancyReport(discrepancyReportId) {
        const self = this;
        Ember.run.once(this, function () {
          window.open(_environment.default.serverURL + '/discrepancy-reports/' + discrepancyReportId + '/download?token=' + self.get("sessionAccount").get("token"), "_blank");
        });
      }

    }
  });

  _exports.default = _default;
});