define("apollo/pods/components/custom-field-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    urlAddress: '/custom-field-values/query',
    field: 'value',
    getDataOptions: function () {
      return {
        definitionId: this.definitionId
      };
    },
    options: [],
    suggestions: function () {
      return this.get('options');
    }.property('options.@each'),
    optionsToMatch: function () {
      const caseInsensitiveOptions = [];
      this.get('options').filter(item => !!item.value).forEach(item => {
        const value = item.value;
        caseInsensitiveOptions.push(value.toLowerCase());
      });
      return caseInsensitiveOptions;
    }.property('options.@each'),
    actions: {
      selectItem: function (item) {
        this.set('selectedFromList', true);
        this.set('selectedValue', item.index);
        this.sendAction('selectItem', item);
      }
    }
  });

  _exports.default = _default;
});