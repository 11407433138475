define("apollo/helpers/step-translation-key-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, nameArgs) {
      const label = nameArgs.label;
      const translationKeyName = label + 'TranslationKey';
      const transportType = nameArgs.transportType;
      const translationKey = transportType.get(translationKeyName);

      if (!translationKey) {
        return this.get('intl').t('step.' + label + '.DEFAULT.label');
      }

      return this.get('intl').t('step.' + label + '.' + translationKey + '.label');
    }

  });

  _exports.default = _default;
});