define("apollo/pods/components/paid-feature-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    isVisible: Ember.computed('authority', function () {
      const companyPricingPlan = this.get('sessionAccount.currentUser.company.pricingPlan');
      const plansWithFeatureAllowed = this.get('sessionAccount.authoritiesDependentOnPricingPlan')[this.get('authority')];
      return plansWithFeatureAllowed && !plansWithFeatureAllowed.includes(companyPricingPlan);
    })
  });

  _exports.default = _default;
});