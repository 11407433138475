define("apollo/pods/components/transport-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jd8sy8xZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"asPlainTemplate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\"],[11,\"class\",\"bold col-xs-12\"],[9],[0,\"\\n        \"],[1,[27,\"transport-modal-header\",null,[[\"transport\",\"asPlainTemplate\"],[[23,[\"transport\"]],[23,[\"asPlainTemplate\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"custom-modal\",null,[[\"classNames\",\"allowDraggable\",\"yieldWithLayout\",\"cancel\"],[\"transport-modal\",false,false,[23,[\"actionAfterClickCancelButton\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n            \"],[7,\"h4\"],[11,\"class\",\"bold col-xs-12\"],[9],[0,\"\\n                \"],[1,[27,\"transport-modal-header\",null,[[\"transport\"],[[23,[\"transport\"]]]]],false],[0,\"\\n\\n                \"],[7,\"i\"],[11,\"class\",\"fa fa-times pull-right clickable\"],[11,\"data-button-name\",\"close\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n            \"],[14,1],[0,\"\\n        \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport-modal/template.hbs"
    }
  });

  _exports.default = _default;
});