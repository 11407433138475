define("apollo/pods/components/warehouse-location/multiple-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "msIfV1Rt",
    "block": "{\"symbols\":[\"swl\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"form-group form-group-padding \",[27,\"if\",[[23,[\"step\",\"errors\",\"stepWarehouseLocations\"]],\"has-error\"],null]]]],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"col-xs-5 control-label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"warehouse.warehouseLocationLabel\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-7\"],[11,\"data-field-name\",\"warehouse-locations\"],[9],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"placeholder\",\"selected\",\"options\",\"disabled\",\"searchEnabled\",\"renderInPlace\",\"onchange\"],[[27,\"t\",[\"warehouse.warehouseLocationLabel\"],null],[23,[\"selectedWarehouseLocations\"]],[23,[\"warehouseLocationsOptions\"]],[23,[\"warehouseLocationDisabled\"]],false,true,[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"inputArray\"]]],null]],null]]],{\"statements\":[[0,\"            \"],[1,[22,1,[\"nameInAppropriateLanguage\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\"],[11,\"class\",\"help-block alert-text\"],[9],[0,\"\\n                \"],[7,\"small\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"errors.blankSelect\"],null],false],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/warehouse-location/multiple-select/template.hbs"
    }
  });

  _exports.default = _default;
});