define("apollo/pods/components/transaction/create-transaction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    TIME_TO_ACCEPT_TRANSPORT_INTERVAL: '3h',
    timeToAcceptTransport: Ember.computed('', function () {
      const setting = this.get('sessionAccount').getSettingValue('TIME_TO_ACCEPT_TRANSPORT_INTERVAL');
      return setting || this.TIME_TO_ACCEPT_TRANSPORT_INTERVAL;
    }),
    actions: {
      createTransactionAndOpenChooseCarrierModal() {
        console.log('Przygotowujemy i pokazujemy okienko wyboru przewoźnika...');
        const self = this;
        const transportType = this.get('transport.transportType');
        const transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
        let currency;

        if (this.get('offer.auction')) {
          currency = this.get('offer.auction.currency');
        } else {
          const symbol = transactionSetting ? transactionSetting.get('defaultCurrency') : 'PLN';
          currency = this.get('store').peekAll('currency').filterBy('symbol', symbol).get('firstObject');
        }

        const principalCompanyId = localStorage.getItem('principalId');
        const timeToAcceptTransport = this.get('timeToAcceptTransport');
        const intervalValue = timeToAcceptTransport.substring(0, timeToAcceptTransport.length - 1);
        const intervalUnit = timeToAcceptTransport.substring(timeToAcceptTransport.length - 1);
        const expectedConfirmationDate = moment(new Date()).add(intervalValue, intervalUnit).toDate();
        const expectedConfirmationTime = moment(expectedConfirmationDate).format('HH:mm');
        const carrierMustAcceptTransport = transactionSetting ? transactionSetting.get('requireCarrierConfirmation') : true;
        const carrierAutoAcceptsTransport = transactionSetting ? transactionSetting.get('carrierAutoAcceptsTransport') : false;
        const transaction = this.get('store').createRecord('transaction', {
          transport: this.get('transport'),
          carrier: this.get('offer.company'),
          price: this.get('offer.price'),
          currency,
          expectedConfirmationDate,
          carrierMustAcceptTransport,
          carrierAutoAcceptsTransport,
          notTheBestOffer: this.get('notTheBestOffer')
        });
        this.set('action', 'showModal');
        self.get('store').query('company', {
          principalCompanyId,
          transportId: self.get('transport.id'),
          partnershipTypesNames: ['CARRIER', 'OPERATOR'],
          statuses: ['NO_INVITATION', 'ACCEPTED', 'UPDATED', 'BLOCKED', 'CREATED_FROM_API']
        }).then(carriers => {
          const model = {
            transaction,
            expectedConfirmationTime,
            expectedConfirmationDate,
            carriers,
            disabledEdition: self.get('disabledEdition')
          };
          self.sendAction('action', 'transaction.modals.choose-carrier', model);
        });
      }

    }
  });

  _exports.default = _default;
});