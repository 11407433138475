define("apollo/pods/warehouses/schedule/controller", ["exports", "apollo/mixins/schedule-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/attachment-mixin", "apollo/mixins/advice-utils-mixin", "apollo/mixins/transports-mixin", "apollo/mixins/transport-form-mixin", "jquery", "apollo/utils/parsers/numberParsers", "apollo/models/schedule-action-select-model"], function (_exports, _scheduleMixin, _persistenceMixin, _attachmentMixin, _adviceUtilsMixin, _transportsMixin, _transportFormMixin, _jquery, _numberParsers, _scheduleActionSelectModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_scheduleMixin.default, _persistenceMixin.default, _attachmentMixin.default, _adviceUtilsMixin.default, _transportsMixin.default, _transportFormMixin.default, {
    session: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    timeWindowService: Ember.inject.service('time-window-service'),
    appTabActiveService: Ember.inject.service('application-tab-active-service'),
    schedulePollingService: Ember.inject.service('polling.schedule-service'),
    transportCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    warehouseZoneTransportTypeService: Ember.inject.service('schedule.warehouse-zone-transport-type-service'),
    company: null,
    selectedUserGroup: null,
    routeName: Ember.computed('hdMode', function () {
      return this.get('hdMode') ? 'hd' : 'schedule';
    }),
    createTransport: Ember.inject.controller('transports/modals/create-transport'),
    applicationController: Ember.inject.controller('application'),
    errors: Ember.A(),
    editedTransportType: null,
    openTransportId: null,
    stepWithoutWindowCopy: null,
    isShowingScheduleActionModal: false,
    blockedWindowsMode: false,
    initResizeObserver: Ember.on('init', function () {
      $(window).on('resize', function () {
        $('.popup-dialog').dialog('option', 'position', {
          my: 'right bottom',
          at: 'right bottom',
          of: window
        });
      });
    }),
    // eslint-disable-next-line ember/no-observers
    setupTimeWindowDescriptionScrollerObserver: Ember.observer('hdMode', function () {
      if (this.get('hdMode')) {
        (0, _jquery.default)(document).on('scroll', () => Ember.run.debounce({
          schedule: this
        }, this.recalculateTimeWindowsDescriptionPosition, 100));
      } else {
        (0, _jquery.default)(document).off('scroll');
      }
    }),
    isDuringWindowSelect: Ember.computed('transportToHandle', 'dedicatedTimeWindowToHandle', function () {
      return !!this.get('transportToHandle') || !!this.get('dedicatedTimeWindowToHandle');
    }),
    closeMultipleTimeWindowsModal: function () {
      this.set('showMultipleTimeWindowsModal', false);
      this.send('hideModal');
    },
    // https://jsfiddle.net/alvaroAV/svvz7tkn/
    isIE: Ember.computed('', function () {
      const ua = window.navigator.userAgent;
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    }),
    recalculateTimeWindowsDescriptionPosition: function () {
      const referenceElement = this.schedule.get('isIE') ? (0, _jquery.default)('.schedule-navigation') : (0, _jquery.default)('.ramp-label');

      if (!referenceElement.length) {
        return;
      }

      const referenceOffset = referenceElement.offset().top + referenceElement.height();
      this.schedule.moveWindowDescriptions(referenceOffset);
    },
    moveWindowDescriptions: function (referenceOffset) {
      const allWindows = (0, _jquery.default)('.window-state-with-transport');

      for (let i = 0; i < allWindows.length; i++) {
        const timeWindowElement = (0, _jquery.default)(allWindows[i]);
        const timeWindowTitle = timeWindowElement.find('.title');
        const timeWindowOffset = timeWindowElement.offset().top;
        const timeWindowHeight = timeWindowElement.height();
        const timeWindowTitleOffset = timeWindowTitle.offset().top;

        if (timeWindowOffset < referenceOffset && timeWindowOffset + timeWindowHeight > referenceOffset) {
          timeWindowTitle.offset({
            top: referenceOffset
          });
        } else if (timeWindowOffset !== timeWindowTitleOffset) {
          timeWindowTitle.offset({
            top: timeWindowOffset
          });
        }
      }
    },
    dragWindowAcrossScheduleDays: function (step, days, target) {
      const isTransportWindow = target.parent('.window-state-with-transport').length;
      const isBlockadeWindow = target.parent('.window-state-disabled').length;
      const isDedicatedWindow = target.parent('.window-state-dedicated').length;
      const windowId = step.get('timeWindow.id') || target.parent().data('schedule-window');
      const windowModel = this.store.peekRecord('timeWindow', windowId);

      try {
        if (isTransportWindow) {
          const warehouseId = step.get('warehouse.id');
          const window = step.get('timeWindow');
          this.set('warehouseStepToHandle', step);
          windowModel.lockForEditing();
          const windowStart = moment(window.get('start')).add(days, 'days').format('YYYY-MM-DD'); // TODO: utworzyć i obsłużyć osobny modal tylko do przenoszenia samego okna czasowego
          // (teraz podpinamy się pod modal edytowania awizacji)

          this.transitionToRoute('warehouses.schedule', warehouseId, windowStart, '1');
        } else if (isBlockadeWindow || isDedicatedWindow) {
          const windowStart = moment(windowModel.get('start')).add(days, 'days').format('YYYY-MM-DD');
          const warehouseId = windowModel.get('ramp.warehouse.id');
          this.set('dedicatedTimeWindowToHandle', windowModel);
          this.set('editedWindow', windowModel);
          windowModel.lockForEditing();
          this.transitionToRoute('warehouses.schedule', warehouseId, windowStart, '1');
        }
      } catch (e) {
        console.log(e);
        windowModel.unlockForEditing();
      }
    },
    transportToHandle: Ember.computed('warehouseStepToHandle.transport', function () {
      return this.get('warehouseStepToHandle.transport');
    }),
    isDuringTransportSave: Ember.computed('transportToHandle.id', function () {
      const transportToHandle = this.get('transportToHandle');
      return transportToHandle && !transportToHandle.get('id');
    }),
    editedWindow: Ember.computed('warehouseStepToHandle.timeWindow', function () {
      return this.get('warehouseStepToHandle.timeWindow.id') ? this.get('warehouseStepToHandle.timeWindow') : null;
    }),
    attachments: Ember.A(),
    info: null,
    shownDaysContainCurrentDay: Ember.computed('shownDays', function () {
      return this.get('shownDays').filterBy('name', this.get('currentFormattedDate')).length;
    }).readOnly(),
    showSelectDepartment: Ember.computed('model.userGroups.@each.length', function () {
      return this.get('model.userGroups.length') > 0;
    }).readOnly(),
    currentMoment: Ember.computed('', function () {
      return moment();
    }),
    currentFormattedDate: Ember.computed('currentMoment', function () {
      return moment(this.get('currentMoment')).format('YYYY-MM-DD');
    }).readOnly(),
    showCurrentHourBackgroundColor: Ember.computed('timeWindowOffsetIntervalSetting', function () {
      const interval = this.get('timeWindowOffsetIntervalSetting');

      if (interval > 60) {
        return '';
      }

      return 'current-hour';
    }),
    currentHour: Ember.computed('currentMoment', function () {
      return moment(this.get('currentMoment')).format('HH:mm');
    }).readOnly(),
    scheduleEndDate: Ember.computed('scheduleStartDate', 'model.delta', function () {
      const delta = parseInt(this.get('model.delta'));
      return moment(this.get('scheduleStartDate')).add(delta - 1, 'days').endOf('day').toDate();
    }),
    // eslint-disable-next-line ember/no-observers
    scheduleDateSelected: Ember.observer('selectedDateString', function () {
      const selectedDate = this.get('selectedDateString');
      this.transitToDate(Ember.isArray(selectedDate) ? selectedDate[0] : selectedDate);
    }),
    viewedColumns: Ember.computed('ramps.length', 'scheduleEndDate', function () {
      return this.get('ramps.length') * this.get('viewedDays');
    }),
    viewedDays: Ember.computed('viewedColumns', function () {
      const start = moment(this.get('scheduleStartDate'));
      const end = moment(this.get('scheduleEndDate'));
      return end.diff(start, 'days') + 1;
    }),
    showLongName: Ember.computed('viewedColumns', function () {
      return this.get('viewedColumns') < 9;
    }),
    scheduleActionSelectModel: Ember.computed(function () {
      return _scheduleActionSelectModel.default.create();
    }),
    // eslint-disable-next-line ember/no-observers
    warehouseSelected: Ember.observer('model.warehouse.id', function () {
      const dateString = this.get('scheduleStartDateFormatted');
      const delta = this.get('model.delta');
      this.set('defaultZoom', undefined);
      this.transitionToRoute('warehouses.' + this.get('routeName'), this.get('model.warehouse.id'), dateString, delta);
    }),
    // eslint-disable-next-line ember/no-observers
    selectedUserGroupObserver: Ember.observer('selectedUserGroup', function () {
      if (this.get('selectedUserGroup')) {
        this.set('company', null);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    selectedCompanyObserver: Ember.observer('company', function () {
      if (this.get('company')) {
        this.set('selectedUserGroup', null);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    showWindowPopUpObserver: Ember.observer('focusedWindowId', function () {
      if (this.get('editedWindow.id')) {
        console.debug(`Okno ${this.get('editedWindow.id')} jest w trakcie edycji. Nie wyświetlamy popupu`);
        return;
      }

      Ember.run.debounce(this, this._showWindowPopUpObserver, 500);
    }),
    _showWindowPopUpObserver: function () {
      const self = this;

      if (self.get('focusedWindowId')) {
        const window = self.store.peekRecord('timeWindow', self.get('focusedWindowId'));

        if (window === null || !window.get('step.id')) {
          return;
        }

        const transportIdToFetch = window.get('relatedTransport.id') ? window.get('relatedTransport.id') : window.get('transport.id');
        self.get('store').findRecord('transport', transportIdToFetch).then(transport => {
          self.getStatuses(transport);
          const customFields = self.get('store').query('customFieldValue', {
            transportId: transport.get('id')
          });
          window.get('transport').set('customFields', customFields);
          const transportTypeScheduleSettings = self.get('store').queryRecord('transport-type-schedule-setting', {
            transportTypeId: transport.get('transportType.id')
          });
          self.send('showPopupDialog', 'dialogs.window-details-dialog', {
            window,
            transport,
            transportTypeScheduleSettings,
            position: self.get('position')
          });
        });
      } else {
        self.send('removePopupMessage');
      }
    },

    shouldSkipAssortmentGroupRestriction() {
      return !this.get('assortmentGroup.id') || this.get('assortmentGroup.id') && this.get('sessionAccount.currentUser').canDiscardAssortmentGroupRestrictions();
    },

    shouldSkipAdviceRangeRestriction(minAdviceDate, transportTypeId) {
      return !minAdviceDate || minAdviceDate && this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId);
    },

    shouldShowAdviceRangeAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate) {
      return minAdviceDate > warehouseEndDate || maxAdviceDate < warehouseStartDate;
    },

    getMinRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId) {
      if (this.shouldSkipAssortmentGroupRestriction()) {
        return minAdviceDate < warehouseEndDate ? minAdviceDate : warehouseEndDate;
      }

      if (this.shouldShowAdviceRangeAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate)) {
        return warehouseStartDate;
      }

      let minAssortmentGroupDate = moment(warehouseStartDate);
      minAssortmentGroupDate = minAssortmentGroupDate.set({
        h: this.get('assortmentGroup.adviceFromHours'),
        m: this.get('assortmentGroup.adviceFromMinutes')
      });

      if (this.shouldSkipAdviceRangeRestriction(minAdviceDate, transportTypeId)) {
        return minAssortmentGroupDate;
      }

      return minAssortmentGroupDate > minAdviceDate ? minAssortmentGroupDate : minAdviceDate;
    },

    getMaxRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId) {
      if (this.shouldSkipAssortmentGroupRestriction()) {
        return maxAdviceDate;
      }

      if (this.shouldShowAdviceRangeAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate)) {
        return warehouseStartDate;
      }

      let maxAssortmentGroupDate = moment(warehouseStartDate);
      maxAssortmentGroupDate = maxAssortmentGroupDate.set({
        h: this.get('assortmentGroup.adviceToHours'),
        m: this.get('assortmentGroup.adviceToMinutes')
      });

      if (this.shouldSkipAdviceRangeRestriction(minAdviceDate, transportTypeId)) {
        return maxAssortmentGroupDate;
      }

      return maxAssortmentGroupDate < maxAdviceDate ? maxAssortmentGroupDate : maxAdviceDate;
    },

    createAutoBlockadeWindow(start, stop, ramp) {
      const window = this.get('store').createRecord('timeWindow', {
        start,
        stop,
        ramp,
        isAutoBlockade: true,
        isLockedForEditing: true
      });
      this.get('windows').pushObject(window);
    },

    shouldSeeAutoBlockadesWindows(transportTypeId, minAdviceDate) {
      const assortmentGroupId = this.get('assortmentGroup.id');
      const userCanDiscardAdviceRestrictions = this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId);
      const userCanDiscardAssortmentGroupRestrictions = this.get('sessionAccount.currentUser').canDiscardAssortmentGroupRestrictions();
      const hasAdviceOnlyOnDedicatedWindowsRole = this.get('sessionAccount').hasRole('ADVICE_ONLY_ON_DEDICATED_WINDOWS');
      const assortmentGroupRestriction = assortmentGroupId && !userCanDiscardAssortmentGroupRestrictions;
      const adviceTimeRestriction = minAdviceDate && !userCanDiscardAdviceRestrictions;

      if (assortmentGroupRestriction) {
        return true;
      }

      if (adviceTimeRestriction) {
        return true;
      }

      if (hasAdviceOnlyOnDedicatedWindowsRole) {
        return true;
      }

      return false;
    },

    _setAutoBlockades: function () {
      const transportTypeId = this.get('warehouseStepToHandle.transport.transportType.id');
      const minAdviceDate = this.get('warehouseStepToHandle.minAdviceDate') || undefined;
      const maxAdviceDate = this.get('warehouseStepToHandle.maxAdviceDate') || undefined;

      if (!this.shouldSeeAutoBlockadesWindows(transportTypeId, minAdviceDate) || !this.get('model.warehouse.id')) {
        return;
      }

      this.get('shownDays').forEach(d => {
        const warehouseStartDate = this.get('model.warehouse').getStartOnDay(d.date);
        const warehouseEndDate = this.get('model.warehouse').getEndOnDay(d.date);
        let topWindowStart = warehouseStartDate;
        const topWindowEnd = this.getMinRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId);
        const bottomWindowStart = this.getMaxRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId);
        const bottomWindowEnd = warehouseEndDate;
        const showTopWindow = topWindowEnd > warehouseStartDate && topWindowEnd < warehouseEndDate;
        let showBottomWindow = bottomWindowStart > warehouseStartDate && bottomWindowStart < warehouseEndDate;
        /*
        * Przypadek, gdy grupa asortymentowa ma ustawione godziny od np. 15:00 do 13:00 (kolejnego dnia).
        * W takim przypadku chcemy wyświetlić tylko jedno okno, które wyklucza
        * godziny poza grupą tj. (z przykładu wyżej: 13:00 - 15:00)
        * */

        if (this.get('assortmentGroup.id') && this.get('assortmentGroup.hasTurnedHours')) {
          topWindowStart = bottomWindowStart;
          showBottomWindow = false;
        }

        this.get('ramps').forEach(ramp => {
          const timeWindows = this.get('filteredWindows').sortBy('start');
          /*
              Tworzenie okien blokady w wolnych miejscach (bez okien czasowych),
              ale pod warunkiem, że użytkownik ma uprawnienie ADVICE_ONLY_ON_DEDICATED_WINDOWS.
          */

          const hasAdviceOnlyOnDedicatedWindowsRole = this.get('sessionAccount').hasRole('ADVICE_ONLY_ON_DEDICATED_WINDOWS');

          if (hasAdviceOnlyOnDedicatedWindowsRole) {
            const windowsArr = []; // Szukamy wszystkich okien na konkretnej rampie i wybieramy
            // tylko te, które mają `state` (czyli wszystkie poza szarymi)

            timeWindows.forEach(tw => {
              if (tw.get('start') >= moment(warehouseStartDate) && tw.get('stop') <= moment(warehouseEndDate) && tw.get('ramp.id') === ramp.get('id') && tw.get('state') !== undefined) {
                windowsArr.push(tw);
              }
            }); // jeśli nie znajedziemy okna na rampie to blokujemy ją całą

            if (windowsArr.length <= 0) {
              this.createAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, ramp);
              return;
            }

            for (let i = 0; i <= windowsArr.length; i++) {
              // podczas pierwszej iteracji stawiamy okno blokady od początku rampy do początku pierwszego okna
              if (i === 0) {
                const stop = windowsArr[0].start ? windowsArr[0].start : '';
                this.createAutoBlockadeWindow(warehouseStartDate, moment(stop), ramp);
              } else if (i >= 1 && i < windowsArr.length) {
                // podczas następynch iteracji z wyjątkiem pierwszej i ostatniej
                // stawiamy okno blokady pomiędzy oknami z tabliczy `windowsArr`
                this.createAutoBlockadeWindow(windowsArr[i - 1].stop, windowsArr[i].start, ramp);
              } else {
                // pozostaje nam ostatnia iteracja, na której stawiamy
                // okno blokady pomiędzy ostatnim znalezionym oknem a końcem rampy
                this.createAutoBlockadeWindow(windowsArr[windowsArr.length - 1].stop, warehouseEndDate, ramp);
              }
            }
          }

          if (showTopWindow) {
            this.createAutoBlockadeWindow(topWindowStart, topWindowEnd, ramp);
          }

          if (showBottomWindow) {
            this.createAutoBlockadeWindow(bottomWindowStart, bottomWindowEnd, ramp);
          }

          if (!showTopWindow && !showBottomWindow && (minAdviceDate >= warehouseEndDate || maxAdviceDate <= warehouseStartDate)) {
            this.createAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, ramp);
          }
        });
      });
    },
    // eslint-disable-next-line ember/no-observers
    removeAutoBlockades: Ember.observer('warehouseStepToHandle', function () {
      if (this.get('warehouseStepToHandle')) {
        return;
      }

      const autoBlockades = this.get('windows').filterBy('isAutoBlockade');
      autoBlockades.forEach(tw => {
        this.get('windows').removeObject(tw);
      });
    }),

    setWindows() {
      if (!this.get('rampsIds.length')) {
        return;
      }

      const transportIds = this.store.peekAll('transport').map(t => {
        return t.id;
      });
      const data = {
        loadedTransportIds: transportIds.join(', '),
        rampsIds: this.get('rampsIds').join(',')
      };
      this.loadWindows(0, data);
    },

    storeDisabledWindow: function (start, stop, rampId) {
      const company = this.get('company');
      const selectedUserGroup = this.get('selectedUserGroup');
      const ramp = this.store.peekRecord('ramp', rampId);
      this.timeWindowService.saveWindow(ramp, company, start, stop, selectedUserGroup).then(savedWindow => {
        this.send('onPoll');
        this.send('removePopupDialog');

        try {
          const windows = this.get('windows');
          windows.pushObject(savedWindow);
        } catch (e) {
          console.error('nie udal sie push okna / ' + e);
        }

        this.setProperties({
          errors: [],
          scheduleClicked: false
        });
      }).catch(response => {
        this.setProperties({
          errors: response.errors,
          scheduleClicked: false
        });
      });
    },

    /**
     * jeżeli na rampie znajdują się tylko okna blokad, usuniemy je wwszystkie.
     * @param windows
     */
    unblockRamp: function (windows) {
      console.log('unblockRamp');
      const self = this;
      let onlyDisabled = true;
      windows.forEach(function (window) {
        if (window.get('state') !== 'state-disabled') {
          onlyDisabled = false;
        }
      }); // wywala blad ale dziala
      // http://stackoverflow.com/questions/15167703/how-to-delete-all-data-from-ember-data-store

      if (onlyDisabled) {
        windows.forEach(function (window) {
          self.send('removeBlockedWindow', window);
        });
      }
    },
    _scheduleEvents: function () {
      if (this.get('hdMode') && this.get('model.delta') === 1) {
        Ember.run.later(this, function () {
          this.scroll();
        }, 1000);
        this.activateScrolling();

        if (this.get('timeToShiftSchedule')) {
          this.activateDayChanging();
        }
      } else {
        this.deactivateScrolling();
        this.deactivateDayChanging();
      }
    },
    // eslint-disable-next-line ember/no-observers
    scheduleEvents: Ember.observer('hdMode', 'model.delta', function () {
      Ember.run.once(this, '_scheduleEvents');
    }),
    activateScrolling: function () {
      console.debug(`Aktywujemy scrollowanie co ${this.get('scrollInterval')} ms..`);
      this.set('scrollTimer', this.schedule(this.scroll));
    },
    deactivateScrolling: function () {
      console.debug('Wyłączamy scrollowanie..');
      Ember.run.cancel(this.get('scrollTimer'));
    },
    scrollInterval: Ember.computed(function () {
      return 60000;
    }).readOnly(),

    schedule(f) {
      if (!this.get('hdMode') || this.get('model.delta') !== 1) {
        return;
      }

      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('scrollTimer', this.schedule(f));
      }, this.get('scrollInterval'));
    },

    scroll() {
      const now = moment();
      const format = 'DD.MM.YYYY';
      const currentScheduleDate = this.get('scheduleStartDate').format(format);

      if (currentScheduleDate !== now.format(format)) {
        console.debug('Harmonogram jest otwarty na innym dniu - nie scrollujemy do aktualnej godziny');
        return;
      }

      const h = now.format('HH');
      const m = now.format('mm');
      const roundedM = Math.floor(parseInt(m) / 15) * 15 % 60;
      let div = $(`div[data-schedule-hour='${h}'] div[data-schedule-minute='${roundedM}']`).first(); // Jeżeli slot godzinny nie będzie podzielony na części (np. 15-min.), musimy wyszukiwać miejsce jedynie wg godziny.

      if (!div.length) {
        div = $(`div[data-schedule-hour='${h}']`).first();
      } // Jeżeli nadal nie znajdziemy odpowiedniego elementu -- oznacza to, że magazyn nie pracuje w danej godzinie


      if (!div.length) {
        return;
      }

      $('html, body').animate({
        scrollTop: div.offset().top - 200
      }, 'slow');
    },

    activateDayChanging: function () {
      if (this.get('dayChangeTimer')) {
        return;
      }

      console.debug('Aktywujemy zmienianie się dnia w widoku HD..');
      this.set('dayChangeTimer', this.scheduleDayChanging(this.changeDay));
    },
    deactivateDayChanging: function () {
      console.debug('Wyłączamy zmienianie się dnia w widoku HD..');
      Ember.run.cancel(this.get('dayChangeTimer'));
    },
    timeToShiftSchedule: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('HD_MODE_NEXT_DAY_SHIFT_TIME');
    }),
    findDayChangeInterval: function () {
      const settingValue = this.get('timeToShiftSchedule');
      const settingHours = settingValue.split(':')[0];
      const settingMinutes = settingValue.split(':')[1];
      const settingTime = moment().set({
        h: settingHours,
        m: settingMinutes,
        s: 0
      });

      if (settingTime.isBefore()) {
        settingTime.add(1, 'd');
      }

      const now = moment();
      return settingTime.diff(now);
    },

    scheduleDayChanging(f) {
      const interval = this.findDayChangeInterval();
      return Ember.run.later(this, function () {
        if (interval > 0) {
          f.apply(this);
        }

        this.scheduleDayChanging(f);
      }, interval);
    },

    changeDay() {
      this.scroll();
      this.send('shiftScheduleRight');
    },

    clockInterval: Ember.computed(function () {
      const pageRenderedAt = this.currentMoment;
      const reminderToNextQuadrant = 15 - pageRenderedAt.minute() % 15;
      return reminderToNextQuadrant * 3600;
    }),

    setClockInterval() {
      const pageRenderedAt = this.currentMoment;
      const reminderToNextQuadrant = 15 - pageRenderedAt.minute() % 15;
      this.set('clockInterval', reminderToNextQuadrant * 3600);
    },

    initializeScheduleClock: Ember.on('init', function () {
      console.debug('Initializing clock..');
      this.set('clockTimer', this.scheduleClock(this.get('setClock')));
    }),
    scheduleClock: function (f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.setClockInterval();
        this.set('timer', this.scheduleClock(f));
      }, this.get('clockInterval'));
    },

    setClock() {
      this.set('currentMoment', moment());
    },

    transitToDate(dateAsString) {
      const warehouseId = this.get('warehouse.id');
      const delta = this.get('model.delta');
      this.transitionToRoute('warehouses.' + this.get('routeName'), warehouseId, dateAsString, delta);
    },

    adviceRestrictionsValidate(start, stop) {
      const transportTypeId = this.get('warehouseStepToHandle.transport.transportType.id');

      if (this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId)) {
        return true;
      }

      const stepToHandle = this.get('warehouseStepToHandle');

      if (stepToHandle.get('minAdviceDate') && start < stepToHandle.get('minAdviceDate') || stepToHandle.get('maxAdviceDate') && stop > stepToHandle.get('maxAdviceDate')) {
        const msg = this.get('intl').t('timeWindow.errors.time.outsideOfAdviceRange', {
          minAdviceDate: moment(stepToHandle.get('minAdviceDate')).format('DD.MM.YYYY HH:mm'),
          maxAdviceDate: moment(stepToHandle.get('maxAdviceDate')).format('DD.MM.YYYY HH:mm')
        });
        this.set('warningMessage', msg);
        return false;
      }

      return true;
    },

    showLegend: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('SCHEDULE_LEGEND_ENABLED') === 'true';
    }),
    findMaxPalletClass: Ember.computed('hasAccessToMaxPallet', 'hasAccessToMaxPalletPerRamp', function () {
      const hasAccessToMaxPallet = this.get('hasAccessToMaxPallet');
      const hasAccessToMaxPalletPerRamp = this.get('hasAccessToMaxPalletPerRamp');

      if (hasAccessToMaxPallet && hasAccessToMaxPalletPerRamp) {
        return 'double-max-pallets';
      } else if (hasAccessToMaxPallet || hasAccessToMaxPalletPerRamp) {
        return 'max-pallets';
      } else {
        return '';
      }
    }),

    findWindowSize(stepWithoutWindow, transport) {
      if (!this.get('editedWindow.id')) {
        return this.getWindowSize(stepWithoutWindow, transport);
      } else {
        // w przypadku edycji okna mamy już jego rozmiar, więc nie musimy nikogo pytać
        return new Promise(resolve => resolve({}));
      }
    },

    findAlertDuringRemovingWindow(window) {
      if (window.get('isDedicated')) {
        return this.get('intl').t('warehouse.confirmDeleteDedicateWindow', {
          company: window.get('company.name')
        });
      }

      const note = window.get('note');

      if (note) {
        return this.get('intl').t('warehouse.windowNoteRemoveWarning', {
          note
        });
      } else if (this.get('sessionAccount').getSettingValue('ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW') === 'true') {
        return this.get('intl').t('warehouse.windowRemoveWarning');
      } else {
        return null;
      }
    },

    onPollWrapper: function () {
      this.send('onPoll');
    },
    shouldLegendHaveFixedPosition: Ember.computed('model', function () {
      return this.get('sessionAccount').getSettingValue('SET_SCHEDULE_LEGEND_TO_FIXED_POSITION') === 'true';
    }),
    shouldDisplayScheduleActionSelectOnClick: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE');
    }),
    actions: {
      toggleIsShowingScheduleActionModal: function () {
        this.toggleProperty('isShowingScheduleActionModal');
      },

      closeMultipleTimeWindowsModal() {
        this.closeMultipleTimeWindowsModal();
      },

      dragWindowAcrossScheduleDays: function (step, days, target) {
        this.dragWindowAcrossScheduleDays(step, days, target);
      },
      switchOffHD: function (warehouseId, date, delta) {
        window.scrollTo(0, 0);
        Ember.run.debounce({
          schedule: this
        }, this.recalculateTimeWindowsDescriptionPosition, 0);
        this.transitionToRoute('warehouses.schedule', warehouseId, date, delta);
      },
      poll: function () {
        this.send('onPoll');
      },

      /** @namespace json.timeWindowIds */
      onPoll: function () {
        const socket = this.get('websocket.isReconnecting') ? null : this.get('websocket').getSocket();
        const params = {
          warehouseId: this.get('warehouse.id'),
          currentRampsIds: this.get('rampsIds')
        };
        const self = this;

        if (socket && socket.connected) {
          this.get('schedulePollingService').useSockets(params.warehouseId, self);
        } else {
          this.get('schedulePollingService').usePolling(params, self);
        }
      },

      onDraggableStop(event) {
        const Window = Ember.Object.extend({
          // eslint-disable-next-line ember/require-return-from-computed
          model: Ember.computed(function () {}),
          isLock: Ember.computed(function () {
            return false;
          }),
          currentLengthInMS: Ember.computed('model', function () {
            return this.get('model.stop') - this.get('model.start');
          })
        });
        const self = this;
        const target = $(event.target);
        const windowId = target.data('schedule-window');
        const left = target.offset().left - $(document).scrollLeft();
        const top = target.offset().top - $(document).scrollTop();
        const bottom = target.height() + top;
        const isSupplement = target.data('is-supplement');
        target.removeClass('noclick');
        this.set('inProgress', true);
        const windowModel = this.store.peekRecord('timeWindow', windowId);

        if (windowModel.get('isLockedForEditing')) {
          console.log('Okno jest już w trakcie edycji - nie można na razie wykonywać na nim dodatkowych operacji!');
          return;
        }

        const sourceWindow = Window.create({
          model: windowModel,
          oldStart: windowModel.get('start'),
          oldStop: windowModel.get('stop'),
          oldRamp: windowModel.get('ramp')
        });
        let minutesDiv, $minutesDiv, newStart, newStop;

        if (!isSupplement) {
          minutesDiv = this.findMinutesDiv(left + 10, top + 2);
          $minutesDiv = $(minutesDiv);
          newStart = this.getDate($minutesDiv);
          newStop = moment(newStart).add(sourceWindow.get('currentLengthInMS'), 'milliseconds').toDate();
        }

        if (!minutesDiv) {
          minutesDiv = this.findMinutesDiv(left + 10, bottom + 2);
          $minutesDiv = $(minutesDiv);
          newStop = this.getDate($minutesDiv);
          newStart = moment(newStop).subtract(sourceWindow.get('currentLengthInMS'), 'milliseconds').toDate();
        }

        if (!minutesDiv) {
          console.debug('Użytkownik próbował postawić okno poza obszarem harmonogramu; przerywamy operację.');
          self.restorePreviousWindowPlace(sourceWindow);
          return;
        }

        const rampId = this.getRampId($minutesDiv);
        const ramp = self.store.peekRecord('ramp', rampId);

        if (newStart.getTime() === sourceWindow.get('model.start').getTime() && newStop.getTime() === sourceWindow.get('model.stop').getTime() && sourceWindow.get('model.ramp.id') === rampId.toString()) {
          console.log('brak zmian; przerywamy');
          sourceWindow.get('model').notifyPropertyChange('start');
          return;
        } // eslint-disable-next-line array-callback-return


        const targetWindowCandidateList = this.get('windows').filter(w => {
          if (parseInt(w.get('id')) !== parseInt(sourceWindow.get('model.id')) && parseInt(w.get('ramp').get('id')) === parseInt(rampId) && !(w.get('stop') <= newStart || w.get('start') >= newStop)) {
            return w;
          }
        });
        let targetWindowCandidate;

        if (targetWindowCandidateList.length > 1) {
          targetWindowCandidate = targetWindowCandidateList.filter(w => {
            return !w.get('isDedicated');
          })[0];
        } else {
          targetWindowCandidate = targetWindowCandidateList[0];
        }

        const targetWindow = Window.create({
          model: targetWindowCandidate
        });
        targetWindow.setProperties({
          oldStart: targetWindow.get('model.start'),
          oldStop: targetWindow.get('model.stop'),
          oldRamp: targetWindow.get('model.ramp')
        });
        sourceWindow.setProperties({
          oldStart: windowModel.get('start'),
          oldStop: windowModel.get('stop'),
          oldRamp: windowModel.get('ramp')
        });
        sourceWindow.get('model').setProperties({
          start: newStart,
          stop: newStop,
          ramp
        });
        console.log(`Próba przesunięcia okna ${windowId}:
                    [${sourceWindow.get('oldStart')}, ${sourceWindow.get('oldRamp.name')} (#${sourceWindow.get('oldRamp.id')})]
                    -> [${newStart}, ${ramp.get('name')} (#${ramp.get('id')})]`);
        const warehouseOpenTime = this.get('warehouse').getStartOnDay(this.get('scheduleStartDate'));
        const warehouseCloseTime = this.get('warehouse').getEndOnDay(this.get('scheduleEndDate'));

        if (targetWindow.get('model') && (this.windowsSwappingBlocked(sourceWindow, targetWindow) || newStart < warehouseOpenTime || newStop > warehouseCloseTime)) {
          const message = this.get('intl').t('ramp.errors.cannotMoveWindowException');
          this.send('showPopupDialog', 'dialogs.message-popup-dialog', sourceWindow.set('detail', message));
          this.restorePreviousWindowPlace(sourceWindow);
          this.restorePreviousWindowPlace(targetWindow);
          return false;
        }

        if (this.get('windowSwapping')) {
          console.debug('Jesteśmy w trakcie zamiany okien. Przerywamy operację przesuwania.');
          return;
        }

        windowModel.lockForEditing();
        const transport = sourceWindow.get('model.transport');

        if (transport && transport.get('id')) {
          sourceWindow.set('model.updateAllFuturePeriodicWindows', this.updateAllFuturePeriodicTransports(transport));
        }

        sourceWindow.get('model').save().then(w => {
          self.send('removePopupDialog');
          self.setProperties({
            inProgress: false,
            errors: []
          });

          if (w.get('transport.id')) {
            w.get('transport').then(t => {
              t.reload().then(() => {
                self.unloadDeletedRecords('transport', t.id, 'checkpoint');
                windowModel.unlockForEditing();
                console.debug(`Zakończyliśmy aktualizację okna ${windowId} z transportem ${t.get('logString')}.`);
              });
            });
          } else {
            windowModel.unlockForEditing();
            console.debug(`Zakończyliśmy aktualizację okna ${windowId}.`);
          }

          this.send('onPoll');
        }).catch(response => {
          this.restorePreviousWindowPlace(sourceWindow);
          self.setProperties({
            inProgress: false,
            errors: response.errors
          });
          windowModel.unlockForEditing();
        });
      },

      toggleblockedWindowsMode: function () {
        this.toggleProperty('blockedWindowsMode');
      },
      createWindow: function (start, stop, rampId, initialStart) {
        if (this.get('scheduleClicked')) {
          return;
        }

        this.set('scheduleClicked', true);

        if (this.get('transportToHandle')) {
          this.send('handleClickWithTransport', start, stop, rampId, initialStart);
        } else {
          const availableTransportTypesInWarehouseZone = this.get('warehouseZoneTransportTypeService').transportTypesThatCanBeCreatedInWarehouseZone(rampId);

          if (this.get('shouldDisplayScheduleActionSelectOnClick') && !this.get('blockedWindowsMode') && availableTransportTypesInWarehouseZone.length > 0) {
            this.set('scheduleClicked', false);
            this.set('scheduleActionSelectModel', _scheduleActionSelectModel.default.create({
              start,
              stop,
              rampId,
              selectedCompany: this.get('company'),
              availableTransportTypesInWarehouseZone
            }));
            this.send('toggleIsShowingScheduleActionModal');
          } else {
            this.send('createBlockedWindow', start, stop, rampId);
          }
        }
      },

      handleClickDedicatedWindow(start, stop, rampId, initialStart) {
        if (this.get('scheduleClicked')) {
          return;
        }

        this.set('dedicatedTimeWindowToHandle', '');
        const ramp = this.store.peekRecord('ramp', rampId);

        if (this.get('editedWindow.id')) {
          const editedWindow = this.get('editedWindow');
          const editedWindowModel = this.store.peekRecord('timeWindow', editedWindow.get('id'));
          console.log(`Edited window: [${editedWindow.get('formattedTime')}]`);
          editedWindow.setProperties({
            oldStart: editedWindow.get('start'),
            oldStop: editedWindow.get('stop'),
            oldRamp: editedWindow.get('ramp'),
            initialStart: initialStart.toDate(),
            start: start.toDate(),
            stop: stop.toDate(),
            ramp
          });
          editedWindowModel.unlockForEditing();
          editedWindow.save().then(() => {
            console.debug(`Zapisano okno czasowe ${editedWindow.id} w zakresie ${start} - ${stop}`);
          }).catch(e => {
            console.log(e);
          });
        }
      },

      handleClickWithTransport(start, stop, rampId, initialStart) {
        if (this.get('scheduleClicked')) {
          return;
        }

        this.set('scheduleClicked', true);
        const self = this;
        this.set('warningMessage', null);
        const transport = this.get('transportToHandle');
        console.debug(`Trying to handle click on ${start} with transport ${transport.get('id')}..`);
        const ramp = this.store.peekRecord('ramp', rampId);

        if (!this.transportCanBeCreatedInThisWarehouse(transport, ramp)) {
          const msg = this.get('intl').t('timeWindow.errors.wrongWarehouse');
          this.set('warningMessage', msg);
          return;
        }

        const stepWithoutWindow = transport.get('firstWarehouseStepWithoutWindow') ? transport.get('firstWarehouseStepWithoutWindow') : this.get('stepWithoutWindowCopy');

        if (stepWithoutWindow) {
          // Ustalamy warehouseZone [A-12929]
          const warehouseZone = this.store.peekRecord('warehouseZone', ramp.get('warehouseZone.id'));
          stepWithoutWindow.set('warehouseZone', warehouseZone);
        } // Tworzymy kopię na potrzeby obsługi błędu w przypadku stawiania okna bez awizacji


        this.set('stepWithoutWindowCopy', stepWithoutWindow);
        this.findWindowSize(stepWithoutWindow, transport).then(response => {
          const estimatedLengthInMinutes = response.estimatedWindowLengthInMinutes;

          if (estimatedLengthInMinutes) {
            stop = moment(start).add(estimatedLengthInMinutes, 'minutes').toDate();
          }

          if (!this.adviceRestrictionsValidate(start, stop)) {
            this.set('scheduleClicked', false);
            return;
          }

          if (this.get('editedWindow.id')) {
            const editedWindow = this.get('editedWindow');
            const editedWindowModel = this.store.peekRecord('timeWindow', editedWindow.get('id'));
            editedWindow.setProperties({
              oldStart: editedWindow.get('start'),
              oldStop: editedWindow.get('stop'),
              oldRamp: editedWindow.get('ramp'),
              initialStart: initialStart.toDate(),
              start: start.toDate(),
              stop: stop.toDate(),
              ramp
            });
            editedWindowModel.unlockForEditing();
            transport.set('editedTimeWindow', editedWindow);
            this.send('processTransportWindowCreation', transport, start);
          } else {
            const tmpWindow = self.store.createRecord('timeWindow', {
              start,
              stop,
              ramp,
              initialStart,
              step: stepWithoutWindow
            });
            stepWithoutWindow.set('timeWindow', tmpWindow);
            transport.get('timeWindows').pushObject(tmpWindow);
            transport.set('editedTimeWindow', tmpWindow);
            self.send('processTransportWindowCreation', transport, start);
          }
        });
      },

      processTransportWindowCreation(transport, start) {
        const self = this;
        const nextStepWithoutWindow = transport.get('firstWarehouseStepWithoutWindow');
        const nextStepWithChangedWarehouse = transport.get('firstChangedWarehouseStep');

        if (nextStepWithoutWindow || nextStepWithChangedWarehouse) {
          const warehouse = nextStepWithoutWindow ? nextStepWithoutWindow.get('warehouse') : nextStepWithChangedWarehouse.get('warehouse');
          const minAdviceDateOrToday = nextStepWithoutWindow.get('minAdviceDate') !== null ? moment(nextStepWithoutWindow.get('minAdviceDate')).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
          const scheduleDateString = nextStepWithoutWindow ? minAdviceDateOrToday : moment(new Date()).format('YYYY-MM-DD');
          const stepToHandle = nextStepWithoutWindow || nextStepWithChangedWarehouse;
          self.set('warehouseStepToHandle', stepToHandle);
          self.set('scheduleClicked', false); // jeśli nie jesteśmy w magazynie lub jesteśmy w innym magazynie niż ten, w którym chcemy postawić okno - wykonujemy przekierowanie

          if (this.get('routeName') !== 'schedule' || this.get('warehouse.id') !== warehouse.get('id')) {
            console.log(`Transport ${transport.get('id')} wymaga wybrania kolejnego okna czasowego - przenosimy się do magazynu ${warehouse.get('name')}..`);

            if (transport.get('stepsWithWarehouse').length > 1) {
              self.set('showMultipleTimeWindowsModal', true);
            }

            return self.transitionToRoute('warehouses.schedule', warehouse.get('id'), scheduleDateString, '1');
          }
        }

        const transportToHandle = self.get('transportToHandle');
        const transportToHandleId = self.get('transportToHandle.id');

        if (transportToHandleId) {
          self.initializeCustomFields(transportToHandle).then(() => {
            const transport = self.get('transportToHandle.content') ? self.get('transportToHandle.content') : self.get('transportToHandle');
            const path = self.get('apolloApiService').APOLLO_API.TRANSPORTS.VALIDATE;
            self.get('apolloApiService').callApolloApi(path, {
              id: transportToHandleId
            }, {
              body: JSON.stringify(transport.serialize())
            }, null).then(response => response.json()).then(response => {
              let errors = [];

              if (response.errors) {
                errors = response.errors.uniq();
              }

              const responseMeta = errors.get('firstObject.meta');

              if (responseMeta) {
                const exceptionInformation = Ember.ObjectProxy.create({
                  initialStart: responseMeta.initialStart,
                  windowSizeInMinutes: responseMeta.windowSizeInMinutes,
                  rampName: responseMeta.rampName,
                  whId: responseMeta.warehouseId
                });
                transport.set('exceptionInformation', exceptionInformation);
              }

              self.set('scheduleClicked', false);

              if (transport.get('transportType.selectionOfWindowBeforeAdvice')) {
                if (transport.get('stepsWithWarehouse').length > 1) {
                  self.send('showModal', 'time-windows-summary-modal', {
                    transportToHandle
                  });
                }

                if (errors.length > 0) {
                  self.set('errors', errors[0]);

                  if (errors[0].meta.exceptionType !== 'WINDOWS_OVERLAP_EXCEPTION') {
                    transport.get('timeWindows').forEach(timeWindow => {
                      timeWindow.rollbackAttributes();
                    });
                  }

                  self.send('openCreateAdviceModal', errors, transport);
                  return;
                }

                self.send('createTimeWindow', transport);
              } else {
                self.send('openCreateAdviceModal', errors, transport);
              }
            });
          });
        } else {
          self.send('createAdvice');
        }
      },

      createTimeWindow(transport) {
        const self = this;
        transport.validate();

        if (transport.get('hasErrors')) {
          return;
        }

        transport.save().then(() => {
          console.log(`Window for transport ${transport.get('id')} has been saved.`);
          self.unloadRedundantTimeWindows(transport);
          transport.reload().then(() => self.handleTransportReload(transport, self));
          const info = self.get('intl').t('warehouse.adviceHasBeenSavedInfo') + moment(transport.get('earliestTimeWindowStart')).format('HH:mm');
          self.set('info', info);
          self.set('warehouseStepToHandle', null);
          self.set('model.errors', []);
        }).catch(response => {
          const errors = response.errors;
          self.set('model.errors', errors);
        });
      },

      openCreateAdviceModal(errors, transport) {
        const self = this;
        transport.get('advice').then(a => {
          if (!a && !transport.get('transportType.selectionOfWindowBeforeAdvice')) {
            self.send('setAdviceForTransport', transport);
          }

          const hasErrors = errors.length >= 1;
          let exceptionType = null;

          if (hasErrors) {
            if (errors[0].meta) {
              exceptionType = errors[0].meta.exceptionType;
            }
          }

          self.send('showModal', 'advice.modals.create-advice', {
            transport,
            errors: errors || [],
            showSearchSpotsButton: self.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)
          });
        });
      },

      setAdviceForTransport(transport) {
        const settingValue = this.get('sessionAccount').getSettingValue('SHOW_TERMS_OF_CONDITIONS_ACCEPTED');
        const advice = this.get('store').createRecord('advice', {
          requiresAcceptanceOfTermsOfCondition: settingValue === 'true'
        });
        transport.set('advice', advice);
      },

      /**
       * metoda wykonywana po kliknięciu w nagłówek rampy.
       * Jeżeli na rampie nie ma okien, utworzymy blokade na całym dniu.
       * W przeciwnym razie, jeżeli są tylko okna blokady, usuniemy je, por. {@link unblockRamp}.
       * @param time
       * @param rampId
       */
      toggleWholeRampWindow: function (time, rampId) {
        console.log('toggleWholeRampWindow');
        const self = this;

        if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          const start = this.get('warehouse').getStartOnDay(time);
          const stop = this.get('warehouse').getEndOnDay(time);
          const windowsOnRamp = self.get('windows').filter(window => {
            return window.get('ramp.id') === rampId && window.get('start') >= start && window.get('stop') <= stop && !window.get('deleted') && !window.get('isAutoBlockade');
          });

          if (windowsOnRamp.get('firstObject') == null) {
            self.storeDisabledWindow(start, stop, rampId);
          } else {
            self.unblockRamp(windowsOnRamp);
          }
        } else {
          console.log('without producer role: ramp click');
        }
      },

      createAdvice() {
        const self = this;
        const transport = this.get('transportToSave');
        transport.set('advice', self.removeWhiteSpacesAndSetUpperCase(transport.get('advice')));
        transport.get('customFields').filterBy('definition.isNumberType').forEach(pair => {
          if (pair.get('value')) {
            pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
          }
        });
        const principalCompany = this.store.peekRecord('company', localStorage.getItem('principalId'));

        if (!transport.get('carrierCompany.name')) {
          transport.set('carrierCompany', null);
        }

        transport.set('principalCompany', principalCompany);
        transport.save().then(function () {
          const transportId = transport.id;
          console.log(`We successfully saved transport ${transport.get('logString')}!`);
          self.set('scheduleClicked', false);
          self.send('removePopupDialog');
          const token = self.get('sessionAccount').get('token');

          if (self.attachments) {
            console.debug(`Saving files for ${transportId}`);
            self.uploadAttachment(transportId, token);
          }

          const steps = transport.get('steps');
          const stepsArray = steps.toArray();
          stepsArray.forEach(function (s) {
            if (s === undefined || !s.id) {
              steps.removeObject(s);

              if (s !== undefined) {
                console.debug(`Unloading redundant step for newly saved transport ${transportId}..`);
                s.unloadRecord();
              }
            }
          });
          const customFields = transport.get('customFields');
          const customFieldsArray = customFields.toArray(); // eslint-disable-next-line array-callback-return

          customFieldsArray.map(function (cf) {
            if (cf === undefined || !cf.id) {
              customFields.removeObject(cf);

              if (cf !== undefined) {
                console.debug(`Unloading redundant custom field for newly saved transport ${transportId}..`);
                cf.unloadRecord();
              }
            }
          });
          transport.get('timeWindows').then(timeWindows => {
            const timeWindowsCopy = timeWindows.slice(0);
            timeWindowsCopy.forEach(tw => {
              if (tw !== undefined && !tw.get('id')) {
                timeWindows.removeObject(tw);
                console.debug(`Unloading redundant time window for newly saved transport ${transportId}..`);
                tw.unloadRecord();
              }
            });
          });
          transport.get('indexes').then(indexes => {
            const indexesCopy = indexes.slice(0);
            indexesCopy.forEach(index => {
              if (index !== undefined && !index.get('id')) {
                indexes.removeObject(index);
                console.debug(`Unloading redundant index for newly saved transport ${transportId}..`);
                index.unloadRecord();
              }
            });
          });
          transport.get('courierPackages').then(cps => {
            const cpCopy = cps.slice(0);
            cpCopy.forEach(cp => {
              if (cp !== undefined && !cp.get('id')) {
                cps.removeObject(cp);
                console.debug(`Unloading redundant courierPackages for newly saved transport ${transportId}..`);
                cp.unloadRecord();
              }
            });
          });
          transport.get('transportType').set('forcedPollRequestTime', new Date().getTime());
          self.send('onPoll');
          const createTransportController = self.get('createTransport');
          createTransportController.setProperties({
            model: null,
            errors: [],
            attachments: []
          });
          self.send('hideModal');
          self.setProperties({
            info: self.get('intl').t('warehouse.adviceHasBeenSavedInfo') + moment(transport.get('earliestTimeWindowStart')).format('HH:mm'),
            warningMessage: null,
            attachments: [],
            transportToSave: null,
            warehouseStepToHandle: null,
            scheduleClicked: false
          });
        }).catch(response => {
          const errors = response.errors;
          const responseMeta = errors[0].meta;
          const exceptionType = responseMeta ? responseMeta.exceptionType : null;

          if (responseMeta) {
            const exceptionInformation = Ember.ObjectProxy.create({
              initialStart: responseMeta.initialStart,
              windowSizeInMinutes: responseMeta.windowSizeInMinutes,
              rampName: responseMeta.rampName
            });
            transport.set('exceptionInformation', exceptionInformation);
          }

          self.set('scheduleClicked', false);
          self.get('createTransport').setProperties({
            errors,
            showSearchSpotsButton: self.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)
          });
          transport.get('steps').filterBy('timeWindow.start').forEach(s => {
            s.set('timeWindow', null);
          });
          transport.get('timeWindows').filterBy('id', null).forEach(tw => {
            tw.setProperties({
              transport: null,
              step: null,
              deleted: null
            });
          });
          self.send('showTransportDetails', transport);
        });
      },

      showTransportDetails: function (transport) {
        console.debug(`Otwieramy okienko ze szczegółami transportu ${transport.get('logString')} z poziomu harmonogramu...`);
        this.set('openTransportId', transport.get('id'));
        this.set('editedTransportType', transport.get('transportType'));
        this.send('showModal', 'transports.modals.create-transport', transport);
      },
      showLoadingModal: function () {
        this.send('showModal', 'transports.modals.loading-data');
      },
      createBlockedWindow: function (start, stop, rampId) {
        this.set('scheduleClicked', true);
        const isDedicatedWindow = !!this.get('company');

        if (isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_MANAGE_DEDICATED_WINDOWS')) {
          console.log('User tried to save a dedicated window, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        } else if (!isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          console.log('User tried to save a blockade, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        }

        const laterWindows = this.get('windows').filter(w => {
          return parseInt(w.get('ramp.id')) === rampId && w.get('start') > start;
        }); // System will try to fit a blockade window if there is not enough space to save it with default length,
        // i.e. user tries to save a 60-min. window at 14:00, but there is already other window at 14:30 -
        // in this case, instead of throwing an error, system will save a 30-min. window.

        if (!this.get('warehouse.timeWindowMustOccupyEntireRamp') && laterWindows.length) {
          const laterWindow = laterWindows.sortBy('start').get('firstObject');
          const laterWindowStart = laterWindow.get('start');
          stop = laterWindowStart < stop ? laterWindowStart : stop;
        } // [A-10607] Gdy okno blokady ustawiamy na koniec dnia magazynu, w przypadku magazynów działających przez 24h
        // odejmujemy sekundę, wtedy okno, które zapiszemy będzie kończyć się tego samego dnia o godzinie 23:59:59
        // (a nie dnia następnego o 00:00:00)


        if (!(start instanceof moment)) {
          start = moment(start);
        }

        if (!(stop instanceof moment)) {
          stop = moment(stop);
        }

        if (start.get('date') < stop.get('date') && stop.get('hour') === 0) {
          stop.subtract(1, 'seconds');
        }

        this.storeDisabledWindow(start, stop, rampId);
      },
      removeBlockedWindow: function (window) {
        const isDedicatedWindow = window.get('isDedicated');

        if (isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_MANAGE_DEDICATED_WINDOWS')) {
          console.log('User tried to remove a dedicated window, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        } else if (!isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          console.log('User tried to remove a blockade, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        }

        const removingAlert = this.findAlertDuringRemovingWindow(window);

        if (removingAlert && !confirm(removingAlert)) {
          return;
        }

        this.send('destroyWindow', window);
      },
      destroyWindow: function (window) {
        const self = this;
        window.deleteRecord();
        window.save().then(function (w) {
          self.send('removePopupDialog');
          self.get('windows').removeObject(w);
          self.get('store').unloadRecord(window);
          self.set('errors', null);
        }).catch(response => {
          self.set('errors', response.errors);
        });
      },

      shiftScheduleLeft(days = 1) {
        const newStartAsString = this.get('scheduleStartDate').subtract(days, 'days').format('YYYY-MM-DD');
        this.transitToDate(newStartAsString);
      },

      shiftScheduleRight(days = 1) {
        const newStartAsString = this.get('scheduleStartDate').add(days, 'days').format('YYYY-MM-DD');
        this.transitToDate(newStartAsString);
      },

      today() {
        this.transitToDate(moment().startOf('day').format('YYYY-MM-DD'));
      },

      transitToDelta(delta = 1) {
        const warehouseId = this.get('warehouse.id');
        this.get('applicationController').setLocalDelta(delta);
        const dateString = this.get('scheduleStartDate').format('YYYY-MM-DD');
        this.transitionToRoute('warehouses.' + this.get('routeName'), warehouseId, dateString, delta);
      },

      showTransportToSave() {
        (0, _jquery.default)('.modal').show();
        $('body').addClass('modal-open');
      },

      openNoteWindow(window) {
        this.send('showModal', 'time-window.modals.window-note', window);
      },

      openIndexesDetailsWindow(transport) {
        this.send('showModal', 'indexes.modals.indexes-details', transport);
      },

      cancelTransportCreation() {
        const self = this;
        const editedWindow = this.get('editedWindow');

        if (editedWindow) {
          const editedWindowModel = this.store.peekRecord('timeWindow', editedWindow.get('id'));
          editedWindowModel.unlockForEditing();

          if (editedWindow.content) {
            editedWindow.content.restorePlace();
          } else {
            if (this.get('dedicatedTimeWindowToHandle')) {
              this.set('dedicatedTimeWindowToHandle', '');
            }

            editedWindow.restorePlace();
          }
        } else {
          self.handleStepsUpdate(this.get('transportToHandle'));
          self.removeUnsavedTimeWindows(this.get('transportToHandle'));
        }

        this.transportCombiner.decombineAllRelatedTransports(this.get('transportToHandle'));
        this.setProperties({
          warningMessage: null,
          transportToSave: null,
          warehouseStepToHandle: null
        });
      },

      setSelectedDateString(date) {
        this.set('selectedDateString', moment(date[0]).local().format('YYYY-MM-DD'));
      },

      setWarehouse(warehouse) {
        const chosenWarehouse = this.get('store').peekRecord('warehouse', warehouse.get('id'));
        this.set('model.warehouse', chosenWarehouse);

        if (this.get('warehouseStepToHandle')) {
          this.set('warehouseStepToHandle.warehouse', chosenWarehouse);
        }
      }

    }
  });

  _exports.default = _default;
});