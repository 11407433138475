define("apollo/pods/companies/ramp-edition/controller", ["exports", "apollo/mixins/ramps-access-mixin", "jquery"], function (_exports, _rampsAccessMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_rampsAccessMixin.default, {
    CHECKBOXES_DEFAULT_CHECKED: false,
    updateForAllUsers: true,
    store: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    intl: Ember.inject.service(),
    errors: [],
    successMessage: null,
    company: Ember.computed('model.company.id', function () {
      return this.get('model.company');
    }),
    principalCompany: Ember.computed(function () {
      const principalId = localStorage.getItem('principalId');
      return this.get('store').peekRecord('company', principalId);
    }),
    companyRamps: Ember.computed('model.companyRamps.[]', function () {
      return this.get('model.companyRamps');
    }),
    setProxiedWarehouses: Ember.observer('proxiedWarehouses.length', 'model', function () {
      const self = this;
      Ember.run.once(this, function () {
        self.checkWarehouses();
      });
    }),
    setProxiedRamps: Ember.observer('proxiedRamps.length', 'model', function () {
      const self = this;
      Ember.run.once(this, function () {
        self.checkRamps();
      });
    }),
    allRampsChecked: Ember.computed('proxiedRamps.length', 'checkedRamps.length', function () {
      return this.get('proxiedRamps.length') === this.get('checkedRamps.length');
    }),

    checkWarehouses() {
      const proxiedWarehouses = this.get('proxiedWarehouses');
      const warehouseIds = this.get('companyRamps').getEach('ramp.warehouse.id').uniq();
      proxiedWarehouses.forEach(w => w.set('checked', warehouseIds.includes(w.get('id'))));
    },

    checkRamps() {
      const proxiedRamps = this.get('proxiedRamps');
      const userRampsIds = this.get('companyRamps').getEach('ramp.id').uniq();
      proxiedRamps.forEach(r => r.set('checked', userRampsIds.includes(r.get('id'))));
    },

    setSuccessMessage: function () {
      this.set('successMessage', this.get('intl').t('common.saveSuccessMessage'));
      this.set('errors', []);
      (0, _jquery.default)('html, body').animate({
        scrollTop: 0
      }, 'slow');
      this.resetButton();
    },
    setErrorMessage: function (message) {
      this.set('successMessage', null);
      this.set('errors', message);
      (0, _jquery.default)('html, body').animate({
        scrollTop: 0
      }, 'slow');
      this.resetButton();
    },
    resetButton: function () {
      const button = (0, _jquery.default)("button[data-button-name='save-ramps']");
      button.button('reset');
    },
    actions: {
      toggleAllRamps: function () {
        const shouldBeChecked = !this.get('allRampsChecked');
        this.get('proxiedWarehouses').forEach(w => {
          w.set('checked', shouldBeChecked);
          w.get('proxiedRamps').forEach(r => r.set('checked', shouldBeChecked));
        });
      },

      async save() {
        const updateForAllUsers = this.get('updateForAllUsers');
        const hasUsers = this.get('company.users.length') > 0;

        if (updateForAllUsers && hasUsers && !confirm(this.get('intl').t('companyRamp.updateForAllUsersConfirmMsg'))) {
          this.resetButton();
          return;
        }

        const checkedRamps = this.get('proxiedRamps').filter(r => r.checked).getEach('id');
        const companyId = this.get('company.id');
        const principalCompanyId = this.get('principalCompany.id');
        const data = JSON.stringify({
          ramps: checkedRamps,
          company: companyId,
          principalCompany: principalCompanyId,
          updateForAllUsers
        });
        const path = this.get('apolloApiService').APOLLO_API.COMPANY_RAMPS.SAVE;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, null, {
            body: data
          });

          if (response.ok) {
            this.setSuccessMessage();
          } else {
            const error = await response.json();
            this.setErrorMessage(error);
          }
        } catch (error) {
          this.setErrorMessage([this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },

      cancel() {
        this.transitionToRoute('companies.show', this.get('company.id'));
      },

      refreshUsers() {
        this.store.query('user', {
          individualRampAccess: true,
          companyId: this.get('company.id')
        }).then(users => {
          Ember.set(this.get('model'), 'users', users);
        });
      }

    }
  });

  _exports.default = _default;
});